import { Box } from '@mui/material';
import { MouseEvent } from 'react';
import { RosterStation } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface StationHeaderProps {
  station: RosterStation;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  children?: React.ReactNode;
  anchorEl?: HTMLElement | null;
  actions?: React.ReactNode;
  certificationRequirements?: React.ReactNode;
}

export const StationHeader = ({
  station,
  onClick,
  anchorEl,
  actions,
  certificationRequirements,
  children,
}: StationHeaderProps) => {
  return (
    <Box>
      <Box
        data-cy={`station-header-${makeTestIdentifier(station.stationName)}`}
        sx={(theme) => ({
          background: station.stationId === 'floater-station' ? theme.palette.stationGray[200] : theme.palette.common.white,
          display: certificationRequirements ? 'inherit' : 'flex',
          justifyContent: certificationRequirements ? 'inherit' : 'space-between',
          border:
            station.stationId === 'floater-station'
              ? `3px solid ${theme.palette.stationGray[400]}`
              : anchorEl
                ? `3px solid ${theme.palette.stationGray[300]}`
                : `3px solid ${theme.palette.stationGray[100]}`,
          p: 1.5,
          borderRadius: 1.5,
          boxShadow: '0px 1px 3px rgba(0,0,0,0.2)',
          cursor: onClick ? 'pointer' : undefined,
          mb: 1.5,
          whiteSpace: 'nowrap',
          ...(onClick && {
            '&:hover': { border: `3px solid ${theme.palette.stationGray[300]}` },
          }),
          [theme.breakpoints.up('sm')]: { mb: 3, maxWidth: '275px' },
        })}
        onClick={onClick}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            color: theme.palette.stationGray[500],
            position: 'relative',
            typography: 'bodySMedium',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.up('sm')]: { typography: 'bodyXSSemibold' },
          })}
        >
          <Box>{station.stationName.toUpperCase()}</Box>
          {actions && <Box>{actions}</Box>}
        </Box>
        {certificationRequirements}
        {children}
      </Box>
    </Box>
  );
};
