import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '@stationwise/component-module';

interface FooterProps {
  setStep: Dispatch<SetStateAction<number>>;
  selectedPerson: string;
  handleSubmit: () => void;
}

export const Footer = ({ selectedPerson, handleSubmit, setStep }: FooterProps) => {
  return (
    <Box
      sx={(theme) => ({
        justifyContent: 'space-between',
        display: 'flex',
        gap: theme.spacing(2),
        mt: theme.spacing(2),
        width: '100%',
        pt: theme.spacing(2),
        pb: theme.spacing(3),
      })}
    >
      <Button sx={{ flex: 1 }} variant="outlined" size="large" onClick={() => setStep(1)}>
        Previous
      </Button>
      <Button sx={{ flex: 1 }} variant="contained" size="large" onClick={() => handleSubmit()} disabled={selectedPerson === ''}>
        Submit
      </Button>
    </Box>
  );
};
