import { Box, Modal } from '@mui/material';
import React, { useState } from 'react';
import { Button, ErrorBanner, SnackbarService, theme, XIcon24 } from '@stationwise/component-module';
import { axios, client } from '@stationwise/share-api';
import { WorkGroupPreview } from '@stationwise/share-types';
import { WorkGroupGrid } from './WorkGroupGrid';

interface WorkGroupModalProps {
  workGroupsView: WorkGroupPreview[];
  title: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  selectedEmployeeIds?: string[];
  updateFunc: React.Dispatch<React.SetStateAction<number>>;
  setSelectedEmployeeIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const WorkGroupModal = ({
  title,
  showModal,
  setShowModal,
  setTitle,
  workGroupsView,
  selectedEmployeeIds,
  updateFunc,
  setSelectedEmployeeIds,
}: WorkGroupModalProps) => {
  const [clicked, setClicked] = useState<number>(-1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const requestAddEmployee = async () => {
    try {
      await client.post(`/organization/workgroups-members/${clicked}/add-employees-to-group/`, {
        employeeIds: selectedEmployeeIds?.map(Number),
      });

      const workGroup = workGroupsView.find((workGroup) => workGroup.id === clicked);
      updateFunc((pre) => pre + 1);
      SnackbarService.notify({
        content: `${selectedEmployeeIds?.length ?? 0} employees added to the ${workGroup?.name ?? 'newly created'} work group.`,
        severity: 'success',
        duration: 3000,
      });
      setSelectedEmployeeIds([]);
      setShowModal(false);
    } catch (error) {
      const message = axios.isAxiosError(error)
        ? error.response?.data?.message || error.message
        : 'An unexpected error occurred.';
      SnackbarService.notify({
        content: message,
        severity: 'error',
        duration: 5000,
      });
      setErrorMessage(message);
      setIsSubmitting(false);
    }
  };

  const handleOps = () => {
    setIsSubmitting(true);
    requestAddEmployee();
  };

  const handleClose = () => {
    setClicked(-1);
    setShowModal(false);
    if (setTitle) setTitle('New work group');
  };
  const errorBanner = !!errorMessage && (
    <ErrorBanner
      sx={{
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        mx: 40,
        height: 'inherit',
      }}
    >
      {errorMessage}
    </ErrorBanner>
  );

  return (
    <Modal
      sx={(theme) => ({
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',

        background: theme.palette.stationGray[200],
      })}
      open={showModal}
    >
      <Box
        sx={{
          display: 'flex',
          height: 'auto',
          width: 'auto',
          my: 9,
          mx: 45,
          gap: theme.spacing(1),
          borderRadius: '16px',
          backgroundColor: theme.palette.common.white,
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            padding: '24px 24px 0px 24px',
            alignSelf: 'stretch',
            borderRadius: '16px 16px 0px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: theme.spacing(1),
            }}
          >
            <Box
              sx={(theme) => ({
                width: '424px',
                typography: 'bodyXLSemibold',
                color: theme.palette.stationGray[900],
              })}
            >
              {title}
            </Box>
            <Box
              sx={(theme) => ({
                width: '448px',
                typography: 'bodyMRegular',
                color: theme.palette.stationGray[700],
              })}
            >
              Select working group
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              width: '32px',
              height: '32px',
              padding: theme.spacing(0.5),
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              position: 'absolute',
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.stationGray[400],
              '&:hover': {
                cursor: 'pointer',
                color: theme.palette.stationBlue[400],
              },
            })}
            onClick={() => handleClose()}
          >
            <XIcon24 />
          </Box>
        </Box>

        <WorkGroupGrid workGroupsView={workGroupsView} clicked={clicked} setClicked={setClicked} />
        <Box
          sx={{
            height: '24px',
          }}
        >
          {errorBanner}
        </Box>

        <Box
          sx={{
            height: '24px',
            alignSelf: 'stretch',
            boxShadow: '0px -4px 6px -1px rgba(10, 14, 22, 0.10), 0px -2px 4px -1px rgba(10, 14, 22, 0.06)',
          }}
        ></Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0px 24px 24px 939px',
            height: '68px',
            width: '100%',

            alignItems: 'center',
            alignSelf: 'stretch',
            borderRadius: '0px 0px 16px 16px',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(1.5),
              alignItems: 'flex-start',
            })}
          >
            <Button variant="outlined" size="large" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="contained" size="large" onClick={() => handleOps()} disabled={clicked === -1} loading={isSubmitting}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
