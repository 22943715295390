import { Box, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { TeamFlag, CertBadge, Loader, NoEligibleShiftTrade, SearchInput, useRosterContext } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ForceShiftTradeEligibleEmployee } from '@stationwise/share-types';
import { getBoardEmployees } from '@stationwise/shift-summary-helper';

interface SelectAvailableEmployeeProps {
  receiverEmployee: ForceShiftTradeEligibleEmployee | null;
  setReceiverEmployee: Dispatch<SetStateAction<ForceShiftTradeEligibleEmployee | null>>;
  handleSubmit: (allPersonnel?: string[]) => void;
  setError?: Dispatch<SetStateAction<boolean>>;
  employeeId: string;
  start: Date;
  end: Date;
}

const capitalizeFirstLetter = (str: string) => {
  if (str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return '';
  }
};

export const SelectAvailableEmployee = ({
  receiverEmployee,
  setReceiverEmployee,
  setError,
  employeeId,
  start,
  end,
}: SelectAvailableEmployeeProps) => {
  const [searchText, setSearchText] = useState('');
  const [availableEmployees, setAvailableEmployees] = useState<ForceShiftTradeEligibleEmployee[]>([]);
  const [isAvailableEmployeesLoading, setIsAvailableEmployeesLoading] = useState(true);
  const { shiftSummaryHelper } = useRosterContext();

  const getEligibleEmployees = async () => {
    try {
      const date = format(shiftSummaryHelper.shiftDuration.startTime, 'yyyy-MM-dd');
      const response = await client.get(`request/shift-trade-request/eligible-employees/?date=${date}&employeeId=${employeeId}`);
      return response.data.employees;
    } catch (error) {
      if (setError) {
        captureException(error);
        setError(true);
      }
    }
  };

  useEffect(() => {
    const getEmployees = async () => {
      const eligibleEmployees = await getEligibleEmployees();
      setAvailableEmployees(eligibleEmployees || []);
      setIsAvailableEmployeesLoading(false);
    };

    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (employee: ForceShiftTradeEligibleEmployee) => {
    setReceiverEmployee(employee);
  };

  const filteredAvailableEmployees = useMemo(() => {
    const employees = getBoardEmployees(shiftSummaryHelper);
    const unavailableEmployees = employees.filter((e) => e.startDateTime < end && e.endDateTime > start);
    const unavailableEmployeeIds = new Set(unavailableEmployees.map((e) => e.id));
    return availableEmployees.filter((e) => !unavailableEmployeeIds.has(`${e.id}`));
  }, [start, end, shiftSummaryHelper, availableEmployees]);

  return (
    <Box
      alignItems="center"
      width={'100%'}
      sx={{
        height: 'auto',
      }}
    >
      {filteredAvailableEmployees.length > 0 && (
        <Box>
          <Box
            width={'100%'}
            sx={(theme) => ({
              typography: 'bodyLMedium',
              mb: theme.spacing(1),
            })}
          >
            Select person
          </Box>
          <Box>
            <SearchInput value={searchText} setValue={setSearchText} color={'white'} />
          </Box>

          <Box
            width={'100%'}
            sx={(theme) => ({
              color: theme.palette.stationGray[500],
              mb: theme.spacing(2),
              mt: theme.spacing(2),
            })}
          >
            Eligible Personnel
          </Box>

          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            <Box
              sx={(theme) => ({
                mb: theme.spacing(5),
              })}
            >
              {filteredAvailableEmployees
                .filter((employee) =>
                  `${employee.firstName} ${employee.lastName}`.toLowerCase().includes(searchText.toLowerCase()),
                )
                .map((employee, idx) => (
                  <Box
                    key={idx}
                    onClick={() => handleSelect(employee)}
                    sx={(theme) => ({
                      p: theme.spacing(1),
                      border:
                        receiverEmployee?.id === employee.id
                          ? `solid 2.5px ${theme.palette.stationGray[900]}`
                          : `solid 2px ${theme.palette.stationGray[100]}`,
                      borderRadius: '8px',
                      mb: theme.spacing(1),
                    })}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={(theme) => ({
                        ml: theme.spacing(1),
                      })}
                    >
                      <Box
                        sx={(theme) => ({
                          color: theme.palette.stationGray[900],
                          typography: 'bodyMMedium',
                        })}
                      >
                        {`${employee.firstName} ${employee.lastName}`}
                      </Box>
                      <Box
                        display="flex"
                        gap={0.5}
                        sx={(theme) => ({
                          minHeight: theme.spacing(2.5),
                          flexFlow: 'row wrap',
                        })}
                      >
                        {employee.certifications.map((cert, index) => (
                          <CertBadge key={`${index}-${cert.code}`} cert={cert} />
                        ))}
                      </Box>
                    </Box>
                    <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', gap: 1, ml: theme.spacing(1) })}>
                      <TeamFlag color={employee.team?.color} />
                      <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodySMedium' })}>
                        {capitalizeFirstLetter(employee?.team?.name ?? '')}
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      )}
      {!isAvailableEmployeesLoading && availableEmployees.length === 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <NoEligibleShiftTrade />
          <Typography
            sx={(theme) => ({ mt: theme.spacing(4), mb: theme.spacing(2) })}
            textAlign={'center'}
            variant="bodyXXLSemibold"
          >
            No users eligible based on <br /> rank and certification <br /> requirements
          </Typography>
          <Typography sx={(theme) => ({ color: theme.palette.stationGray[500] })} variant="bodyMMedium">
            Please choose another date to trade.
          </Typography>
        </Box>
      )}
      {isAvailableEmployeesLoading && availableEmployees.length === 0 && <Loader sx={{ mt: 2 }} />}
    </Box>
  );
};
