import { RosterDataSource, RosterStation, ShiftPlanStruct } from '@stationwise/share-types';

export const requireStation = ({ station }: ShiftPlanStruct) => {
  if (!station) {
    throw new Error('Station required on ShiftPlanStruct');
  }
  return { station };
};

export const upsertStation = (stations: Map<string, RosterStation>, struct: ShiftPlanStruct, newData: Partial<RosterStation>) => {
  let newStation = struct.station || {
    stationId: `${-Date.now()}`,
    stationName: '',
    dataSource: RosterDataSource.STATION,
    apparatuses: [],
    certificationRequirements: [],
    address: { street1: '', street2: '', city: '', state: '', postalCode: '' },
    isNewlyCreated: true,
  };
  newStation = { ...newStation, ...newData };
  newStation.certificationRequirements = newStation.certificationRequirements.filter((certReq) => certReq.amount > 0);

  const newStations = new Map(stations);
  newStations.set(newStation.stationId, newStation);

  const newFloaterStation = newStations.get('floater-station');
  if (newStation.isNewlyCreated && newFloaterStation) {
    // Add newly created station before floater station.
    newStations.delete(newFloaterStation.stationId);
    newStations.set(newFloaterStation.stationId, newFloaterStation);
  }

  return newStations;
};

export const removeStation = (stations: Map<string, RosterStation>, struct: ShiftPlanStruct) => {
  const { station } = requireStation(struct);

  const newStations = new Map(stations);
  newStations.delete(station.stationId);
  return newStations;
};
