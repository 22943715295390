import { ReactComponent as KeyboardArrowDownSymbol } from '@material-symbols/svg-400/outlined/keyboard_arrow_down.svg';
import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { useFetchStaffingLists } from '../../../hooks';
import { Button } from '../../Button';
import { SvgIcon } from '../../SvgIcon';

interface ActionButtonProps {
  requestOvertimeDisabled?: boolean;
  handleRequestOvertimeClick?: (staffingList: ListFieldsStaffingList) => void;
  setCreateIncidentOpen?: (value: boolean) => void;
  setCreateIncident: (value: boolean) => void;
}

export const ActionButton = ({
  requestOvertimeDisabled,
  handleRequestOvertimeClick,
  setCreateIncidentOpen,
  setCreateIncident,
}: ActionButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isLoading: staffingListsIsLoading, data } = useFetchStaffingLists();
  const staffingLists = data?.map((sl: ListFieldsStaffingList) => sl) || [];
  const disabled = requestOvertimeDisabled || staffingListsIsLoading;

  const filteredStaffingLists = staffingLists.filter((sl) => sl.isOptOut || sl.isSignUp);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-cy="request-overtime-button"
        endIcon={<SvgIcon component={KeyboardArrowDownSymbol} />}
        variant="contained"
        disabled={disabled}
        onClick={handleClick}
      >
        Sign Up
      </Button>
      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {filteredStaffingLists.map((staffingList: ListFieldsStaffingList) => (
          <MenuItem
            key={`Request_${staffingList.id}`}
            data-cy={`request-staffing-list-${makeTestIdentifier(staffingList.name)}`}
            onClick={(event) => {
              setCreateIncident(false);
              setCreateIncidentOpen && setCreateIncidentOpen(false);
              if (handleRequestOvertimeClick) handleRequestOvertimeClick(staffingList);
              handleClose(event);
            }}
            sx={{
              typography: 'bodySMRegular',
            }}
          >
            {`${staffingList.isOptOut ? 'Opt out from ' : 'Sign up for '}${staffingList.name}`}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
