import { Box } from '@mui/material';
import { ComponentPropsWithoutRef } from 'react';
import { Certification } from '@stationwise/share-types';
import { CertBadge } from '../Badge';
import { CardTooltip } from './CardTooltip';

const INVISIBLE_CERT: Pick<Certification, 'name' | 'code' | 'color'> = { name: '\xa0', code: '\xa0', color: 'gray' };

interface CardCertBadgesProps extends Omit<ComponentPropsWithoutRef<typeof CertBadge>, 'cert'> {
  certs: Pick<Certification, 'name' | 'code' | 'color'>[];
}

export const CardCertBadges = ({ certs, sx, ...props }: CardCertBadgesProps) => {
  if (!certs.length) {
    return (
      <Box
        sx={{
          display: 'inline-flex',
          overflow: 'hidden',
          userSelect: 'none',
          visibility: 'hidden',
          whiteSpace: 'nowrap',
          width: 0,
        }}
      >
        <CertBadge cert={INVISIBLE_CERT} {...props} />
      </Box>
    );
  }

  return (
    <CardTooltip
      anchorContent={certs.map((cert, index) => (
        <CertBadge key={`${index}-${cert.code}`} cert={cert} {...props} />
      ))}
      anchorSx={[{ gap: 0.5, position: 'relative', overflow: 'hidden' }, ...(Array.isArray(sx) ? sx : sx ? [sx] : [])]}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      popoverSx={(theme) => ({
        color: theme.palette.stationGray[900],
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1.5,
        p: 2,
      })}
      popoverContent={certs.map((cert, index) => (
        <Box key={`${index}-${cert.code}`} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CertBadge cert={cert} {...props} />
          <Box sx={{ typography: 'bodySRegular' }}>{cert.name}</Box>
        </Box>
      ))}
    />
  );
};
