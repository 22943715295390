import { RosterDataSource } from '@stationwise/share-types';
import {
  checkIsPlannedEmployee,
  getDepartmentPayCodes,
  makeAssignmentSplit,
  setEmployeeActiveId,
} from '@stationwise/shift-summary-helper';
import { SplitProps } from '../types';
import { setSplits } from './general';

export const cancelTimeOffSplit = (props: SplitProps) => {
  const { departmentInfo } = props.shiftSummaryHelper;
  if (props.split.reference.type === 'TIME_OFF_REQUEST') {
    const timeOffId = props.split.reference.id;
    const newSplits = [...props.splits];
    if (props.split.backup.reference.type === 'ASSIGNMENT' && timeOffId <= 0) {
      const newAssignmentSplit = { ...props.split, reference: props.split.backup.reference };
      newSplits[props.index] = { ...newAssignmentSplit, backup: newAssignmentSplit };
    } else if (!checkIsPlannedEmployee(props.shiftSummaryHelper, props.employee)) {
      newSplits.splice(props.index, 1);
    } else {
      const newFloater = setEmployeeActiveId({
        ...props.employee,
        dataSource: RosterDataSource.FLOATER,
        startDateTime: props.split.startDateTime,
        endDateTime: props.split.endDateTime,
        payCodes: getDepartmentPayCodes(departmentInfo, [props.employee.defaults.regularAssignmentPayCode]),
        detailCodes: [],
        trade: null,
      });
      const newFloaterSplit = makeAssignmentSplit(props.shiftSummaryHelper, null, null, null, newFloater);
      newSplits[props.index] = { ...newFloaterSplit, backup: newFloaterSplit };
    }

    setSplits(props, newSplits);
    if (timeOffId > 0) {
      props.setCancelTimeOffPayloads((p) => [...p, { timeOffId }]);
    }
  }
};

export const createTimeOffSplit = (props: SplitProps) => {
  const position = props.split.backup.reference.type === 'ASSIGNMENT' ? props.split.backup.reference.position : null;
  const newSplits = [...props.splits];
  newSplits[props.index] = {
    ...props.split,
    reference: {
      type: 'TIME_OFF_REQUEST',
      id: 0,
      payCodeId: 0,
      positionId: Number(position?.id) || null,
    },
  };
  setSplits(props, newSplits);
};

export const updateTimeOffSplitReason = (props: SplitProps, payCodeId: number) => {
  if (props.split.reference.type === 'TIME_OFF_REQUEST') {
    const newSplits = [...props.splits];
    newSplits[props.index] = {
      ...props.split,
      reference: { ...props.split.reference, payCodeId },
    };
    setSplits(props, newSplits);
  }
};
