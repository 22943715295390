import { Box } from '@mui/material';
import { Button } from '@stationwise/component-module';
import { useShiftPlanContext } from '../ShiftPlanContext';

interface ShiftPlanDialogActionsProps {
  isSaveDisabled?: boolean;
  saveText?: string;
  onSave: () => void;
}

export const ShiftPlanDialogActions = (props: ShiftPlanDialogActionsProps) => {
  const { selectedAction, setSelectedAction } = useShiftPlanContext();

  return (
    <Box sx={{ display: 'flex', gap: 2, p: 3, '& > *': { flex: 1 } }}>
      <Button variant="outlined" size="large" onClick={() => setSelectedAction(null)}>
        Cancel
      </Button>
      <Button variant="contained" size="large" disabled={props.isSaveDisabled || !selectedAction} onClick={props.onSave}>
        {props.saveText || 'Save'}
      </Button>
    </Box>
  );
};
