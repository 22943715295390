import { format } from 'date-fns';
import { Rank } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';

export const generateRankOrder = (startRankId: number, uniqueRanks: Rank[]): { rankId: number; isSelected: boolean }[] => {
  const startRankIndex = uniqueRanks.findIndex((rank) => rank.id === startRankId);
  if (startRankIndex === -1) return uniqueRanks.map((rank) => ({ rankId: rank.id, isSelected: false }));

  const higherRanks = uniqueRanks.slice(startRankIndex).map((rank) => ({ rankId: rank.id, isSelected: true }));
  const lowerRanks = uniqueRanks
    .slice(0, startRankIndex)
    .reverse()
    .map((rank) => ({ rankId: rank.id, isSelected: true }));
  return [...higherRanks, ...lowerRanks];
};

export const getFormattedTimes = (vacancy: HiringEngineVacancy) => {
  const startDateTime = new Date(new Date(vacancy.startDateTime).getTime());
  const endDateTime = new Date(new Date(vacancy.endDateTime).getTime());
  endDateTime < startDateTime && endDateTime.setMinutes(endDateTime.getMinutes() + 24 * 60);

  const differenceInMinutesTotal = differenceInUTCMinutes(endDateTime, startDateTime);

  return {
    startTimeFormatted: format(startDateTime, 'HH:mm'),
    endTimeFormatted: format(endDateTime, 'HH:mm'),
    hoursDifference: Math.floor(differenceInMinutesTotal / 60),
    minutesDifference: differenceInMinutesTotal % 60,
  };
};
