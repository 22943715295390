import { Box, DialogTitle, useTheme } from '@mui/material';
import { useState } from 'react';
import {
  User01Icon24,
  MultipleSelect,
  AdminSelect,
  getCertColors,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { upsertPosition } from '../../helpers/crudPosition';
import { movePositionAssignmentsToFloater } from '../../helpers/updateAssignments';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { IconCircle, ShiftPlanDialogActions } from '../ShiftPlanDialog';

export const UpsertPositionForm = () => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { shiftDuration, stations, setStations, assignments, setAssignments, selectedStruct, setSelectedAction } =
    useShiftPlanContext();
  const { station: selectedStation, apparatus: selectedApparatus, position: selectedPosition } = selectedStruct;

  const [rankCode, setRankCode] = useState(selectedPosition?.rank.code || '');
  const [certCodes, setCertCodes] = useState(selectedPosition?.certifications.map((c) => c.code) || []);

  const rankOptions = departmentInfoState.departmentInfo.ranks.map((r) => ({ label: r.name, value: r.code }));
  const certOptions = departmentInfoState.departmentInfo.certifications.map((c) => {
    const { color } = getCertColors({ theme, color: c.color });
    return { label: c.name, value: c.code, color };
  });

  const isSaveDisabled = !rankCode;

  const onSave = () => {
    const rank = departmentInfoState.departmentInfo.ranks.find((r) => r.code === rankCode);
    const certifications = departmentInfoState.departmentInfo.certifications.filter((c) => certCodes.includes(c.code));
    if (rank) {
      const newPositionData = {
        rank,
        certifications,
        startDateTime: shiftDuration.startTime,
        endDateTime: shiftDuration.endTime,
      };
      setStations(upsertPosition(stations, selectedStruct, newPositionData));
      setAssignments(movePositionAssignmentsToFloater(assignments, selectedStruct));
      setSelectedAction(null);
    }
  };

  return (
    <Box sx={{ '& .MuiInputLabel-root': { top: '-5px' } }}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 3, pt: 3, pb: 2 }}>
        {!selectedPosition && (
          <IconCircle sx={{ color: theme.palette.stationIndigo[600], mb: 2 }}>
            <User01Icon24 />
          </IconCircle>
        )}
        <Box sx={{ typography: 'bodyXLSemibold' }}>{selectedPosition ? 'Edit position' : 'Add position'}</Box>
        {!selectedPosition && (
          <Box sx={{ mt: 0.5, typography: 'bodyMRegular' }}>
            {`to ${selectedStation?.stationName}, ${selectedApparatus?.name}`}
          </Box>
        )}
      </DialogTitle>
      <Box sx={{ flex: 1, minHeight: '1px', overflowY: 'auto', px: 3, pt: 1, width: '495px', maxWidth: '100%' }}>
        <Box sx={{ color: theme.palette.stationGray[700], typography: 'bodySMedium', mb: 1 }}>Rank</Box>
        <AdminSelect items={rankOptions} selectedItem={rankCode} setSelectedItem={setRankCode} placeholder="Choose rank" />
        <Box sx={{ color: theme.palette.stationGray[700], typography: 'bodySMedium', mt: 3, mb: 1 }}>Requirements</Box>
        <MultipleSelect
          items={certOptions}
          selectedItems={certCodes}
          setSelectedItems={setCertCodes}
          placeholder="Add certification"
        />
      </Box>
      <ShiftPlanDialogActions isSaveDisabled={isSaveDisabled} onSave={onSave} />
    </Box>
  );
};
