import { theme } from '@stationwise/component-module';

export interface Activity {
  name: string;
  activityType: string;
  timestamp: Date;
  comments?: string;
  target?: {
    subject: string;
    action: string;
    url?: string;
  };
}

export function getTypeColor(type: string) {
  switch (type) {
    case 'Request':
      return theme.palette.stationTeal[500];
    case 'Review':
      return theme.palette.stationBlue[500];
    case 'Staffing':
      return theme.palette.stationYellow[500];
    case 'Message':
      return theme.palette.stationLightBlue[500];
    case 'Cancellation':
      return theme.palette.stationRed[500];
    default:
      return theme.palette.stationGreen[500];
  }
}
