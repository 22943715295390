import { Box, useTheme } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { StaffingListsContent } from './StaffingListsContent';

export const StaffingListsMain = () => {
  useDocumentTitle('Staffing Lists');
  const theme = useTheme();

  return (
    <AppPageLayout sx={{ background: theme.palette.common.white }}>
      <Box sx={{ px: 5, pt: 4, pb: 8 }}>
        <Box component="h1" sx={{ m: 0, typography: 'heading4' }}>
          Staffing Lists
        </Box>
      </Box>
      <StaffingListsContent />
    </AppPageLayout>
  );
};
