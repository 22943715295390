import { Box, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { Button, ToggleButton, ToggleButtonGroup, exportData } from '@stationwise/component-module';
import { EmployeePayroll } from '@stationwise/share-types';

interface EmployeePayrollExportModalProps {
  exportModelOpen: boolean;
  setExportModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeePayroll: EmployeePayroll;
}

export const EmployeePayrollExportModal = ({
  exportModelOpen,
  setExportModelOpen,
  employeePayroll,
}: EmployeePayrollExportModalProps) => {
  const [selectedExportType, setSelectedExportType] = useState('csv');
  const handleExport = async () => {
    const params = {
      payPeriodId: employeePayroll?.payPeriod.id,
      employeeId: employeePayroll?.employee.id,
      exportType: selectedExportType,
    };
    await exportData('payroll/export/', params, 'payroll');
    setExportModelOpen(false);
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={exportModelOpen}
      onClose={() => setExportModelOpen(false)}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
        })}
      >
        <Box
          sx={(theme) => ({
            padding: '24px 24px 0px 24px',
            borderRadius: '16px 16px 0px 0px',
            width: theme.spacing(53.5),
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            maxHeight: theme.spacing(100),
            gap: theme.spacing(1),
            typography: 'bodyXLSemibold',
          })}
        >
          <Typography sx={{ typography: 'bodyXLSemibold' }}>Export time card</Typography>
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(1),
            marginX: theme.spacing(3),
            gap: theme.spacing(1),
          })}
        >
          <Typography sx={{ typography: 'bodySMedium' }}>Choose export type</Typography>
          <ToggleButtonGroup
            exclusive
            color="primary"
            size="large"
            value={selectedExportType}
            onChange={(_event, newValue) => newValue !== null && setSelectedExportType(newValue)}
            aria-label="Choose export type"
            sx={(theme) => ({
              marginTop: theme.spacing(0.5),
              width: '100%',
              '& .MuiToggleButton-root': { p: theme.spacing(1, 2) },
            })}
          >
            <ToggleButton value="csv">CSV</ToggleButton>
            <ToggleButton value="iif">IIF</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            mt: theme.spacing(2),
            gap: theme.spacing(1.5),
            width: '100%',
            padding: theme.spacing(2),
          })}
        >
          <Button onClick={() => setExportModelOpen(false)} variant="outlined" size="large" sx={{ width: '100%' }}>
            {'Cancel'}
          </Button>
          <Button variant="contained" size="large" onClick={handleExport} sx={{ width: '100%' }}>
            {'Export'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
