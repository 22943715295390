import { Box } from '@mui/material';
import { ShiftPlanChipType, ShiftPlanAPIData } from '@stationwise/share-types';
import { useShiftPlan } from '../hooks/useShiftPlan';
import { RosterEditor } from './RosterEditor';
import { ShiftPlanContextProvider } from './ShiftPlanContext';
import { TopBar } from './TopBar';

interface ShiftPlanContentProps {
  data: ShiftPlanAPIData;
  forceRefetch: (battalionId: string) => void;
}

export const ShiftPlanContent = (props: ShiftPlanContentProps) => {
  const context = useShiftPlan(props);

  return (
    <ShiftPlanContextProvider value={context}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TopBar />
        <Box sx={{ flex: 1, minHeight: '1px', position: 'relative' }}>
          {context.selectedChipType === ShiftPlanChipType.ROSTER_EDITOR && <RosterEditor />}
        </Box>
      </Box>
    </ShiftPlanContextProvider>
  );
};
