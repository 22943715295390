import { Box, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useId, useState } from 'react';
import { AllowedColors } from '@stationwise/share-types';
import { CheckIcon20, PlusIcon16, XIcon16 } from '../../assets';
import { GetComponentColorsProps } from '../../utils/colors';

export interface FilterType {
  name: string;
  code?: string;
  color?: AllowedColors;
}

interface FilterProps {
  value: Set<string>;
  onChange: (value: Set<string>) => void;
  filterName?: string;
  filters: FilterType[];
  getFilterColors?: (colorsProps: GetComponentColorsProps) => { color: string };
}

export const Filter = ({ value, onChange, filterName, filters, getFilterColors }: FilterProps) => {
  const id = useId();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const onClickMenuButton = (event: MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);
  const onCloseMenu = () => setMenuAnchorEl(null);

  const onToggleSelected = (selected: string) => {
    const newValue = new Set(value);
    newValue.has(selected) ? newValue.delete(selected) : newValue.add(selected);
    onChange(newValue);
    onCloseMenu();
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mt: 2 }}>
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', gap: 0.5 }}>
        {Array.from(value).map((selected) => (
          <Box
            key={selected}
            sx={(theme) => ({
              backgroundColor: theme.palette.stationGray[100],
              borderRadius: '9999px',
              display: 'flex',
              alignItems: 'center',
              p: theme.spacing(0.5, 0.75),
              typography: 'bodySRegular',
            })}
          >
            <Box sx={(theme) => ({ p: theme.spacing(0, 0.5) })}>{selected}</Box>
            <Box
              component="button"
              type="button"
              onClick={() => onToggleSelected(selected)}
              sx={(theme) => ({
                backgroundColor: 'transparent',
                border: 0,
                borderRadius: '50%',
                color: theme.palette.stationGray[400],
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '16px',
                height: '16px',
                p: 0,
                '&:hover': { backgroundColor: theme.palette.common.white, color: theme.palette.stationGray[900] },
                '& svg path': { stroke: 'currentColor' },
              })}
            >
              <XIcon16 />
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        component="button"
        type="button"
        id={`${id}button`}
        aria-controls={menuAnchorEl ? `${id}menu` : undefined}
        aria-haspopup="true"
        aria-expanded={menuAnchorEl ? 'true' : undefined}
        onClick={onClickMenuButton}
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.stationGray[300]}`,
          borderRadius: 1,
          color: theme.palette.stationGray[700],
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '28px',
          gap: 0.5,
          p: theme.spacing(0, 1, 0, 0.75),
          typography: 'buttonS',
          '&:hover': { backgroundColor: theme.palette.stationGray[50] },
          '& svg path': { stroke: theme.palette.stationGray[900] },
        })}
      >
        <PlusIcon16 />
        {`Add ${filterName || ''} filter`}
      </Box>
      <Menu
        id={`${id}menu`}
        anchorEl={menuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        open={!!menuAnchorEl}
        onClose={onCloseMenu}
        MenuListProps={{ 'aria-labelledby': `${id}button` }}
        sx={(theme) => ({
          '& .MuiMenu-paper': {
            border: `1px solid ${theme.palette.stationGray[100]}`,
            borderRadius: '12px',
            boxShadow: '0px 4px 6px -2px #0A0E160D, 0px 10px 15px -3px #0A0E161A',
            maxHeight: 310,
          },
          '& .MuiMenu-list': { p: theme.spacing(1) },
        })}
      >
        {filters.map((filter) => (
          <MenuItem
            key={filter.code || filter.name}
            onClick={() => onToggleSelected(filter.code || filter.name)}
            sx={(theme) => ({
              border: '1px solid transparent',
              borderRadius: '8px',
              color: theme.palette.stationGray[500],
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              p: 1,
              typography: 'bodyMRegular',
              '&:hover': {
                backgroundColor: theme.palette.common.white,
                borderColor: value.has(filter.code || filter.name)
                  ? theme.palette.stationGray[900]
                  : theme.palette.stationGray[400],
              },
              '& svg path': {
                stroke: theme.palette.stationGray[900],
                visibility: !value.has(filter.code || filter.name) ? 'hidden' : undefined,
              },
            })}
          >
            {filter.code && <Box sx={{ flex: 1 }}>{filter.name}</Box>}
            <Box sx={(theme) => ({ color: getFilterColors?.({ theme, color: filter.color }).color })}>
              {filter.code || filter.name}
            </Box>
            <CheckIcon20 />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
