import { Box, Theme, useTheme } from '@mui/material';
import { useState } from 'react';
import { TimeOffLimit } from '@stationwise/share-types';
import { Pencil01Icon24 } from '../../assets/index';
import { BlankCard } from '../BlankCard';
import { AccrualEditModal } from './AccrualEditModal';
import { TimeOffProps, TimeOff } from './TimeOff';

const mapAccruals = (theme: Theme, accruals: TimeOffLimit[]) => {
  const color = [
    theme.palette.stationPink[600],
    theme.palette.stationBlue[600],
    theme.palette.stationPurple[600],
    theme.palette.stationLightBlue[500],
  ];
  const pendingColor = [
    theme.palette.stationPink[300],
    theme.palette.stationBlue[300],
    theme.palette.stationPurple[300],
    theme.palette.stationLightBlue[300],
  ];

  const mapped: TimeOffProps[] = [];
  if (Array.isArray(accruals)) {
    accruals.forEach((acc, index) => {
      if (!acc.isUnlimited) {
        mapped.push({
          label: acc.payCode.name,
          remaining: acc.accruedTimeOff,
          pending: acc.pending,
          maxAccrualLimit: acc.payCode.maxAccrualLimit || 365 * 24,
          barColor: color[index % 4],
          pendingBarColor: pendingColor[index % 4],
        });
      }
    });
  }
  return mapped;
};

export const AccrualsCard = ({
  accruals,
  handleRefresh,
  showEditModal,
}: {
  accruals: TimeOffLimit[];
  handleRefresh?: () => void;
  showEditModal?: boolean;
}) => {
  const theme = useTheme();
  const mappedAccruals = mapAccruals(theme, accruals);
  const [isAccrualsModalOpen, setIsAccrualsModalOpen] = useState(false);

  if (mappedAccruals.length === 0) {
    return null;
  }

  return (
    <>
      {showEditModal && (
        <AccrualEditModal
          showModal={isAccrualsModalOpen}
          setShowModal={setIsAccrualsModalOpen}
          accruals={accruals}
          handleRefresh={handleRefresh}
        />
      )}
      <BlankCard
        type="passive"
        sxProps={{
          border: `1px solid ${theme.palette.stationGray[100]}`,
          padding: 2,
          boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
          borderRadius: 2,
        }}
      >
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
            mt: 1,
            typography: 'bodyLSemibold',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 1,
                typography: 'heading6',
              }}
            >
              Accruals
            </Box>
            {showEditModal && (
              <Box sx={{ cursor: 'pointer', display: 'flex' }}>
                <Pencil01Icon24
                  onClick={() => {
                    setIsAccrualsModalOpen(true);
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {mappedAccruals.map((accrual) => (
          <Box key={accrual.label}>
            <TimeOff
              label={accrual.label}
              remaining={accrual.remaining}
              pending={accrual.pending}
              maxAccrualLimit={accrual.maxAccrualLimit}
              barColor={accrual.barColor}
              pendingBarColor={accrual.pendingBarColor}
            />
          </Box>
        ))}
      </BlankCard>
    </>
  );
};
