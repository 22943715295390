import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EmployeePayrollNavigation } from '@stationwise/share-types';
import { ChevronLeftIcon24, ChevronRightIcon24 } from '../../assets';
import { Button } from '../Button';

interface EmployeePickerProps {
  employeePayrollNavigation: EmployeePayrollNavigation | null;
}

export const EmployeePicker: React.FC<EmployeePickerProps> = ({ employeePayrollNavigation }) => {
  const navigate = useNavigate();

  const handleNextEmployeePayroll = () => {
    navigate(`/app/payroll/employee/${employeePayrollNavigation?.nextEmployee?.payrollId}`);
  };

  const handlePreviousEmployeePayroll = () => {
    navigate(`/app/payroll/employee/${employeePayrollNavigation?.prevEmployee?.payrollId}`);
  };

  const limitNameLength = (name: string, maxLength = 18): string => {
    return name.length > maxLength ? name.substring(0, maxLength - 3) + '...' : name;
  };

  return (
    <Box
      sx={(theme) => ({
        paddingBottom: theme.spacing(0),
      })}
    >
      <Box sx={{ display: 'flex', gap: 1, mt: 2, mb: 2 }}>
        {employeePayrollNavigation?.prevEmployee && (
          <Button onClick={handlePreviousEmployeePayroll} variant="outlined" sx={{ borderRadius: '9999px', pl: 1 }}>
            <ChevronLeftIcon24 />
            {limitNameLength(employeePayrollNavigation.prevEmployee.name)}
          </Button>
        )}
        {employeePayrollNavigation?.nextEmployee && (
          <Button onClick={handleNextEmployeePayroll} variant="outlined" sx={{ borderRadius: '9999px', pr: 1 }}>
            {limitNameLength(employeePayrollNavigation.nextEmployee.name)}
            <ChevronRightIcon24 />
          </Button>
        )}
      </Box>
    </Box>
  );
};
