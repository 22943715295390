import { useEffect, useState } from 'react';
import { COLLISION_REGION, checkIsFloater, updateBoardEmployeeNote } from '@stationwise/shift-summary-helper';
import { useLoadedAuthUserContext } from '../../../Auth';
import { OverrideModal } from '../../../Override/OverrideModal';
import { Popover, POPOVER_POSITION } from '../../../Popover';
import { notifyEmployeeNoteChanged } from '../EmployeePreview/Note/snackbarHelper';
import { useRosterContext } from '../RosterContext';
import { LastChangesError } from './LastChangesError';

/**
 * This component renders a popover explaining why the user's last drag-and-drop action is invalid.
 * Ideally the popover is anchored near the employee card that was moved.
 * If it cannot find this card, it anchors to a fallback element.
 * It relies on data attributes in `getLastChangesErrorPopoverAnchorProps` and `getLastChangesErrorPopoverFallbackAnchorProps`
 * to find the ideal and fallback anchor elements, respectively.
 */

export const getLastChangesErrorPopoverAnchorProps = (id: string) => {
  return { 'data-lcep-anchor-id': id };
};

export const getLastChangesErrorPopoverFallbackAnchorProps = () => {
  return { 'data-lcep-fallback-anchor': 'true' };
};

export const LastChangesErrorPopover = () => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const {
    lastChanges,
    setLastChanges,
    initialShiftSummaryHelper,
    shiftSummaryHelper,
    setShiftSummaryHelper,
    setUserHasMadeChanges,
  } = useRosterContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);
  const [overrideNote, setOverrideNote] = useState('');

  useEffect(() => {
    if (lastChanges) {
      const newAnchorId = checkIsFloater(lastChanges.employee) ? COLLISION_REGION.FLOATERS_BAR : lastChanges.employee.activeId;
      let newAnchorEl = document.querySelector(`[data-lcep-anchor-id="${newAnchorId}"]`);
      newAnchorEl = newAnchorEl || document.querySelector('[data-lcep-fallback-anchor="true"]');
      setAnchorEl(newAnchorEl as HTMLElement | null);
      setOverrideNote(lastChanges.employee.noteOverride?.note || '');
    } else {
      setAnchorEl(null);
      setOverrideNote('');
    }
  }, [lastChanges]);

  if (!lastChanges) {
    return null;
  }

  const handleUndo = () => {
    setShiftSummaryHelper(lastChanges.shiftSummaryHelper);
    setUserHasMadeChanges(lastChanges.shiftSummaryHelper !== initialShiftSummaryHelper);
    setLastChanges(null);
  };

  const handleApply = () => {
    const newShiftSummaryHelper = updateBoardEmployeeNote(
      shiftSummaryHelper,
      overrideNote,
      lastChanges.employee.id,
      authUserState.employee,
    );
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    setLastChanges(null);
    setIsOverrideModalOpen(false);
    notifyEmployeeNoteChanged(lastChanges.employee, overrideNote);
  };

  const handleCancel = () => {
    setIsOverrideModalOpen(false);
    setOverrideNote(lastChanges.employee.noteOverride?.note || '');
  };

  return (
    <Popover
      data-cy="last-changes-error-popover"
      anchorEl={anchorEl}
      open={!!anchorEl}
      {...(checkIsFloater(lastChanges.employee) ? POPOVER_POSITION.TOP : POPOVER_POSITION.RIGHT)}
      sx={(theme) => ({
        '& .SWPopover-content': { backgroundColor: theme.palette.stationGray[800], borderRadius: '8px' },
        '& .SWPopover-arrow': { backgroundColor: theme.palette.stationGray[800] },
      })}
    >
      <LastChangesError
        current={{
          nameLabel: lastChanges.employee.name,
          rank: lastChanges.employee.rank,
          certs: lastChanges.employee.certifications,
        }}
        onClickUndo={handleUndo}
        onClickOverride={lastChanges.error.canOverride ? () => setIsOverrideModalOpen(true) : undefined}
        errorMessages={lastChanges.error.messages}
      />
      <OverrideModal
        isOpen={isOverrideModalOpen}
        onApply={handleApply}
        onCancel={handleCancel}
        overrideNote={overrideNote}
        setOverrideNote={setOverrideNote}
        title="Error override"
        subtitle="Please describe why this override is a feasible change in your schedule"
      />
    </Popover>
  );
};
