import { ShiftPlanAssignment, ShiftPlanStruct } from '@stationwise/share-types';

export const movePositionAssignmentsToFloater = (assignments: ShiftPlanAssignment[], { position }: ShiftPlanStruct) => {
  if (!position) {
    return assignments;
  }

  return assignments.map((assignment) => {
    const refPositionId = assignment.reference.position && `${assignment.reference.position.id}`;
    if (refPositionId !== position.id) {
      return assignment;
    }

    return {
      ...assignment,
      reference: { apparatus: null, position: null },
    };
  });
};

export const moveApparatusAssignmentsToFloater = (assignments: ShiftPlanAssignment[], { apparatus }: ShiftPlanStruct) => {
  if (!apparatus) {
    return assignments;
  }

  const positionIds = new Set(apparatus.positions.map((p) => p.id));
  return assignments.map((assignment) => {
    const refApparatusId = assignment.reference.apparatus && `${assignment.reference.apparatus.id}`;
    const refPositionId = assignment.reference.position && `${assignment.reference.position.id}`;
    if (refApparatusId !== apparatus.id && !positionIds.has(refPositionId || '')) {
      return assignment;
    }

    return {
      ...assignment,
      reference: { apparatus: null, position: null },
    };
  });
};

export const moveStationAssignmentsToFloater = (assignments: ShiftPlanAssignment[], { station }: ShiftPlanStruct) => {
  if (!station) {
    return assignments;
  }

  let newAssignments = assignments;
  station.apparatuses.forEach((apparatus) => {
    newAssignments = moveApparatusAssignmentsToFloater(assignments, { station, apparatus });
  });
  return newAssignments;
};
