import { LoggedInEmployeeDataView } from '../employee/getLoggedInEmployee';
import { regularPayCodeMock, standByPayCodeMock, overtimePayCodeMock } from './payCode';
import { firefighterRankMock } from './rank';

export const mockedUser: LoggedInEmployeeDataView = {
  email: 'teddy.mckinney@example.com',
  id: '7f67fb30-c369-11ed-a4df-1e151fc4d9e1',
  firstName: 'Teddy',
  lastName: 'Mckinney',
  phone: null,
  rank: firefighterRankMock,
  defaults: {
    arrivedEarlyPayCode: standByPayCodeMock,
    heldOverPayCode: standByPayCodeMock,
    overtimeAssignmentPayCode: overtimePayCodeMock,
    regularAssignmentPayCode: regularPayCodeMock,
  },
  unreadMessages: [1, 2],
  certifications: [],
  isNonShift: false,
  isApparatusLogin: false,
  departmentDomain: 'stationwise',
  departments: [{ id: 70372, name: 'Stationwise', domain: 'stationwise' }],
  lastOvertimeCreatedAt: new Date(),
  employeeType: 'FULL_TIME',
  mobileIntercomSupportEnabled: true,
  managerOne: null,
  managerTwo: null,
  managerThree: null,
  capabilities: [],
};
