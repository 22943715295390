import { useDocumentTitle, CAPABILITIES } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { TeamSettings } from './components/TeamSettings';

export const TeamPage = () => {
  useDocumentTitle('Employees');

  return (
    <AppPageLayout
      sx={(theme) => ({ background: theme.palette.common.white })}
      requiredCapability={CAPABILITIES.VIEW_EMPLOYEES_PAGE}
    >
      <TeamSettings />
    </AppPageLayout>
  );
};
