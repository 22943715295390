import { Autocomplete, Box, createFilterOptions, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { ChevronDownIcon20, Trash04Icon20, Modal } from '@stationwise/component-module';
import { Manager } from '@stationwise/share-types';
import { WarningModal } from '../../../../../../components/Common';

type ManagerAutocompleteProps = {
  title: string;
  manager: Manager;
  setManager: React.Dispatch<React.SetStateAction<Manager>>;
  options: Manager[];
};

export const ManagerAutocomplete = (props: ManagerAutocompleteProps) => {
  const filter = createFilterOptions<Manager>();
  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const handleManagerChange = (_event: React.SyntheticEvent, newValue: Manager | string | null) => {
    if (newValue && typeof newValue !== 'string' && 'id' in newValue) {
      if (!newValue.canEditPayroll) {
        setWarningModalOpen(true);
      }
      props.setManager(newValue);
    }
  };

  return (
    <Box>
      <Box
        sx={(theme) => ({
          typography: 'bodySMedium',
          textAlign: 'left',
          paddingTop: theme.spacing(2),
          color: theme.palette.stationGray[700],
        })}
      >
        {props.title}
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: theme.spacing(1),
        })}
      >
        <Autocomplete
          sx={{
            width: '100%',
          }}
          value={props.manager ?? ''}
          onChange={handleManagerChange}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            return filtered;
          }}
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={props.options}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option?.name ?? '';
          }}
          renderOption={(itemProps, option) => (
            <li {...itemProps} key={itemProps.key}>
              {option.name}
            </li>
          )}
          disableClearable
          forcePopupIcon={true}
          popupIcon={<ChevronDownIcon20 />}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiOutlinedInput-root': { padding: '2.5px 3px 2.5px 12px' },
              }}
            />
          )}
        />
        <IconButton
          aria-label="Delete manager"
          onClick={() =>
            props.setManager({
              name: '',
              id: null,
              canEditPayroll: false,
            })
          }
          sx={{ ml: 1 }}
        >
          <Trash04Icon20 />
        </IconButton>
      </Box>

      <Modal open={warningModalOpen} setOpen={setWarningModalOpen}>
        <WarningModal setModalOpen={setWarningModalOpen} onConfirm={() => setWarningModalOpen(false)} hideButtons={true}>
          <Box sx={(theme) => ({ width: '416px', mb: theme.spacing(3) })}>
            <Box sx={{ typography: 'bodyXXLSemibold' }}>Warning</Box>
            <Box sx={(theme) => ({ typography: 'bodyMRegular', mt: theme.spacing(2) })}>
              This user does not currently have access to the payroll page. Making them a manager will give them access to view,
              edit, and approve timecards.
            </Box>
          </Box>
        </WarningModal>
      </Modal>
    </Box>
  );
};
