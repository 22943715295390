import { Box, DialogTitle, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { HornIcon24, Select } from '@stationwise/component-module';
import { moveApparatus } from '../../helpers/crudApparatus';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { IconCircle, ShiftPlanDialogActions } from '../ShiftPlanDialog';

export const MoveApparatusForm = () => {
  const theme = useTheme();
  const { stations, setStations, selectedStruct, setSelectedAction } = useShiftPlanContext();
  const { station: selectedFromStation, apparatus: selectedApparatus } = selectedStruct;

  const toStations = useMemo(() => {
    return Array.from(stations.values())
      .filter((s) => s.stationId !== selectedFromStation?.stationId && s.stationId !== 'floater-station')
      .map((s) => ({ label: s.stationName, value: s.stationId }));
  }, [stations, selectedFromStation]);

  const [selectedToStation, setSelectedToStation] = useState(stations.get(toStations[0]?.value || ''));

  const isSaveDisabled = !selectedToStation;

  const onSave = () => {
    setStations(moveApparatus(stations, selectedStruct, { station: selectedToStation }));
    setSelectedAction(null);
  };

  return (
    <>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 3, pt: 3, pb: 2 }}>
        <IconCircle sx={{ color: theme.palette.stationIndigo[600], mb: 2 }}>
          <HornIcon24 />
        </IconCircle>
        <Box sx={{ typography: 'bodyXLSemibold' }}>{`Move ${selectedApparatus?.name}`}</Box>
        <Box sx={{ mt: 0.5, typography: 'bodyMRegular' }}>{`to ${selectedToStation?.stationName}`}</Box>
      </DialogTitle>
      <Box sx={{ flex: 1, minHeight: '1px', overflowY: 'auto', px: 3, py: 2, width: '398px', maxWidth: '100%' }}>
        <Select
          width="100%"
          items={toStations}
          onChange={(newValue) => setSelectedToStation(stations.get(newValue))}
          selected={selectedToStation?.stationId || ''}
        />
      </Box>
      <ShiftPlanDialogActions isSaveDisabled={isSaveDisabled} onSave={onSave} />
    </>
  );
};
