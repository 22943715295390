import { Box, Typography } from '@mui/material';
import { ChevronLeftIcon20, ChevronRightIcon20 } from '../../../assets';

interface DateSelectorProps {
  title: string;
  prevHandle: () => void;
  nextHandle: () => void;
}

export const DateSelector = ({ title, prevHandle, nextHandle }: DateSelectorProps) => {
  return (
    <Box
      data-testid="calendar-date-selector"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          gap: '6px',
        },
        gap: '12px',
      })}
    >
      <Box
        data-cy="prev-month-button"
        sx={(theme) => ({
          color: theme.palette.stationGray[400],
          cursor: 'pointer',
          display: 'flex',
          alignContent: 'center',
        })}
      >
        <ChevronLeftIcon20 onClick={prevHandle} />
      </Box>
      <Typography data-cy="month-selector" variant="bodyMMedium">
        {title}
      </Typography>
      <Box
        data-cy="next-month-button"
        sx={(theme) => ({
          color: theme.palette.stationGray[400],
          cursor: 'pointer',
          display: 'flex',
          alignContent: 'center',
        })}
      >
        <ChevronRightIcon20 onClick={nextHandle} />
      </Box>
    </Box>
  );
};
