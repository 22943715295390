import { Box } from '@mui/material';
import { ElementType } from 'react';
import { ChevronDownIcon16 } from '../../assets';
import { Button, ButtonProps } from './Button';

type SelectButtonProps<E extends ElementType> = ButtonProps<E> & {
  caret?: boolean;
};

export const SelectButton = <E extends ElementType>({ children, caret, sx, ...props }: SelectButtonProps<E>) => {
  const { variant = 'outlined' } = props;

  return (
    <Button
      variant={variant}
      {...props}
      sx={[
        (theme) => ({
          color: theme.palette.text.primary,
          gap: 1,
          minHeight: '40px',
          typography: 'bodySRegular',
          ...(variant === 'outlined' && {
            background: theme.palette.common.white,
            borderColor: theme.palette.stationGray[200],
            boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
            '&:hover': {
              background: theme.palette.common.white,
              borderColor: theme.palette.stationGray[400],
            },
          }),
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
      {caret !== undefined && (
        <Box
          component={ChevronDownIcon16}
          sx={(theme) => ({ color: theme.palette.action.active, transform: caret ? 'rotate(180deg)' : undefined })}
        />
      )}
    </Button>
  );
};
