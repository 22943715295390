import { ReactComponent as AddSymbol } from '@material-symbols/svg-400/outlined/add.svg';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { EmployeeTypes, ListFieldsStaffingList } from '@stationwise/share-types';
import { Button, SvgIcon, theme, useAuthUserCapabilities } from '../../../component-module';
import { useLoadedAuthUserContext } from '../../Auth';
import { getDepartmentFeatureFlagValue, useLoadedDepartmentInfoContext } from '../../Department';
import { DisplayOption } from '../Calendar';
import { ActionButton } from './ActionButton';
import { DateSelector } from './DateSelector';
import { MoreButton } from './MoreButton';

interface HeaderProps {
  title: string;
  selectedView: DisplayOption;
  prevHandle: () => void;
  nextHandle: () => void;
  handleRequestOvertimeClick?: (staffingList: ListFieldsStaffingList) => void;
  handleCreateIncidentClick?: () => void;
  children?: ReactNode;
  isLoading?: boolean;
  setCreateIncident: (value: boolean) => void;
  setCreateIncidentOpen?: (value: boolean) => void;
  createIncidentOpen?: boolean;
  selectedStaffingList?: ListFieldsStaffingList | null;
  viewingPersonalCalendar: boolean;
  setRequestMultipleDaysOff?: (value: boolean) => void;
  requestMultipleDaysOff?: boolean;
}

export const Header = ({
  title,
  selectedView,
  prevHandle,
  nextHandle,
  handleRequestOvertimeClick,
  handleCreateIncidentClick,
  isLoading,
  setCreateIncident,
  setCreateIncidentOpen,
  createIncidentOpen,
  selectedStaffingList,
  viewingPersonalCalendar,
  setRequestMultipleDaysOff,
  requestMultipleDaysOff = false,
}: HeaderProps) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const capabilities = useAuthUserCapabilities();
  const incidentReportAdminEnabled = capabilities.MANAGE_INCIDENT_REPORT;

  const overtimeRequestEnabled = departmentContext.departmentInfo.settings.overtimeRequestEnabled;
  const incidentFormEnabled = departmentContext.departmentInfo.settings.incidentFormEnabled;
  const multipleTOREnabled = getDepartmentFeatureFlagValue(departmentContext, 'MULTIPLE_TOR');

  const showActionButton =
    !viewingPersonalCalendar &&
    !authUserState.employee.isNonShift &&
    !(authUserState.employee.employeeType === EmployeeTypes.VOLUNTEER) &&
    !isLoading &&
    (overtimeRequestEnabled || incidentFormEnabled) &&
    selectedView === 'dayGridMonth';

  const onClickCreateIncident = () => {
    setCreateIncident(true);
    setCreateIncidentOpen && setCreateIncidentOpen(true);
    handleCreateIncidentClick && handleCreateIncidentClick();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={(theme) => ({
        pb: '12px',
        pt: '12px',
        position: selectedView === 'listMonth' ? 'sticky' : 'unset',
        top: '0px',
        zIndex: selectedView === 'listMonth' ? theme.zIndex.appBar : theme.zIndex.fab,
        background: theme.palette.common.white,
        borderTop: `1px dashed ${theme.palette.stationGray[100]}`,
      })}
    >
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            ml: theme.spacing(1),
          },
          ml: theme.spacing(2),
        }}
      >
        <Box sx={{ mt: { md: '10px' }, textAlign: 'center' }}>
          <DateSelector title={title} prevHandle={prevHandle} nextHandle={nextHandle} />
        </Box>
      </Box>
      <Box display="flex">
        {showActionButton && (
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                mr: theme.spacing(1),
              },
              mr: theme.spacing(2),
            })}
          >
            <ActionButton
              requestOvertimeDisabled={createIncidentOpen || requestMultipleDaysOff}
              handleRequestOvertimeClick={handleRequestOvertimeClick}
              setCreateIncidentOpen={setCreateIncidentOpen}
              setCreateIncident={setCreateIncident}
            />
          </Box>
        )}
        {showActionButton && incidentFormEnabled && incidentReportAdminEnabled && (
          <Box sx={(theme) => ({ mr: theme.spacing(1) })}>
            <Button
              onClick={onClickCreateIncident}
              disabled={!!selectedStaffingList || requestMultipleDaysOff}
              variant="outlined"
              startIcon={<SvgIcon component={AddSymbol} />}
            >
              Incident
            </Button>
          </Box>
        )}
        {showActionButton && multipleTOREnabled && (
          <Box sx={{ mr: 2 }}>
            <MoreButton
              disabled={isLoading || !!selectedStaffingList || createIncidentOpen}
              setRequestMultipleDaysOff={setRequestMultipleDaysOff}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
