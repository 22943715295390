import { getDepartmentFeatureFlagValue, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { NoMatch } from '../NoMatch';
import { ShiftPlanMain } from './components/ShiftPlanMain';

export const ShiftPlan = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  if (!getDepartmentFeatureFlagValue(departmentInfoState, 'SHIFT_PLAN')) {
    return <NoMatch />;
  }

  return <ShiftPlanMain />;
};
