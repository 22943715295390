import { Box, BoxProps, useTheme } from '@mui/material';

type AddStructPlaceholderProps = BoxProps<'div'>;

export const AddStructPlaceholder = ({ children, sx, ...props }: AddStructPlaceholderProps) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      sx={[
        {
          background: theme.palette.stationGray[100],
          borderRadius: 2,
          border: `1px solid ${theme.palette.stationGray[300]}`,
          color: theme.palette.stationGray[500],
          cursor: 'pointer',
          height: '104px',
          width: '100%',
          p: theme.spacing(1, 1.5),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          typography: 'buttonL',
          '&:hover': { background: theme.palette.stationGray[200] },
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
