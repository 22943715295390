import { Box, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { CardCertBadges, DriverIndicator, EmptyPosition, RankBadge, ShiftDurationIndicator } from '@stationwise/component-module';
import { RosterApparatus, RosterPosition, ShiftPlanAssignment } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { formatShiftDuration } from '@stationwise/shift-summary-helper';
import { ShiftPlanAssignmentCard } from './ShiftPlanAssignmentCard';

interface ShiftPlanPositionProps {
  apparatus: RosterApparatus;
  position: RosterPosition;
  assignments: ShiftPlanAssignment[];
  actions?: ReactNode;
  onClickPosition: (apparatus: RosterApparatus, position: RosterPosition) => void;
}

export const ShiftPlanPosition = ({ apparatus, position, assignments, ...props }: ShiftPlanPositionProps) => {
  const theme = useTheme();

  if (assignments.length === 0) {
    return (
      <Box
        onClick={apparatus.isReserved ? undefined : () => props.onClickPosition(apparatus, position)}
        sx={{ cursor: apparatus.isReserved ? 'default' : 'pointer' }}
      >
        <EmptyPosition position={position} actions={props.actions} />
      </Box>
    );
  }

  return (
    <Box
      onClick={apparatus.isReserved ? undefined : () => props.onClickPosition(apparatus, position)}
      sx={{
        background: theme.palette.common.white,
        borderRadius: 2,
        border: `2px solid ${theme.palette.stationGray[400]}`,
        cursor: apparatus.isReserved ? 'default' : 'pointer',
        p: 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {position.driver && <DriverIndicator sx={{ mr: 0.5 }} />}
        <RankBadge rank={position.rank} />
        <Box sx={{ flex: 1 }} />
        <ShiftDurationIndicator
          durationLabel={formatShiftDuration({ startTime: position.startDateTime, endTime: position.endDateTime })}
          durationMinutes={differenceInUTCMinutes(position.endDateTime, position.startDateTime)}
        />
      </Box>
      {(position.certifications.length > 0 || props.actions) && (
        <Box sx={{ display: 'flex', mt: 1, justifyContent: 'space-between', gap: 0.5 }}>
          <CardCertBadges certs={position.certifications} />
          {props.actions && (
            <Box
              sx={{ position: 'relative', top: '2px', right: '-2px', minWidth: 20, height: '100%' }}
              onClick={(e) => e.stopPropagation()}
            >
              {props.actions}
            </Box>
          )}
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 1 }}>
        {assignments.map((assignment, i) => {
          return <ShiftPlanAssignmentCard key={i} assignment={assignment} />;
        })}
      </Box>
    </Box>
  );
};
