// Intention of this function is to do nothing as a function
// This is similar to lodash noop (No Operation -> No Op -> noop)

import { format, parseISO } from 'date-fns';

export const noop = () => {};

export function getOffsetTop(elem: HTMLElement | null): number {
  let offsetTop = 0;
  while (elem) {
    offsetTop += elem.offsetTop;
    elem = elem.offsetParent as HTMLElement | null;
  }
  return offsetTop;
}

export function isValidPhoneNumber(phone: string): boolean {
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  return phoneRegex.test(phone);
}

export function isValidEmail(email: string): boolean {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
}

/**
 * Formats a time period into a human-readable string.
 *
 * @param startDate - The start date of the period in ISO format (e.g., '2023-04-01')
 * @param endDate - The end date of the period in ISO format (e.g., '2023-04-30')
 * @returns A formatted string representing the time period (e.g., 'Apr 01 - Apr 30, 2023')
 */
export function formatTimePeriod(startDate: string, endDate: string): string {
  const parsedStartDate = parseISO(startDate);
  const parsedEndDate = parseISO(endDate);
  return `${format(parsedStartDate, 'MMM dd')} - ${format(parsedEndDate, 'MMM dd')}, ${format(parsedEndDate, 'yyyy')}`;
}
