import { Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';
import { Button, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { Battalion, Team } from '@stationwise/share-types';
import { TeamColoredBar } from '../../../../components/Common';
import { useShiftTemplateContext } from '../contexts/ShiftTemplateContext';
import { BattalionSelect } from './BattalionSelect';
import { TeamSelect } from './TeamSelect';

interface TopBarProps {
  saveChanges?: () => void;
  forceRefetch: (teamId: string, battalionId: string) => void;
  lastSaved: string;
  onPublish: () => void;
  onChangeDate: () => void;
  onCancelFutureDated: () => void;
  dateForPublish: Date;
  setDateForPublish: (date: Date) => void;
  onDiscard: () => void;
  isCurrentlyActive?: boolean;
  futureDated: boolean;
  shiftTemplateDate: Date;
  today: Date;
}

export const TopBar = ({
  saveChanges,
  forceRefetch,
  lastSaved,
  onPublish,
  onChangeDate,
  dateForPublish,
  setDateForPublish,
  onDiscard,
  isCurrentlyActive,
  futureDated,
  onCancelFutureDated,
  shiftTemplateDate,
  today,
}: TopBarProps) => {
  const { shiftTemplateHelper, resetValues, userHasMadeChanges } = useShiftTemplateContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const teams = departmentContext.departmentInfo.teams.sort((a, b) => a.name.localeCompare(b.name));
  const battalions = departmentContext.departmentInfo.battalions.sort((a, b) => a.name.localeCompare(b.name));
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(shiftTemplateHelper.currentTeams[0]);
  const [selectedBattalion, setSelectedBattalion] = useState<Battalion | undefined>(shiftTemplateHelper.currentBattalion);
  const onChangeTeam = (selectedOption: string) => {
    const selectedTeam = teams.find((team) => team.id.toString() === selectedOption);
    setSelectedTeam(selectedTeam);
    forceRefetch(selectedOption, shiftTemplateHelper.currentBattalion.id.toString());
  };

  const onChangeBattalion = (selectedOption: string) => {
    const selectedBattalion = battalions.find((b) => b.id.toString() === selectedOption);
    setSelectedBattalion(selectedBattalion);
    forceRefetch(shiftTemplateHelper.currentTeams[0].id.toString(), selectedOption);
  };

  return (
    <Box>
      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          display: 'flex',
          alignItems: 'center',
          p: theme.spacing(1, 3),
          width: '100%',
        })}
      >
        <Box>
          <TeamSelect teams={teams} selectedTeam={selectedTeam?.id.toString() || ''} onChange={onChangeTeam} />
        </Box>
        {battalions.length > 1 && (
          <Box sx={{ ml: 2 }}>
            <BattalionSelect
              battalions={battalions}
              selectedBattalion={selectedBattalion?.id.toString() || ''}
              onChange={onChangeBattalion}
            />
          </Box>
        )}
        {!isCurrentlyActive && lastSaved && (
          <>
            <Box display="flex" gap={1} alignItems="center" sx={{ ml: 3 }}>
              {!futureDated && (
                <Box sx={{ display: 'flex', gap: 1, minHeight: '40px' }}>
                  <Button variant="outlined" onClick={onDiscard} data-cy="remove">
                    Discard draft
                  </Button>
                  <Button
                    variant="contained"
                    disabled={userHasMadeChanges || dateForPublish == null}
                    onClick={onPublish}
                    data-cy="publish"
                  >
                    Publish
                  </Button>
                </Box>
              )}
              {futureDated && (
                <Box sx={{ display: 'flex', gap: 1, minHeight: '40px' }}>
                  <Button variant="outlined" disabled={userHasMadeChanges} onClick={onCancelFutureDated}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={new Date(dateForPublish).toISOString() === new Date(shiftTemplateDate).toISOString()}
                    onClick={onChangeDate}
                  >
                    Change date
                  </Button>
                </Box>
              )}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Publish Date"
                  disabled={userHasMadeChanges}
                  minDate={new Date(today)}
                  value={new Date(dateForPublish)}
                  onChange={(newDate) => setDateForPublish(newDate || today)}
                />
              </LocalizationProvider>
            </Box>
            <Box
              typography="bodyMRegular"
              display="flex"
              alignItems="center"
              sx={(theme) => ({ ml: 3, color: theme.palette.stationGray[300] })}
            >
              {!futureDated ? lastSaved : 'Will be active on ' + new Date(shiftTemplateDate).toDateString()}
            </Box>
          </>
        )}

        {!futureDated && (
          <Box sx={{ display: 'flex', gap: 1, minHeight: '40px', marginLeft: 'auto', marginRight: 0 }}>
            <Button variant="outlined" disabled={!userHasMadeChanges} onClick={resetValues} data-cy="cancel">
              Cancel
            </Button>
            <Button variant="contained" disabled={!userHasMadeChanges} onClick={saveChanges} data-cy="save">
              Save as draft
            </Button>
          </Box>
        )}
      </Box>
      {selectedTeam && <TeamColoredBar shiftTeam={selectedTeam} />}
    </Box>
  );
};
