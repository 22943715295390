import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { MultipleSelect, SelectItem } from '@stationwise/component-module';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { getFilterAndSortOptions } from './filterAndSortOptions';

interface FiltersProps {
  isSignUp: boolean;
  selectedIncludeOptions: string[];
  setSelectedIncludeOptions: (selected: string[]) => void;
  selectedExcludeOptions: string[];
  setSelectedExcludeOptions: (selected: string[]) => void;
  includePrevDate: boolean;
  setIncludePrevDate: (include: boolean) => void;
  includeNextDate: boolean;
  setIncludeNextDate: (include: boolean) => void;
  staffingListToIncludeIds: string[];
  setStaffingListToIncludeIds: (ids: string[]) => void;
  staffingListToExcludeIds: string[];
  setStaffingListToExcludeIds: (ids: string[]) => void;
  dialogOpen: boolean;
  staffingLists: ListFieldsStaffingList[];
  selectedStaffingList: ListFieldsStaffingList | undefined;
}

export const Filters = ({
  isSignUp,
  selectedIncludeOptions,
  setSelectedIncludeOptions,
  includeNextDate,
  setIncludeNextDate,
  includePrevDate,
  setIncludePrevDate,
  staffingListToIncludeIds,
  setStaffingListToIncludeIds,
  staffingListToExcludeIds,
  setStaffingListToExcludeIds,
  selectedExcludeOptions,
  setSelectedExcludeOptions,
  dialogOpen,
  staffingLists,
  selectedStaffingList,
}: FiltersProps) => {
  const staffingListSelectItems: SelectItem[] = staffingLists
    .filter((sl) => sl.id !== selectedStaffingList?.id)
    .map((sl) => ({ value: sl.id.toString(), label: sl.name }));

  const { includeOptions, excludeOptions } = getFilterAndSortOptions();
  const [availableIncludeOptions, setAvailableIncludeOptions] = useState<SelectItem[]>(includeOptions);
  const [availableExcludeOptions, setAvailableExcludeOptions] = useState<SelectItem[]>(excludeOptions);
  const [availableStaffingListsToInclude, setAvailableStaffingListsToInclude] = useState<SelectItem[]>(staffingListSelectItems);
  const [availableStaffingListsToExclude, setAvailableStaffingListsToExclude] = useState<SelectItem[]>(staffingListSelectItems);

  useEffect(() => {
    if (!dialogOpen) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  const reset = () => {
    setAvailableIncludeOptions(includeOptions);
    setAvailableExcludeOptions(excludeOptions);
  };

  useEffect(() => {
    setAvailableStaffingListsToExclude(staffingListSelectItems.filter((item) => !staffingListToIncludeIds.includes(item.value)));
    setAvailableStaffingListsToInclude(staffingListSelectItems.filter((item) => !staffingListToExcludeIds.includes(item.value)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffingListToIncludeIds, staffingListToExcludeIds]);

  const handleChangeIncludePrevDate = () => {
    setIncludePrevDate(!includePrevDate);
    setIncludeNextDate(false);
  };

  const handleChangeIncludeNextDate = () => {
    setIncludeNextDate(!includeNextDate);
    setIncludePrevDate(false);
  };
  return (
    <>
      <Box
        sx={(theme) => ({
          typography: 'bodyMSemibold',
          lineHeight: '20px',
          my: 2,
          color: theme.palette.stationGray[700],
        })}
      >
        Filters
      </Box>
      {!isSignUp && (
        <>
          <Box>
            <MultipleSelect
              items={availableIncludeOptions}
              selectedItems={selectedIncludeOptions}
              setSelectedItems={setSelectedIncludeOptions}
              placeholder=""
              hideValue
              showLabel
              inputLabel="Include"
            />
          </Box>
          {selectedIncludeOptions.includes('INCLUDE_PREV_OR_NEXT_SHIFT_DATE_EMPLOYEES') && (
            <Box sx={{ mt: 1 }}>
              <FormControlLabel
                control={<Checkbox onChange={handleChangeIncludePrevDate} checked={includePrevDate} />}
                label="Include previous shift date"
              />
              <FormControlLabel
                control={<Checkbox onChange={handleChangeIncludeNextDate} checked={includeNextDate} />}
                label="Include next shift date"
              />
              {!includeNextDate && !includePrevDate && (
                <Box sx={(theme) => ({ ml: 0.5, mt: 1, typography: 'bodyXSRegular', color: theme.palette.stationRed[500] })}>
                  {`* Please select previous or next shift date.`}
                </Box>
              )}
            </Box>
          )}
          {selectedIncludeOptions.includes('INCLUDE_OTHER_STAFFING_LIST') && (
            <Box sx={{ mt: 2 }}>
              <MultipleSelect
                items={availableStaffingListsToInclude}
                selectedItems={staffingListToIncludeIds}
                setSelectedItems={setStaffingListToIncludeIds}
                placeholder=""
                hideValue
                showLabel
                inputLabel="Select staffing lists to include"
              />
              {staffingListToIncludeIds.length === 0 && (
                <Box sx={(theme) => ({ ml: 0.5, mt: 1, typography: 'bodyXSRegular', color: theme.palette.stationRed[500] })}>
                  {`* Please select the staffing lists you want to include.`}
                </Box>
              )}
            </Box>
          )}
        </>
      )}
      <Box sx={{ mt: 2 }}>
        <MultipleSelect
          items={availableExcludeOptions}
          selectedItems={selectedExcludeOptions}
          setSelectedItems={setSelectedExcludeOptions}
          placeholder=""
          hideValue
          showLabel
          inputLabel="Exclude"
        />
      </Box>
      {selectedExcludeOptions.includes('EXCLUDE_OTHER_STAFFING_LIST') && (
        <Box sx={{ mt: 2 }}>
          <MultipleSelect
            items={availableStaffingListsToExclude}
            selectedItems={staffingListToExcludeIds}
            setSelectedItems={setStaffingListToExcludeIds}
            placeholder=""
            hideValue
            showLabel
            inputLabel="Select staffing lists to exclude"
          />
          {staffingListToExcludeIds.length === 0 && (
            <Box sx={(theme) => ({ ml: 0.5, mt: 1, typography: 'bodyXSRegular', color: theme.palette.stationRed[500] })}>
              {`* Please select the staffing lists you want to exclude.`}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
