import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { BigArrowRightIcon, BlankCard, Chip } from '@stationwise/component-module';

export const OvertimeRequestCard = ({
  shift,
  children,
  handleClick,
  cancelled,
}: {
  shift: EventInput | EventImpl;
  children?: React.ReactNode;
  handleClick?: (arg0: EventInput | EventImpl) => void;
  cancelled: boolean;
}) => {
  const backgroundColor = shift.backgroundColor;
  const theme = useTheme();
  return (
    <Box
      className="shift-card-box"
      sx={{
        ml: '16px',
        mr: '16px',
        pb: '16px',
      }}
      onClick={() => handleClick && handleClick(shift)}
    >
      <BlankCard>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Chip
            label={(shift.title || '').toUpperCase()}
            chipSxProps={() => ({
              background: backgroundColor ?? theme.palette.stationPurple[200],
              height: '24px',
              width: '110%',
              p: '4px 2px',
              borderRadius: '7px',
            })}
            variant="eyebrow"
            typographyStyles={{
              letterSpacing: '0.03em',
              color: shift.textColor ? shift.textColor : 'inherit',
            }}
          />
          {cancelled && (
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.stationGray[100],
                borderRadius: 4,
                padding: '0px 12px 0px 12px',
              })}
            >
              <Typography variant="bodySRegular">Cancelled</Typography>
            </Box>
          )}
        </Box>
        <Box display="flex">
          {shift.start?.toString() && <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'M/d/yy')}</Typography>}
          <Box sx={{ ml: '9px', mr: '9px', mt: '2px' }}>
            <BigArrowRightIcon />
          </Box>
          {shift.end?.toString() && <Typography variant="bodyMRegular">{format(shift.end?.toString(), 'M/d/yy')}</Typography>}
          <Box
            display="flex"
            sx={(theme) => ({
              ml: '10px',
              color: theme.palette.stationGray[400],
            })}
          >
            {shift.start?.toString() && (
              <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'HH:mm')}</Typography>
            )}
            <Box sx={{ ml: '3px', mr: '3px' }}>-</Box>
            {shift.end?.toString() && <Typography variant="bodyMRegular">{format(shift.end?.toString(), 'HH:mm')}</Typography>}
          </Box>
        </Box>
        {children}
      </BlankCard>
    </Box>
  );
};
