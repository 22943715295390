import { Box, useTheme } from '@mui/material';
import { ShiftPlanAction } from '@stationwise/share-types';
import { CreateButton } from '../../../../../components/Common/CreateButton';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanStation } from './ShiftPlanStation';
import { StructDialogs } from './StructDialogs';

export const RosterEditor = () => {
  const theme = useTheme();
  const { stations, assignmentGroups, setSelectedStruct, setSelectedAction } = useShiftPlanContext();
  const stationsList = Array.from(stations.values());
  const regularStationsList = stationsList.filter((s) => s.stationId !== 'floater-station');

  const onAddStation = () => {
    setSelectedStruct({});
    setSelectedAction(ShiftPlanAction.UPSERT_STATION);
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ flex: 1, height: '100%', position: 'relative', overflowY: 'auto' }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, 100%)',
            justifyContent: 'center',
            gap: theme.spacing(4.5, 0),
            p: 1.5,
            [theme.breakpoints.up('sm')]: {
              gridTemplateColumns: 'repeat(auto-fit, minmax(275px, max-content))',
              gap: theme.spacing(4.5, 1.5),
            },
          }}
        >
          {stationsList.map((station) => (
            <ShiftPlanStation
              key={station.stationId}
              station={station}
              assignmentGroups={assignmentGroups}
              isMoveApparatusPossible={regularStationsList.length > 1}
              setSelectedStruct={setSelectedStruct}
              setSelectedAction={setSelectedAction}
            />
          ))}
        </Box>
      </Box>
      <CreateButton onClick={onAddStation}>Add station</CreateButton>
      <StructDialogs />
    </Box>
  );
};
