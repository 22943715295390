import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { Button, SelectItem, SnackbarService } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { Filters } from './configuration/Filters';
import { Sorts } from './configuration/Sorts';
import { getFilterAndSortOptions } from './configuration/filterAndSortOptions';

interface StaffingListDialogProps {
  staffingListDialogOpen: boolean;
  setStaffingListDialogOpen: (open: boolean) => void;
  selectedStaffingList?: ListFieldsStaffingList;
  staffingLists: ListFieldsStaffingList[];
  forceRefetch: () => void;
  setSelectedStaffingList: (list: ListFieldsStaffingList) => void;
}

export const StaffingListDialog = ({
  staffingListDialogOpen,
  setStaffingListDialogOpen,
  selectedStaffingList,
  staffingLists,
  forceRefetch,
  setSelectedStaffingList,
}: StaffingListDialogProps) => {
  const { includeOptions, excludeOptions, tiebreakerRules } = getFilterAndSortOptions();

  const getIncludeOptions = () => {
    if (!selectedStaffingList) {
      return [];
    }

    return includeOptions.filter((option) => selectedStaffingList?.filters?.includes(option.value)).map((option) => option.value);
  };

  const getExcludeOptions = () => {
    if (!selectedStaffingList) {
      return [];
    }
    return excludeOptions.filter((option) => selectedStaffingList?.filters?.includes(option.value)).map((option) => option.value);
  };

  const [staffingListName, setStaffingListName] = useState<string>('');
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [isOptOut, setIsOptOut] = useState<boolean>(false);

  // filters
  const [selectedIncludeOptions, setSelectedIncludeOptions] = useState<string[]>([]);
  const [selectedExcludeOptions, setSelectedExcludeOptions] = useState<string[]>([]);
  const [staffingListToExcludeIds, setStaffingListToExcludeIds] = useState<string[]>([]);
  const [staffingListToIncludeIds, setStaffingListToIncludeIds] = useState<string[]>([]);
  const [includePrevDate, setIncludePrevDate] = useState<boolean>(false);
  const [includeNextDate, setIncludeNextDate] = useState<boolean>(false);

  // sorts
  const [selectedSortRule, setSelectedSortRule] = useState<string>('');
  const [selectedTiebreakerRules, setSelectedTiebreakerRules] = useState<SelectItem[]>([]);

  // overtime thresholds
  const [isMinHours, setIsMinHours] = useState<boolean>(false);
  const [isAccHours, setIsAccHours] = useState<boolean>(false);
  const [overtimeThreshold, setOvertimeThreshold] = useState<number>(0);

  // total overtime rules
  const [daysAgo, setDaysAgo] = useState<number>(0);
  const [totalOvertimeIntervalStartDates, setTotalOvertimeIntervalStartDates] = useState<Date[]>([]);

  const [error, setError] = useState({ duplicatedStaffingList: false });

  const [savedStaffingList, setSavedStaffingList] = useState<ListFieldsStaffingList | null>(null);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);

  // avoid duplicated names
  const existingStaffingListNames = staffingLists
    .filter((sl) => sl.id !== selectedStaffingList?.id)
    .map((sl) => sl.name.toLowerCase());

  useEffect(() => {
    if (staffingListDialogOpen) {
      initializeStates();
    } else {
      resetStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffingListDialogOpen, selectedStaffingList]);

  useEffect(() => {
    // After reloading the lists the one we just edited is selected
    if (savedStaffingList) {
      const newSelectedStaffingList = staffingLists.find((sl) => sl.id === savedStaffingList.id);
      if (newSelectedStaffingList) {
        setSelectedStaffingList(newSelectedStaffingList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffingLists, savedStaffingList]);

  useEffect(() => {
    checkSaveIsDisabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    staffingListName,
    selectedIncludeOptions,
    selectedExcludeOptions,
    includeNextDate,
    includePrevDate,
    daysAgo,
    totalOvertimeIntervalStartDates,
    staffingListToExcludeIds,
    staffingListToIncludeIds,
    selectedSortRule,
    selectedTiebreakerRules,
  ]);

  const checkSaveIsDisabled = () => {
    if (
      !staffingListName ||
      error.duplicatedStaffingList ||
      (selectedIncludeOptions.includes('INCLUDE_PREV_OR_NEXT_SHIFT_DATE_EMPLOYEES') && !includeNextDate && !includePrevDate) ||
      (selectedIncludeOptions.includes('INCLUDE_OTHER_STAFFING_LIST') && staffingListToIncludeIds.length === 0) ||
      (selectedExcludeOptions.includes('EXCLUDE_OTHER_STAFFING_LIST') && staffingListToExcludeIds.length === 0) ||
      ((selectedSortRule === 'TOTAL_OVERTIME' || selectedTiebreakerRules.map((tr) => tr.value).includes('TOTAL_OVERTIME')) &&
        daysAgo === 0 &&
        totalOvertimeIntervalStartDates.length === 0)
    ) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  };
  const initializeStates = () => {
    setStaffingListName(selectedStaffingList?.name || '');
    // filters
    setSelectedExcludeOptions(getExcludeOptions());
    setSelectedIncludeOptions(getIncludeOptions());

    setStaffingListToIncludeIds(selectedStaffingList?.staffingListToIncludeIds?.map((id) => id.toString()) || []);
    setStaffingListToExcludeIds(selectedStaffingList?.staffingListToExcludeIds?.map((id) => id.toString()) || []);

    // overtime threshold
    let threshold = 0;
    if (selectedStaffingList?.params?.overtimeMinHoursThreshold && selectedStaffingList?.params?.overtimeMinHoursThreshold > 0) {
      threshold = selectedStaffingList?.params?.overtimeMinHoursThreshold;
      setIsMinHours(true);
    } else if (
      selectedStaffingList?.params?.overtimeAccHoursThreshold &&
      selectedStaffingList?.params?.overtimeAccHoursThreshold > 0
    ) {
      threshold = selectedStaffingList?.params?.overtimeAccHoursThreshold;
      setIsAccHours(true);
    }
    setOvertimeThreshold(threshold);

    // sorts
    setSelectedSortRule(
      selectedStaffingList?.sorts && selectedStaffingList.sorts.length > 0 ? selectedStaffingList.sorts[0] : '',
    );

    setSelectedTiebreakerRules(
      selectedStaffingList?.sorts
        ? selectedStaffingList?.sorts?.slice(1).map((option) => ({
            value: option,
            label: tiebreakerRules.find((tiebreaker) => tiebreaker.value === option)?.label || '',
          }))
        : [],
    );

    // checkboxes
    setIsSignUp(selectedStaffingList?.isSignUp || false);
    setIsOptOut(selectedStaffingList?.isOptOut || false);
    setIncludePrevDate(selectedStaffingList?.params?.shiftDateChoice === 'PREVIOUS' || false);
    setIncludeNextDate(selectedStaffingList?.params?.shiftDateChoice === 'NEXT' || false);

    // total overtime rules
    const overtimeIntervalStarts = selectedStaffingList?.params?.overtimeIntervalStarts;
    if (overtimeIntervalStarts) {
      setDaysAgo(overtimeIntervalStarts.find((start) => start.ago)?.day || 0);
      const overtimeStarts: Date[] = [];

      overtimeIntervalStarts.forEach((start) => {
        if (!start.ago && start.month) {
          const newDate = new Date();
          newDate.setDate(start.day);
          newDate.setMonth(start.month - 1);
          overtimeStarts.push(newDate);
        }
      });

      setTotalOvertimeIntervalStartDates(overtimeStarts);
    } else {
      setDaysAgo(0);
    }
  };

  const resetStates = () => {
    setSelectedSortRule('');
    setSelectedTiebreakerRules([]);
    setOvertimeThreshold(0);
    setIsMinHours(false);
    setIsAccHours(false);
    setSelectedIncludeOptions([]);
    setSelectedExcludeOptions([]);
    setIsOptOut(false);
    setIsSignUp(false);
    setTotalOvertimeIntervalStartDates([]);
    setDaysAgo(0);
    setIncludePrevDate(false);
    setIncludeNextDate(false);
    setStaffingListToExcludeIds([]);
    setStaffingListToIncludeIds([]);
  };

  const onClose = () => {
    setStaffingListDialogOpen(false);
  };

  const handleChangeIsSignup = () => {
    setIsSignUp((prev) => !prev);
    setIsOptOut(false);
    setSelectedIncludeOptions([]);
    setStaffingListToIncludeIds([]);
  };

  const handleChangeIsOptout = () => {
    setIsOptOut((prev) => !prev);
    setIsSignUp(false);
  };

  const handleSave = () => {
    saveChanges();
    onClose();
  };

  const saveChanges = async () => {
    try {
      const config = buildConfigObject();

      // edition
      if (selectedStaffingList) {
        await client.patch(`/staffing-list/staffing-lists/${selectedStaffingList.id}/`, config);
        setSavedStaffingList(selectedStaffingList);
      }
      // creation
      else {
        const response = await client.post('/staffing-list/staffing-lists/', config);
        setSavedStaffingList(response.data);
      }
      forceRefetch();

      SnackbarService.notify({
        content: 'The staffing list was saved successfully.',
        severity: 'success',
        duration: 3000,
      });
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: `An error occurred while saving staffing list changes.`,
        severity: 'error',
        duration: 5000,
      });
    }
  };

  const buildConfigObject = () => {
    const allFilters = selectedIncludeOptions.concat(selectedExcludeOptions);
    let params = {};

    if (selectedIncludeOptions.includes('INCLUDE_PREV_OR_NEXT_SHIFT_DATE_EMPLOYEES') && (includeNextDate || includePrevDate)) {
      params = {
        ...params,
        shiftDateChoice: includeNextDate ? 'NEXT' : 'PREVIOUS',
      };
    }
    if (overtimeThreshold > 0 && (isMinHours || isAccHours)) {
      params = isAccHours
        ? { ...params, overtimeAccHoursThreshold: overtimeThreshold }
        : { ...params, overtimeMinHoursThreshold: overtimeThreshold };
    }
    // add overtime_interval_starts param
    if (selectedSortRule === 'TOTAL_OVERTIME' || selectedTiebreakerRules.map((tr) => tr.value).includes('TOTAL_OVERTIME')) {
      const overtimeIntervalStarts = [];

      if (daysAgo > 0) {
        overtimeIntervalStarts.push({ day: daysAgo, ago: true });
      }
      totalOvertimeIntervalStartDates.forEach((date) =>
        overtimeIntervalStarts.push({ month: date.getMonth() + 1, day: date.getDate() }),
      );

      params = { ...params, overtimeIntervalStarts: overtimeIntervalStarts };
    }

    return {
      name: staffingListName,
      filters: allFilters,
      sorts: selectedSortRule ? [selectedSortRule].concat(selectedTiebreakerRules.map((tr) => tr.value)) : [],
      params: params,
      isOptOut: isOptOut,
      isSignUp: isSignUp,
      staffingListToIncludeIds: selectedIncludeOptions.includes('INCLUDE_OTHER_STAFFING_LIST')
        ? staffingListToIncludeIds.map((id) => Number(id))
        : [],
      staffingListToExcludeIds: selectedExcludeOptions.includes('EXCLUDE_OTHER_STAFFING_LIST')
        ? staffingListToExcludeIds.map((id) => Number(id))
        : [],
    };
  };

  return (
    <Dialog open={staffingListDialogOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{!selectedStaffingList ? 'Create staffing list' : `Edit ${selectedStaffingList.name} list`}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            pt: 1.5,
            mb: 1,
            justifyContent: 'center',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            '.MuiFormControl-root': {
              width: '100%',
              '.MuiInputLabel-root': {
                top: '-5px',
              },
            },
          }}
        >
          <TextField
            label="Name"
            value={staffingListName}
            error={error.duplicatedStaffingList}
            helperText={error.duplicatedStaffingList ? 'This staffing list already exists, please choose another name.' : ''}
            onChange={(event) => {
              setStaffingListName(event.currentTarget.value);
              if (existingStaffingListNames.includes(event.currentTarget.value.toLowerCase())) {
                setError({ ...error, duplicatedStaffingList: true });
              } else if (error.duplicatedStaffingList) {
                setError({ ...error, duplicatedStaffingList: false });
              }
            }}
            required
            fullWidth={true}
          />
        </Box>
        <FormControlLabel control={<Checkbox onChange={handleChangeIsSignup} checked={isSignUp} />} label="Is sign up list" />
        <FormControlLabel control={<Checkbox onChange={handleChangeIsOptout} checked={isOptOut} />} label="Is opt out list" />
        <Filters
          selectedExcludeOptions={selectedExcludeOptions}
          setSelectedExcludeOptions={setSelectedExcludeOptions}
          staffingListToExcludeIds={staffingListToExcludeIds}
          setStaffingListToExcludeIds={setStaffingListToExcludeIds}
          selectedIncludeOptions={selectedIncludeOptions}
          setSelectedIncludeOptions={setSelectedIncludeOptions}
          staffingListToIncludeIds={staffingListToIncludeIds}
          setStaffingListToIncludeIds={setStaffingListToIncludeIds}
          isSignUp={isSignUp}
          includeNextDate={includeNextDate}
          setIncludeNextDate={setIncludeNextDate}
          includePrevDate={includePrevDate}
          setIncludePrevDate={setIncludePrevDate}
          staffingLists={staffingLists}
          selectedStaffingList={selectedStaffingList}
          dialogOpen={staffingListDialogOpen}
        />
        <Sorts
          selectedSortRule={selectedSortRule}
          setSelectedSortRule={setSelectedSortRule}
          selectedTiebreakerRules={selectedTiebreakerRules}
          setSelectedTiebreakerRules={setSelectedTiebreakerRules}
          isAccHours={isAccHours}
          setIsAccHours={setIsAccHours}
          isMinHours={isMinHours}
          setIsMinHours={setIsMinHours}
          overtimeThreshold={overtimeThreshold}
          setOvertimeThreshold={setOvertimeThreshold}
          daysAgo={daysAgo}
          setDaysAgo={setDaysAgo}
          totalOvertimeIntervalStartDates={totalOvertimeIntervalStartDates}
          setTotalOvertimeIntervalStartDates={setTotalOvertimeIntervalStartDates}
          dialogOpen={staffingListDialogOpen}
        />
      </DialogContent>
      <DialogActions sx={{ mx: 1, padding: 2, gap: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="outlined" size="large" onClick={onClose} sx={{ width: '100%' }}>
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={handleSave} sx={{ width: '100%' }} disabled={saveDisabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
