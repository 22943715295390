import { Box } from '@mui/material';
import { PreviewButton, ViewProfileButton } from '@stationwise/component-module';

interface ShiftTemplateActionsProps {
  employeeId: string;
  onUnassignClick: () => void;
}

export const ShiftTemplateActions = ({ onUnassignClick, employeeId }: ShiftTemplateActionsProps) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
      <ViewProfileButton sx={{ flex: 1 }} employeeId={employeeId} />
      <PreviewButton sx={{ flex: 1 }} variant="contained" onClick={onUnassignClick}>
        Unassign
      </PreviewButton>
    </Box>
  );
};
