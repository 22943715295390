import React from 'react';

interface Props {
  paneId: string;
  children: React.ReactNode;
}

export const PaneContentWrapper = ({ children }: Props) => {
  return children;
};
