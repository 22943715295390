import { Box, List, ListItem } from '@mui/material';
import { useState, Dispatch, SetStateAction } from 'react';
import { Button, CalendarIcon16, ChevronDownIcon20 } from '@stationwise/component-module';

interface RequestMultipleDaysProps {
  setRequestMultipleDaysOff: Dispatch<SetStateAction<boolean>>;
  selectedTimeOffDates: string[];
  setSelectedTimeOffDates: Dispatch<SetStateAction<string[]>>;
  setTimeOffRequestOpen: Dispatch<SetStateAction<boolean>>;
}

export const RequestMultipleDays = ({
  setRequestMultipleDaysOff,
  selectedTimeOffDates,
  setSelectedTimeOffDates,
  setTimeOffRequestOpen,
}: RequestMultipleDaysProps) => {
  const [isListView, setIsListView] = useState(false);
  const iosWebViewPaddingBottom = 0;

  const handleOnClose = () => {
    setRequestMultipleDaysOff(false);
    setIsListView(false);
  };
  const handleShowMoreClick = () => {
    setIsListView(!isListView);
  };

  const handleRequestOvertime = () => {
    handleOnClose();
    setTimeOffRequestOpen(true);
  };

  // The `spacer` ensures the `content` doesn't overlap with whatever is rendered above this component.
  // The height of the `spacer` is roughly the height of the `content` when `See all` is in the collapsed state.
  const spacer = <Box sx={{ height: '148px' }} />;
  const content = (
    <Box
      sx={(theme) => ({
        position: 'fixed',
        bottom: iosWebViewPaddingBottom,
        width: 'calc(100% - 16px)',
        margin: theme.spacing(1),
        border: '1px solid #DEE3ED',
        borderRadius: '16px',
        boxShadow: '0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
        background: theme.palette.common.white,
        zIndex: theme.zIndex.modal,
        maxWidth: '768px',
        left: '50%',
        display: 'flex',
        alignItems: 'center',
        transform: 'translateX(-50%)',
      })}
    >
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          typography: 'bodyMMedium',
        })}
      >
        Select the dates you would like to request off.
      </Box>

      {/* TODO do we want to show the dates in a list view? */}
      {false && selectedTimeOffDates.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
          }}
        >
          {isListView ? (
            <List sx={{ p: 0 }}>
              {selectedTimeOffDates.map((date, index) => (
                <ListItem key={index} sx={{ pb: 0 }}>
                  <Box
                    sx={(theme) => ({
                      mr: theme.spacing(1),
                      display: 'flex',
                      '& svg': {
                        '& path': {
                          stroke: theme.palette.stationGray[400],
                        },
                      },
                    })}
                  >
                    <CalendarIcon16 />
                  </Box>
                  <Box sx={{ typography: 'bodyMRegular' }}>{date}</Box>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box
              sx={(theme) => ({
                px: theme.spacing(2),
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '65%',
                typography: 'bodyMRegular',
              })}
            >
              {selectedTimeOffDates.join(', ')}
            </Box>
          )}

          <Box
            onClick={handleShowMoreClick}
            sx={(theme) => ({
              color: theme.palette.stationPurple[600],
              alignItems: 'center',
              justifyContent: 'center',
              px: theme.spacing(2),
            })}
          >
            {isListView ? (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.stationPurple[600],
                    },
                  },
                })}
              >
                <ChevronDownIcon20 />
                <Box
                  sx={(theme) => ({
                    ml: theme.spacing(0.5),
                    typography: 'bodyMMedium',
                  })}
                >
                  Collapse
                </Box>
              </Box>
            ) : (
              <Box
                sx={(theme) => ({
                  ml: theme.spacing(0.5),
                  typography: 'bodyMMedium',
                })}
              >
                See all
              </Box>
            )}
          </Box>
        </Box>
      )}

      <Box
        sx={(theme) => ({
          width: '100%',
          p: theme.spacing(1),
          justifyContent: 'center',
          display: 'flex',
        })}
      >
        <Box
          sx={(theme) => ({
            width: '50%',
            m: theme.spacing(1),
          })}
        >
          <Button
            data-cy="request-overtime-cancel-button"
            variant="outlined"
            onClick={() => {
              setSelectedTimeOffDates([]);
              handleOnClose();
            }}
            sx={{ width: '100%' }}
          >
            Cancel
          </Button>
        </Box>
        <Box sx={(theme) => ({ width: '50%', m: theme.spacing(1) })}>
          <Button
            data-cy="request-multiple-days-off-submit-button"
            variant="contained"
            disabled={selectedTimeOffDates?.length > 1 ? false : true}
            onClick={() => handleRequestOvertime()}
            sx={{ width: '100%' }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
  return (
    <>
      {spacer}
      {content}
    </>
  );
};
