/* eslint-disable no-restricted-imports */
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  alpha,
} from '@mui/material';

export { ToggleButton } from '@mui/material';

export type ToggleButtonGroupProps = MuiToggleButtonGroupProps;

export const ToggleButtonGroup = ({ children, sx, ...props }: ToggleButtonGroupProps) => {
  const { color = 'standard', orientation = 'horizontal', size = 'medium' } = props;

  return (
    <MuiToggleButtonGroup
      {...props}
      sx={[
        (theme) => ({
          // Action color overrides won't be necessary after we fix them in `themeConfig.ts`.
          '& .MuiToggleButton-root': {
            color: 'rgba(0, 0, 0, 0.54)',
            textTransform: 'none',
            ...(size === 'large' && { typography: 'buttonL', minHeight: '42px' }),
            ...(size === 'medium' && { typography: 'buttonM', minHeight: '36px' }),
            ...(size === 'small' && { typography: 'buttonS', minHeight: '30px' }),
          },
          '& .MuiToggleButton-root:hover': {
            background: alpha(theme.palette.text.primary, 0.04),
          },
          '& .MuiToggleButton-root.Mui-disabled': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
          },
          '& .MuiToggleButton-root.MuiToggleButtonGroup-middleButton.Mui-disabled': {
            ...(orientation === 'horizontal' && { borderLeftColor: 'transparent' }),
            ...(orientation === 'vertical' && { borderTopColor: 'transparent' }),
          },
          '& .MuiToggleButton-root.MuiToggleButtonGroup-lastButton.Mui-disabled': {
            ...(orientation === 'horizontal' && { borderLeftColor: 'transparent' }),
            ...(orientation === 'vertical' && { borderTopColor: 'transparent' }),
          },
          '& .MuiToggleButton-root.Mui-selected': {
            background: alpha(color === 'standard' ? theme.palette.text.primary : theme.palette[color].main, 0.08),
            color: color === 'standard' ? theme.palette.text.primary : theme.palette[color].main,
          },
          '& .MuiToggleButton-root.Mui-selected:hover': {
            background: alpha(color === 'standard' ? theme.palette.text.primary : theme.palette[color].main, 0.12),
          },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </MuiToggleButtonGroup>
  );
};
