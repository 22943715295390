import { Box } from '@mui/material';
import { useState } from 'react';
import { theme, Button, Upload03Icon20, ClockRewindIcon20, useAuthUserCapabilities } from '@stationwise/component-module';
import { EmployeePayroll } from '@stationwise/share-types';
import { EmployeePayrollExportModal } from './EmployeePayrollExportModal';

interface EmployeePayrollTableHeaderContentProps {
  employeePayroll: EmployeePayroll;
  setOpenChangeLog: (value: boolean) => void;
}

export const EmployeePayrollTableHeaderContent = ({
  employeePayroll,
  setOpenChangeLog,
}: EmployeePayrollTableHeaderContentProps) => {
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const capabilities = useAuthUserCapabilities();
  const canViewChangeLog = capabilities.VIEW_CHANGE_LOGS;

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      })}
    >
      <Box
        sx={{
          typography: 'bodyXXLMedium',
          color: theme.palette.stationGray[900],
        }}
      >
        Payroll
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
        }}
      >
        {canViewChangeLog && (
          <Button
            variant="outlined"
            sx={(theme) => ({ gap: theme.spacing(1), '& svg path': { stroke: 'currentColor' } })}
            onClick={() => setOpenChangeLog(true)}
          >
            <ClockRewindIcon20 />
            Change log
          </Button>
        )}
        <EmployeePayrollExportModal
          exportModelOpen={exportModalOpen}
          setExportModelOpen={setExportModalOpen}
          employeePayroll={employeePayroll}
        />
        <Button
          variant="contained"
          sx={(theme) => ({ gap: theme.spacing(1), '& svg path': { stroke: 'currentColor' } })}
          onClick={() => setExportModalOpen(true)}
        >
          <Upload03Icon20 />
          Export time card
        </Button>
      </Box>
    </Box>
  );
};
