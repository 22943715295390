export const AUTO_HIRE_HISTORY_STATUS = {
  TO_BE_NOTIFIED: 'TO_BE_NOTIFIED',
  SIGNED_UP: 'SIGNED_UP',
  NOTIFIED: 'NOTIFIED',
  SKIPPED: 'SKIPPED',
  NOW_CHOOSING: 'NOW_CHOOSING',
};

export const AUTO_HIRE_STATUS = {
  IN_QUEUE: 'IN_QUEUE',
  IN_PROGRESS: 'IN_PROGRESS',
  ALL_NOTIFIED: 'ALL_NOTIFIED',
  INACTIVE: 'INACTIVE',
  COMPLETED: 'COMPLETED',
};
