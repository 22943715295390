import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { client } from '@stationwise/share-api';
import { RosterTemporaryNonShiftAssignment, ShiftSummary } from '@stationwise/share-types';
import { IShiftSummaryHelper } from '@stationwise/shift-summary-helper';

export const useSaveTemporaryNonShiftAssignments = (shiftSummary: ShiftSummary, shiftSummaryHelper: IShiftSummaryHelper) => {
  const [assignments, setAssignments] = useState<RosterTemporaryNonShiftAssignment[]>([]);
  const [cancelledAssignmentIds, setCancelledAssignmentIds] = useState<number[]>([]);

  const resetState = useCallback(() => {
    setAssignments([]);
    setCancelledAssignmentIds([]);
  }, []);

  const addAssignment = useCallback((assignment: RosterTemporaryNonShiftAssignment) => {
    setAssignments((prevAssignments) => [...prevAssignments, assignment]);
  }, []);

  const removeAssignment = useCallback((candidateId: string) => {
    setAssignments((prevAssignments) => prevAssignments.filter((a) => a.candidateId !== candidateId));
  }, []);

  const cancelAssignment = useCallback((assignmentId: number) => {
    setCancelledAssignmentIds((prevAssignmentIds) => [...prevAssignmentIds, assignmentId]);
  }, []);

  const save = async () => {
    const date = format(shiftSummaryHelper.shiftDuration.startTime, 'yyyy-MM-dd');
    if (assignments.length > 0) {
      await client.post('/non-shift/temporary-lists/', { date, shiftSummaryShiftDateId: shiftSummary.id, assigns: assignments });
    }
  };

  return { assignments, cancelledAssignmentIds, addAssignment, removeAssignment, cancelAssignment, save, resetState };
};
