import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { Button, SnackbarService, XCloseIcon24 } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { StaffingListItemEmployee } from '@stationwise/share-types';
import { EligibleEmployeesContent } from './EligibleEmployeesContent';

interface RequestVolunteersEligibleEmployeesMainProps {
  selectedDate: string;
  showRequestVolunteersEligibleEmployees: boolean;
  setShowRequestVolunteersEligibleEmployees: (show: boolean) => void;
  requestVolunteersIsLoading: boolean;
  setRequestVolunteersIsLoading: (loading: boolean) => void;
  forceRefetch: () => void;
  setRequestVolunteersIsOpen: (open: boolean) => void;
}

export const RequestVolunteersEligibleEmployeesMain = ({
  selectedDate,
  showRequestVolunteersEligibleEmployees,
  setShowRequestVolunteersEligibleEmployees,
  requestVolunteersIsLoading,
  setRequestVolunteersIsLoading,
  forceRefetch,
  setRequestVolunteersIsOpen,
}: RequestVolunteersEligibleEmployeesMainProps) => {
  const [eligibleEmployees, setEligibleEmployees] = useState<StaffingListItemEmployee[]>([]);
  const requestVolunteers = async () => {
    try {
      setRequestVolunteersIsLoading(true);
      const response = await client.post(`/shift/bulk-call/`, {
        shiftDate: selectedDate,
        eligibleEmployeeIds: eligibleEmployees.map((employee) => employee.id),
      });
      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });
      forceRefetch();
      setRequestVolunteersIsLoading(false);
      setRequestVolunteersIsOpen(false);
      setShowRequestVolunteersEligibleEmployees(false);
    } catch (error) {
      SnackbarService.notify({
        content: 'Error requesting volunteers',
        severity: 'error',
      });
      captureException(error);
      setRequestVolunteersIsLoading(false);
      setRequestVolunteersIsOpen(false);
      setShowRequestVolunteersEligibleEmployees(false);
    }
  };
  const handleClose = () => {
    setShowRequestVolunteersEligibleEmployees(false);
  };

  return (
    <Dialog
      open={showRequestVolunteersEligibleEmployees}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '660px',
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Box sx={{ typography: 'bodyXLSemibold', textAlign: 'center' }}>Request volunteers</Box>
          <Box
            onClick={handleClose}
            sx={(theme) => ({
              color: theme.palette.stationGray[400],
              cursor: 'pointer',
              display: 'inline',
              '&:hover': {
                color: theme.palette.stationGray[700],
              },
            })}
          >
            <XCloseIcon24 />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <EligibleEmployeesContent setEligibleEmployees={setEligibleEmployees} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={requestVolunteers}
          variant="contained"
          size="large"
          sx={{ width: '100%' }}
          disabled={eligibleEmployees.length === 0}
          loading={requestVolunteersIsLoading}
        >
          Send Volunteer Request
        </Button>
      </DialogActions>
    </Dialog>
  );
};
