import { EventClickArg, EventInput, EventSourceFuncArg } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, isSameDay, isValid, parseISO } from 'date-fns';
import { useRef, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';
import { client } from '@stationwise/share-api';
import { GetMyScheduledCalendarDataView, GetShiftTeamsView, Team, ListFieldsStaffingList } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { CalendarShiftCard } from '../CalendarShiftCard';
import { useLoadedDepartmentInfoContext } from '../Department/context/DepartmentInfo';
import { StatusBadge } from '../StatusBadge';
import { CustomSelectDayCellContent } from './CustomSelectDayCellContent';
import { Header as CalendarHeader } from './Header';
import { splitEvents, isPastDate, isEventSelectDisabled, parseDateParam, formatDate, isFutureDate } from './calendarHelper';
import { SHIFT_TITLES, STATUS_TITLES } from './constants';
import './calendar.css';

export type DisplayOption = 'listMonth' | 'dayGridMonth';

export interface PayPeriodDateInfo {
  startDate: string;
  endDate: string;
}
export interface DisplayOptionObj {
  displayName: string;
  value: DisplayOption;
}
const displayOptions: DisplayOptionObj[] = [
  { displayName: 'month', value: 'dayGridMonth' },
  { displayName: 'list', value: 'listMonth' },
];

export const getDisplayOptionByName = (name: string) => {
  const option = displayOptions.find((option) => option.displayName === name);
  return option || displayOptions[0];
};

export const getDisplayOptionByValue = (value: DisplayOption) => {
  const option = displayOptions.find((option) => option.value === value);
  return option || displayOptions[0];
};

export const getStatus = (status: string, isWaitlist = false) => {
  switch (status) {
    case STATUS_TITLES.APPROVED:
      return 'Approved';
    case STATUS_TITLES.DENIED:
      return 'Denied';
    case STATUS_TITLES.USER_CANCELLED:
      return 'Cancelled';
    case isWaitlist && (STATUS_TITLES.USER_MESSAGE_SENT || STATUS_TITLES.POSTPONED):
      return 'On waitlist';
    default:
      return 'Pending';
  }
};

export const Calendar = ({
  displayDayEvents,
  getDateIntervalEvents,
  handleRequestOvertimeClick,
  handleCreateIncidentClick,
  setSelectedOvertimeDates,
  setSelectedIncidentDate,
  setSelectedTimeOffDates,
  selectedDate,
  selectedOvertimeDates,
  selectedIncidentDate,
  selectedTimeOffDates,
  selectedView,
  selectedStaffingList,
  createIncidentOpen,
  setCreateIncidentOpen,
  setRefetchEvents,
  refetchEvents = false,
  setSelectedDate,
  setSelectedEvent,
  setSelectedView,
  heightOfCalendar,
  isLoading,
  setIsLoading,
  fetchPayPeriods,
  createIncident,
  setCreateIncident,
  viewingPersonalCalendar = false,
  requestMultipleDaysOff = false,
  setRequestMultipleDaysOff,
  setDrawerOpen,
  handleShiftOverviewOpen,
}: {
  displayDayEvents: (dayEvents: EventInput[]) => void;
  handleRequestOvertimeClick?: (staffingList: ListFieldsStaffingList) => void;
  handleCreateIncidentClick?: () => void;
  getDateIntervalEvents: (fetchInfo: EventSourceFuncArg) => Promise<GetMyScheduledCalendarDataView[]>;
  setSelectedOvertimeDates?: Dispatch<SetStateAction<string[]>>;
  setSelectedIncidentDate?: Dispatch<SetStateAction<string>>;
  setSelectedTimeOffDates?: Dispatch<SetStateAction<string[]>>;
  selectedDate: Date;
  selectedOvertimeDates?: string[];
  selectedIncidentDate?: string;
  selectedTimeOffDates?: string[];
  selectedView: DisplayOption;
  selectedStaffingList?: ListFieldsStaffingList | null;
  createIncidentOpen?: boolean;
  setCreateIncidentOpen?: (value: boolean) => void;
  setRefetchEvents: Dispatch<SetStateAction<boolean>>;
  refetchEvents: boolean;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  setSelectedEvent: Dispatch<SetStateAction<EventInput | EventImpl>>;
  setSelectedView: Dispatch<SetStateAction<DisplayOption>>;
  heightOfCalendar: number;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  fetchPayPeriods: (startDate: Date, endDate: Date) => Promise<PayPeriodDateInfo[]>;
  createIncident?: boolean;
  setCreateIncident?: (value: boolean) => void;
  viewingPersonalCalendar?: boolean;
  requestMultipleDaysOff?: boolean;
  setRequestMultipleDaysOff?: (value: boolean) => void;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  handleShiftOverviewOpen?: (event: React.MouseEvent<HTMLElement>, shift: EventInput | EventImpl) => void;
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarRef = useRef<InstanceType<typeof FullCalendar>>(null);
  const [title, setTitle] = useState('');
  const [shiftTeams, setShiftTeams] = useState<Map<string, Team>>(new Map());
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  // add 1 second to the department.shiftStart to avoid displaying the event on the next day
  const nextDayThreshold = `${departmentInfoState.departmentInfo.shiftStart}:01`;
  const theme = useTheme();
  const splittedEvents = useRef<EventInput[]>([]);
  const [payPeriodDates, setPayPeriodDates] = useState<PayPeriodDateInfo[]>([]);

  const selectedDateRef = useRef(selectedDate);
  selectedDateRef.current = selectedDate;

  const createShiftTeamMap = (shiftDateTeamsView: GetShiftTeamsView[]) => {
    const shiftTeamMap = new Map<string, Team>();

    // hide team badge for dates with multiple teams to avoid confusion
    shiftDateTeamsView
      .filter((shiftDateTeams) => shiftDateTeams.shiftTeams.length === 1)
      .forEach((shiftDateTeams) => {
        shiftTeamMap.set(shiftDateTeams.date, shiftDateTeams.shiftTeams[0]);
      });
    setShiftTeams(shiftTeamMap);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const api = calendarRef?.current?.getApi();
    api && setTitle(api.view.title);

    const parsedDate = parseDateParam(searchParams.get('date') || '');
    if (format(parsedDate, 'yyyy-MM-dd') !== format(selectedDate, 'yyyy-MM-dd')) {
      setSelectedDate(parsedDate);

      //if the date change is coming from the url editing and not the calendar interface
      if (api) {
        queueMicrotask(() => {
          api.gotoDate(parsedDate);
          setTitle(api.view.title);
        });
      }
    }

    const currentDisplayOption = getDisplayOptionByValue(selectedView);
    const newDisplayOption = getDisplayOptionByName(searchParams.get('display') || '');
    if (newDisplayOption !== currentDisplayOption) {
      setSelectedView(newDisplayOption.value);
      if (api) {
        queueMicrotask(() => {
          api.changeView(newDisplayOption.value, parsedDate);
          setTitle(api.view.title);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (isMounted) {
      const isDateValid = isValid(parseISO(searchParams.get('date') || ''));
      const isDisplayValid = displayOptions.some((option) => option.displayName === searchParams.get('display'));
      if (!isDateValid || !isDisplayValid) {
        setSearchParams((prevSearchParams) => {
          const newSearchParams = new URLSearchParams(prevSearchParams);
          !isDateValid && newSearchParams.set('date', format(selectedDate, 'yyyy-MM-dd'));
          !isDisplayValid && newSearchParams.set('display', getDisplayOptionByValue(selectedView).displayName);
          return newSearchParams;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useEffect(() => {
    if (refetchEvents) {
      const api = calendarRef?.current?.getApi();
      if (api) {
        queueMicrotask(() => {
          api.refetchEvents();
          setRefetchEvents(false);
        });
      }
    }
  }, [setRefetchEvents, refetchEvents]);

  const setDateInUrl = (urlDate: Date, day = true) => {
    const dateStr = format(urlDate, day ? 'yyyy-MM-dd' : 'yyyy-MM');
    if (!viewingPersonalCalendar) {
      setSearchParams((prevSearchParams) => {
        const newSearchParams = new URLSearchParams(prevSearchParams);
        newSearchParams.set('date', dateStr);
        return newSearchParams;
      });
    }
    setSelectedDate(parseDateParam(dateStr));
  };

  const nextHandle = () => {
    const api = calendarRef?.current?.getApi();
    if (api) {
      api.next();
      setDateInUrl(api.view.currentStart, false);
      setTitle(api.view.title);
    }
  };

  const prevHandle = () => {
    const api = calendarRef?.current?.getApi();
    if (api) {
      api.prev();
      setDateInUrl(api.view.currentStart, false);
      setTitle(api.view.title);
    }
  };

  const filterDayEvents = (dateStr: string): EventInput[] => {
    const date = new Date(dateStr);
    return splittedEvents.current.filter((e) => {
      return e.start && isSameDay(new Date(e.start.toString()), date);
    });
  };

  const handleSelectDate = (date: Date) => {
    if (!createIncident && handleSelectAllow(date) && setSelectedOvertimeDates && selectedStaffingList) {
      const formattedDate = format(date, 'MM/dd/yy');
      if (selectedOvertimeDates?.includes(formattedDate)) {
        setSelectedOvertimeDates((prevDates) => prevDates.filter((date) => date !== formattedDate));
      } else {
        setSelectedOvertimeDates((prevDates) => [...prevDates, formattedDate]);
      }
    }

    if (!createIncident && handleSelectAllow(date) && setSelectedTimeOffDates && requestMultipleDaysOff) {
      const formattedDate = format(date, 'MM/dd/yy');
      if (selectedTimeOffDates?.includes(formattedDate)) {
        setSelectedTimeOffDates((prevDates) => prevDates.filter((date) => date !== formattedDate));
      } else {
        setSelectedTimeOffDates((prevDates) => [...prevDates, formattedDate]);
      }
    }

    if (createIncidentOpen && handleSelectAllow(date) && setSelectedIncidentDate) {
      setSelectedIncidentDate(format(date, 'MM/dd/yy'));
    }
  };

  const fetchShiftTeams = async (startDate: Date, endDate: Date, battalionId: number | null): Promise<GetShiftTeamsView[]> => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    let result: GetShiftTeamsView[] = [];
    await client
      .get('/shift/shift-teams/', {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          battalionId: battalionId,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        captureException(error);
      });
    return result;
  };

  // NOTE: This handler is called when an individual event in a day cell is clicked. It's different from clicking the
  // day cell containing the event. That handler is found in the <FullCalendar> `views.dayGridMonth.dateClick` field.
  const handleEventClick = (eventClickArg: EventClickArg) => {
    if (!requestMultipleDaysOff && eventClickArg.event) {
      setSelectedEvent(eventClickArg.event);
    }
    if (!requestMultipleDaysOff && !selectedStaffingList && !createIncidentOpen) {
      const dayEvents = filterDayEvents(eventClickArg.event.startStr);
      displayDayEvents(dayEvents);
      eventClickArg.event.start && setDateInUrl(eventClickArg.event.start);
      if (selectedView !== 'listMonth') {
        setDrawerOpen(true);
      }
    }
    if (selectedStaffingList || createIncidentOpen || requestMultipleDaysOff) {
      eventClickArg.event.start && handleSelectDate(eventClickArg.event.start);
    }
  };

  const handleDateClick = (dateClickArg: DateClickArg) => {
    if (!isLoading) {
      if (!selectedStaffingList && !createIncidentOpen && !requestMultipleDaysOff) {
        const dayEvents = filterDayEvents(`${dateClickArg.dateStr}T00:00:00`);
        displayDayEvents(dayEvents);
        setDateInUrl(dateClickArg.date);
        setDrawerOpen(true);
      }
      if (selectedStaffingList || createIncidentOpen || requestMultipleDaysOff) {
        handleSelectDate(dateClickArg.date);
      }
    }
  };

  const getEvent = (date: Date) => {
    const formattedDate = format(date, 'MM/dd/yy');
    const api = calendarRef?.current?.getApi();
    if (api) {
      const existingEvent = api.getEvents().find((event) => format(event.start as Date, 'MM/dd/yy') === formattedDate);
      return existingEvent;
    }
    return null;
  };

  const hasEvent = (date: Date) => {
    const formattedDate = format(date, 'MM/dd/yy');
    const api = calendarRef?.current?.getApi();
    if (api) {
      const existingEvent = api.getEvents().find((event) => {
        return format(event.start as Date, 'MM/dd/yy') === formattedDate;
      });
      if (existingEvent === undefined) {
        return false;
      }
      return isEventSelectDisabled(existingEvent.title);
    }
    return false;
  };

  const handleSelectAllow = (date: Date) => {
    const inPast = createIncident ? isFutureDate(date) : isPastDate(date);
    const existingEvent = !createIncident && hasEvent(date);

    if (requestMultipleDaysOff) {
      return !inPast && getEvent(date)?.title === SHIFT_TITLES.REGULAR && !hasTimeOffRequest(date);
    }

    return !existingEvent && !inPast;
  };

  const getStatusBadge = (event: EventInput) => {
    const status = getStatus(event['status']);
    return <StatusBadge status={status} />;
  };

  const hasTimeOffRequest = (date: Date) => {
    const api = calendarRef?.current?.getApi();
    if (api) {
      const events = api.getEvents().filter((event) => format(event.start as Date, 'MM/dd/yy') === format(date, 'MM/dd/yy'));
      return events.some((event) => event.extendedProps.eventType === 'TIME_OFF_REQUEST');
    }
    return false;
  };

  return (
    <Box
      className={`calendar-${selectedView} user`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
      }}
    >
      <CalendarHeader
        selectedView={selectedView}
        nextHandle={nextHandle}
        prevHandle={prevHandle}
        title={title}
        handleRequestOvertimeClick={handleRequestOvertimeClick}
        handleCreateIncidentClick={handleCreateIncidentClick}
        isLoading={isLoading}
        setCreateIncident={(value: boolean) => setCreateIncident && setCreateIncident(value)}
        setCreateIncidentOpen={(value: boolean) => setCreateIncidentOpen && setCreateIncidentOpen(value)}
        createIncidentOpen={createIncidentOpen}
        selectedStaffingList={selectedStaffingList}
        viewingPersonalCalendar={viewingPersonalCalendar}
        setRequestMultipleDaysOff={setRequestMultipleDaysOff}
        requestMultipleDaysOff={requestMultipleDaysOff}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <FullCalendar
          ref={calendarRef}
          displayEventTime={false}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          selectable={createIncident ? createIncidentOpen : !!selectedStaffingList}
          loading={(loading: boolean) => {
            if (loading !== isLoading) setIsLoading(loading);
          }}
          height={`${heightOfCalendar}px`}
          timeZone="local"
          dayMaxEventRows={true}
          progressiveEventRendering={true}
          initialDate={selectedDate}
          initialEvents={(fetchInfo, successCallback, failureCallback) => {
            Promise.all([
              fetchShiftTeams(fetchInfo.start, fetchInfo.end, null),
              fetchPayPeriods(fetchInfo.start, fetchInfo.end),
            ]).then(([shiftTeams, payPeriodDateInfo]) => {
              createShiftTeamMap(shiftTeams);
              setPayPeriodDates(payPeriodDateInfo);
            });
            displayDayEvents([]);
            getDateIntervalEvents(fetchInfo)
              .then((events) => {
                //splitted multiday events to be displayed in monthly view as different slots
                const splitted = splitEvents(events, theme);
                splittedEvents.current = splitted;

                const dayEvents = filterDayEvents(selectedDateRef.current.toISOString());

                const api = calendarRef?.current?.getApi();

                // if we are standing in the current month then display today events cards below the calendar
                if (
                  api?.view.currentStart.getMonth() === selectedDateRef.current.getMonth() &&
                  api?.view.currentStart.getFullYear() === selectedDateRef.current.getFullYear()
                ) {
                  displayDayEvents(dayEvents);
                }
                successCallback(splitted);
              })
              .catch((err) => {
                captureException(err);
                failureCallback(err);
              });
          }}
          firstDay={0}
          initialView={selectedView}
          locale="en-US"
          headerToolbar={false}
          views={{
            dayGridMonth: {
              titleFormat: { month: 'short', year: 'numeric' },
              dateClick: handleDateClick,
              eventClick: handleEventClick,
              fixedWeekCount: false,
              nextDayThreshold: nextDayThreshold,
              dayCellContent: (dayCellContentArg) => (
                <CustomSelectDayCellContent
                  cellContent={dayCellContentArg}
                  selectedDates={(() => {
                    if (selectedOvertimeDates && selectedOvertimeDates.length > 0) {
                      return selectedOvertimeDates;
                    } else if (selectedTimeOffDates && selectedTimeOffDates.length > 0) {
                      return selectedTimeOffDates;
                    } else if (selectedIncidentDate) {
                      return [selectedIncidentDate];
                    }
                    return [];
                  })()}
                  shiftTeamMap={shiftTeams}
                  payPeriodDates={payPeriodDates}
                />
              ),
              dayCellClassNames: (dayCellContentArg) => {
                if (selectedStaffingList || createIncidentOpen || requestMultipleDaysOff) {
                  const cellDate = dayCellContentArg.date;
                  const formatCellDate = format(cellDate, 'MM/dd/yy');
                  if (
                    selectedOvertimeDates?.includes(formatCellDate) ||
                    selectedIncidentDate === formatCellDate ||
                    selectedTimeOffDates?.includes(formatCellDate)
                  ) {
                    return 'selected-cell';
                  } else if (
                    (selectedStaffingList && (isPastDate(cellDate) || hasEvent(cellDate))) ||
                    (createIncidentOpen && isFutureDate(cellDate)) ||
                    (requestMultipleDaysOff &&
                      (isPastDate(cellDate) ||
                        !(getEvent(cellDate)?.title === SHIFT_TITLES.REGULAR) ||
                        hasTimeOffRequest(cellDate)))
                  ) {
                    return 'disabled-cell';
                  }
                } else if (isSameDay(selectedDate, dayCellContentArg.date)) {
                  return 'selected-cell-border';
                }
                return '';
              },
              eventContent: (eventContentArg) => {
                // NOTE: Overriding the default event content to add a `data-cy` test identifier
                return (
                  <div className="fc-event-main-frame">
                    <div className="fc-event-title-container">
                      <div
                        className="fc-event-title fc-sticky"
                        data-cy={`event-${makeTestIdentifier(eventContentArg.event.title)}`}
                      >
                        {eventContentArg.event.title}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            listMonth: {
              titleFormat: { month: 'short', year: 'numeric' },
              eventClick: handleEventClick,
              nextDayThreshold: nextDayThreshold,
              listDayFormat: {
                day: 'numeric',
                weekday: 'long',
              },
              listDaySideFormat: false,
              eventContent: (eventContentArg) => {
                let statusBadge = null;
                if (
                  eventContentArg.event.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME ||
                  eventContentArg.event.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME_REQUEST
                ) {
                  statusBadge = getStatusBadge(eventContentArg.event as EventInput);
                }
                return (
                  <CalendarShiftCard
                    handleClick={handleShiftOverviewOpen}
                    shift={eventContentArg.event}
                    statusBadge={statusBadge}
                  />
                );
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
