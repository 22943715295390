import { Box, BoxProps } from '@mui/material';

export const COLUMNS = [
  { name: '', width: '24px', whiteSpace: 'nowrap', align: 'right' },
  { name: 'Name', width: '127px', whiteSpace: 'nowrap' },
  { name: 'Rank', width: '40px', whiteSpace: 'nowrap' },
  { name: 'Certifications', width: '130px', whiteSpace: 'nowrap' },
  { name: 'Notes', width: '120px', whiteSpace: 'nowrap' },
];

interface CellProps extends BoxProps<'div'> {
  colIndex: number;
}

export const Cell = ({ children, colIndex, sx, ...props }: CellProps) => {
  const column = COLUMNS[colIndex];
  return (
    <Box
      {...props}
      sx={[
        (theme) => ({
          alignSelf: 'center',
          flex: column.width === 'auto' ? '1' : undefined,
          width: column.width,
          p: theme.spacing(0, 0.5),
          pr: column.name === 'Certifications' ? 4.5 : 0.5,
          typography: 'bodyXSRegular',
          lineHeight: 1,
          whiteSpace: column.whiteSpace,
          textAlign: column.align || 'left',
          '&:first-of-type': { pl: 0 },
          '&:last-of-type': { pr: 0 },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
