import { ReactNode, createContext, useContext } from 'react';
import { UseShiftPlanReturnType } from '../hooks/useShiftPlan';

const ShiftPlanContext = createContext<UseShiftPlanReturnType | null>(null);

interface ShiftPlanContextProviderProps {
  value: UseShiftPlanReturnType;
  children: ReactNode;
}

export const ShiftPlanContextProvider = ({ value, children }: ShiftPlanContextProviderProps) => {
  return <ShiftPlanContext.Provider value={value}>{children}</ShiftPlanContext.Provider>;
};

export const useShiftPlanContext = () => {
  const context = useContext(ShiftPlanContext);
  if (!context) {
    throw new Error('useShiftPlanContext must be within a ShiftPlanContextProvider');
  }
  return context;
};
