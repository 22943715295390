import { Box } from '@mui/material';
import { Button } from '@stationwise/component-module';

interface PrintDayScheduleModalFooterProps {
  handleClose: () => void;
}

export const PrintDayScheduleModalFooter = ({ handleClose }: PrintDayScheduleModalFooterProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
      <Button
        variant="contained"
        size="large"
        onClick={() => {
          handleClose();
          setTimeout(() => {
            window.print();
          }, 50);
        }}
      >
        Print
      </Button>
    </Box>
  );
};
