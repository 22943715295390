import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { isAxiosError } from 'axios';
import { format } from 'date-fns';
import { ReactNode, useState } from 'react';
import { SnackbarService, Modal, useLoadedDepartmentInfoContext, Loader } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { WarningModal } from '../../../../components/Common';
import { CreateButton } from '../../../../components/Common/CreateButton';
import { ShiftTemplateContextProvider } from '../contexts/ShiftTemplateContext';
import { ShiftTemplateSummary } from '../hooks/useFetchCurrentShiftTemplate';
import { useShiftTemplate } from '../hooks/useShiftTemplate';
import { getUpdatedPositionIds } from '../positions';
import { PublishPreview } from '../preview';
import { PublishingPreviewDetail } from './Publishing/PublishingPreviewDetail';
import { PublishingEffectsDetail } from './PublishingEffectsDetail';
import { ShiftTemplateBoard } from './ShiftTemplateBoard';
import { StationDialog } from './Station/StationDialog';
import { TopBar } from './TopBar';

interface ISaveShiftTemplatePayload {
  employeeId: number | null;
  apparatusId: number | null;
  positionId: number | null;
  startTime: number;
  endTime: number;
  isTemporary: boolean;
  payCodes: number[];
  detailCodes?: number[];
}

export type shiftTemplateAction =
  | 'publish_warning'
  | 'publish'
  | 'discard'
  | 'cancel_future_dated_warning'
  | 'cancel_future_dated'
  | 'change_date_warning'
  | 'change_date';

const shiftTemplateActionButtonTextMap: { [key in shiftTemplateAction]: string } = {
  publish_warning: 'Publish',
  publish: 'Publish',
  discard: 'Discard',
  cancel_future_dated_warning: 'Approve Cancellation',
  cancel_future_dated: 'Approve Cancellation',
  change_date_warning: 'Change Date',
  change_date: 'Change Date',
};

const shiftTemplateActionsWarningMappings: Map<shiftTemplateAction, shiftTemplateAction> = new Map([
  ['publish_warning', 'publish'],
  ['cancel_future_dated_warning', 'cancel_future_dated'],
  ['change_date_warning', 'change_date'],
]);

interface ShiftTemplateContentProps {
  shiftTemplateSummary: ShiftTemplateSummary;
  forceRefetch: (teamId: string, battalionId: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export const ShiftTemplateContent = ({ shiftTemplateSummary, forceRefetch, setIsLoading }: ShiftTemplateContentProps) => {
  const shiftTemplate = useShiftTemplate({
    shiftTemplateSummary: shiftTemplateSummary,
  });
  const { isStationDialogOpen, setIsStationDialogOpen, setSelectedStation, addAssignmentForNewlyCreatedPosition } = shiftTemplate;
  const shiftTemplateInfo = shiftTemplate.getShiftTemplateInfo();
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const [warning, setWarning] = useState<{
    message: string;
    secondaryMessage?: string;
    extraInfo?: ReactNode;
  } | null>(null);
  const [selectedAction, setSelectedAction] = useState<shiftTemplateAction>('publish_warning');

  const [publishPreview, setPublishPreview] = useState<PublishPreview>();

  const [dateForPublish, setDateForPublish] = useState<Date>(
    shiftTemplateInfo.activationDate ? shiftTemplateInfo.activationDate : shiftTemplateInfo.date,
  );

  const lastSaved =
    shiftTemplateInfo.updatedAt && shiftTemplateInfo.updatedBy
      ? `Last saved by ${shiftTemplateInfo.updatedBy} on ${format(shiftTemplateInfo.updatedAt, 'MMM d')} at ${format(shiftTemplateInfo.updatedAt, 'HH:mm')}`
      : '';

  const { dispatch } = useLoadedDepartmentInfoContext();

  const changeAllStationCardsToShiftTemplateSummary = () => {
    let payload: ISaveShiftTemplatePayload[] = [];

    // Map each position to add to payload
    const { allStationCards, shiftDuration } = shiftTemplate.shiftTemplateHelper;
    Array.from(allStationCards.values()).forEach((station) => {
      // Not Temporary Positions
      station.apparatuses.forEach((apparatus) => {
        apparatus.positions
          .filter((position) => !position.isTemporary)
          .forEach((position) => {
            if ((station.isNewlyCreated && apparatus.isNewlyCreated) || position.isNewlyCreated) {
              addAssignmentForNewlyCreatedPosition(position, apparatus, station);
            } else if (position.employees.length > 0) {
              const employeeInPosition = position.employees.map((employee) => {
                return station.stationId === 'floater-station'
                  ? {
                      employeeId: Number(employee.id) || null,
                      apparatusId: null,
                      positionId: null,
                      startTime: differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime),
                      endTime: differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime),
                      isTemporary: false,
                      payCodes: employee.payCodes.map((pc) => pc.id),
                    }
                  : {
                      employeeId: employee.id ? Number(employee.id) : null,
                      apparatusId: Number(apparatus.id),
                      positionId: Number(position.id),
                      startTime: differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime),
                      endTime: differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime),
                      isTemporary: false,
                      payCodes: employee.payCodes.map((pc) => pc.id),
                      detailCodes: employee.detailCodes.map((detailCode) => detailCode.id),
                    };
              });

              payload = [...payload, ...employeeInPosition];
            } else {
              payload.push({
                employeeId: null,
                apparatusId: Number(apparatus.id),
                positionId: Number(position.id),
                startTime: 0,
                endTime: 24 * 60,
                isTemporary: false,
                payCodes: [],
              });
            }
          });
      });

      //Temporary Positions
      station.apparatuses.forEach((apparatus) => {
        apparatus.positions
          .filter((position) => position.isTemporary)
          .forEach((position) => {
            if (position.isNewlyCreated || apparatus.isNewlyCreated || station.isNewlyCreated) {
              addAssignmentForNewlyCreatedPosition(position, apparatus, station);
            } else {
              const employee = position.employees[0];
              payload.push({
                employeeId: employee.id ? Number(employee.id) : null,
                apparatusId: Number(apparatus.id),
                positionId: null,
                startTime: differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime),
                endTime: differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime),
                isTemporary: true,
                payCodes: employee.payCodes.map((pc) => pc.id),
                detailCodes: employee.detailCodes.map((detailCode) => detailCode.id),
              });
            }
          });
      });
    });
    return payload;
  };

  const saveChanges = async () => {
    try {
      setIsLoading(true);
      const assignments = changeAllStationCardsToShiftTemplateSummary();
      // we need to get all the positions that have been updated to bypass the initial backend validation since certification requirements may be different now
      const updatedPositionIds = getUpdatedPositionIds(shiftTemplate.manageStationsPayload);

      const response = await client.post('/shift/shift-template/', {
        assignments,
        shiftTemplateId: shiftTemplate.getShiftTemplateInfo().id,
        ccVersion: shiftTemplate.getShiftTemplateInfo().ccVersion,
        teamId: shiftTemplate.currentTeams[0].id,
        battalionId: shiftTemplate.currentBattalion.id,
        updatedApparatusModes: shiftTemplate.updatedApparatusModes,
        manageStationsPayload: shiftTemplate.manageStationsPayload,
        updatedPositionIds: updatedPositionIds,
      });
      forceRefetch(shiftTemplate.currentTeams[0].id.toString(), shiftTemplate.currentBattalion.id.toString());
      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      const message = isAxiosError(error) ? error.response?.data?.message || error.message : 'Error saving changes';

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
      setIsLoading(false);
    }
  };

  const executePublishOrDiscard = async (action: shiftTemplateAction) => {
    try {
      const response = await client.patch(`/shift/shift-template/${shiftTemplate.getShiftTemplateInfo().id}/`, {
        isPublish: action === 'publish',
        ccVersion: shiftTemplate.getShiftTemplateInfo().ccVersion,
        publishDate: format(dateForPublish, 'yyyy-MM-dd'),
      });

      forceRefetch(shiftTemplate.currentTeams[0].id.toString(), shiftTemplate.currentBattalion.id.toString());

      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });

      dispatch({ type: 'REFETCH_DEPARTMENT_INFO' }); // will update the stations in each DeparmentInfo.battalions to include the new ones
    } catch (error) {
      setIsLoading(false);
      const message = isAxiosError(error)
        ? error.response?.data?.error || error.message
        : `Error ${action === 'publish' ? 'publishing' : 'discarding'} shift template`;

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
    }
  };

  const executeCancelFutureDated = async () => {
    try {
      const response = await client.delete(`/shift/shift-template/${shiftTemplate.getShiftTemplateInfo().id}/`, {
        params: {
          ccVersion: shiftTemplate.getShiftTemplateInfo().ccVersion,
        },
      });

      forceRefetch(shiftTemplate.currentTeams[0].id.toString(), shiftTemplate.currentBattalion.id.toString());

      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });

      dispatch({ type: 'REFETCH_DEPARTMENT_INFO' }); // will update the stations in each DeparmentInfo.battalions to include the new ones
    } catch (error) {
      setIsLoading(false);
      const message = isAxiosError(error) ? error.response?.data?.error || error.message : `Error cancelling shift template`;

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
    }
  };

  const executeChangeDate = async () => {
    try {
      const response = await client.put(`/shift/shift-template/${shiftTemplate.getShiftTemplateInfo().id}/`, {
        publishDate: format(dateForPublish, 'yyyy-MM-dd'),
        ccVersion: shiftTemplate.getShiftTemplateInfo().ccVersion,
      });
      forceRefetch(shiftTemplate.currentTeams[0].id.toString(), shiftTemplate.currentBattalion.id.toString());

      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });

      dispatch({ type: 'REFETCH_DEPARTMENT_INFO' }); // will update the stations in each DeparmentInfo.battalions to include the new ones
    } catch (error) {
      setIsLoading(false);
      const message = isAxiosError(error)
        ? error.response?.data?.error || error.message
        : `Error changing date of shift template`;

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
    }
  };

  const onChangeDate = async () => {
    setWarning({
      message: 'You are about to change date of a future dated shift template',
      secondaryMessage: 'Once published it will become active on ' + new Date(dateForPublish).toDateString(),
      extraInfo: <PublishingEffectsDetail />,
    });
    setSelectedAction('change_date_warning');
    setWarningModalOpen(true);
  };

  const onPublish = () => {
    setWarning({
      message: 'You are about to publish a new shift template',
      secondaryMessage: 'Once published it will become active on ' + new Date(dateForPublish).toDateString(),
      extraInfo: <PublishingEffectsDetail />,
    });
    setSelectedAction('publish_warning');
    setWarningModalOpen(true);
  };

  const onCancelFutureDated = async () => {
    setWarning({
      message: 'You are about to cancel a future dated shift template',
      secondaryMessage: 'Once cancelled it will be discarded immediately',
      extraInfo: <PublishingEffectsDetail isCancel={true} />,
    });
    setSelectedAction('cancel_future_dated_warning');
    setWarningModalOpen(true);
  };

  const onDiscard = () => {
    setWarning({
      message: 'You are about to discard your shift template draft',
      secondaryMessage: 'Are you sure?',
    });
    setSelectedAction('discard');
    setWarningModalOpen(true);
  };

  async function loadPreview(action: shiftTemplateAction) {
    try {
      const response = await client.get(`/shift/shift-template/preview/${shiftTemplate.getShiftTemplateInfo().id}/`, {
        params: {
          ccVersion: shiftTemplate.getShiftTemplateInfo().ccVersion,
          publishDate: format(dateForPublish, 'yyyy-MM-dd'),
          action: action,
        },
      });
      const data = await response.data;
      setPublishPreview(data);
    } catch (error) {
      const message = isAxiosError(error)
        ? error.response?.data?.error || error.message
        : `Error previewing shift template changes`;

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
      setWarningModalOpen(false);
    }
  }

  const executeSelectedAction = async () => {
    if (shiftTemplateActionsWarningMappings.has(selectedAction)) {
      setWarning({
        message: 'Changes preview',
        secondaryMessage: 'Following changes will be applied',
      });
      setSelectedAction(shiftTemplateActionsWarningMappings.get(selectedAction) || 'publish');
      await loadPreview(shiftTemplateActionsWarningMappings.get(selectedAction) || 'publish');
      setWarningModalOpen(true);
    } else {
      setWarningModalOpen(false);
      setIsLoading(true);
      if (selectedAction === 'publish' || selectedAction === 'discard') {
        await executePublishOrDiscard(selectedAction);
      } else if (selectedAction === 'cancel_future_dated') {
        await executeCancelFutureDated();
      } else if (selectedAction === 'change_date') {
        await executeChangeDate();
      }
    }
  };

  const handleAddStation = () => {
    setSelectedStation(null);
    setIsStationDialogOpen(true);
  };

  return (
    <ShiftTemplateContextProvider shiftTemplate={shiftTemplate}>
      <Box>
        <TopBar
          saveChanges={saveChanges}
          forceRefetch={forceRefetch}
          today={shiftTemplateSummary.date}
          lastSaved={lastSaved}
          onPublish={onPublish}
          futureDated={shiftTemplate.getShiftTemplateInfo().futureDated}
          onChangeDate={onChangeDate}
          dateForPublish={dateForPublish}
          setDateForPublish={setDateForPublish}
          onDiscard={onDiscard}
          isCurrentlyActive={shiftTemplate.getShiftTemplateInfo().isCurrentlyActive}
          onCancelFutureDated={onCancelFutureDated}
          shiftTemplateDate={shiftTemplate.getShiftTemplateInfo().activationDate}
        />
      </Box>
      <Box
        sx={
          shiftTemplate.getShiftTemplateInfo().futureDated
            ? { pointerEvents: 'none', filter: 'invert(25%)', flex: 1, position: 'relative' }
            : { flex: 1, position: 'relative', overflow: 'hidden' }
        }
      >
        <ShiftTemplateBoard />
      </Box>
      {!shiftTemplate.getShiftTemplateInfo().futureDated && <CreateButton onClick={handleAddStation}>Add station</CreateButton>}
      {isStationDialogOpen && <StationDialog />}
      {warning && (
        <Modal open={warningModalOpen} setOpen={setWarningModalOpen}>
          <WarningModal
            hideButtons={!publishPreview && selectedAction === 'publish'}
            setModalOpen={setWarningModalOpen}
            onConfirm={executeSelectedAction}
            confirmButtonText={shiftTemplateActionButtonTextMap[selectedAction]}
          >
            <Box sx={(theme) => ({ width: '450px', mb: theme.spacing(3) })}>
              <Box sx={{ typography: 'bodyXXLSemibold' }}>{warning.message}</Box>
              <Box sx={(theme) => ({ typography: 'bodyMRegular', mt: theme.spacing(2) })}>{warning.secondaryMessage}</Box>
              {warning.extraInfo && warning.extraInfo}
              {(selectedAction === 'publish' || selectedAction === 'cancel_future_dated' || selectedAction === 'change_date') &&
                (publishPreview ? <PublishingPreviewDetail publishPreview={publishPreview} /> : <Loader />)}
            </Box>
          </WarningModal>
        </Modal>
      )}
    </ShiftTemplateContextProvider>
  );
};
