import { Box, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, memo, useEffect, useRef, useState } from 'react';
import { TeamFlag, useLoadedAuthUserContext } from '@stationwise/component-module';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { capitalize, makeTestIdentifier } from '@stationwise/share-utils';

interface PersonalStaffingListItemsProps {
  isLoading: boolean;
  selectedStaffingList: ListFieldsStaffingList | undefined;
  shouldScrollToMeItem: boolean;
  setShouldScrollToMeItem: Dispatch<SetStateAction<boolean>>;
}

export const PersonalStaffingListItems = memo((props: PersonalStaffingListItemsProps) => {
  const { shouldScrollToMeItem, setShouldScrollToMeItem } = props;
  const theme = useTheme();
  const { state: authUserState } = useLoadedAuthUserContext();
  const meItemRef = useRef<HTMLDivElement | null>(null);
  const [isMeItemHighlighted, setIsMeItemHighlighted] = useState(false);

  useEffect(() => {
    if (shouldScrollToMeItem) {
      meItemRef.current?.scrollIntoView({ behavior: 'smooth' });
      setIsMeItemHighlighted(true);
      setShouldScrollToMeItem(false);
    }
  }, [shouldScrollToMeItem, setShouldScrollToMeItem]);

  return (
    <Box sx={{ mt: 2, visibility: props.isLoading ? 'hidden' : undefined }}>
      {props.selectedStaffingList?.items?.map((item) => {
        const isMeItem = item.employee.id === authUserState.employee.id;

        return (
          <Box
            key={item.order}
            data-cy={`${makeTestIdentifier(item.employee.name)}-staffing-list-item`}
            ref={isMeItem ? meItemRef : null}
            sx={{
              background: isMeItem && isMeItemHighlighted ? theme.palette.stationGray[100] : undefined,
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              mx: -2,
              p: theme.spacing(1.5, 2),
            }}
          >
            <Box
              sx={{
                background: theme.palette.stationGray[600],
                color: theme.palette.common.white,
                borderRadius: '50%',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                typography: 'bodySMedium',
              }}
            >
              {item.order}
            </Box>
            <Box sx={{ flex: 1, typography: 'bodyLMedium' }}>{item.employee.name}</Box>
            {item.employee.team && (
              <>
                <Box sx={{ color: theme.palette.stationGray[500], mr: -0.5, typography: 'bodySMedium' }}>
                  {capitalize(item.employee.team.name)}
                </Box>
                <TeamFlag color={item.employee.team.color} />
              </>
            )}
          </Box>
        );
      })}
    </Box>
  );
});

PersonalStaffingListItems.displayName = 'Memo(PersonalStaffingListItems)';
