import { Box, Switch, Tooltip, useTheme } from '@mui/material';
import { useRosterContext } from '@stationwise/component-module';

const getAlignVerticalCenter01Icon16URL = (color: string) => {
  const svg = encodeURIComponent(
    `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 8.00065H14M8 1.33398V5.66732M8 5.66732L10.6667 3.00065M8 5.66732L5.33333 3.00065M8 14.6673V10.334M8 10.334L10.6667 13.0007M8 10.334L5.33333 13.0007" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  );
  return `url('data:image/svg+xml,${svg}')`;
};

export const CollapseSwitch = () => {
  const { preferences } = useRosterContext();
  const theme = useTheme();
  return (
    <Tooltip
      arrow
      title="Collapse"
      placement="bottom"
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.stationGray[800],
            borderRadius: 2,
            p: '10px 12px',
            typography: 'bodySMedium',
            '& .MuiTooltip-arrow': { color: theme.palette.stationGray[800] },
          },
        },
      }}
    >
      <Box sx={{ display: 'inline-flex' }}>
        <Switch
          checked={preferences.isCollapsed}
          onChange={(event) => preferences.setIsCollapsed(event.target.checked)}
          sx={(theme) => ({
            width: 56,
            height: 32,
            p: 0,
            '& .MuiSwitch-switchBase': {
              p: 0.5,
              '&:hover': { backgroundColor: 'transparent' },
              '&.Mui-checked': {
                transform: 'translateX(24px)',
                color: theme.palette.common.white,
                '& .MuiSwitch-thumb::before': {
                  backgroundImage: getAlignVerticalCenter01Icon16URL(theme.palette.stationGray[900]),
                },
                '& + .MuiSwitch-track': { backgroundColor: theme.palette.stationBlue[600], opacity: 1 },
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: '0px 1px 2px 0px #0A0E160F, 0px 1px 3px 0px #0A0E161A',
              width: 24,
              height: 24,
              '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: getAlignVerticalCenter01Icon16URL(theme.palette.stationGray[400]),
              },
            },
            '& .MuiSwitch-track': { borderRadius: '9999px', backgroundColor: theme.palette.stationGray[200], opacity: 1 },
          })}
        />
      </Box>
    </Tooltip>
  );
};
