import { Box, ButtonProps as AddNoteButtonProps } from '@mui/material';
import { ElementType } from 'react';
import { Pencil01Icon16 } from '../../../../../assets';
import { PreviewButton } from '../../../../EmployeePreview';

export const AddNoteButton = <E extends ElementType>({ sx, ...props }: AddNoteButtonProps<E>) => {
  return (
    <PreviewButton {...props} sx={[{ gap: 0.5 }, ...(Array.isArray(sx) ? sx : sx ? [sx] : [])]}>
      <Box component={Pencil01Icon16} sx={{ '& path': { stroke: 'currentColor' } }} />
      Add note
    </PreviewButton>
  );
};
