import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState, useRef, useEffect } from 'react';
import { theme, Button, SnackbarService, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { AutoHireIntervalPicker } from '../../HiringEngine/AutoHire/AutoHireIntervalPicker';

export const HiringEngineDepartmentSettingsTab = () => {
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();
  const [orders, setOrders] = useState<number[]>([]);
  const [roundDuration, setRoundDuration] = useState(30);
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [initialOrders, setInitialOrders] = useState<number[]>([]);
  const [initialRoundDuration, setInitialRoundDuration] = useState(30);

  useEffect(() => {
    const fetchDefaultSettings = async () => {
      try {
        const response = await client.get('auto-hire/default-settings/');
        setOrders(response.data.autoHireDefaultRankProcessOrder || []);
        setRoundDuration(response.data.autoHireDefaultNotificationInterval || 30);
        setInitialOrders(response.data.autoHireDefaultRankProcessOrder || []);
        setInitialRoundDuration(response.data.autoHireDefaultNotificationInterval || 30);
      } catch (error) {
        SnackbarService.notify({
          content: 'Failed to fetch auto hire settings',
          severity: 'error',
          duration: 5000,
        });
        console.error('Failed to fetch auto hire settings:', error);
        captureException(error);
      }
    };
    fetchDefaultSettings();
  }, []);

  const handleSaveSettings = async () => {
    setIsSaving(true);
    try {
      await client.post('auto-hire/save-default-settings/', {
        rank_process_order: orders,
        notification_interval: roundDuration,
      });

      setInitialOrders([...orders]);
      setInitialRoundDuration(roundDuration);

      SnackbarService.notify({
        content: 'Auto hire settings saved successfully',
        severity: 'success',
        duration: 2000,
      });
    } catch (error) {
      SnackbarService.notify({
        content: 'Failed to save auto hire settings',
        severity: 'error',
        duration: 5000,
      });
      console.error('Failed to save auto hire settings:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSort = () => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const updatedOrders = [...orders];
      const draggedItemContent = updatedOrders.splice(dragItem.current, 1)[0];
      updatedOrders.splice(dragOverItem.current, 0, draggedItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setOrders(updatedOrders);
    }
  };

  const hasChanges = () => {
    if (orders.length !== initialOrders.length) return true;
    const ordersChanged = orders.some((order, index) => order !== initialOrders[index]);
    const durationChanged = roundDuration !== initialRoundDuration;
    return ordersChanged || durationChanged;
  };

  return (
    <Box sx={{ height: '100%', p: theme.spacing(3) }}>
      {/* Alert Box */}
      <Box sx={{ maxWidth: '1200px', mb: theme.spacing(3) }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            p: theme.spacing(2),
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.stationBlue[100],
            border: `1px solid ${theme.palette.stationBlue[200]}`,
          })}
        >
          <Typography
            sx={(theme) => ({
              color: theme.palette.stationBlue[700],
              typography: 'bodySRegular',
            })}
          >
            These are global hiring engine settings. Changes will only affect future auto hire processes and will not impact any
            currently active ones.
          </Typography>
        </Box>
      </Box>

      {/* Settings Container */}
      <Box sx={{ maxWidth: '1200px', width: '100%' }}>
        <Box sx={{ display: 'flex', gap: theme.spacing(3), justifyContent: 'space-between' }}>
          {/* Rank Order Section */}
          <Box sx={{ flex: '0 0 400px' }}>
            <Typography variant="h6" sx={{ mb: theme.spacing(2) }}>
              Auto Hire Rank Call Order
            </Typography>
            <Box
              sx={(theme) => ({
                p: theme.spacing(2),
                borderRadius: theme.spacing(1),
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.stationGray[200]}`,
                minHeight: '400px',
                display: 'flex',
                flexDirection: 'column',
              })}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.palette.stationGray[600],
                  typography: 'bodySRegular',
                  mb: theme.spacing(2),
                })}
              >
                Drag and drop to reorder the ranks. Auto hire will notify ranks in this order.
              </Typography>
              <Box sx={{ flex: 1, overflow: 'auto', pr: theme.spacing(1) }}>
                {orders.map((rankId, index) => {
                  const rank = departmentInfo.ranks.find((r) => r.id === rankId);
                  return (
                    <Box
                      key={index}
                      draggable
                      onDragStart={() => (dragItem.current = index)}
                      onDragEnter={() => (dragOverItem.current = index)}
                      onDragEnd={handleSort}
                      onDragOver={(e) => e.preventDefault()}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 0.5,
                        backgroundColor: theme.palette.stationGray[50],
                        p: theme.spacing(1),
                        borderRadius: theme.spacing(1),
                        border: '1px solid',
                        borderColor: dragItem.current === index ? theme.palette.primary.light : 'transparent',
                        opacity: 1,
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          backgroundColor: theme.palette.stationGray[300],
                          cursor: 'move',
                        },
                      }}
                    >
                      <DragIndicatorIcon
                        sx={{
                          visibility: 'visible',
                          marginRight: theme.spacing(1),
                          color: theme.palette.stationGray[500],
                          '&:hover': {
                            color: theme.palette.primary.dark,
                          },
                        }}
                      />
                      <Typography sx={{ width: '20px', textAlign: 'center', color: theme.palette.stationGray[500] }}>
                        {index + 1}
                      </Typography>
                      <Typography sx={{ flex: 1, ml: theme.spacing(1.5) }}>{rank?.name || 'Unknown Rank'}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>

          {/* Round Duration Section */}
          <Box sx={{ flex: '0 0 300px' }}>
            <Typography variant="h6" sx={{ mb: theme.spacing(2) }}>
              Auto Hire Round Duration
            </Typography>
            <Box
              sx={(theme) => ({
                p: theme.spacing(2),
                borderRadius: theme.spacing(1),
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.stationGray[200]}`,
                height: 'fit-content',
              })}
            >
              <AutoHireIntervalPicker
                value={roundDuration.toString()}
                onChange={(newValue) => {
                  if (newValue) {
                    const totalMinutes = parseInt(newValue);
                    setRoundDuration(totalMinutes);
                  }
                }}
                sx={{
                  width: '120px',
                  boxShadow: 'none',
                  '& .MuiInputBase-input': {
                    fontSize: '16px',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: '24px',
                  },
                }}
              />
              <Typography
                sx={(theme) => ({
                  color: theme.palette.stationGray[600],
                  typography: 'bodySRegular',
                  mt: theme.spacing(2),
                })}
              >
                Auto hire sends notifications to rank groups in the order shown on the left. Each round starts after the specified
                interval
              </Typography>
            </Box>
          </Box>

          {/* Save Button Section */}
          <Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
            <Button variant="contained" size="large" onClick={handleSaveSettings} disabled={isSaving || !hasChanges()}>
              Save Settings
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
