import { Box, useTheme } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { PersonalStaffingListsContent } from './PersonalStaffingListsContent';

export const PersonalStaffingListsMain = () => {
  useDocumentTitle('Staffing Lists');
  const theme = useTheme();

  return (
    <AppPageLayout>
      <Box sx={{ background: theme.palette.common.white, height: '100%', width: '100%', maxWidth: '768px', margin: '0 auto' }}>
        <Box sx={{ background: theme.palette.common.white, p: theme.spacing(3.5, 2, 4.5, 2) }}>
          <Box component="h1" sx={{ typography: 'bodyXXLSemibold', m: theme.spacing(0, 0, 1, 0) }}>
            My Staffing Lists
          </Box>
          <PersonalStaffingListsContent />
        </Box>
      </Box>
    </AppPageLayout>
  );
};
