import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { memo } from 'react';
import { CustomTable, Loader, TableProps, TeamBadge } from '@stationwise/component-module';
import { ListFieldsStaffingList } from '@stationwise/share-types';

interface StaffingListItemsProps {
  isLoading: boolean;
  selectedStaffingList: ListFieldsStaffingList | undefined;
  searchInput: string;
  selectedTeam: string;
  selectedLastOvertimeDate: string;
  selectedStation: string;
}

export const StaffingListItems = memo((props: StaffingListItemsProps) => {
  const items = (props.selectedStaffingList?.items || []).filter((item) => {
    let match = true;
    if (props.searchInput) {
      match = match && item.employee.name.toLowerCase().includes(props.searchInput.toLowerCase());
    }
    if (props.selectedTeam) {
      match = match && item.employee.team?.name === props.selectedTeam;
    }
    if (props.selectedStation) {
      match = match && item.employee.station.name === props.selectedStation;
    }
    if (props.selectedLastOvertimeDate) {
      match = match && item.attributes.lastThreeOvertimes?.[0]?.date === props.selectedLastOvertimeDate;
    }
    return match;
  });

  const getColumns = () => {
    const columns: TableProps['columns'] = [
      { id: 'order', label: 'NO.' },
      { id: 'firstName', label: 'FIRST NAME' },
      { id: 'lastName', label: 'LAST NAME' },
      { id: 'team', label: 'SHIFT' },
      { id: 'station', label: 'STATION' },
    ];

    if (props.selectedStaffingList) {
      if (props.selectedStaffingList.sorts?.includes('LAST_OVERTIME_STAFFED_AT')) {
        columns.push({ id: 'lastOvertimeStaffedAt', label: 'LAST OT STAFF TIME' });
      }
      if (props.selectedStaffingList.sorts?.includes('LAST_OVERTIME_DATE')) {
        columns.push({ id: 'lastOvertimeDate', label: 'LAST OT WORK DATE' });
      }
      if (props.selectedStaffingList.sorts?.includes('SECOND_LAST_OVERTIME_DATE')) {
        columns.push({ id: 'secondLastOvertimeDate', label: '2ND OT WORK DATE' });
      }
      if (props.selectedStaffingList.sorts?.includes('THIRD_LAST_OVERTIME_DATE')) {
        columns.push({ id: 'thirdLastOvertimeDate', label: '3RD OT WORK DATE' });
      }
      if (props.selectedStaffingList.params?.overtimeAccHoursThreshold) {
        columns.push({ id: 'overtimeAccHours', label: 'PARTIAL HOURS' });
      }
      if (props.selectedStaffingList.sorts?.includes('TOTAL_OVERTIME')) {
        columns.push({ id: 'totalOvertime', label: 'TOTAL OT (hours)' });
      }
    }

    return columns;
  };

  const data = items.map((item) => {
    const [firstName, ...lastNames] = item.employee.name.split(' ');

    const lastOvertimeStaffedAt = item.attributes.lastThreeOvertimes?.[0]?.staffedAt;
    const lastOvertimeDate = item.attributes.lastThreeOvertimes?.[0]?.date;
    const secondLastOvertimeDate = item.attributes.lastThreeOvertimes?.[1]?.date;
    const thirdLastOvertimeDate = item.attributes.lastThreeOvertimes?.[2]?.date;

    return {
      order: item.order,
      firstName,
      lastName: lastNames.join(' '),
      team: item.employee.team && <TeamBadge team={item.employee.team} />,
      station: item.employee.station.name,
      lastOvertimeStaffedAt: lastOvertimeStaffedAt && format(lastOvertimeStaffedAt, 'MMM dd, yyyy | HH:mm.ss'),
      lastOvertimeDate: lastOvertimeDate && format(parseISO(lastOvertimeDate), 'MMM dd, yyyy'),
      secondLastOvertimeDate: secondLastOvertimeDate && format(parseISO(secondLastOvertimeDate), 'MMM dd, yyyy'),
      thirdLastOvertimeDate: thirdLastOvertimeDate && format(parseISO(thirdLastOvertimeDate), 'MMM dd, yyyy'),
      overtimeAccHours: item.attributes.overtimeAccHours,
      totalOvertime: item.attributes.totalOvertimeMinutes ? item.attributes.totalOvertimeMinutes / 60 : 0,
    };
  });

  return (
    <Box sx={{ position: 'relative', minHeight: 140 }}>
      {props.isLoading && (
        <Box sx={{ pt: 11, position: 'absolute', top: 0, left: 0, width: '100%' }}>
          <Loader data-cy="staffing-list-items-loader" />
        </Box>
      )}
      <Box sx={{ visibility: props.isLoading ? 'hidden' : undefined }}>
        <CustomTable columns={getColumns()} data={data} />
        {!props.selectedStaffingList?.items?.length && (
          <Box sx={{ display: 'flex', py: 3, justifyContent: 'center', typography: 'bodyMMedium' }}>
            {!props.selectedStaffingList ? 'There are no lists' : 'This list is empty'}
          </Box>
        )}
      </Box>
    </Box>
  );
});

StaffingListItems.displayName = 'Memo(StaffingListItems)';
