import { Box } from '@mui/material';
import React from 'react';
import { CircleWarningIcon20 } from '@stationwise/component-module';

interface RemainingTimeErrorProps {
  hours: number;
  type: string;
}

export const RemainingTimeError = ({ hours, type }: RemainingTimeErrorProps) => {
  return (
    <Box
      display="flex"
      sx={(theme) => ({
        background: 'linear-gradient(0deg, #FFE4E6, #FFE4E6), linear-gradient(0deg, #FECDD3, #FECDD3)',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.stationRose[200]}`,
        p: theme.spacing(2),
        mt: theme.spacing(1),
        alignItems: 'center',
      })}
    >
      <Box
        sx={{
          width: '20px',
          height: '20px',
        }}
      >
        <CircleWarningIcon20 />
      </Box>
      <Box sx={(theme) => ({ ml: 1, color: theme.palette.stationRose[700], typography: 'bodyMRegular' })}>
        You only have{' '}
        <Box component="span" sx={{ fontWeight: 600 }}>
          {hours.toFixed(2)} hours
        </Box>{' '}
        of available {type.toLowerCase()} hours.
        <Box>Choose a value lower than that.</Box>
      </Box>
    </Box>
  );
};
