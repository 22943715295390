import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import {
  NonShiftEmployee,
  RosterDataSource,
  RosterAdministrationStation,
  RosterAdministrationApparatus,
} from '@stationwise/share-types';
import { cutDuration, getOverrideEmployeePositionFields, setEmployeeActiveId } from '@stationwise/shift-summary-helper';

interface Props {
  selectedDate: string;
  skip?: boolean;
}

const makeAdministrationEmployees = (nonShiftEmployee: NonShiftEmployee) => {
  const nonShiftDuration = {
    startDateTime: new Date(nonShiftEmployee.startDateTime),
    endDateTime: new Date(nonShiftEmployee.endDateTime),
  };
  const nonShiftUnavailableDurations = nonShiftEmployee.unavailableDurations.map((d) => ({
    startDateTime: new Date(d.startDateTime),
    endDateTime: new Date(d.endDateTime),
  }));
  return cutDuration(nonShiftDuration, nonShiftUnavailableDurations).map((d) => {
    return setEmployeeActiveId({
      id: `${nonShiftEmployee.id}`,
      dataSource: RosterDataSource.ADMINISTRATION,
      name: nonShiftEmployee.name,
      rank: nonShiftEmployee.rank,
      certifications: [],
      team: nonShiftEmployee.team,
      defaults: nonShiftEmployee.defaults,
      startDateTime: d.startDateTime,
      endDateTime: d.endDateTime,
      breakDuration: nonShiftEmployee.nonShiftAssignment.breakDuration ?? undefined,
      payCodes: [nonShiftEmployee.payCode],
      detailCodes: [],
      ...getOverrideEmployeePositionFields(),
    });
  });
};

export const makeAdministrationStations = (
  nonShiftEmployeesByStation: Record<string, NonShiftEmployee[]>,
): RosterAdministrationStation[] => {
  const stations: RosterAdministrationStation[] = [];
  Object.entries(nonShiftEmployeesByStation).forEach(([stationName, nonShiftEmployees], stationIndex) => {
    const station: RosterAdministrationStation = {
      stationName,
      stationId: `administration-station-${stationIndex}`,
      dataSource: RosterDataSource.ADMINISTRATION,
      apparatuses: [],
      certificationRequirements: [],
    };
    stations.push(station);

    nonShiftEmployees.forEach((nonShiftEmployee) => {
      const existingApparatus = station.apparatuses.find((a) => a.name === nonShiftEmployee.nonShiftAssignment.apparatus);
      const apparatus: RosterAdministrationApparatus = existingApparatus || {
        id: `${station.stationId}-apparatus-${station.apparatuses.length}`,
        dataSource: RosterDataSource.ADMINISTRATION,
        name: nonShiftEmployee.nonShiftAssignment.apparatus,
        isForShiftLeader: false,
        positions: [],
        certificationRequirements: [],
      };
      if (!existingApparatus) {
        station.apparatuses.push(apparatus);
      }

      apparatus.positions.push({
        id: `${apparatus.id}-position-${nonShiftEmployee.id}`,
        dataSource: RosterDataSource.ADMINISTRATION,
        employees: makeAdministrationEmployees(nonShiftEmployee),
        startDateTime: new Date(nonShiftEmployee.startDateTime),
        endDateTime: new Date(nonShiftEmployee.endDateTime),
        rank: nonShiftEmployee.rank,
        certifications: [],
        isTemporary: false,
        driver: false,
        nonShiftEmployee,
      });
    });
  });

  stations.forEach((station) => {
    station.apparatuses.sort((a, b) => a.name.localeCompare(b.name));
  });

  return stations.sort((a, b) => a.stationName.localeCompare(b.stationName));
};

export const useFetchAdministrationStations = ({ selectedDate, skip = false }: Props) => {
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<RosterAdministrationStation[] | null>(null);

  useEffect(() => {
    if (skip) {
      setIsLoading(false);
      setIsError(false);
      setData(makeAdministrationStations({}));
      return;
    }
    setIsLoading(true);
    setIsError(false);
    setData(null);
    client
      .get('employee/non-shift-employees/', {
        params: {
          date: selectedDate,
        },
      })
      .then((response) => {
        setData(makeAdministrationStations(response.data));
      })
      .catch((error) => {
        setIsError(true);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedDate, skip, refetchCounter]);

  const forceRefetch = useCallback(() => setRefetchCounter((c) => c + 1), []);

  return {
    isLoading,
    isError,
    data,
    forceRefetch,
  };
};
