import { Box, Modal } from '@mui/material';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Button, SnackbarService, theme, useLoadedDepartmentInfoContext, AdminSelect } from '@stationwise/component-module';
import { axios, client } from '@stationwise/share-api';
import { PayCode } from '@stationwise/share-types';
import { AddEmployeeProps } from './MyTeamMain';

interface AddEmployeeDefaultsProps {
  defaultsOpen: boolean;
  setDefaultsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeeInfo: AddEmployeeProps;
  addEmployeeInfo: (employeeInfo: AddEmployeeProps) => void;
  initialState: AddEmployeeProps;
}

export const AddEmployeeDefaults = ({
  defaultsOpen,
  setDefaultsOpen,
  employeeInfo,
  addEmployeeInfo,
  initialState,
}: AddEmployeeDefaultsProps) => {
  const isDisabled =
    !employeeInfo.arrivedEarlyPayCode ||
    !employeeInfo.heldOverPayCode ||
    !employeeInfo.overtimeAssignmentPayCode ||
    !employeeInfo.regularAssignmentPayCode;

  const { state: department } = useLoadedDepartmentInfoContext();
  const payCodes = department.departmentInfo.payCodes;
  const navigate = useNavigate();

  const regularOptions = payCodes
    .filter((code: PayCode) => code.payCodeType === 'REGULAR')
    .map((code: PayCode) => ({
      label: code.name,
      value: code.code,
    }));

  const overtimeOptions = payCodes
    .filter((code: PayCode) => code.payCodeType === 'OVERTIME')
    .map((code: PayCode) => ({
      label: code.name,
      value: code.code,
    }));

  const handleFieldChange = (fieldName: string, value: string | number | Date | string[] | null) => {
    addEmployeeInfo({ ...employeeInfo, [fieldName]: value });
  };

  const handleModalClose = () => {
    addEmployeeInfo(initialState);
    setDefaultsOpen(false);
  };

  const handleSaveEmployee = () => {
    handleSave();
    setDefaultsOpen(false);
    addEmployeeInfo(initialState);
  };

  const handleSave = async () => {
    try {
      const params = {
        firstName: employeeInfo.firstName,
        lastName: employeeInfo.lastName,
        email: employeeInfo.email,
        phone: employeeInfo.phone,
        hireDate: employeeInfo.hireDate,
        type: employeeInfo.type,
        rank: employeeInfo.rank,
        driversLicenseNumber: employeeInfo.driversLicenseNumber,
        driversLicenseClasses: employeeInfo.driversLicenseClasses,
        arrivedEarlyPayCode: employeeInfo.arrivedEarlyPayCode,
        heldOverPayCode: employeeInfo.heldOverPayCode,
        overtimeAssignmentPayCode: employeeInfo.overtimeAssignmentPayCode,
        regularAssignmentPayCode: employeeInfo.regularAssignmentPayCode,
        straightPayHours: employeeInfo.straightPayHours,
      };
      const response = await client.post('/employee/team/', params);
      navigate(`/app/employees/${response.data['id']}`);
    } catch (err: unknown) {
      captureException(err);
      const message = axios.isAxiosError(err) ? err.response?.data?.error || err.message : 'An unexpected error occurred.';
      SnackbarService.notify({
        content: message,
        severity: 'error',
        duration: 5000,
      });
    }
  };

  return (
    <Modal open={defaultsOpen}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '95vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Add pay code details
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                color: theme.palette.stationGray[700],
                width: '49%',
              })}
            >
              Arrived early pay code <span style={{ color: theme.palette.stationRed[600] }}>*</span>
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
                width: '49%',
              })}
            >
              {overtimeOptions && (
                <AdminSelect
                  items={overtimeOptions}
                  selectedItem={employeeInfo.arrivedEarlyPayCode || ''}
                  setSelectedItem={(arrivedEarlyPayCode) => handleFieldChange('arrivedEarlyPayCode', arrivedEarlyPayCode)}
                  placeholder="Choose a pay code"
                />
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                color: theme.palette.stationGray[700],
                width: '49%',
              })}
            >
              Held over pay code <span style={{ color: theme.palette.stationRed[600] }}>*</span>
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
                width: '49%',
              })}
            >
              {overtimeOptions && (
                <AdminSelect
                  items={overtimeOptions}
                  selectedItem={employeeInfo.heldOverPayCode || ''}
                  setSelectedItem={(heldOverPayCode) => handleFieldChange('heldOverPayCode', heldOverPayCode)}
                  placeholder="Choose a pay code"
                />
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                color: theme.palette.stationGray[700],
                width: '49%',
              })}
            >
              Overtime assignment pay code <span style={{ color: theme.palette.stationRed[600] }}>*</span>
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
                width: '49%',
              })}
            >
              {overtimeOptions && (
                <AdminSelect
                  items={overtimeOptions}
                  selectedItem={employeeInfo.overtimeAssignmentPayCode || ''}
                  setSelectedItem={(overtimeAssignmentPayCode) =>
                    handleFieldChange('overtimeAssignmentPayCode', overtimeAssignmentPayCode)
                  }
                  placeholder="Choose a pay code"
                />
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                color: theme.palette.stationGray[700],
                width: '49%',
              })}
            >
              Regular assignment pay code <span style={{ color: theme.palette.stationRed[600] }}>*</span>
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
                width: '49%',
              })}
            >
              {regularOptions && (
                <AdminSelect
                  items={regularOptions}
                  selectedItem={employeeInfo.regularAssignmentPayCode || ''}
                  setSelectedItem={(regularAssignmentPayCode) =>
                    handleFieldChange('regularAssignmentPayCode', regularAssignmentPayCode)
                  }
                  placeholder="Choose a pay code"
                />
              )}
            </Box>
          </Box>

          {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                color: theme.palette.stationGray[700],
                width: '49%',
              })}
            >
              Straight pay hours <span style={{ color: theme.palette.stationRed[600] }}>*</span>
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
                width: '49%',
              })}
            >
              <Input
                type="number"
                value={employeeInfo.straightPayHours || ''}
                onChange={(event) => handleFieldChange('straightPayHours', event.target.value)}
              />
            </Box>
          </Box> */}

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleModalClose()}>
              Cancel
            </Button>
            <Button variant="contained" size="large" sx={{ width: '216px' }} disabled={isDisabled} onClick={handleSaveEmployee}>
              Add employee
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
