import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { SelectButton } from '../../../Button';
import { GenericDrawer } from '../../../GenericDrawerOrModal';
import { useRosterContext } from '../RosterContext';
import { StationsMenu } from './StationsMenu';

export const StationsSelect = () => {
  const { isPersonalRoster, preferences } = useRosterContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const selectedBattalion = preferences.battalions.find((b) => b.id === preferences.selectedBattalionId);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SelectButton
        data-cy="station-select-button"
        onClick={handleClick}
        caret={open}
        variant={isPersonalRoster ? 'text' : 'outlined'}
        sx={{ minHeight: isPersonalRoster ? '36px' : undefined, ml: isPersonalRoster ? 1 : 2 }}
      >
        {selectedBattalion?.name}
      </SelectButton>
      {isMobileApp ? (
        <GenericDrawer
          anchor="bottom"
          drawerOpen={anchorEl !== null}
          handleOnClose={handleClose}
          loading={false}
          showHeader={true}
          disableFooter={true}
          noBorderOnHeader={true}
          headerTitle="Battalions"
          sxProps={() => ({ '& .MuiDrawer-paper': { height: '100%', borderRadius: 0 } })}
        >
          <StationsMenu anchorEl={anchorEl} handleClose={handleClose} />
        </GenericDrawer>
      ) : (
        <StationsMenu anchorEl={anchorEl} handleClose={handleClose} />
      )}
    </>
  );
};
