import { Box } from '@mui/material';
import { SelectButton } from '@stationwise/component-module';

interface LeftSideFiltersProps {
  todayHandle: () => void;
}

export const LeftSideFilters = ({ todayHandle }: LeftSideFiltersProps) => {
  return (
    <Box
      sx={{
        ml: '24px',
        minWidth: 0,
      }}
    >
      <SelectButton sx={{ typography: 'bodyMRegular' }} onClick={() => todayHandle()}>
        Today
      </SelectButton>
    </Box>
  );
};
