import { Box } from '@mui/material';
import React from 'react';
import { SelectButton, useRosterContext } from '@stationwise/component-module';
import { ResponsibilityContent } from './ResponsibilityContent';

export const Responsibility = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { shiftSummaryHelper } = useRosterContext();
  const currentDate = shiftSummaryHelper.shiftDuration.startTime;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <SelectButton data-cy="responsibility-select-button" onClick={handleClick} sx={{ typography: 'bodySMedium' }} caret={open}>
        Responsibilities
      </SelectButton>
      <ResponsibilityContent anchorEl={anchorEl} handleClose={handleClose} currentDate={currentDate} />
    </Box>
  );
};
