import { useState } from 'react';
import { useLoadedAuthUserContext, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import {
  Candidate,
  DetailCode,
  PayCode,
  RosterApparatus,
  RosterEmployee,
  RosterPosition,
  RosterStation,
} from '@stationwise/share-types';
import { makeDateInterval } from '@stationwise/share-utils';
import {
  getShiftSummaryHelper,
  modifyBoardEmployeeDetailCodes,
  overrideBoardEmployeePayCodes,
} from '@stationwise/shift-summary-helper';
import {
  addCreateApparatusToStationsPayload,
  addRemoveApparatusToStationsPayload,
  addUpdateApparatusToStationsPayload,
  ApparatusMode,
  createApparatus,
  deleteApparatus,
  moveApparatusToStation,
  toggleApparatusMode,
  updateApparatus,
} from '../apparatuses';
import {
  addExcessCapacityToApparatus,
  assignEmployee,
  getCurrentAssignations,
  sendApparatusEmployeesToFloaterStation,
  sendPositionEmployeesToFloaterStation,
  unassignEmployee,
} from '../assignments';
import { buildFloaterStation } from '../floaters';
import { addPositionChangesToStationsPayload, createPosition, deletePosition, updatePosition } from '../positions';
import {
  createStation,
  updateStation,
  ManageStationsPayload,
  deleteStation,
  addCreateToStationsPayload,
  addRemoveToStationsPayload,
  addUpdateToStationsPayload,
} from '../stations';
import { ShiftTemplateSummary } from './useFetchCurrentShiftTemplate';

interface UseRosterInput {
  shiftTemplateSummary: ShiftTemplateSummary;
}

export const useShiftTemplate = ({ shiftTemplateSummary }: UseRosterInput) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;

  const shiftDuration = makeDateInterval(shiftTemplateSummary.date || new Date(), departmentInfo.shiftStart, 24);

  const initShiftTemplateHelper = () => {
    const newShiftTemplateHelper = getShiftSummaryHelper({
      departmentInfo,
      shiftSummary: {
        ...shiftTemplateSummary,
        holidayEmployeeIds: [],
        shiftColor: shiftTemplateSummary.shiftTeams[0].color,
        plannedAssignments: [],
        employeeSplits: {},
      },
      shiftDuration,
    });

    newShiftTemplateHelper.allStationCards.set(
      'floater-station',
      buildFloaterStation(newShiftTemplateHelper, shiftTemplateSummary.floaters),
    );
    return newShiftTemplateHelper;
  };

  const [initialShiftTemplateHelper, setInitialShiftTemplateHelper] = useState(initShiftTemplateHelper);
  const [shiftTemplateHelper, setShiftTemplateHelper] = useState(initialShiftTemplateHelper);
  const [userHasMadeChanges, setUserHasMadeChanges] = useState(false);
  const [error, setError] = useState<{ message: string; secondaryMessage?: string; canOverride?: boolean } | null>(null);
  const [errorWarningModalOpen, setErrorWarningModalOpen] = useState<boolean>(false);
  const [unsavedUnassignedEmployees, setUnsavedUnassignedEmployees] = useState<RosterEmployee[]>([]);
  const [updatedApparatusModes, setUpdatedApparatusModes] = useState<ApparatusMode[]>([]);
  const [isStationDialogOpen, setIsStationDialogOpen] = useState(false);
  const [selectedStation, setSelectedStation] = useState<RosterStation | null>(null);
  const [manageStationsPayload, setManageStationsPayload] = useState<ManageStationsPayload>({
    create: [],
    update: [],
    delete: [],
  });
  const [stationWarningModalOpen, setStationWarningModalOpen] = useState(false);

  const resetValues = () => {
    const newInitialShiftTemplateHelper = initShiftTemplateHelper();
    setInitialShiftTemplateHelper(newInitialShiftTemplateHelper);
    setShiftTemplateHelper(newInitialShiftTemplateHelper);
    setUserHasMadeChanges(false);
    setError(null);
    setUnsavedUnassignedEmployees([]);
    setUpdatedApparatusModes([]);
    setManageStationsPayload({ create: [], update: [], delete: [] });
    setSelectedStation(null);
  };

  const handlePayCodeChange = (payCodes: PayCode[], detailCodes: DetailCode[], note: string, employee: RosterEmployee) => {
    setShiftTemplateHelper((prev) => {
      return overrideBoardEmployeePayCodes(prev, payCodes, detailCodes, note, employee.activeId, authUserState.employee);
    });
    setUserHasMadeChanges(true);
  };

  const handleDetailCodeChange = (detailCodes: DetailCode[], employee: RosterEmployee) => {
    setShiftTemplateHelper((prev) => modifyBoardEmployeeDetailCodes(prev, detailCodes, employee.activeId));
    setUserHasMadeChanges(true);
  };

  const handleToggleApparatusMode = (apparatus: RosterApparatus, isReserved: boolean) => {
    const newShiftTemplateHelper = toggleApparatusMode(shiftTemplateHelper, apparatus);
    const apparatusIndex = updatedApparatusModes.findIndex(
      (updatedApparatus) => updatedApparatus.apparatusId === Number(apparatus.id),
    );
    let newUpdatedApparatusModes = [];

    if (apparatusIndex >= 0) {
      newUpdatedApparatusModes = [...updatedApparatusModes];
      newUpdatedApparatusModes[apparatusIndex].isReserved = isReserved;
    } else {
      newUpdatedApparatusModes = [...updatedApparatusModes, { apparatusId: Number(apparatus.id), isReserved }];
    }
    const { updatedShiftTemplateHelper } = sendApparatusEmployeesToFloaterStation(newShiftTemplateHelper, apparatus);
    setShiftTemplateHelper(updatedShiftTemplateHelper);
    setUpdatedApparatusModes(newUpdatedApparatusModes);
    setUserHasMadeChanges(true);
  };

  const assignEmployeeToPosition = (
    apparatus: RosterApparatus,
    position: RosterPosition,
    candidate: Candidate,
    activeEmployee?: RosterEmployee | null,
  ) => {
    const { newShiftTemplateHelper, assignmentError } = assignEmployee(
      shiftTemplateHelper,
      apparatus,
      position,
      candidate,
      activeEmployee,
    );

    if (assignmentError) {
      setError(assignmentError);
      if (assignmentError.message && !assignmentError.canOverride) {
        setErrorWarningModalOpen(true);
      }
    } else {
      setShiftTemplateHelper(newShiftTemplateHelper);
      setUserHasMadeChanges(true);
    }

    return !assignmentError;
  };

  const unassignEmployeeFromPosition = (apparatus: RosterApparatus, position: RosterPosition, assignee: RosterEmployee) => {
    const { newShiftTemplateHelper } = unassignEmployee(shiftTemplateHelper, apparatus, position, assignee);
    setShiftTemplateHelper(newShiftTemplateHelper);
    setUserHasMadeChanges(true);
  };

  const addExcessCapacity = (apparatus: RosterApparatus, candidate: Candidate) => {
    const { newShiftTemplateHelper, assignmentError } = addExcessCapacityToApparatus(shiftTemplateHelper, apparatus, candidate);

    if (assignmentError) {
      setError(assignmentError);
      if (assignmentError.message && !assignmentError.canOverride) {
        setErrorWarningModalOpen(true);
      }
    } else {
      setShiftTemplateHelper(newShiftTemplateHelper);
      setUserHasMadeChanges(true);
    }
    return !assignmentError;
  };

  const getPositionsPayloadForApparatusToMove = (apparatusToMove: RosterApparatus, originalStation: RosterStation) => {
    const updateStationIndex = manageStationsPayload.update.findIndex(
      (objToUpdate) => objToUpdate.id === originalStation.stationId,
    );

    if (updateStationIndex >= 0) {
      const stationToUpdate = manageStationsPayload.update[updateStationIndex].station;

      if (stationToUpdate.apparatusesPayload) {
        const updateApparatusIndex = stationToUpdate.apparatusesPayload.update.findIndex(
          (objToUpdate) => objToUpdate.id === apparatusToMove.id,
        );

        if (updateApparatusIndex >= 0) {
          const apparatusToUpdate = stationToUpdate.apparatusesPayload?.update[updateApparatusIndex].apparatus;
          return apparatusToUpdate?.positionsPayload;
        }
      }
    }
    return { create: [], update: [], delete: [] };
  };

  const moveApparatus = (apparatus: RosterApparatus, originalStation: RosterStation, destinationStationId: string) => {
    const destinationStation = shiftTemplateHelper.allStationCards.get(destinationStationId);

    if (destinationStation) {
      setUserHasMadeChanges(true);
      const { updatedShiftTemplateHelper } = moveApparatusToStation(shiftTemplateHelper, apparatus, destinationStationId);
      setShiftTemplateHelper(updatedShiftTemplateHelper);

      const positionsPayloadForApparatusToMove = getPositionsPayloadForApparatusToMove(apparatus, originalStation);

      let newManageStationsPayload = addRemoveApparatusToStationsPayload(manageStationsPayload, originalStation, apparatus);
      newManageStationsPayload = addCreateApparatusToStationsPayload(
        newManageStationsPayload,
        destinationStation,
        apparatus,
        positionsPayloadForApparatusToMove,
        true,
      );

      setManageStationsPayload(newManageStationsPayload);
    }
  };

  const getShiftTemplateInfo = () => {
    return {
      id: shiftTemplateSummary.id,
      ccVersion: shiftTemplateSummary.ccVersion,
      updatedBy: shiftTemplateSummary.updatedBy,
      updatedAt: shiftTemplateSummary.updatedAt,
      isCurrentlyActive: shiftTemplateSummary.isCurrentlyActive,
      futureDated: shiftTemplateSummary.futureDated,
      date: shiftTemplateSummary.date,
      activationDate: shiftTemplateSummary.activationDate
        ? new Date(shiftTemplateSummary.activationDate + 'T08:00:00')
        : shiftTemplateSummary.activationDate,
    };
  };

  const filterAvailableCandidates = (candidates: Candidate[]) => {
    const currentAssignations = getCurrentAssignations(shiftTemplateHelper);
    const availableCandidates: number[] = [];

    candidates.forEach((candidate) => {
      const candidateAssignations = currentAssignations.filter((assigned) => assigned.id === candidate.id.toString());

      if (
        candidateAssignations.length === 0 ||
        (candidate.partialPlannedPositions?.length !== 0 &&
          candidateAssignations.length !== candidate.partialPlannedPositions?.length)
      ) {
        availableCandidates.push(candidate.id);
      }
    });

    return availableCandidates;
  };

  const mapPartialPositions = (candidates: Candidate[]) => {
    const currentAssignations = getCurrentAssignations(shiftTemplateHelper);
    const newCandidates: Candidate[] = [];

    candidates.forEach((candidate) => {
      if (candidate.partialPlannedPositions?.length === 0) {
        newCandidates.push(candidate);
      } else {
        const candidateAssignations = currentAssignations.filter((assigned) => assigned.id === candidate.id.toString());

        candidate.partialPlannedPositions?.forEach((pos) => {
          if (
            !candidateAssignations.some(
              (assignation) => pos.startDateTime && assignation.startDateTime.getTime() === new Date(pos.startDateTime).getTime(),
            )
          ) {
            const newCandidate = {
              ...candidate,
              startDateTime: pos.startDateTime && new Date(pos.startDateTime),
              endDateTime: pos.endDateTime && new Date(pos.endDateTime),
            };
            newCandidates.push(newCandidate);
          }
        });
      }
    });

    return newCandidates;
  };

  const addStation = (station: RosterStation) => {
    setUserHasMadeChanges(true);
    const newManageStationsPayload = addCreateToStationsPayload(manageStationsPayload, station);
    setManageStationsPayload(newManageStationsPayload);

    const updatedShiftTemplateHelper = createStation(shiftTemplateHelper, station);
    setShiftTemplateHelper(updatedShiftTemplateHelper);
  };

  const removeStation = (station: RosterStation) => {
    setUserHasMadeChanges(true);
    const newManageStationsPayload = addRemoveToStationsPayload(manageStationsPayload, station);
    setManageStationsPayload(newManageStationsPayload);

    const updatedShiftTemplateHelper = deleteStation(shiftTemplateHelper, station.stationId);
    setShiftTemplateHelper(updatedShiftTemplateHelper);
  };

  const editStation = (station: RosterStation) => {
    setUserHasMadeChanges(true);
    const newManageStationsPayload = addUpdateToStationsPayload(manageStationsPayload, station);
    setManageStationsPayload(newManageStationsPayload);

    const updatedShiftTemplateHelper = updateStation(shiftTemplateHelper, station);
    setShiftTemplateHelper(updatedShiftTemplateHelper);
  };

  const addApparatus = (apparatus: RosterApparatus, station: RosterStation) => {
    setUserHasMadeChanges(true);
    const newManageStationsPayload = addCreateApparatusToStationsPayload(manageStationsPayload, station, apparatus);
    setManageStationsPayload(newManageStationsPayload);

    const updatedShiftTemplateHelper = createApparatus(shiftTemplateHelper, apparatus, station);
    setShiftTemplateHelper(updatedShiftTemplateHelper);
  };

  const editApparatus = (apparatus: RosterApparatus, station: RosterStation) => {
    setUserHasMadeChanges(true);
    const newManageStationsPayload = addUpdateApparatusToStationsPayload(manageStationsPayload, station, apparatus);
    setManageStationsPayload(newManageStationsPayload);

    const updatedShiftTemplateHelper = updateApparatus(shiftTemplateHelper, apparatus, station);
    setShiftTemplateHelper(updatedShiftTemplateHelper);
  };

  const removeApparatus = (apparatus: RosterApparatus, station: RosterStation) => {
    setUserHasMadeChanges(true);
    const newManageStationsPayload = addRemoveApparatusToStationsPayload(manageStationsPayload, station, apparatus);
    setManageStationsPayload(newManageStationsPayload);

    const updatedShiftTemplateHelper = deleteApparatus(shiftTemplateHelper, apparatus, station);
    setShiftTemplateHelper(updatedShiftTemplateHelper);
  };

  const addPosition = (position: RosterPosition, apparatus: RosterApparatus, station: RosterStation) => {
    setUserHasMadeChanges(true);
    const newManageStationsPayload = addPositionChangesToStationsPayload(
      shiftTemplateHelper.shiftDuration,
      manageStationsPayload,
      position,
      apparatus,
      station,
      'create',
    );
    setManageStationsPayload(newManageStationsPayload);

    const updatedShiftTemplateHelper = createPosition(shiftTemplateHelper, position, apparatus, station);
    setShiftTemplateHelper(updatedShiftTemplateHelper);
  };

  const editPosition = (position: RosterPosition, apparatus: RosterApparatus, station: RosterStation) => {
    setUserHasMadeChanges(true);
    let newShiftTemplateHelper = { ...shiftTemplateHelper };

    const newManageStationsPayload = addPositionChangesToStationsPayload(
      shiftTemplateHelper.shiftDuration,
      manageStationsPayload,
      position,
      apparatus,
      station,
      'update',
    );
    setManageStationsPayload(newManageStationsPayload);

    if (position.employees.length > 0) {
      const { updatedShiftTemplateHelper } = sendPositionEmployeesToFloaterStation(newShiftTemplateHelper, position, apparatus);
      newShiftTemplateHelper = updatedShiftTemplateHelper;
    }
    newShiftTemplateHelper = updatePosition(shiftTemplateHelper, { ...position, employees: [] }, apparatus, station);
    setShiftTemplateHelper(newShiftTemplateHelper);
  };

  const removePosition = (position: RosterPosition, apparatus: RosterApparatus, station: RosterStation) => {
    setUserHasMadeChanges(true);
    const newManageStationsPayload = addPositionChangesToStationsPayload(
      shiftTemplateHelper.shiftDuration,
      manageStationsPayload,
      position,
      apparatus,
      station,
      'delete',
    );

    setManageStationsPayload(newManageStationsPayload);

    const updatedShiftTemplateHelper = deletePosition(shiftTemplateHelper, position, apparatus, station);
    setShiftTemplateHelper(updatedShiftTemplateHelper);
  };

  const addAssignmentForNewlyCreatedPosition = (position: RosterPosition, apparatus: RosterApparatus, station: RosterStation) => {
    const newManageStationsPayload = addPositionChangesToStationsPayload(
      shiftTemplateHelper.shiftDuration,
      manageStationsPayload,
      position,
      apparatus,
      station,
      position.isTemporary ? 'create' : 'update',
    );
    setManageStationsPayload(newManageStationsPayload);
  };

  const getShiftLeaderApparatusSelected = () => {
    for (const station of shiftTemplateHelper.allStationCards.values()) {
      const shiftLeaderApparatus = station.apparatuses.find((app) => app.isForShiftLeader);
      if (shiftLeaderApparatus) {
        return shiftLeaderApparatus;
      }
    }
    return;
  };

  return {
    error,
    resetValues,
    userHasMadeChanges,
    currentTeams: shiftTemplateSummary.shiftTeams,
    currentBattalion: shiftTemplateSummary.battalion,
    shiftTemplateHelper,
    assignEmployeeToPosition,
    unassignEmployeeFromPosition,
    errorWarningModalOpen,
    setErrorWarningModalOpen,
    handleDetailCodeChange,
    handlePayCodeChange,
    addExcessCapacity,
    filterAvailableCandidates,
    mapPartialPositions,
    getShiftTemplateInfo,
    setUnsavedUnassignedEmployees,
    unsavedUnassignedEmployees,
    handleToggleApparatusMode,
    updatedApparatusModes,
    moveApparatus,
    addStation,
    editStation,
    removeStation,
    isStationDialogOpen,
    setIsStationDialogOpen,
    selectedStation,
    setSelectedStation,
    stationWarningModalOpen,
    setStationWarningModalOpen,
    manageStationsPayload,
    addApparatus,
    editApparatus,
    removeApparatus,
    addPosition,
    editPosition,
    removePosition,
    addAssignmentForNewlyCreatedPosition,
    getShiftLeaderApparatusSelected,
  };
};

export type UseShiftTemplateReturnType = ReturnType<typeof useShiftTemplate>;
