import { Box } from '@mui/material';
import { Badge, RankBadge } from '@stationwise/component-module';
import { EmployeeChange } from '../../preview';

interface PublishingPreviewDetailEmployeeBlockProps {
  employees: EmployeeChange[];
  blockTitle: string;
}

export const PublishingPreviewDetailEmployeeBlock = ({ employees, blockTitle }: PublishingPreviewDetailEmployeeBlockProps) => {
  return (
    <Box>
      {employees && employees.length != 0 && (
        <Box>
          <Box sx={(theme) => ({ typography: 'bodyLSemibold', pt: theme.spacing(2) })}>{blockTitle}</Box>
          {employees.map((employee) => (
            <Box sx={{ marginY: 2, ml: 2 }} key={'employee_change' + employee.employee.id}>
              <RankBadge sx={{ mr: 1 }} rank={employee.employee.rank} />
              <Box sx={{ display: 'inline', typography: 'bodyMSemibold' }}>{employee.employee.name} </Box>
              <Box sx={{ ml: 6, mt: 1 }}>
                {employee.cancelledTimeOffs.length !== 0 && (
                  <Box sx={{ display: 'inline', typography: 'bodySSemibold' }}>Time-offs to be cancelled:</Box>
                )}
                {employee.cancelledTimeOffs.map((cancelledTimeOff) => (
                  <Box key={'canc_time_of_' + cancelledTimeOff.id} sx={{ ml: 2, display: 'list-item' }}>
                    <Box
                      sx={{
                        typography: 'bodySRegular',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Badge label={cancelledTimeOff.startDate} color={'red'} />
                    </Box>
                    {cancelledTimeOff.apparatusName && cancelledTimeOff.stationName ? (
                      <Box sx={{ typography: 'bodySSemibold', ml: 1 }}>
                        {cancelledTimeOff.apparatusName} at {cancelledTimeOff.stationName}
                      </Box>
                    ) : (
                      <Box sx={{ typography: 'bodySSemibold', ml: 1 }}>Floater</Box>
                    )}
                  </Box>
                ))}
                {employee.cancelledShiftTrades.length !== 0 && (
                  <Box sx={{ typography: 'bodySSemibold' }}>Shift trades to be cancelled:</Box>
                )}
                {employee.cancelledShiftTrades.map((cancelledShiftTrade) => (
                  <Box key={'canc_shift_trade_' + cancelledShiftTrade.id} sx={{ ml: 2, display: 'list-item' }}>
                    <Box
                      sx={{
                        typography: 'bodySRegular',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Badge label={cancelledShiftTrade.startDate} color={'red'} />
                    </Box>
                    {cancelledShiftTrade.apparatusName && cancelledShiftTrade.stationName ? (
                      <Box sx={{ typography: 'bodySSemibold', ml: 1 }}>
                        {cancelledShiftTrade.apparatusName} at {cancelledShiftTrade.stationName}
                      </Box>
                    ) : (
                      <Box sx={{ typography: 'bodySSemibold', ml: 1 }}>Floater</Box>
                    )}

                    <Box sx={{ typography: 'bodySRegular', ml: 1 }}>
                      {cancelledShiftTrade.senderName} {'<-'} {cancelledShiftTrade.receiverName}
                    </Box>
                    {cancelledShiftTrade.cancelReason && (
                      <Box sx={{ typography: 'bodySSemibold', ml: 1 }}>({cancelledShiftTrade.cancelReason})</Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
