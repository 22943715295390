import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { client } from '@stationwise/share-api';
import { ShiftPlanAPIData } from '@stationwise/share-types';

export const useFetchShiftPlan = () => {
  const location = useLocation();
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);
  const [data, setData] = useState<ShiftPlanAPIData | null>(null);
  const [selectedBattalionId, setSelectedBattalionId] = useState<string | null>(location.state?.battalionId || null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    setData(null);
    client
      .get('/shift/shift-plan/', {
        params: { battalionId: selectedBattalionId },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
        captureException(error);
      });
  }, [refetchCounter, selectedBattalionId]);

  const forceRefetch = useCallback((battalionId: string) => {
    setIsLoading(true);
    setSelectedBattalionId(battalionId);
    setRefetchCounter((c) => c + 1);
  }, []);

  return {
    isLoading,
    isError: !!error,
    data,
    forceRefetch,
  };
};
