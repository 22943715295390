import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon20 } from '../../../assets';
import { useDocumentTitle } from '../../../hooks';
import { Button } from '../../Button';

interface MagicLinkPageLayoutProps {
  icon: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  body: ReactNode;
}

export const MagicLinkPageLayout = ({ icon, title, subtitle, body }: MagicLinkPageLayoutProps) => {
  useDocumentTitle('Magic Link Login');
  return (
    <Box
      sx={(theme) => ({
        px: 2,
        py: 4,
        [theme.breakpoints.up('sm')]: {
          py: 6,
        },
      })}
    >
      <Box sx={{ mx: 'auto', width: 328, maxWidth: '100%' }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mx: 'auto',
            mb: 3,
            width: 48,
            height: 48,
            borderRadius: '50%',
            backgroundColor: theme.palette.stationBlue[100],
            color: theme.palette.stationBlue[600],
            [theme.breakpoints.up('sm')]: {
              mb: 6,
              width: 80,
              height: 80,
            },
            '& svg': {
              width: 24,
              height: 24,
              [theme.breakpoints.up('sm')]: {
                width: 40,
                height: 40,
              },
            },
          })}
        >
          {icon}
        </Box>
        <Box
          component="h1"
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
            mt: 0,
            mb: 2,
            typography: 'heading4',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
              fontSize: '32px',
              mb: 3,
            },
          })}
        >
          {title}
        </Box>
        <Box
          component="p"
          sx={(theme) => ({
            color: theme.palette.stationGray[600],
            mt: 0,
            mb: 5,
            typography: 'bodyMRegular',
            textAlign: 'center',
          })}
        >
          {subtitle}
        </Box>
        {body}
        <Button size="large" component={Link} to="/login" startIcon={<ArrowLeftIcon20 />} sx={{ width: '100%' }}>
          Back to log in
        </Button>
      </Box>
    </Box>
  );
};
