import { Box } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { ShiftTradeContent } from './components/ShiftTradeContent';

export const ShiftTrade = () => {
  useDocumentTitle('Shift Trade Request');
  return (
    <AppPageLayout>
      <Box
        data-testid="shift-trade-main"
        sx={(theme) => ({
          background: theme.palette.common.white,
          height: '100%',
          width: '100%',
          maxWidth: '768px',
          margin: '0 auto',
        })}
      >
        <ShiftTradeContent />
      </Box>
    </AppPageLayout>
  );
};
