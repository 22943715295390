import { useMediaQuery, useTheme, Drawer } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useEffect, useState } from 'react';
import { SelectButton } from '../../Button';
import { SelectContactOptionProps } from './Options';
import { Recipients } from './Recipients';
import { ISelectedRecipients } from './types';

interface SelectRecipientsProps {
  data: {
    [key in string]: SelectContactOptionProps[];
  };
  isEditable?: boolean;
  editRecipients: (selectedRecipients: ISelectedRecipients) => void;
  reset: boolean;
}

export const SelectRecipients = ({ data, isEditable, editRecipients, reset }: SelectRecipientsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setAnchorEl(null);
    handleClose();
  }, [reset]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const openPopOver = Boolean(anchorEl);

  return (
    <>
      <SelectButton
        data-cy="message-to"
        disabled={!isEditable}
        onClick={handleClick}
        caret={open}
        sx={(theme) => ({
          color: theme.palette.stationGray[500],
          minHeight: '28px',
          p: 0,
          typography: 'bodyXSMedium',
        })}
      >
        TO
      </SelectButton>

      {isSmallScreen && (
        <Drawer sx={{ display: !open ? 'none' : 'inherit' }} open={openPopOver} onClose={handleClose} anchor={'bottom'}>
          <Recipients
            data={data}
            onAddRecipients={(selectedRecipients, close = true) => {
              if (close) {
                handleClose();
              }
              editRecipients(selectedRecipients);
            }}
            onClose={handleClose}
          />
        </Drawer>
      )}

      {!isSmallScreen && (
        <Popover
          sx={{ display: !open ? 'none' : 'inherit' }}
          open={openPopOver}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Recipients
            data={data}
            onAddRecipients={(selectedRecipients, close = true) => {
              if (close) {
                handleClose();
              }
              editRecipients(selectedRecipients);
            }}
            onClose={handleClose}
          />
        </Popover>
      )}
    </>
  );
};
