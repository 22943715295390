import { Box, useTheme } from '@mui/material';
import { RosterDuration } from '@stationwise/share-types';
import { formatDurationMinutes } from '@stationwise/shift-summary-helper';
import { UnstaffedBackground } from '../../../../assets';
import { SplitTime } from './SplitTime';

interface UnstaffedCardProps {
  duration: RosterDuration;
}

export const UnstaffedCard = ({ duration }: UnstaffedCardProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: `url('${UnstaffedBackground}')`,
        border: `1px solid ${theme.palette.stationGray[400]}`,
        borderRadius: '16px',
        p: 2,
        mt: 2,
      }}
    >
      <Box sx={{ typography: 'bodySMedium' }}>Not Staffed</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mt: 3 }}>
        <SplitTime disabled={true} label="Start" value={duration.startDateTime} />
        <SplitTime disabled={true} label="End" value={duration.endDateTime} />
        <Box sx={{ flex: 1, whiteSpace: 'nowrap' }}>
          Duration
          <br />
          {formatDurationMinutes(duration)}
        </Box>
      </Box>
    </Box>
  );
};
