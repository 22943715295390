import { Box } from '@mui/material';
import { Fragment } from 'react';
import { Badge } from '@stationwise/component-module';
import { AllowedColors, Station } from '@stationwise/share-types';

interface PublishingPreviewDetailDepartmentStructureBlockRowProps {
  station: Station;
  badgeColor: AllowedColors;
  badgeLabel: string;
}

export const PublishingPreviewDetailDepartmentStructureBlockRow = ({
  station,
  badgeColor,
  badgeLabel,
}: PublishingPreviewDetailDepartmentStructureBlockRowProps) => {
  return (
    <Fragment>
      <Box sx={{ display: 'list-item', textWrap: 'wrap' }}>{station.name} </Box>
      <Box sx={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
        <Badge color={badgeColor} label={badgeLabel} />
      </Box>
    </Fragment>
  );
};
