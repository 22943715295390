import { Box } from '@mui/material';
import React from 'react';
import { noop } from '@stationwise/share-utils';
import { Button, ChipFilter } from '../../../components';

interface FooterProps {
  onAddRecipients?: () => void;
  amountSelected?: number;
  onCloseChip?: () => void;
}

export const Footer = ({ onAddRecipients, amountSelected, onCloseChip }: FooterProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px -2px 4px -1px rgba(10, 14, 22, 0.06), 0px -4px 6px -1px rgba(10, 14, 22, 0.10)',
        padding: '16px 24px',
        position: 'sticky',
        bottom: 0,
        backgroundColor: theme.palette.common.white,
      })}
    >
      <Box>
        {amountSelected && amountSelected > 0 ? (
          <ChipFilter label={`${amountSelected} selected`} onClose={onCloseChip} icon={null} action={noop} />
        ) : null}
      </Box>
      <Button variant="contained" data-cy="add-recipients-button" onClick={onAddRecipients}>
        Add Recipients
      </Button>
    </Box>
  );
};
