import { DepartmentInfo, ShiftPlanAPIData, ShiftPlanAssignment } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { getDepartmentPayCodes } from '@stationwise/shift-summary-helper';

export const initializeAssignments = (departmentInfo: DepartmentInfo, data: ShiftPlanAPIData) => {
  return data.assignments.map((assignment) => {
    return {
      ...assignment,
      startDateTime: new Date(assignment.startDateTime),
      endDateTime: new Date(assignment.endDateTime),
      payCodes: getDepartmentPayCodes(departmentInfo, assignment.payCodes),
    };
  });
};

export const getPositionAssignmentSortKey = (a: ShiftPlanAssignment, b: ShiftPlanAssignment) => {
  return (
    (a.employee.team?.name || '').localeCompare(b.employee.team?.name || '') ||
    differenceInUTCMinutes(a.startDateTime, b.startDateTime) ||
    differenceInUTCMinutes(a.endDateTime, b.endDateTime) ||
    Number(a.employee.id) - Number(b.employee.id)
  );
};

export const getNonPositionAssignmentSortKey = (a: ShiftPlanAssignment, b: ShiftPlanAssignment) => {
  return (
    (a.employee.team?.name || '').localeCompare(b.employee.team?.name || '') ||
    Number(a.employee.id) - Number(b.employee.id) ||
    differenceInUTCMinutes(a.startDateTime, b.startDateTime)
  );
};

export const getAssignmentGroups = (assignments: ShiftPlanAssignment[]) => {
  const positionAssignments = new Map<string, ShiftPlanAssignment[]>();
  const excessAssignments = new Map<string, ShiftPlanAssignment[]>();
  const floaterAssignments: ShiftPlanAssignment[] = [];
  assignments.forEach((assignment) => {
    const { apparatus, position } = assignment.reference;
    if (position) {
      const group = positionAssignments.get(`${position.id}`) || [];
      group.push(assignment);
      positionAssignments.set(`${position.id}`, group);
    } else if (apparatus) {
      const group = excessAssignments.get(`${apparatus.id}`) || [];
      group.push(assignment);
      excessAssignments.set(`${apparatus.id}`, group);
    } else {
      floaterAssignments.push(assignment);
    }
  });
  positionAssignments.forEach((assignments) => assignments.sort(getPositionAssignmentSortKey));
  excessAssignments.forEach((assignments) => assignments.sort(getNonPositionAssignmentSortKey));
  floaterAssignments.sort(getNonPositionAssignmentSortKey);
  return { positionAssignments, excessAssignments, floaterAssignments };
};
