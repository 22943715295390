import { SxProps, Theme, useTheme } from '@mui/material';
import { RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { checkIsActingAsRank, checkIsPlannedEmployee, checkIsShift } from '@stationwise/shift-summary-helper';
import { Pencil01Icon16 } from '../../../../assets';
import { CardTooltip } from '../../../Card';
import { FilledPosition } from '../../../Position/FilledPosition';
import { getEmployeeCardStyles } from '../EmployeeCard';
import { useRosterContext } from '../RosterContext';

interface RosterAssignedEmployeeCardProps {
  apparatus: RosterApparatus;
  position: RosterPosition;
  employee: RosterEmployee;
  isCollapsed: boolean;
  cardSxProps?: SxProps<Theme>;
}

export const RosterAssignedEmployeeCard = ({
  apparatus,
  position,
  employee,
  isCollapsed,
  cardSxProps,
  ...props
}: RosterAssignedEmployeeCardProps) => {
  const theme = useTheme();
  const { isPersonalRoster, selectedEmptyPositionState, shiftSummaryHelper } = useRosterContext();
  const isPlannedEmployee = checkIsPlannedEmployee(shiftSummaryHelper, employee);
  const cardType = !isPlannedEmployee && checkIsShift(position) ? 'border' : 'default';

  const notesIndicator = !isPersonalRoster && employee.noteOverride?.note && (
    <CardTooltip anchorContent={<Pencil01Icon16 />} popoverContent="Click to see note details" />
  );

  return (
    <FilledPosition
      actingAs={checkIsActingAsRank(shiftSummaryHelper, position, employee)}
      isCollapsed={isCollapsed}
      position={position}
      employee={employee}
      cardType={cardType}
      notesIndicator={notesIndicator}
      {...props}
      cardSxProps={[
        { border: 0, '&:hover': { border: 0 } },
        getEmployeeCardStyles({ theme, employeeActiveId: employee.activeId, selectedEmptyPositionState }),
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    />
  );
};
