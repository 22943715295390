import { Box, MenuItem, Select as MuiSelect, SelectChangeEvent, SxProps, Theme, FormControl, InputLabel } from '@mui/material';
import { ChevronDownIcon16 } from '../../assets';

export interface AdminSelectItem {
  label: string;
  secondLabel?: string;
  value: string;
  color?: string;
  helperText?: string;
}
export const AdminSelect = ({
  items,
  selectedItem,
  setSelectedItem,
  labelStyles,
  secondLabelStyles,
  secondLabelAdornment,
  onChangeSelected,
  placeholder,
  secondLabelPosition,
}: {
  items: AdminSelectItem[];
  selectedItem: string;
  setSelectedItem: (selected: string) => void;
  labelStyles?: SxProps<Theme>;
  secondLabelStyles?: SxProps<Theme>;
  secondLabelAdornment?: React.ReactElement;
  onChangeSelected?: () => void;
  placeholder: string;
  secondLabelPosition?: 'start' | 'end';
}) => {
  const onChange = (event: SelectChangeEvent) => {
    setSelectedItem(event.target.value as string);
    if (onChangeSelected) {
      onChangeSelected();
    }
  };
  return (
    <FormControl
      sx={(theme) => ({
        width: '100%',
        '& .MuiOutlinedInput-root': { height: '44px' },
        '& .MuiOutlinedInput-input': { py: 0 },
        '& .Mui-focused': { color: theme.palette.stationGray[900] },
      })}
    >
      <InputLabel
        shrink={false}
        sx={(theme) => ({
          typography: 'bodyMRegular',
          color: theme.palette.stationGray[500],
          transform: 'translate(14px, 11px)',
        })}
      >
        {!selectedItem && placeholder}
      </InputLabel>
      <MuiSelect
        displayEmpty
        value={selectedItem}
        onChange={(event: SelectChangeEvent) => onChange(event)}
        sx={{
          borderRadius: '8px',
          typography: 'bodySRegular',
          '& .MuiSelect-icon': { right: '16px', top: selectedItem ? '13px' : 'unset' },
          scroll: 'auto',
          maxHeight: '220px',
          width: '100%',
          overflowX: 'hidden',
          pr: '3px',
        }}
        MenuProps={{
          autoFocus: false,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          sx: (theme) => ({
            '& .Mui-selected': {
              backgroundColor: theme.palette.common.white,
              borderRadius: '8px',
              border: `1px solid ${theme.palette.stationGray[900]}`,
            },
          }),
          PaperProps: {
            style: {
              maxHeight: 264,
            },
          },
        }}
        IconComponent={ChevronDownIcon16}
        renderValue={(selected) => {
          const selectedOption = items.find((item) => item.value === selected);
          return (
            <Box sx={{ py: 1.5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {selectedOption?.label}
            </Box>
          );
        }}
      >
        {items.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={(theme) => ({
              borderRadius: '8px',
              color: theme.palette.stationGray[900],
              typography: 'bodySRegular',
              mx: theme.spacing(1.5),
            })}
          >
            <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
              <Box display="flex" justifyContent="space-between" sx={{ width: '100%' }}>
                <Box
                  display="flex"
                  sx={[
                    ...(Array.isArray(labelStyles) ? labelStyles : labelStyles ? [labelStyles] : []),
                    (theme) => ({
                      typography: 'bodyMRegular',
                      color: option.value === '' ? theme.palette.stationGray[500] : 'inherit',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }),
                  ]}
                >
                  {option.label}
                  {option.secondLabel && secondLabelPosition && secondLabelPosition === 'start' && (
                    <Box
                      key={option.value}
                      sx={[
                        (theme) => ({
                          color: !option.color || option.color === 'white' ? theme.palette.stationGray[500] : option.color,
                        }),
                        ...(Array.isArray(secondLabelStyles) ? secondLabelStyles : secondLabelStyles ? [secondLabelStyles] : []),
                      ]}
                    >
                      {option.secondLabel}
                    </Box>
                  )}
                </Box>
                {option.secondLabel && secondLabelPosition && secondLabelPosition === 'end' && (
                  <Box
                    sx={[
                      (theme) => ({
                        display: 'flex',
                        pt: '2px',
                        mr: '3px',
                        typography: 'bodySMedium',
                        color: theme.palette.stationGray[500],
                      }),
                      ...(Array.isArray(secondLabelStyles) ? secondLabelStyles : secondLabelStyles ? [secondLabelStyles] : []),
                    ]}
                  >
                    {option.secondLabel && secondLabelPosition && secondLabelPosition === 'end' && option.secondLabel}
                    {secondLabelAdornment && secondLabelAdornment}
                  </Box>
                )}
              </Box>

              {option.helperText && (
                <Box
                  sx={(theme) => ({
                    typography: 'bodyXSRegular',
                    color: theme.palette.stationGray[500],
                    mt: theme.spacing(0.5),
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'normal',
                    overflowWrap: 'normal',
                    width: '100%',
                  })}
                >
                  {option.helperText}
                </Box>
              )}
            </Box>
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
