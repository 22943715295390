import { Box, alpha, useTheme } from '@mui/material';
import { getCertColors } from '@stationwise/component-module';
import { CertificationRequirement } from '@stationwise/share-types';

interface CertificationRequirementChipsProps {
  certificationRequirements: CertificationRequirement[];
}

export const CertificationRequirementChips = (props: CertificationRequirementChipsProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1, minHeight: '20px' }}>
      {[...props.certificationRequirements]
        .sort((a, b) => a.code.localeCompare(b.code))
        .map((certReq) => {
          const colors = getCertColors({ theme, color: certReq.color });
          return (
            <Box
              key={certReq.id}
              sx={{
                ...colors,
                borderRadius: '4px',
                boxShadow: `inset 0 0 0 1px ${alpha(theme.palette.common.black, 0.12)}`,
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5,
                padding: '2px 4px',
                typography: 'tagline1',
                lineHeight: 1,
                textWrap: 'nowrap',
              }}
            >
              {certReq.code}
              <Box
                sx={{
                  background: colors.color,
                  color: theme.palette.common.white,
                  borderRadius: '50%',
                  width: '16px',
                  height: '16px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {certReq.amount}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};
