import { ReactComponent as KeyboardArrowDownSymbol } from '@material-symbols/svg-400/outlined/keyboard_arrow_down.svg';
import { Accordion, AccordionDetails, AccordionSummary, Box, DialogTitle, TextField, useTheme } from '@mui/material';
import { useId, useMemo, useState } from 'react';
import { SvgIcon, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { upsertStation } from '../../helpers/crudStation';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanDialogActions } from '../ShiftPlanDialog';
import { CertificationRequirementCounter, makeCertificationRequirements } from './CertificationRequirementCounter';

export const UpsertStationForm = () => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { stations, setStations, selectedStruct, setSelectedAction } = useShiftPlanContext();
  const { station: selectedStation } = selectedStruct;

  const id = useId();
  const [stationName, setStationName] = useState(selectedStation?.stationName || '');
  const [addressStreet1, setAddressStreet1] = useState(selectedStation?.address?.street1 || '');
  const [addressStreet2, setAddressStreet2] = useState(selectedStation?.address?.street2 || '');
  const [addressCity, setAddressCity] = useState(selectedStation?.address?.city || '');
  const [addressState, setAddressState] = useState(selectedStation?.address?.state || '');
  const [addressPostalCode, setAddressPostalCode] = useState(selectedStation?.address?.postalCode || '');
  const [certificationRequirements, setCertificationRequirements] = useState(() => {
    return makeCertificationRequirements(departmentInfoState.departmentInfo, selectedStation?.certificationRequirements);
  });

  const existingStationNames = useMemo(() => {
    // TODO: Validate NonShiftAssignment stations when saving as draft.
    const realNames = Array.from(stations.values()).map((s) => s.stationName.toLowerCase());
    const fakeNames = ['__events__', 'event groups', 'temporary assignment'];
    return new Set([...realNames, ...fakeNames]);
  }, [stations]);

  const [error, setError] = useState({ duplicatedStation: false });
  const isSaveDisabled = !stationName || Object.values(error).some(Boolean);

  const onSave = () => {
    const newStationData = {
      stationName,
      address: {
        street1: addressStreet1,
        street2: addressStreet2,
        city: addressCity,
        state: addressState,
        postalCode: addressPostalCode,
      },
      certificationRequirements,
    };
    setStations(upsertStation(stations, selectedStruct, newStationData));
    setSelectedAction(null);
  };

  return (
    <>
      <DialogTitle sx={{ px: 3, pt: 3, pb: 2, typography: 'bodyXLSemibold' }}>
        {selectedStation ? 'Edit station' : 'Add station'}
      </DialogTitle>
      <Box sx={{ flex: 1, minHeight: '1px', overflowY: 'auto', px: 3, pt: 1, width: '495px', maxWidth: '100%' }}>
        <TextField
          label="Name"
          value={stationName}
          required={true}
          error={error.duplicatedStation}
          helperText={error.duplicatedStation ? 'This station already exists, please choose another name.' : ''}
          size="small"
          sx={{ width: '100%', '& .MuiOutlinedInput-input': { p: '8.5px 14px' } }}
          onChange={(event) => {
            setStationName(event.target.value);
            if (existingStationNames.has(event.target.value.toLowerCase())) {
              setError({ ...error, duplicatedStation: true });
            } else if (error.duplicatedStation) {
              setError({ ...error, duplicatedStation: false });
            }
          }}
        />
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<SvgIcon component={KeyboardArrowDownSymbol} />}
            aria-controls={`${id}address-content`}
            id={`${id}address-header`}
          >
            Address
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label="Street 1"
              value={addressStreet1}
              size="small"
              sx={{ width: '100%', '& .MuiOutlinedInput-input': { p: '8.5px 14px' } }}
              onChange={(event) => setAddressStreet1(event.target.value)}
            />
            <TextField
              label="Street 2"
              value={addressStreet2}
              size="small"
              sx={{ mt: 2, width: '100%', '& .MuiOutlinedInput-input': { p: '8.5px 14px' } }}
              onChange={(event) => setAddressStreet2(event.target.value)}
            />
            <TextField
              label="City"
              value={addressCity}
              size="small"
              sx={{ mt: 2, width: '100%', '& .MuiOutlinedInput-input': { p: '8.5px 14px' } }}
              onChange={(event) => setAddressCity(event.target.value)}
            />
            <TextField
              label="State"
              value={addressState}
              size="small"
              sx={{ mt: 2, width: '100%', '& .MuiOutlinedInput-input': { p: '8.5px 14px' } }}
              onChange={(event) => setAddressState(event.target.value)}
            />
            <TextField
              label="Postal code"
              value={addressPostalCode}
              size="small"
              sx={{ mt: 2, width: '100%', '& .MuiOutlinedInput-input': { p: '8.5px 14px' } }}
              onChange={(event) => setAddressPostalCode(event.target.value)}
            />
          </AccordionDetails>
        </Accordion>
        <Box sx={{ color: theme.palette.stationGray[700], my: 2.5, typography: 'eyebrow' }}>Station level requirements</Box>
        {certificationRequirements.map((certReq) => (
          <CertificationRequirementCounter
            key={certReq.id}
            certificationRequirement={certReq}
            setCertificationRequirements={setCertificationRequirements}
          />
        ))}
      </Box>
      <ShiftPlanDialogActions isSaveDisabled={isSaveDisabled} onSave={onSave} />
    </>
  );
};
