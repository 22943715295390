import { Typography, Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { client, isAxiosError } from '@stationwise/share-api';
import { ArrowLeftIcon20 } from '../../assets';
import { useDocumentTitle } from '../../hooks';
import { Button } from '../Button';
import { LoginWithActivationCode } from './components/LoginWithActivationCode';
import { LoginWithQR } from './components/LoginWithQR';
import { LogoWrapper } from './components/LogoWrapper';

export const ApparatusLoginPage = () => {
  useDocumentTitle('Apparatus Login');
  const [activationCode, setActivationCode] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const activationUrl = 'mobile.stationwise.com/activate';
  const navigate = useNavigate();
  type ApparatusLoginResponse = {
    activationCode: string;
    userEmail: string;
  };

  async function createApparatusLoginCredentials(): Promise<ApparatusLoginResponse> {
    const response = await client.post('/auth/activation-code/');
    return response.data;
  }
  useEffect(() => {
    if (activationCode === null) {
      createApparatusLoginCredentials()
        .then((data) => {
          setActivationCode(data.activationCode);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (isAxiosError(error)) {
            console.error('API Error:', error.response?.data);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkActivationCodeAndLogin = async () => {
    try {
      const params = {
        code: activationCode,
      };
      const response = await client.get('/auth/activation-code/', { params });
      const data = response.data;
      if (data.userEmail) {
        // Trigger login flow if activation code has been set with user email,
        setLoading(false);
        performLogin(data.activationCode);
      }
    } catch (error) {
      setLoading(false);
      if (isAxiosError(error)) {
        console.error('API Error:', error.response?.data);
      }
    }
  };

  const performLogin = async (activationCode: string) => {
    try {
      await client.post('/auth/apparatus-login/', {
        code: activationCode,
      });
      navigate('/app/personal-roster');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      if (!message) {
        captureException(err);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (activationCode) {
        checkActivationCodeAndLogin();
      }
    }, 5000);
    return () => clearInterval(intervalId); // Clear the interval on component unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationCode]);
  const onclick = () => {
    createApparatusLoginCredentials()
      .then((data) => {
        setActivationCode(data.activationCode);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (isAxiosError(error)) {
          console.error('API Error:', error.response?.data);
        }
      });
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.stationGray[200],
        minHeight: '100vh',
      })}
    >
      <Box sx={{ pt: 1 }}>
        <LogoWrapper mb={6} />
        <Box
          component="h1"
          sx={{
            mb: 6,
          }}
        >
          <Typography
            sx={(theme) => ({
              color: theme.palette.stationGray[500],
              typography: 'bodyXLMedium',
              textAlign: 'center',
              mb: 1,
            })}
          >
            Apparatus login
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              typography: 'heading3',
              textAlign: 'center',
            })}
          >
            Two ways to log in
          </Typography>
        </Box>
        <LoginWithQR activationCode={activationCode} activationUrl={'https://' + activationUrl} />
        <LoginWithActivationCode
          activationCode={activationCode}
          activationUrl={activationUrl}
          loading={loading}
          onclick={onclick}
        />
        <Button color="inherit" size="large" component={Link} to="/login" startIcon={<ArrowLeftIcon20 />} sx={{ width: '100%' }}>
          Back to log in
        </Button>
      </Box>
    </Box>
  );
};
