import { Box, Chip } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { ChevronDownIcon20, ChevronUpIcon20 } from '@stationwise/component-module';
import { PayrollCode } from '@stationwise/share-types';
import { PayrollCardItem } from './PayrollCardItem';
import { getColorForPayCodeType } from './utils';
interface PayrollCardProps {
  days: Date[];
  payroll: PayrollCode[];
  totalHours: number;
  setOpenCompTimeModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleRequestCompTime: (day: Date, overtimeCode: string) => void;
  setPayrollCode: React.Dispatch<React.SetStateAction<PayrollCode | null>>;
  type: string;
  name: string;
  isPending?: boolean;
}

export const PayrollCard = ({
  days,
  payroll,
  totalHours,
  setOpenCompTimeModal,
  handleRequestCompTime,
  setPayrollCode,
  type,
  name,
  isPending = false,
}: PayrollCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.stationGray[100]}`,
        borderRadius: theme.spacing(1.5),
        pt: theme.spacing(1.5),
        pb: theme.spacing(1),
        // this is the 4px stripe on the left side of the card
        background: `linear-gradient(90deg, ${getColorForPayCodeType(type, theme)} 4px, ${theme.palette.common.white} 0)`,
      })}
    >
      <Box
        sx={(theme) => ({
          borderBottom: isOpen ? `2px solid ${theme.palette.stationGray[100]}` : 'none',
          ml: '4px', // this is to offset the left stripe on the card and avoid border overlapping it
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr 32px',
            alignContent: 'center',
            pl: theme.spacing(1.5), // removing 4px to account for the left stripe margin offset
            pr: theme.spacing(1.5),
            pb: theme.spacing(1),
          })}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '2fr 1fr',
            }}
          >
            <Box>
              <Box sx={(theme) => ({ mb: theme.spacing(1.5), typography: 'bodyXXLMedium' })}>
                {type
                  .toLowerCase()
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </Box>
              <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>{name}</Box>
            </Box>
            <Box
              sx={(theme) => ({
                pr: theme.spacing(2),
                pl: theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'center',
                borderLeft: `1px solid ${theme.palette.stationGray[100]}`,
                typography: 'bodyXXLRegular',
                minWidth: 'max-content',
              })}
            >
              {isPending && (
                <Chip
                  sx={(theme) => ({
                    height: '2em',
                    width: '5.7em',
                    minWidth: 'max-content',
                    paddingTop: '.2em',
                    marginBottom: theme.spacing(1),
                    fontWeight: 400,
                  })}
                  label="Pending"
                  variant="outlined"
                />
              )}
              {totalHours.toFixed(2)} h
            </Box>
          </Box>
          <Box
            onClick={() => setIsOpen(!isOpen)}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderLeft: `1px solid ${theme.palette.stationGray[100]}`,
              '& svg path': {
                stroke: theme.palette.stationGray[300],
              },
            })}
          >
            {isOpen ? <ChevronUpIcon20 /> : <ChevronDownIcon20 />}
          </Box>
        </Box>
      </Box>
      {isOpen && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {payroll.map((payCode) => (
            <Box key={payCode.code}>
              <Box
                sx={(theme) => ({
                  ml: '4px',
                  display: 'flex',
                  flexDirection: 'row',
                  pl: theme.spacing(1.5),
                  height: theme.spacing(8),
                  pr: theme.spacing(5),
                  pt: theme.spacing(2.5),
                  alignContent: 'center',
                  backgroundColor: theme.palette.stationGray[50],
                  borderBottom: `1px solid ${theme.palette.stationGray[100]}`,
                  color: theme.palette.stationGray[900],
                  justifyContent: 'space-between',
                  typography: 'bodyLMedium',
                })}
              >
                {payCode.name}{' '}
                <Box
                  sx={(theme) => ({
                    pr: theme.spacing(2),

                    alignItems: 'end',
                    justifyContent: 'center',
                    typography: 'bodyXLMedium',
                  })}
                >
                  {payCode.total.toFixed(2)} h
                </Box>{' '}
              </Box>

              {days.map((day) => {
                const dayHours = payCode.dayHours.find((dayHours) => dayHours.date === format(day, 'yyyy-MM-dd'));
                return dayHours && dayHours.hours > 0 ? (
                  <PayrollCardItem
                    key={day.toDateString()}
                    currentDate={day}
                    dayHours={dayHours}
                    payCode={payCode}
                    setOpenCompTimeModal={setOpenCompTimeModal}
                    handleRequestCompTime={handleRequestCompTime}
                    setPayrollCode={setPayrollCode}
                  />
                ) : null;
              })}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
