import { DayCellContentArg } from '@fullcalendar/core';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Team } from '@stationwise/share-types';
import { CheckIcon16, DollarIcon16 } from '../../assets';
import { PayPeriodDateInfo } from './Calendar';
import { CalendarCellShiftBadge } from './CalendarCellShiftBadge';

export const CustomSelectDayCellContent = ({
  cellContent,
  selectedDates,
  shiftTeamMap,
  payPeriodDates,
}: {
  cellContent: DayCellContentArg;
  selectedDates?: string[];
  shiftTeamMap: Map<string, Team>;
  payPeriodDates?: PayPeriodDateInfo[];
}) => {
  const isSelected = selectedDates?.includes(format(cellContent.date, 'MM/dd/yy'));
  const team = shiftTeamMap.get(format(cellContent.date, 'yyyy-MM-dd'));
  const formattedDate = format(cellContent.date, 'yyyy-MM-dd');
  const isPayPeriodStart = payPeriodDates?.some((period) => period.startDate === formattedDate);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" width="100%" justifyContent="center">
        <CalendarCellShiftBadge team={team} />
        <Box>
          <Box
            data-cy={cellContent.isToday ? 'today-cell' : 'day-cell'}
            display="flex"
            sx={(theme) => ({
              backgroundColor: cellContent.isToday ? theme.palette.common.black : 'unset',
              color: cellContent.isToday ? theme.palette.common.white : 'unset',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              textAlign: 'center',
              pt: 'unset',
              justifyContent: 'center',
              alignItems: 'center',
            })}
          >
            <Typography variant="bodyXSRegular">{cellContent.dayNumberText}</Typography>
            {isPayPeriodStart && (
              <Box
                display="flex"
                sx={(theme) => ({
                  justifyContent: 'center',
                  position: 'absolute',
                  right: '2px',
                  color: theme.palette.stationGray[400],
                })}
              >
                <DollarIcon16 />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {isSelected && (
        <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <Box
            display="flex"
            sx={(theme) => ({
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              justifyContent: 'center',
              alignContent: 'center',
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.black,
              flexWrap: 'wrap',
              my: theme.spacing(2),
            })}
          >
            <CheckIcon16 />
          </Box>
        </Box>
      )}
    </Box>
  );
};
