import { SxProps, Theme } from '@mui/material';
import { RosterPosition } from '@stationwise/share-types';
import { checkIsShift, checkIsStrikeTeam } from '@stationwise/shift-summary-helper';
import { useAuthUserCapabilities } from '../../../../utils/capabilities';
import { EmptyPosition } from '../../../Position/EmptyPosition';
import { useRosterContext } from '../RosterContext';

interface RosterEmptyPositionCardProps {
  position: RosterPosition;
  isCollapsed: boolean;
  isOver: boolean;
  cardSxProps?: SxProps<Theme>;
}

export const RosterEmptyPositionCard = ({ position, isCollapsed, isOver, cardSxProps }: RosterEmptyPositionCardProps) => {
  const { isReadonly, selectedEmptyPositionState } = useRosterContext();
  const capabilities = useAuthUserCapabilities();
  const hasCandidateAssignee = selectedEmptyPositionState.candidateAssigneeIds.size > 0;
  const isSelectable =
    !isReadonly &&
    ((checkIsShift(position) && capabilities.EDIT_ROSTER_ASSIGNMENTS) ||
      (checkIsStrikeTeam(position) && capabilities.MANAGE_EVENTS));
  const isNotSelected = hasCandidateAssignee && selectedEmptyPositionState.position?.id !== position.id;

  return (
    <EmptyPosition
      position={position}
      isCollapsed={isCollapsed}
      isOver={isOver}
      onClick={() => selectedEmptyPositionState.setPosition(position)}
      isNotSelected={isNotSelected}
      isSelectable={isSelectable}
      cardSxProps={[
        { border: 0, '&:hover': { border: 0 } },
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    />
  );
};
