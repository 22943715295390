import { Box, Typography, useMediaQuery, Theme } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  TeamFlag,
  Loader,
  NoEligibleShiftTrade,
  SearchInput,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { EligibleEmployee } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';

interface ShiftTradeSelectProps {
  selectedPeople: string[];
  setSelectedPeople: Dispatch<SetStateAction<string[]>>;
  handleSubmit: (allPersonnel?: string[]) => void;
  setError?: Dispatch<SetStateAction<boolean>>;
  shiftDate: string;
}

const minutesToHours = (minutes: number) => {
  const hours = minutes / 60;
  return hours.toFixed(2).toString();
};

const capitalizeFirstLetter = (str: string) => {
  if (str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return '';
  }
};

export const ShiftTradeSelect = ({
  selectedPeople,
  setSelectedPeople,
  handleSubmit,
  setError,
  shiftDate,
}: ShiftTradeSelectProps) => {
  const [searchText, setSearchText] = useState('');
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isSendToAllEnabled = departmentInfoState.departmentInfo.settings.shiftTradeSendToAllEligibleEmployeesEnabled;
  const initialButtonLabel = isSendToAllEnabled ? 'Send call to all qualified personnel' : 'Request shift trade';
  const [buttonLabel, setButtonLabel] = useState<ReactNode>(initialButtonLabel);
  const [isAvailableEmployeesLoading, setIsAvailableEmployeesLoading] = useState(true);
  const [availableEmployees, setAvailableEmployees] = useState<EligibleEmployee[]>([]);
  const [ruleDescriptions, setRuleDescriptions] = useState<string[]>([]);

  const iosWebViewPaddingBottom = useIOSPaddingBottom();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getEligibleEmployees = async () => {
    try {
      const date = format(shiftDate, 'yyyy-MM-dd');
      const response = await client.get(`request/shift-trade-request/eligible-employees/?date=${date}`);
      return response.data;
    } catch (error) {
      if (setError) {
        captureException(error);
        setError(true);
      }
    }
  };

  useEffect(() => {
    const getEmployees = async () => {
      const response = await getEligibleEmployees();
      const eligibleEmployees = response.employees;
      const ruleDescriptions = response.ruleDescriptions;
      setAvailableEmployees(eligibleEmployees || []);
      setRuleDescriptions(ruleDescriptions || []);
      setIsAvailableEmployeesLoading(false);
    };

    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendRequest = () => {
    if (selectedPeople.length === 0) {
      const allEmployees = availableEmployees.map((employee) => employee.id);
      handleSubmit(allEmployees);
    } else {
      handleSubmit();
    }
  };

  const handleSelect = (employee: EligibleEmployee) => {
    if (selectedPeople.includes(employee.id)) {
      if (selectedPeople.length === 1) {
        setButtonLabel(isSendToAllEnabled ? 'Send call to all qualified personnel' : 'Request shift trade');
      } else if (selectedPeople.length === 2) {
        setButtonLabel('Request shift trade');
      } else {
        setButtonLabel(
          <Box component="span">
            Send call to{' '}
            <Box component="span" sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
              {selectedPeople.length - 1} persons
            </Box>
          </Box>,
        );
      }
      setSelectedPeople(selectedPeople.filter((id) => id !== employee.id));
    } else {
      if (selectedPeople.length === 0) {
        setButtonLabel('Request shift trade');
      } else {
        setButtonLabel(
          <Box component="span">
            Send call to{' '}
            <Box component="span" sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
              {selectedPeople.length + 1} persons
            </Box>
          </Box>,
        );
      }

      setSelectedPeople([...selectedPeople, employee.id]);
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        width: '100%',
      }}
    >
      {ruleDescriptions.length > 0 && (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.stationGray[100],
            mb: theme.spacing(2),
            typography: 'bodySMedium',
            borderRadius: 3,
            p: theme.spacing(2),
          })}
        >
          Eligibility rules: this list includes employees who…
          <Box sx={(theme) => ({ pl: theme.spacing(1), mt: theme.spacing(1) })}>
            {ruleDescriptions.map((ruleDescriptions, index) => (
              <Box sx={{ typography: 'bodyXSRegular' }} key={index}>
                • {ruleDescriptions}
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {availableEmployees.length > 0 && (
        <Box>
          <Box
            sx={(theme) => ({
              p: theme.spacing(0.5),
            })}
          >
            <SearchInput value={searchText} setValue={setSearchText} color={'white'} />
          </Box>

          <Box
            width={'100%'}
            sx={(theme) => ({
              color: theme.palette.stationGray[500],
              mb: theme.spacing(2),
              mt: theme.spacing(2),
            })}
          >
            Eligible Personnel
          </Box>

          <Box
            sx={(theme) => ({
              //paddingBottom: `calc(10vh + ${iosWebViewPaddingBottom})`,
              pb: iosWebViewPaddingBottom,
              mb: theme.spacing(5),
            })}
          >
            {availableEmployees
              .filter((employee) => `${employee.firstName} ${employee.lastName}`.toLowerCase().includes(searchText.toLowerCase()))
              .map((employee, idx) => (
                <Box
                  data-cy={makeTestIdentifier(`${employee.firstName}-${employee.lastName}`)}
                  key={idx}
                  onClick={() => handleSelect(employee)}
                  sx={(theme) => ({
                    p: theme.spacing(1),
                    border: selectedPeople.includes(employee.id)
                      ? `solid 2.5px ${theme.palette.stationGray[900]}`
                      : `solid 2px ${theme.palette.stationGray[100]}`,
                    borderRadius: '8px',
                    mb: theme.spacing(1),
                  })}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={(theme) => ({
                      ml: theme.spacing(1),
                    })}
                  >
                    <Box
                      sx={(theme) => ({
                        color: theme.palette.stationGray[900],
                        typography: 'bodyMMedium',
                      })}
                    >
                      {`${employee.firstName} ${employee.lastName}`}
                    </Box>
                    {employee.hoursOwed !== 0 && (
                      <Box
                        sx={(theme) => ({
                          color: employee.owesToResponder ? theme.palette.stationGreen[500] : theme.palette.stationYellow[500],
                          typography: 'bodyMMedium',
                        })}
                      >
                        {employee.owesToResponder
                          ? `Owes me ${minutesToHours(employee.hoursOwed)} h`
                          : `I owe ${minutesToHours(employee.hoursOwed)} h`}
                      </Box>
                    )}
                  </Box>
                  <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', gap: 1, ml: theme.spacing(1) })}>
                    <TeamFlag color={employee.team?.color} />
                    <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodySMedium' })}>
                      {capitalizeFirstLetter(employee.team?.name ?? '')}
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
          <Box
            sx={(theme) => ({
              position: 'sticky',
              bottom: 0,
              width: '100%',
              padding: theme.spacing(2),
              paddingBottom: isMobile ? iosWebViewPaddingBottom : theme.spacing(2),
              borderRadius: 2,
              border: '1px solid #ddd',
              boxShadow: '0px -4px 6px -2px rgba(10, 14, 22, 0.05), 0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
              backgroundColor: theme.palette.common.white,
            })}
          >
            <Box>
              <Button
                data-cy="request-shift-trade-button"
                variant="contained"
                size="large"
                sx={{ width: '100%' }}
                onClick={handleSendRequest}
              >
                {buttonLabel}
              </Button>
              <Box
                sx={(theme) => ({
                  color: theme.palette.stationGray[600],
                  mt: theme.spacing(2),
                  typography: 'bodyXSRegular',
                  maxWidth: '500px',
                })}
              >
                Submitting this request confirms my electronic signature and acknowledgement of department staffing rules and
                policies.
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {!isAvailableEmployeesLoading && availableEmployees.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <NoEligibleShiftTrade />
          <Typography
            sx={(theme) => ({ mt: theme.spacing(4), mb: theme.spacing(2) })}
            textAlign={'center'}
            variant="bodyXXLSemibold"
          >
            No users eligible based on <br /> rank and certification <br /> requirements
          </Typography>
          <Typography sx={(theme) => ({ color: theme.palette.stationGray[500] })} variant="bodyMRegular">
            Please choose another date to trade.
          </Typography>
          <Box sx={{ height: '100px' }} />
        </Box>
      )}
      {isAvailableEmployeesLoading && availableEmployees.length === 0 && <Loader sx={{ my: 5 }} />}
    </Box>
  );
};
