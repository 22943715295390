export enum NotificationCategory {
  MESSAGES = 'messages',
  REQUESTS = 'requests',
  SHIFT_ASSIGNMENTS = 'shift_assignments',
  ADMIN_ACTIONS = 'admin_actions',
  OVERTIME = 'overtime',
  SHIFT_TRADE = 'shift_trade',
}

export const CATEGORY_CHOICES: [NotificationCategory, string][] = [
  [NotificationCategory.MESSAGES, 'Messages'],
  [NotificationCategory.REQUESTS, 'Requests'],
  [NotificationCategory.SHIFT_ASSIGNMENTS, 'Shift Assignments'],
  [NotificationCategory.ADMIN_ACTIONS, 'Admin Actions'],
  [NotificationCategory.OVERTIME, 'Overtime'],
  [NotificationCategory.SHIFT_TRADE, 'Shift Trade'],
];

export interface NotificationSetting {
  notificationType: NotificationCategory;
  email: boolean;
  sms: boolean;
}

export const getDepartmentNotificationSetting = (
  settings: NotificationSetting[] | undefined,
  category: NotificationCategory | undefined,
  mode: 'email' | 'sms',
): boolean => {
  if (!settings || !settings) return false;
  const setting = settings.find((s) => s.notificationType === category);
  return setting ? setting[mode] : false;
};

export const warningMessage = (smsChangeRestricted: boolean, emailChangeRestricted: boolean) => {
  if (smsChangeRestricted && emailChangeRestricted) {
    return "Your department doesn't allow you to disable your notification preferences for this category. Please contact your administrator.";
  } else if (smsChangeRestricted) {
    return 'You cannot change your SMS notification preferences for this category. Please contact your administrator.';
  } else if (emailChangeRestricted) {
    return 'You cannot change your email notification preferences for this category. Please contact your administrator.';
  }
  return '';
};
