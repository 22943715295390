import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { CircleWarningIcon20 } from '../../../../assets';
import { Button } from '../../../Button';
import { CardName, CardNameProps } from './CardName';

interface LastChangesErrorProps {
  current: CardNameProps;
  onClickOverride?: () => void;
  onClickUndo?: () => void;
  errorMessages: ReactNode[];
}

export const LastChangesError = ({ current, onClickOverride, onClickUndo, errorMessages }: LastChangesErrorProps) => {
  return (
    <Box sx={{ width: 264, p: 1 }}>
      <Box
        sx={(theme) => ({
          background: '#472038',
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
          p: '12px',
          pb: '19px',
        })}
      >
        <CardName {...current} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={(theme) => ({
          background: '#472038',
          borderBottomLeftRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
          p: '12px',
          mt: '2px',
        })}
      >
        {errorMessages.map((errorMessage, i) => (
          <Box
            key={i}
            sx={(theme) => ({
              display: 'flex',
              gap: 1,
              color: theme.palette.stationRed[500],
              typography: 'bodySRegular',
            })}
          >
            <Box sx={{ display: 'inline-flex', mt: '3px' }}>
              <Box
                component={CircleWarningIcon20}
                sx={(theme) => ({ height: '14px', width: '14px', color: theme.palette.stationRed[500] })}
              />
            </Box>
            {errorMessage}
          </Box>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1.5 }}>
          {!onClickOverride && <Box sx={{ flex: 1 }} />}
          {onClickOverride && (
            <Box sx={(theme) => ({ flex: 1, background: theme.palette.common.white, borderRadius: 1, display: 'inline-flex' })}>
              <Button variant="outlined" size="small" onClick={onClickOverride} sx={{ width: '100%' }}>
                Override
              </Button>
            </Box>
          )}
          <Box sx={(theme) => ({ flex: 1, background: theme.palette.common.white, borderRadius: 1, display: 'inline-flex' })}>
            <Button variant="outlined" size="small" onClick={onClickUndo} sx={{ width: '100%' }}>
              Undo
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
