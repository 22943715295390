import { Box, ButtonBase, useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { getCertColors, MinusIcon20, PlusIcon20 } from '@stationwise/component-module';
import { CertificationRequirement, DepartmentInfo } from '@stationwise/share-types';

interface CertificationRequirementCounterProps {
  certificationRequirement: CertificationRequirement;
  setCertificationRequirements: Dispatch<SetStateAction<CertificationRequirement[]>>;
}

export const makeCertificationRequirements = (departmentInfo: DepartmentInfo, certReqs?: CertificationRequirement[]) => {
  const newCertReqs = new Map<string, CertificationRequirement>();
  departmentInfo.certifications.forEach((c) => {
    newCertReqs.set(c.code, { id: c.id, name: c.name, code: c.code, color: c.color, amount: 0 });
  });
  certReqs?.forEach((certReq) => newCertReqs.set(certReq.code, certReq));
  return Array.from(newCertReqs.values());
};

export const CertificationRequirementCounter = (props: CertificationRequirementCounterProps) => {
  const theme = useTheme();
  const { color } = getCertColors({ theme, color: props.certificationRequirement.color });

  const updateAmount = (newAmount: number) => {
    props.setCertificationRequirements((prevCertReqs) => {
      return prevCertReqs.map((prevCertReq) => {
        const isThisCertReq = prevCertReq.id === props.certificationRequirement.id;
        return isThisCertReq ? { ...prevCertReq, amount: Math.max(0, newAmount) } : prevCertReq;
      });
    });
  };

  return (
    <Box sx={{ borderBottom: `1px solid ${theme.palette.stationGray[100]}`, display: 'flex', alignItems: 'flex-start', p: 1 }}>
      <Box sx={{ color, width: '36px' }}>{props.certificationRequirement.code}</Box>
      <Box sx={{ color: theme.palette.stationGray[500], flex: 1, px: 2.5 }}>{props.certificationRequirement.name}</Box>
      <Box
        sx={{
          color: props.certificationRequirement.amount > 0 ? theme.palette.stationGray[900] : theme.palette.stationGray[400],
          display: 'flex',
          alignItems: 'center',
          '& button': { background: theme.palette.stationGray[100], borderRadius: '50%', height: '20px', width: '20px' },
          '& svg path': { stroke: theme.palette.stationGray[400] },
        }}
      >
        <ButtonBase onClick={() => updateAmount(props.certificationRequirement.amount - 1)}>
          <MinusIcon20 />
        </ButtonBase>
        <Box sx={{ width: '36px', textAlign: 'center' }}>{props.certificationRequirement.amount}</Box>
        <ButtonBase onClick={() => updateAmount(props.certificationRequirement.amount + 1)}>
          <PlusIcon20 />
        </ButtonBase>
      </Box>
    </Box>
  );
};
