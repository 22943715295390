import { Box, Typography, useTheme } from '@mui/material';
import { StationIcon20, User01Icon20, Chip, SHIFT_TITLES, TeamFlag } from '@stationwise/component-module';
import { AllowedColors } from '@stationwise/share-types';

export const ShiftHeader = ({
  employeeName,
  stationName,
  apparatusName,
  teamColor,
  shiftName,
  shiftTitle,
}: {
  employeeName: string;
  stationName: string;
  apparatusName: string;
  teamColor?: AllowedColors;
  shiftName: string;
  shiftTitle: string;
}) => {
  const theme = useTheme();

  const getShiftTitleColor = (shiftTitle: string) => {
    switch (shiftTitle) {
      case SHIFT_TITLES.OVERTIME:
        return theme.palette.stationPink[500];
      default:
        return theme.palette.stationBlue[500];
    }
  };

  return (
    <>
      <Box
        data-cy="shiftheader"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mb: theme.spacing(1),
        }}
      >
        <Box>
          <Typography
            variant="bodyLSemibold"
            sx={{
              color: theme.palette.stationGray[900],
            }}
          >
            Next shift
          </Typography>
        </Box>
        {shiftName && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TeamFlag color={teamColor} />
            <Typography data-cy="shift-name" variant="bodySMedium" sx={{ color: theme.palette.stationGray[500] }}>
              {shiftName}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], display: 'flex' })}>
            <User01Icon20 />
          </Box>

          <Box data-cy="employee-name" sx={(theme) => ({ ml: theme.spacing(1) })}>
            <Typography variant="bodyMMedium">{employeeName}</Typography>
          </Box>
        </Box>
        <Box>
          <Chip
            label={shiftTitle}
            chipSxProps={() => ({
              background: getShiftTitleColor(shiftTitle),
              height: '24px',
              minWidth: '130px',
              p: '4px 6px',
              borderRadius: '7px',
            })}
            variant="eyebrow"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        sx={(theme) => ({
          mt: theme.spacing(1),
          borderBottom: `solid 1px ${theme.palette.stationGray[100]}`,
        })}
        justifyContent="space-between"
        alignItems="center"
      >
        {stationName && apparatusName && (
          <Box data-cy="station-name" display="flex" alignItems="center" sx={{ mb: theme.spacing(2) }}>
            <StationIcon20 />
            <Box sx={(theme) => ({ ml: theme.spacing(1) })}>
              <Typography variant="bodyMMedium">{`${stationName}, ${apparatusName}`}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
