import { TextField, Checkbox, FormControlLabel, Box, Autocomplete } from '@mui/material';
import React from 'react';
import { FieldDefinition, FieldType } from '@stationwise/share-types';

type FormFieldProps<T extends FieldType = FieldType> = {
  field: FieldDefinition<T>;
  onChange: (value: T extends 'BOOLEAN' ? boolean : string) => void;
  defaultValue?: T extends 'BOOLEAN' ? boolean : string;
  disabled?: boolean;
};

export const CustomIncidentFields: React.FC<FormFieldProps> = ({ defaultValue, field, onChange, disabled }) => {
  const { label, fieldType, required, options } = field;

  interface HeaderProps {
    label: string;
    required: boolean;
  }

  const Header = ({ label, required }: HeaderProps) => {
    return (
      <Box
        sx={(theme) => ({
          mt: theme.spacing(2),
          typography: 'bodyLMedium',
          display: 'flex',
        })}
      >
        {label}
        {required && <Box sx={(theme) => ({ ml: theme.spacing(1), typography: 'bodyLMedium' })}> (required)</Box>}
      </Box>
    );
  };

  const renderField = () => {
    switch (fieldType) {
      case 'TEXT':
        return (
          <>
            <Header label={label} required={required} />
            <Box
              sx={(theme) => ({
                display: 'flex',
                width: '100%',
                pt: theme.spacing(1),
              })}
            >
              <TextField
                defaultValue={defaultValue}
                required={required}
                fullWidth
                onChange={(e) => onChange(e.target.value)}
                disabled={!!disabled}
              />
            </Box>
          </>
        );
      case 'TEXTAREA':
        return (
          <>
            <Header label={label} required={required} />
            <Box
              sx={(theme) => ({
                display: 'flex',
                width: '100%',
                pt: theme.spacing(1),
              })}
            >
              <TextField
                defaultValue={defaultValue}
                required={required}
                multiline
                rows={4}
                fullWidth
                onChange={(e) => onChange(e.target.value)}
                disabled={!!disabled}
              />
            </Box>
          </>
        );
      case 'SELECT':
        return (
          <>
            <Header label={label} required={required} />
            <Box
              sx={(theme) => ({
                display: 'flex',
                width: '100%',
                pt: theme.spacing(1),
              })}
            >
              <Autocomplete
                sx={{ width: '100%' }}
                options={options ?? []}
                getOptionLabel={(option) => String(option)}
                onChange={(_e, value) => onChange(value as string)}
                value={defaultValue || undefined}
                renderInput={(params) => <TextField {...params} placeholder={'Choose ' + label} />}
                disabled={!!disabled}
              />
            </Box>
          </>
        );
      case 'TIME':
        return (
          <>
            <Header label={label} required={required} />
            <Box
              sx={(theme) => ({
                display: 'flex',
                width: '100%',
                pt: theme.spacing(1),
              })}
            >
              <TextField
                type="time"
                defaultValue={defaultValue}
                required={required}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => onChange(e.target.value)}
                disabled={!!disabled}
              />
            </Box>
          </>
        );
      case 'BOOLEAN':
        return (
          <>
            <Header label={label} required={required} />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={defaultValue !== undefined && typeof defaultValue === 'boolean' ? defaultValue : undefined}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={!!disabled}
                  />
                }
                label={label}
              />
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return <Box sx={(theme) => ({ marginBottom: theme.spacing(2) })}>{renderField()}</Box>;
};
