import { Box, Drawer, SxProps, useMediaQuery, Theme } from '@mui/material';
import { Modal } from '@stationwise/component-module';
import { DrawerFooter, FooterButton } from './DrawerFooter';
import { DrawerHeader } from './DrawerHeader';

interface GenericDrawerOrModalProps {
  anchor: 'bottom' | 'top' | 'left' | 'right';
  drawerOpen: boolean;
  handleOnClose: () => void;
  loading: boolean;
  footerButtons?: FooterButton[];
  headerTitle?: string;
  children: JSX.Element;
  showHeader?: boolean;
  disableFooter?: boolean;
  sxFooterProps?: SxProps;
  noBorderOnHeader?: boolean;
  paddingBottom?: string;
  sxProps?: SxProps;
  headerIcon?: React.ReactNode;
}
export const GenericDrawerOrModal = ({
  anchor,
  drawerOpen,
  handleOnClose,
  loading,
  headerTitle,
  footerButtons,
  children,
  showHeader,
  disableFooter,
  sxFooterProps,
  noBorderOnHeader,
  paddingBottom,
  sxProps,
  headerIcon,
}: GenericDrawerOrModalProps) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (!isSmallScreen) {
    return (
      <Modal hideCloseIcon open={drawerOpen} setOpen={handleOnClose}>
        <Box
          sx={{
            flex: '1',
            position: 'relative',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            maxWidth: '80vw',
          }}
        >
          {showHeader && (
            <DrawerHeader
              headerTitle={headerTitle || ''}
              handleOnClose={handleOnClose}
              noBorder={noBorderOnHeader}
              headerIcon={headerIcon}
            />
          )}
          {children}
          {!disableFooter && <DrawerFooter loading={loading} sxProps={sxFooterProps} buttons={footerButtons} />}
        </Box>
      </Modal>
    );
  }

  return (
    <Drawer anchor={anchor} open={drawerOpen} onClose={handleOnClose} sx={sxProps}>
      {showHeader && (
        <DrawerHeader
          headerTitle={headerTitle || ''}
          handleOnClose={handleOnClose}
          noBorder={noBorderOnHeader}
          headerIcon={headerIcon}
        />
      )}
      {children}
      {!disableFooter && <DrawerFooter loading={loading} sxProps={sxFooterProps} buttons={footerButtons} />}
      {paddingBottom !== '0px' && (
        <Box
          sx={{
            paddingBottom: paddingBottom,
          }}
        />
      )}
    </Drawer>
  );
};
