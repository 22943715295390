import { RosterPosition } from '@stationwise/share-types';
import { checkIsEvent, formatShiftDuration } from '@stationwise/shift-summary-helper';
import { EmptyCard, EmptyCardProps } from '../EmptyCard';

interface EmptyPositionProps extends Partial<EmptyCardProps> {
  position: RosterPosition;
  isCollapsed?: boolean;
  isOver?: boolean;
  onClick?: () => void;
  isNotSelected?: boolean;
  isSelectable?: boolean;
  actions?: React.ReactNode;
}

export const EmptyPosition = ({
  position,
  isCollapsed,
  isOver,
  onClick,
  isSelectable,
  isNotSelected,
  cardSxProps,
  actions,
  ...props
}: EmptyPositionProps) => {
  const isEventPosition = checkIsEvent(position);
  return (
    <EmptyCard
      withButton={isSelectable && isEventPosition}
      testId={`empty-card-${position.id}`}
      withPlusSign={isSelectable && !isEventPosition}
      onClick={isSelectable && onClick ? onClick : undefined}
      rank={position.rank}
      durationLabel={formatShiftDuration({ startTime: position.startDateTime, endTime: position.endDateTime })}
      certs={isCollapsed ? [] : position.certifications}
      {...props}
      actions={actions}
      cardSxProps={[
        (theme) => ({
          height: isCollapsed ? (isSelectable && isEventPosition ? '88px' : '48px') : '114px',
          ...(isNotSelected && { opacity: 0.5 }),
          transition: 'opacity 0.5s ease',
          [theme.breakpoints.up('sm')]: {
            height: isCollapsed ? (isSelectable && isEventPosition ? '80px' : '40px') : '104px',
          },
          overflow: 'hidden',
        }),
        (theme) => {
          if (!isOver) {
            return {};
          }

          return { background: theme.palette.stationGray[300] };
        },
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    />
  );
};
