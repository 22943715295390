import { Box } from '@mui/material';
import { Button } from '../../Button';

interface ConversationButtonsProps {
  handleSubmitClick: () => void;
  loading: boolean;
  handleOnCloseDrawer: () => void;
  disableSubmit: boolean;
  isFollowUp: boolean;
  rosterMessage?: boolean;
}
export const ConversationButtons = ({
  handleSubmitClick,
  handleOnCloseDrawer,
  loading,
  disableSubmit,
  isFollowUp,
  rosterMessage,
}: ConversationButtonsProps) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          mr: 1,
          position: 'absolute',
          bottom: '14px',
          right: rosterMessage ? '154px' : isFollowUp ? '8px' : '82px',
          zIndex: theme.zIndex.mobileStepper,
          borderRadius: '6px',
        })}
      >
        <Button
          data-cy="send-button"
          size="small"
          variant="contained"
          disabled={disableSubmit}
          loading={loading}
          sx={{ minHeight: '28px' }}
          onClick={handleSubmitClick}
        >
          Send
        </Button>
      </Box>
      {!isFollowUp && (
        <Box
          sx={(theme) => ({
            mr: 1,
            position: 'absolute',
            bottom: '14px',
            right: rosterMessage ? '80px' : '8px',
            zIndex: theme.zIndex.mobileStepper,
            borderRadius: '6px',
          })}
        >
          <Button
            data-cy="cancel-button"
            size="small"
            variant="outlined"
            sx={{ minHeight: '28px' }}
            onClick={handleOnCloseDrawer}
          >
            Cancel
          </Button>
        </Box>
      )}
    </>
  );
};
