import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { ErrorPage, LottieLogoPageLoader, SnackbarService, useDocumentTitle } from '@stationwise/component-module';
import { axios, client } from '@stationwise/share-api';
import { AppPageLayout } from '../../../AppPageLayout';
import { ChangePassword } from './ChangePassword';
import { Details } from './Details/Details';
import { NotificationDrawer } from './NotificationDrawer';
import { Notifications } from './Notifications';
import { Security } from './Security';
import { SyncCalendars } from './SyncCalendars';
import { NotificationSetting, getDepartmentNotificationSetting, NotificationCategory } from './utils';

export const PreferencesContent = () => {
  useDocumentTitle('Preferences');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNotificationTypeLoading, setIsNotificationTypeLoading] = useState(false);
  const [clickedNotificationType, setClickedNotificationType] = useState<NotificationCategory>();
  const [checkedSms, setCheckedSms] = useState(false);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [departmentNotificationSettings, setDepartmentNotificationSettings] = useState<NotificationSetting[]>([]);
  const [userNotificationSettings, setUserNotificationSettings] = useState<NotificationSetting[]>([]);
  const [selectedDepartmentSmsSetting, setSelectedDepartmentSmsSetting] = useState(false);
  const [selectedDepartmentEmailSetting, setSelectedDepartmentEmailSetting] = useState(false);
  const [showChangePasswordPage, setShowChangePasswordPage] = useState(false);

  const fetchDepartmentNotificationSettings = async () => {
    setIsLoading(true);
    try {
      const response = await client.get('/notification/department-notification-settings/', {
        params: {},
      });
      setDepartmentNotificationSettings(response.data);
      return response.data;
    } catch (err) {
      const message = axios.isAxiosError(err) ? err.response?.data?.message || err.message : 'An unexpected error occurred.';
      setIsError(message);
      captureException(err);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserNotificationSettings = async () => {
    setIsNotificationTypeLoading(true);
    setIsDrawerOpen(false);
    try {
      const response = await client.get('/notification/notification-preference/');
      setUserNotificationSettings(response.data);
      setIsNotificationTypeLoading(false);
      return response.data;
    } catch (err) {
      const message = axios.isAxiosError(err) ? err.response?.data?.message || err.message : 'An unexpected error occurred.';
      SnackbarService.notify({
        content: message,
        severity: 'error',
        duration: 5000,
      });
      setIsNotificationTypeLoading(false);
      setIsDrawerOpen(false);
      captureException(err);
      return [];
    }
  };

  const handleClickCategory = (category: NotificationCategory) => {
    setClickedNotificationType(category);
    setIsDrawerOpen(true);
  };

  useEffect(() => {
    fetchDepartmentNotificationSettings();
    fetchUserNotificationSettings();
  }, []);

  useEffect(() => {
    if (!clickedNotificationType) return;
    setCheckedEmail(getDepartmentNotificationSetting(userNotificationSettings, clickedNotificationType, 'email'));
    setCheckedSms(getDepartmentNotificationSetting(userNotificationSettings, clickedNotificationType, 'sms'));
    setSelectedDepartmentEmailSetting(
      getDepartmentNotificationSetting(departmentNotificationSettings, clickedNotificationType, 'email'),
    );
    setSelectedDepartmentSmsSetting(
      getDepartmentNotificationSetting(departmentNotificationSettings, clickedNotificationType, 'sms'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedNotificationType]);

  return (
    <AppPageLayout>
      {isLoading ? (
        <LottieLogoPageLoader />
      ) : isError ? (
        <ErrorPage />
      ) : (
        <Box
          sx={(theme) => ({
            background: showChangePasswordPage ? theme.palette.common.white : undefined,
            height: '100%',
            width: '100%',
            maxWidth: '768px',
            margin: '0 auto',
          })}
        >
          {!showChangePasswordPage && (
            <>
              <Box
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                  typography: 'bodyXXLSemibold',
                  pt: theme.spacing(3),
                  px: theme.spacing(2),
                  width: '100%',
                })}
              >
                Preferences
              </Box>
              <Box
                sx={(theme) => ({
                  display: 'inline-flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: theme.spacing(3),
                  p: theme.spacing(2.5),
                  width: 'inherit',
                })}
              >
                <Details />
                <Security setShowChangePasswordPage={setShowChangePasswordPage} />
                <Notifications setClicked={handleClickCategory} />
                <SyncCalendars />
              </Box>
              <NotificationDrawer
                checkedEmail={checkedEmail}
                checkedSms={checkedSms}
                clickedNotificationType={clickedNotificationType}
                fetchUserNotificationSettings={fetchUserNotificationSettings}
                isDrawerOpen={isDrawerOpen}
                isNotificationTypeLoading={isNotificationTypeLoading}
                selectedDepartmentEmailSetting={selectedDepartmentEmailSetting}
                selectedDepartmentSmsSetting={selectedDepartmentSmsSetting}
                setCheckedSms={setCheckedSms}
                setCheckedEmail={setCheckedEmail}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            </>
          )}
          {showChangePasswordPage && <ChangePassword setShowChangePasswordPage={setShowChangePasswordPage} />}
        </Box>
      )}
    </AppPageLayout>
  );
};
