import { format, setHours, startOfDay } from 'date-fns';
import { useState } from 'react';
import { getVisibleTags, useLoadedDepartmentInfoContext, useRosterContext } from '@stationwise/component-module';
import { ON_ROSTER_TEMPORARY_NON_SHIFT_TYPES, TemporaryNonShiftType } from '@stationwise/share-types';
import {
  filterTemporaryNonShiftPayCodes,
  createTemporaryNonShiftAssignment,
  removeEmployeeAvailability,
  offsetMinutes,
} from '@stationwise/shift-summary-helper';
import { Step, Stepper } from '../../../../../components/Common';
import { MoveToTemporaryNonShiftStepOne, STEP_NUMBER as STEP_NUMBER_ZERO } from './MoveToTemporaryNonShiftStepOne';
import { MoveToTemporaryNonShiftStepTwo, STEP_NUMBER as STEP_NUMBER_ONE } from './MoveToTemporaryNonShiftStepTwo';

interface MoveToTemporaryNonShiftStepperProps {
  temporaryNonShiftType: TemporaryNonShiftType;
}

export const MoveToTemporaryNonShiftStepper = ({ temporaryNonShiftType }: MoveToTemporaryNonShiftStepperProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const visibilityTag = temporaryNonShiftType === 'EXTENDED_LEAVE' ? 'EXT_LEAVE' : 'LIGHT_DUTY';
  const visibleTags = getVisibleTags(departmentInfoState.departmentInfo, visibilityTag, 'paycode');
  const payCodes = filterTemporaryNonShiftPayCodes(
    departmentInfoState.departmentInfo.payCodes,
    temporaryNonShiftType,
    visibleTags,
  );

  const {
    selectedFilledPositionState,
    shiftSummaryHelper,
    setShiftSummaryHelper,
    setUserHasMadeChanges,
    temporaryNonShiftAssignmentsState,
  } = useRosterContext();
  const { shiftDuration } = shiftSummaryHelper;

  const [nextDisabled, setNextDisabled] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  const activationDateTime = shiftDuration.startTime;
  const [deactivationDate, setDeactivationDate] = useState<Date | null>(() => startOfDay(activationDateTime));
  const [payCodeCode, setPayCodeCode] = useState('');
  const [activeWeekdays, setActiveWeekdays] = useState<number[] | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(activationDateTime);
  const [endTime, setEndTime] = useState<Date | null>(() => {
    return setHours(activationDateTime, activationDateTime.getHours() + Math.min(8, shiftDuration.hours));
  });

  const [currentStepNumber, setCurrentStepNumber] = useState(STEP_NUMBER_ZERO);

  const onSubmit = () => {
    const payCode = payCodes.find((pc) => pc.code === payCodeCode);
    if (!selectedFilledPositionState.employee || !deactivationDate || !payCode || !startTime || !endTime) {
      throw new Error('Cannot move employee to temporary non-shift assignment');
    }

    const startMinutes = offsetMinutes(startTime, shiftDuration.startTime);
    const endMinutes = offsetMinutes(endTime, shiftDuration.startTime);
    const assignment = {
      temporaryNonShiftType,
      candidateId: selectedFilledPositionState.employee.id,
      toDate: format(deactivationDate, 'yyyy-MM-dd'),
      activeWeekdays: activeWeekdays || undefined,
      startTime: startMinutes,
      endTime: endMinutes,
      payCodeId: payCode.id,
      activationDate: activationDateTime,
      deactivationDate: deactivationDate,
    };

    let newShiftSummaryHelper = removeEmployeeAvailability({
      shiftSummaryHelper,
      employeeId: assignment.candidateId,
      startTime: shiftDuration.startTime,
      endTime: shiftDuration.endTime,
    });
    if (ON_ROSTER_TEMPORARY_NON_SHIFT_TYPES.has(temporaryNonShiftType)) {
      newShiftSummaryHelper = createTemporaryNonShiftAssignment({
        shiftSummaryHelper: newShiftSummaryHelper,
        employee: selectedFilledPositionState.employee,
        assignment,
      });
    }

    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    temporaryNonShiftAssignmentsState.addAssignment(assignment);
    selectedFilledPositionState.setSelectedMoveToTemporaryNonShiftType(null);
    selectedFilledPositionState.closeDialog();
  };

  let title = '';
  if (temporaryNonShiftType === TemporaryNonShiftType.LIGHT_DUTY) {
    title = 'Set light duty work schedule';
  } else if (temporaryNonShiftType === TemporaryNonShiftType.EXTENDED_LEAVE) {
    title = 'Set extended leave schedule';
  }

  const commonStepProps = { currentStepNumber, setNextDisabled, setCompletedSteps };
  const steps: Map<TemporaryNonShiftType, Step[]> = new Map([
    [
      TemporaryNonShiftType.LIGHT_DUTY,
      [
        {
          stepNumber: STEP_NUMBER_ZERO,
          component: (
            <MoveToTemporaryNonShiftStepOne
              {...commonStepProps}
              activationDateTime={activationDateTime}
              deactivationDate={deactivationDate}
              setDeactivationDate={setDeactivationDate}
              payCodes={payCodes}
              selectedPayCodeCode={payCodeCode}
              setSelectedPayCodeCode={setPayCodeCode}
              skipStartTime={false}
            />
          ),
        },
        {
          stepNumber: STEP_NUMBER_ONE,
          component: (
            <MoveToTemporaryNonShiftStepTwo
              {...commonStepProps}
              activeWeekdays={activeWeekdays}
              setActiveWeekdays={setActiveWeekdays}
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
            />
          ),
        },
      ],
    ],
    [
      TemporaryNonShiftType.EXTENDED_LEAVE,
      [
        {
          stepNumber: STEP_NUMBER_ZERO,
          component: (
            <MoveToTemporaryNonShiftStepOne
              {...commonStepProps}
              activationDateTime={activationDateTime}
              deactivationDate={deactivationDate}
              setDeactivationDate={setDeactivationDate}
              payCodes={payCodes}
              selectedPayCodeCode={payCodeCode}
              setSelectedPayCodeCode={setPayCodeCode}
              skipStartTime={true}
            />
          ),
        },
      ],
    ],
  ]);

  const currentSteps = steps.get(temporaryNonShiftType) || [];

  return (
    <Stepper
      title={title}
      steps={currentSteps}
      onClose={() => selectedFilledPositionState.setSelectedMoveToTemporaryNonShiftType(null)}
      onFinalSubmit={onSubmit}
      nextDisabled={nextDisabled}
      setNextDisabled={setNextDisabled}
      completedSteps={completedSteps}
      currentStep={currentSteps[currentStepNumber]}
      setCurrentStep={(step) => setCurrentStepNumber(step.stepNumber)}
    />
  );
};
