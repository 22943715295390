import { Box } from '@mui/material';
import { CALENDAR_EVENT_TYPES } from '@stationwise/component-module';
import { RequestOverview } from '@stationwise/share-types';
import { Header } from './Header';
import { Overview } from './Overview';

export interface OverviewCardProps {
  overview: RequestOverview;
  eventColor?: string;
}

export const OverviewCard = ({ overview, eventColor }: OverviewCardProps) => {
  return (
    <Box
      sx={(theme) => ({
        pt: theme.spacing(2),
        borderRadius: '12px',
        border: `1px solid ${theme.palette.stationGray[200]}`,
      })}
    >
      <Box sx={(theme) => ({ pb: !overview.detailCode ? theme.spacing(1) : 'unset' })}>
        <Header overview={overview} eventColor={eventColor} />
        {overview.eventType === CALENDAR_EVENT_TYPES.ADDITIONAL_PAID_TIME_REQUEST && overview.payCode && (
          <Overview overview={overview} />
        )}
      </Box>
    </Box>
  );
};
