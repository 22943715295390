import { ReactComponent as ArrowForwardSymbol } from '@material-symbols/svg-400/outlined/arrow_forward.svg';
import { Box, InputAdornment } from '@mui/material';
import { captureException } from '@sentry/react';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircleWarningIcon20, CreateNewPasswordForm, Button, Input, SvgIcon } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { ChangePasswordTopBar } from './ChangePasswordTopBar';

interface ChangePasswordProps {
  setShowChangePasswordPage: (value: boolean) => void;
}

export const ChangePassword = ({ setShowChangePasswordPage }: ChangePasswordProps) => {
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [uid, setUid] = useState('');
  const [resetPasswordToken, setResetPasswordToken] = useState('');
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);

  const navigate = useNavigate();

  const setAnError = (message: string) => {
    setErrorMessage(message);
    setPassword('');
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await client.post('/auth/change-password/', {
        password,
      });
      setUid(response.data.uid);
      setResetPasswordToken(response.data.token);
      setShowNewPasswordForm(true);
      setPassword('');
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      if (!message) {
        captureException(err);
      }
      setAnError(message || 'Something went wrong please try again later');
    }
    setIsSubmitting(false);
  };

  const handleForgotPassword = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    try {
      navigate('/login/forgot-password');
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      if (!message) {
        captureException(err);
      }
      setAnError(message || 'Something went wrong please try again later');
    }
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handleSubmit();
  };

  return (
    <Box sx={(theme) => ({ background: theme.palette.common.white, minHeight: '100%', position: 'relative', px: 2, pb: 9 })}>
      <ChangePasswordTopBar setShowChangePasswordPage={setShowChangePasswordPage} />
      {!showNewPasswordForm && (
        <Box>
          <Box component="form" onSubmit={handleFormSubmit}>
            <Box>
              <Box>
                <Input
                  data-cy="password"
                  disabled={isSubmitting}
                  error={!!errorMessage}
                  label="Type your current password*"
                  type={'password'}
                  value={password}
                  placeholder="Current Password"
                  onChange={(event) => {
                    setPassword(event.currentTarget.value);
                    setErrorMessage('');
                  }}
                  sx={(theme) => ({
                    color: errorMessage ? theme.palette.stationRose[900] : theme.palette.stationGray[900],
                    typography: 'bodyMRegular',
                  })}
                  endAdornment={<InputAdornment position="end">{errorMessage && <CircleWarningIcon20 />}</InputAdornment>}
                />

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    onClick={handleForgotPassword}
                    component="span"
                    sx={(theme) => ({
                      color: theme.palette.stationRose[600],
                      mt: theme.spacing(2),
                      typography: 'bodySRegular',
                      textAlign: 'left',
                    })}
                  >
                    {errorMessage}
                  </Box>

                  <Box
                    onClick={handleForgotPassword}
                    component="span"
                    sx={(theme) => ({
                      color: theme.palette.stationPurple[500],
                      mt: theme.spacing(2),
                      typography: 'bodySMedium',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    })}
                  >
                    Forgot Password?
                  </Box>
                </Box>
              </Box>

              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  padding: theme.spacing(2),
                })}
              >
                <Button
                  data-cy="submit-login-form"
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={!password}
                  loading={isSubmitting}
                  sx={{ gap: 1 }}
                >
                  Choose new password
                  <SvgIcon component={ArrowForwardSymbol} />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {showNewPasswordForm && (
        <CreateNewPasswordForm uid={uid} token={resetPasswordToken} setShowNewPasswordForm={setShowNewPasswordForm} />
      )}
    </Box>
  );
};
