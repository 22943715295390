import Box from '@mui/material/Box';
import { format } from 'date-fns';
import { useState } from 'react';
import { useAuthUserCapabilities } from '@stationwise/component-module';
import { EditAccountControl } from './Edit/EditAccountControl';
import { InfoCard, InfoCardField } from './InfoCard';

type AccountControlSectionProps = {
  isActive: boolean;
  canDeactivate: boolean;
  lastPasswordChange: string;
  employeeId: string;
  handleRefresh: () => void;
};

export const AccountControlSection = ({
  isActive,
  canDeactivate,
  lastPasswordChange,
  employeeId,
  handleRefresh,
}: AccountControlSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const accountStatus = isActive ? 'Active' : 'Inactive';
  const accountStatusColor = isActive ? 'green' : 'gray';
  const capabilities = useAuthUserCapabilities();

  const fieldDefs: InfoCardField[][] = [
    [
      {
        label: 'Last Password Change',
        field: {
          value: lastPasswordChange,
          type: 'date',
        },
      },
      {
        label: 'Account Status',
        field: {
          value: accountStatus,
          type: 'badge',
          extra: {
            badgeColor: accountStatusColor,
          },
        },
      },
    ],
  ];

  const handleEditModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <EditAccountControl
        showModal={showModal}
        setShowModal={setShowModal}
        isActive={isActive}
        canDeactivate={canDeactivate}
        lastPasswordChange={lastPasswordChange ? format(new Date(lastPasswordChange), 'MMMM d, yyyy') : 'Date not available'}
        employeeId={employeeId}
        handleRefresh={handleRefresh}
      />
      <Box
        width="100%"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: 2,
          border: `1px solid ${theme.palette.stationGray[200]}`,
          p: theme.spacing(4, 3, 3, 3),
          gap: theme.spacing(3),
        })}
      >
        <InfoCard
          fieldDefs={fieldDefs}
          title="Account Control"
          handleEditModal={capabilities.EMPLOYEE_ACCOUNT_CONTROL ? handleEditModal : undefined}
        />
      </Box>
    </>
  );
};
