import { Box } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '../../../Button';

export interface ShiftTradeRefundOptionsProps {
  selectedButton: string;
  handleButtonClick: (arg0: RefundOptType) => void;
}

export type RefundOptType = 'REFUND_HOURS' | 'KEEP_BALANCE';

export const ShiftTradeRefundOptions = ({ selectedButton, handleButtonClick }: ShiftTradeRefundOptionsProps) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          typography: 'bodySMedium',
          textAlign: 'left',
          color: theme.palette.stationGray[700],
          mt: theme.spacing(2),
        })}
      >
        Trade hours balance
      </Box>
      <ToggleButtonGroup
        exclusive
        color="primary"
        size="large"
        value={selectedButton}
        onChange={(_event, newValue) => newValue !== null && handleButtonClick(newValue)}
        aria-label="Trade hours balance"
        sx={(theme) => ({
          py: 1,
          width: '100%',
          '& .MuiToggleButton-root': { flex: 1, p: theme.spacing(1, 2) },
        })}
      >
        <ToggleButton value="REFUND_HOURS">Undo traded hours</ToggleButton>
        <ToggleButton value="KEEP_BALANCE">Leave hours balance</ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
