import { ErrorPage, LottieLogoPageLoader, useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { useFetchShiftPlan } from '../hooks/useFetchShiftPlan';
import { ShiftPlanContent } from './ShiftPlanContent';

export const ShiftPlanMain = () => {
  useDocumentTitle('Shift Planning');
  const { isLoading, isError, data, forceRefetch } = useFetchShiftPlan();

  return (
    <AppPageLayout sx={(theme) => ({ background: theme.palette.common.white })}>
      {isLoading && <LottieLogoPageLoader />}
      {!isLoading && (isError || !data) && <ErrorPage />}
      {!isLoading && !isError && data && <ShiftPlanContent data={data} forceRefetch={forceRefetch} />}
    </AppPageLayout>
  );
};
