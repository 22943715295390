import { Box } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { MessagesInboxContent } from './components/MessagesInboxContent';

export const MessagesInbox = () => {
  useDocumentTitle('Inbox');
  return (
    <AppPageLayout>
      <Box data-testid="messages-inbox" sx={{ height: '100%', width: '100%', maxWidth: '768px', margin: '0 auto' }}>
        <MessagesInboxContent />
      </Box>
    </AppPageLayout>
  );
};
