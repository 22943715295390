import { ReactComponent as ArrowForwardSymbol } from '@material-symbols/svg-400/outlined/arrow_forward.svg';
import { ReactComponent as CheckCircleSymbol } from '@material-symbols/svg-400/outlined/check_circle.svg';
import { ReactComponent as DeleteSymbol } from '@material-symbols/svg-400/outlined/delete.svg';
import { Alert, Box, Dialog, DialogTitle, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { captureException } from '@sentry/react';
import { useMemo, useState } from 'react';
import {
  Button,
  theme,
  exportData,
  LetterAvatar,
  SnackbarService,
  useLoadedDepartmentInfoContext,
  SvgIcon,
} from '@stationwise/component-module';
import { axios, client } from '@stationwise/share-api';
import { BasicEmployee, PayCode } from '@stationwise/share-types';
import { DownloadTimeoffsModal } from './DownloadTimeoffsModal';
import { UploadBox } from './UploadBox';
import { WarningModal } from './WarningModal';

const convertMinutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60) % 24;
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};

const parseTimeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const BulkUploadModal = ({
  setBulkUploadOpen,
  uploadType,
}: {
  setBulkUploadOpen: (open: boolean) => void;
  uploadType: 'accruals' | 'time-off' | 'custom-fields';
}) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const [fileDetails, setFileDetails] = useState<{ name: string; size: string } | null>(null);
  const [uploadStatus, setUploadStatus] = useState<'loading' | 'complete' | null>(null);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [downloadTimeOffsOpen, setDownloadTimeOffsOpen] = useState(false);
  const equalityCheck = (changes1: typeof accrualChanges, changes2: typeof accrualChanges) => {
    if (changes1.length !== changes2.length) return false;

    return changes1.every((change1) =>
      changes2.some(
        (change2) =>
          change1.email === change2.email &&
          change1.payCode.code === change2.payCode.code &&
          change1.oldValue === change2.oldValue &&
          change1.newValue === change2.newValue,
      ),
    );
  };
  const [accrualChanges, setAccrualChanges] = useState<
    {
      employeeName: string;
      email: string;
      payCode: PayCode;
      oldValue: number;
      newValue: number;
    }[]
  >([]);

  const [timeOffChanges, setTimeOffChanges] = useState<
    {
      employeeEmail: string;
      payCode: string;
      shiftDate: string;
      partialStartTime: string;
      partialEndTime: string;
      new: boolean;
    }[]
  >([]);

  const [customFieldChanges, setCustomFieldChanges] = useState<
    {
      employeeEmail: string;
      fieldDefinition: string;
      fieldValue: string;
    }[]
  >([]);

  const hasExistingTimeOffChanges = useMemo(() => {
    return timeOffChanges.some((change) => change.new === false);
  }, [timeOffChanges]);

  const isSingleEmployeeChange = useMemo(() => {
    if (accrualChanges.length > 0) {
      const uniqueEmails = new Set(accrualChanges.map((change) => change.email));
      return uniqueEmails.size === 1;
    }

    if (timeOffChanges.length > 0) {
      const uniqueEmails = new Set(timeOffChanges.map((change) => change.employeeEmail));
      return uniqueEmails.size === 1;
    }

    return false;
  }, [accrualChanges, timeOffChanges]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [parsedAccruals, setParsedAccruals] = useState<
    {
      employeeEmail: string;
      payCode: string;
      accruedTimeOff: number;
    }[]
  >([]);

  const handleClose = () => {
    setBulkUploadOpen(false);
    setFileDetails(null);
    setUploadStatus(null);
    setWarningDialogOpen(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const endpoint =
        uploadType === 'accruals'
          ? '/employee/accruals/bulk-update/'
          : uploadType === 'time-off'
            ? '/request/time-off-request/bulk-upload/'
            : '/employee/custom-fields/bulk-update/';

      const data =
        uploadType === 'accruals'
          ? {
              updates: accrualChanges.map((change) => ({
                employee_email: change.email,
                pay_code: change.payCode.code,
                accrued_time_off: change.newValue,
              })),
            }
          : uploadType === 'time-off'
            ? {
                updates: timeOffChanges
                  .filter((change) => change.new)
                  .map((change) => ({
                    employee_email: change.employeeEmail,
                    pay_code: change.payCode,
                    shift_date: change.shiftDate,
                    partial_start_time: change.partialStartTime,
                    partial_end_time: change.partialEndTime,
                  })),
              }
            : {
                updates: customFieldChanges.map((change) => ({
                  employee_email: change.employeeEmail,
                  field_definition: change.fieldDefinition,
                  field_value: change.fieldValue,
                })),
              };

      if (uploadType === 'accruals') {
        const response = await client.post('/employee/accruals/compare/', { imported_accruals: parsedAccruals });
        const changes = response.data.changes;

        if (!equalityCheck(changes, accrualChanges)) {
          handleClose();
          SnackbarService.notify({
            content: 'There were other changes made on accruals while uploading, please upload again.',
            severity: 'error',
            duration: 5000,
          });
          return;
        }
      }

      await client.post(endpoint, data);

      handleClose();
      SnackbarService.notify({
        content:
          uploadType === 'accruals'
            ? 'Accruals updated successfully'
            : uploadType === 'time-off'
              ? 'Time-off requests uploaded successfully'
              : 'Miscellaneous information updated successfully',
        severity: 'success',
        showCloseButton: true,
        duration: 5000,
      });
    } catch (error) {
      captureException(error);

      const errorMessage = (() => {
        if (axios.isAxiosError(error)) {
          if (error.response?.data?.nonFieldErrors) {
            return error.response.data.nonFieldErrors.join(' ');
          }
          return error.response?.data;
        }
        return 'There was an issue processing your request.';
      })();

      SnackbarService.notify({
        content: errorMessage,
        severity: 'error',
        duration: 5000,
      });
      handleClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = () => {
    setFileDetails(null);
    setUploadStatus(null);
  };

  const handleExport = async () => {
    const params =
      uploadType === 'accruals'
        ? {}
        : {
            ids: selectedEmployees.map((employee) => employee.id),
          };

    await exportData(
      uploadType === 'accruals'
        ? '/employee/accruals/export/'
        : uploadType === 'time-off'
          ? '/request/time-off-request/export/'
          : '/employee/custom-fields/export/',
      params,
      uploadType === 'accruals' ? 'accruals.csv' : uploadType === 'time-off' ? 'time-offs.csv' : 'custom-fields.csv',
    );

    setDownloadTimeOffsOpen(false);
    setSelectedEmployees([]);
  };

  const [selectedEmployees, setSelectedEmployees] = useState<BasicEmployee[]>([]);

  return (
    <Dialog open={true} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          justifyContent: 'center',
          borderRadius: theme.spacing(1.5),
          width: '496px',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <DialogTitle
          sx={{
            typography: 'h6',
            textAlign: 'left',
          }}
        >
          {(() => {
            if (uploadType === 'accruals') {
              return 'Upload accruals CSV';
            } else if (uploadType === 'time-off') {
              return 'Upload time-off CSV';
            } else if (uploadType === 'custom-fields') {
              return 'Upload miscellaneous information CSV';
            }
          })()}
        </DialogTitle>

        {uploadStatus !== 'complete' && (
          <UploadBox
            setFileDetails={setFileDetails}
            uploadType={uploadType}
            setAccrualChanges={setAccrualChanges}
            setTimeOffChanges={setTimeOffChanges}
            setCustomFieldChanges={setCustomFieldChanges}
            setUploadStatus={setUploadStatus}
            handleDelete={handleDelete}
            setParsedAccruals={setParsedAccruals}
          />
        )}

        {fileDetails && (
          <Box
            sx={(theme) => ({
              borderRadius: '4px',
              padding: theme.spacing(2),
              mx: theme.spacing(3),
              boxShadow:
                '0px 1px 3px 0px rgba(17, 24, 39, 0.04), 0px 1px 1px 0px rgba(17, 24, 39, 0.07), 0px 2px 1px -1px rgba(17, 24, 39, 0.10)',
            })}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography sx={{ typography: 'bodyMRegular', color: theme.palette.text.primary }}>{fileDetails.name}</Typography>
                <Typography sx={{ typography: 'bodySRegular', color: theme.palette.text.secondary }}>
                  {fileDetails.size} • {uploadStatus === 'loading' && <span>Loading...</span>}
                  {uploadStatus === 'complete' && <span>Complete</span>}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                <SvgIcon
                  component={DeleteSymbol}
                  onClick={handleDelete}
                  sx={(theme) => ({
                    color: theme.palette.action.active,
                    width: theme.spacing(2.5),
                    height: theme.spacing(2.5),
                    cursor: 'pointer',
                    '&:hover': {
                      color: theme.palette.error.main,
                    },
                  })}
                />
                {uploadStatus === 'loading' && <CircularProgress size={16} sx={{ ml: theme.spacing(1) }} />}
                {uploadStatus === 'complete' && (
                  <SvgIcon component={CheckCircleSymbol} sx={(theme) => ({ color: theme.palette.success.main })} />
                )}
              </Box>
            </Box>
          </Box>
        )}
        {uploadStatus !== 'complete' && (
          <Typography
            sx={{ typography: 'body1', color: theme.palette.text.primary, mt: theme.spacing(3), mx: theme.spacing(3) }}
            onClick={uploadType === 'time-off' ? () => setDownloadTimeOffsOpen(true) : handleExport}
          >
            Your CSV should be in pre-defined format to work properly.{' '}
            <span style={{ textDecoration: 'underline', color: theme.palette.primary.main, cursor: 'pointer' }}>
              Download your current CSV,
            </span>{' '}
            edit the data, and upload back to achieve best results.
          </Typography>
        )}

        {uploadType === 'time-off' && (
          <Alert severity="info" sx={(theme) => ({ mx: theme.spacing(3), mt: theme.spacing(2) })}>
            {"It's only possible to change future records. Past records will not be affected. "}
            {'Existing time-offs will be replaced with the ones in the document.'}
          </Alert>
        )}
        {uploadStatus === 'complete' && (
          <>
            <Typography
              sx={{ typography: 'subtitle1', color: theme.palette.text.primary, mt: theme.spacing(3), mx: theme.spacing(3) }}
            >
              Change log
            </Typography>
            {uploadType === 'accruals' &&
              (accrualChanges.length === 0 ? (
                <Typography
                  sx={{ typography: 'body2', color: theme.palette.text.secondary, mt: theme.spacing(2), mx: theme.spacing(3) }}
                >
                  There are no changes found in your file, make sure you uploaded the correct CSV file
                </Typography>
              ) : (
                <Box
                  sx={{
                    mt: theme.spacing(2),
                    mx: theme.spacing(3),
                    padding: theme.spacing(2),
                    gap: theme.spacing(1),
                    maxHeight: '200px',
                    overflowY: 'auto',
                    borderRadius: '6px',
                    border: `1px solid ${theme.palette.grey[200]}`,
                  }}
                >
                  {Object.entries(
                    accrualChanges.reduce(
                      (acc, change) => {
                        if (!acc[change.email]) {
                          acc[change.email] = {
                            name: change.employeeName,
                            changes: [],
                          };
                        }
                        acc[change.email].changes.push(change);
                        return acc;
                      },
                      {} as Record<string, { name: string; changes: (typeof accrualChanges)[number][] }>,
                    ),
                  ).map(([email, { name, changes }], index, array) => (
                    <Box
                      key={email}
                      sx={{
                        borderBottom: index === array.length - 1 ? 'none' : `1px solid ${theme.palette.grey[200]}`,
                        pb: index === array.length - 1 ? 0 : theme.spacing(2),
                        mb: index === array.length - 1 ? 0 : theme.spacing(2),
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), mb: theme.spacing(1) }}>
                        <LetterAvatar firstName={name} />
                        <Typography sx={{ typography: 'body2' }}>{name}</Typography>
                      </Box>

                      {changes.map((change, idx) => (
                        <Box
                          key={`${email}-${change.payCode.code}`}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mt: idx > 0 ? 1 : 0,
                          }}
                        >
                          <Typography sx={{ typography: 'body2' }}>{change.payCode.name}</Typography>

                          <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                            <Typography sx={{ typography: 'body2', color: theme.palette.text.secondary }}>
                              {change.oldValue === 1e308 ? 'Unlimited' : `${change.oldValue}h`}
                            </Typography>
                            <SvgIcon
                              component={ArrowForwardSymbol}
                              fontSize="small"
                              sx={{ color: theme.palette.text.secondary }}
                            />
                            <Typography sx={{ typography: 'body2' }}>
                              {change.newValue === 1e308 ? 'Unlimited' : `${change.newValue}h`}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              ))}

            {uploadType === 'time-off' &&
              (timeOffChanges.length === 0 ? (
                <Typography
                  sx={{ typography: 'body2', color: theme.palette.text.secondary, mt: theme.spacing(2), mx: theme.spacing(3) }}
                >
                  There are no changes found in your file, make sure you uploaded the correct CSV file
                </Typography>
              ) : (
                <Box
                  sx={{
                    mt: theme.spacing(2),
                    mx: theme.spacing(3),
                    padding: theme.spacing(2),
                    gap: theme.spacing(1),
                    maxHeight: '200px',
                    overflowY: 'auto',
                    borderRadius: '6px',
                    border: `1px solid ${theme.palette.grey[200]}`,
                  }}
                >
                  {Object.entries(
                    timeOffChanges
                      .filter((change) => {
                        if (change.new) return true;
                        return !timeOffChanges.some(
                          (otherChange) =>
                            otherChange.new &&
                            otherChange.employeeEmail === change.employeeEmail &&
                            otherChange.payCode === change.payCode &&
                            otherChange.shiftDate === change.shiftDate &&
                            Number(otherChange.partialStartTime) === Number(change.partialStartTime) &&
                            Number(otherChange.partialEndTime) === Number(change.partialEndTime),
                        );
                      })
                      .reduce(
                        (acc, change) => {
                          if (!acc[change.employeeEmail]) {
                            acc[change.employeeEmail] = {
                              email: change.employeeEmail,
                              changes: [],
                            };
                          }
                          acc[change.employeeEmail].changes.push(change);
                          return acc;
                        },
                        {} as Record<
                          string,
                          {
                            email: string;
                            changes: (typeof timeOffChanges)[number][];
                          }
                        >,
                      ),
                  ).map(([email, { changes }], index, array) => (
                    <Box
                      key={email}
                      sx={{
                        borderBottom: index === array.length - 1 ? 'none' : `1px solid ${theme.palette.grey[200]}`,
                        pb: index === array.length - 1 ? 0 : theme.spacing(2),
                        mb: index === array.length - 1 ? 0 : theme.spacing(2),
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), mb: theme.spacing(1) }}>
                        <LetterAvatar firstName={email.split('@')[0]} />
                        <Typography sx={{ typography: 'body2' }}>{email}</Typography>
                      </Box>

                      {changes.map((change, idx) => (
                        <Box
                          key={`${email}-${change.payCode}-${idx}`}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mt: idx > 0 ? 1 : 0,
                          }}
                        >
                          <Typography
                            sx={{
                              typography: 'body2',
                              color: theme.palette.text.primary,
                              textDecoration: change.new ? 'none' : 'line-through',
                            }}
                          >
                            {change.payCode}
                          </Typography>

                          <Typography
                            sx={{
                              typography: 'body2',
                              color: theme.palette.text.primary,
                              textDecoration: change.new ? 'none' : 'line-through',
                            }}
                          >
                            {change.shiftDate}
                            {', '}
                            {convertMinutesToTime(
                              parseTimeToMinutes(departmentInfoState.departmentInfo.shiftStart) +
                                parseInt(change.partialStartTime),
                            )}{' '}
                            -{' '}
                            {convertMinutesToTime(
                              parseTimeToMinutes(departmentInfoState.departmentInfo.shiftStart) + parseInt(change.partialEndTime),
                            )}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              ))}

            {uploadType === 'custom-fields' && (
              <Box
                sx={{
                  mt: theme.spacing(2),
                  mx: theme.spacing(3),
                  padding: theme.spacing(2),
                  maxHeight: '200px',
                  overflowY: 'auto',
                  borderRadius: '6px',
                  border: `1px solid ${theme.palette.grey[200]}`,
                }}
              >
                {Object.entries(
                  customFieldChanges.reduce(
                    (acc, change) => {
                      if (!acc[change.employeeEmail]) {
                        acc[change.employeeEmail] = { changes: [] };
                      }
                      acc[change.employeeEmail].changes.push(change);
                      return acc;
                    },
                    {} as Record<string, { changes: typeof customFieldChanges }>,
                  ),
                ).map(([email, { changes }], index, array) => (
                  <Box
                    key={email}
                    sx={{
                      borderBottom: index === array.length - 1 ? 'none' : `1px solid ${theme.palette.grey[200]}`,
                      pb: index === array.length - 1 ? 0 : theme.spacing(2),
                      mb: index === array.length - 1 ? 0 : theme.spacing(2),
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), mb: theme.spacing(2) }}>
                      <LetterAvatar firstName={email.split('@')[0]} />
                      <Typography sx={{ typography: 'body2' }}>{email}</Typography>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          gap: theme.spacing(1),
                          pb: theme.spacing(1),
                          borderBottom: `1px solid ${theme.palette.grey[200]}`,
                        }}
                      >
                        <Typography sx={{ typography: 'body2', color: theme.palette.text.secondary }}>Field</Typography>
                        <Typography sx={{ typography: 'body2', color: theme.palette.text.secondary }}>Value</Typography>
                      </Box>

                      {changes.map((change, idx) => (
                        <Box
                          key={`${email}-${change.fieldDefinition}-${idx}`}
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: theme.spacing(1),
                            py: theme.spacing(1),
                            borderBottom: idx === changes.length - 1 ? 'none' : `1px solid ${theme.palette.grey[200]}`,
                          }}
                        >
                          <Typography sx={{ typography: 'body2' }}>{change.fieldDefinition}</Typography>
                          <Typography sx={{ typography: 'body2' }}>{change.fieldValue || '-'}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
        <Box
          sx={(theme) => ({
            justifyContent: 'space-between',
            display: 'flex',
            mt: theme.spacing(5),
            width: '100%',
          })}
        >
          <Button
            variant="outlined"
            size="large"
            sx={{ width: '216px', ml: theme.spacing(3), mb: theme.spacing(3) }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ width: '216px', mr: theme.spacing(3), mb: theme.spacing(3) }}
            disabled={
              uploadStatus !== 'complete' ||
              (timeOffChanges.length === 0 && accrualChanges.length === 0 && customFieldChanges.length === 0)
            }
            onClick={() => setWarningDialogOpen(true)}
          >
            Submit
          </Button>
        </Box>
      </Box>
      {warningDialogOpen && (
        <WarningModal
          open={warningDialogOpen}
          onClose={() => setWarningDialogOpen(false)}
          uploadType={uploadType}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          isSingleEmployeeChange={isSingleEmployeeChange}
          hasExistingTimeOffChanges={uploadType === 'time-off' ? hasExistingTimeOffChanges : false}
        />
      )}
      {downloadTimeOffsOpen && (
        <DownloadTimeoffsModal
          open={downloadTimeOffsOpen}
          onClose={() => setDownloadTimeOffsOpen(false)}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          handleExport={handleExport}
        />
      )}
    </Dialog>
  );
};
