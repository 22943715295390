/* eslint-disable no-restricted-imports */
import { Box, Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';
import { ElementType } from 'react';

export type ButtonProps<E extends ElementType = 'button'> = MuiButtonProps<E> & {
  // This won't be necessary after we upgrade to MUI v6.4.0.
  loading?: boolean;
};

// This won't be necessary after we remove the unwanted override in `themeConfig.ts`.
const MUI_BUTTON_PADDING: Record<string, string> = {
  'small|text': '4px 5px',
  'medium|text': '6px 8px',
  'large|text': '8px 11px',
  'small|outlined': '3px 9px',
  'medium|outlined': '5px 15px',
  'large|outlined': '7px 21px',
  'small|contained': '4px 10px',
  'medium|contained': '6px 16px',
  'large|contained': '8px 22px',
};

export const Button = <E extends ElementType>({ children, loading, sx, ...props }: ButtonProps<E>) => {
  const { size = 'medium', variant = 'text' } = props;

  return (
    <MuiButton
      disableFocusRipple
      disableElevation
      {...props}
      disabled={props.disabled || loading}
      sx={[
        (theme) => ({
          p: MUI_BUTTON_PADDING[`${size}|${variant}`],
          color: loading ? 'transparent !important' : undefined,
          textTransform: 'none',
          ...(size === 'large' && { typography: 'buttonL', minHeight: '42px' }),
          ...(size === 'medium' && { typography: 'buttonM', minHeight: '36px' }),
          ...(size === 'small' && { typography: 'buttonS', minHeight: '30px' }),
          '&.Mui-focusVisible': {
            boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px ${theme.palette.common.white}, 0px 0px 0px 4px ${theme.palette.stationBlue[500]}`,
          },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {loading && (
        <Box
          component="span"
          sx={(theme) => ({
            color: theme.palette.action.disabled,
            display: 'inline-flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '& ~ *': { opacity: 0 },
          })}
        >
          <CircularProgress color="inherit" size={16} />
        </Box>
      )}
      {children}
    </MuiButton>
  );
};
