import { format, parseISO, setMinutes } from 'date-fns';
import { useState } from 'react';
import { SnackbarService, useLoadedDepartmentInfoContext, useRosterContext } from '@stationwise/component-module';
import { RosterStation, StrikeTeam as StrikeTeamType, StrikeTeamPosition, DEFAULT_RANK } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { makeEventStations, sortStations, DEFAULT_EVENT_STATION } from '@stationwise/shift-summary-helper';
import { Step, Stepper } from '../../../../../components/Common';
import { PeopleAndRankRequirements } from './CreationSteps/PeopleAndRankRequirements';
import { makeDefaultPerson } from './CreationSteps/PeopleAndRankRequirements/Person';
import { PerformanceDates } from './CreationSteps/PerformanceDates';
import { TeamDefinition } from './CreationSteps/TeamDefinition';

export interface ITeamDefinition {
  name: string;
  station: string;
}
export interface IPersonRequirements {
  rank: string;
  specialities: string[];
  startMinutes: number;
  endMinutes: number;
}
export interface IPerformanceDates {
  endDate: Date;
  repeatOn: number[];
}

export const StrikeTeam = () => {
  const { createStrikeTeamModalState, shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges } = useRosterContext();
  const { duplicatedFromApparatus } = createStrikeTeamModalState;
  const { shiftDuration } = shiftSummaryHelper;

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();

  const [nextDisabled, setNextDisabled] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  const [teamDefinition, setTeamDefinition] = useState<ITeamDefinition>(() => {
    if (duplicatedFromApparatus) {
      return {
        name: duplicatedFromApparatus.strikeTeam.name,
        station: duplicatedFromApparatus.strikeTeam.station,
      };
    }

    return { name: '', station: DEFAULT_EVENT_STATION };
  });

  const [peopleAndRankRequirements, setPeopleAndRankRequirements] = useState<IPersonRequirements[]>(() => {
    if (duplicatedFromApparatus) {
      return duplicatedFromApparatus.strikeTeam.positions.map((position) => ({
        rank: (position.rank || DEFAULT_RANK).code,
        specialities: position.certifications.map((c) => c.code),
        startMinutes: differenceInUTCMinutes(new Date(position.startDateTime), shiftDuration.startTime),
        endMinutes: differenceInUTCMinutes(new Date(position.endDateTime), shiftDuration.startTime),
      }));
    }

    return [makeDefaultPerson()];
  });

  const [performanceDates, setPerformanceDates] = useState<IPerformanceDates>(() => {
    if (duplicatedFromApparatus) {
      return {
        endDate: parseISO(duplicatedFromApparatus.strikeTeam.deactivationDate),
        repeatOn: duplicatedFromApparatus.strikeTeam.activeWeekdays,
      };
    }

    return { endDate: shiftDuration.startTime, repeatOn: [] };
  });

  const firstStep = {
    title: 'Event definition',
    stepNumber: 0,
    component: (
      <TeamDefinition
        teamDefinition={teamDefinition}
        setNextDisabled={setNextDisabled}
        setTeamDefinition={setTeamDefinition}
        completeStep={() => completeStep([0, 1])} //second step has no restrictions to be completed
      />
    ),
  };

  const [currentStep, setCurrentStep] = useState<Step>(firstStep);
  const steps: Step[] = [
    firstStep,
    {
      title: 'People and rank requirements',
      stepNumber: 1,
      component: (
        <PeopleAndRankRequirements
          setNextDisabled={setNextDisabled}
          setPeopleAndRankRequirements={setPeopleAndRankRequirements}
          peopleAndRankRequirements={peopleAndRankRequirements}
          completeStep={() => completeStep([1])}
          teamDefinition={teamDefinition}
          currentStep={currentStep.stepNumber}
        />
      ),
    },
    {
      title: 'Performance dates',
      stepNumber: 2,
      component: (
        <PerformanceDates
          performanceDates={performanceDates}
          setNextDisabled={setNextDisabled}
          completeStep={() => completeStep([2])}
          setPerformanceDates={setPerformanceDates}
        />
      ),
    },
  ];

  const completeStep = (stepNumbers: number[]) => {
    setCompletedSteps([...new Set([...completedSteps, ...stepNumbers])]);
  };

  const onCreateStrikeTeam = () => {
    //Map all the selected data to StrikeTeam model
    const strikeTeamPositions: StrikeTeamPosition[] = [];
    peopleAndRankRequirements.forEach((position, index) => {
      const rank = departmentInfoState.departmentInfo.ranks.find((rank) => rank.code === position.rank) || DEFAULT_RANK;
      if (rank) {
        const startDateTime = setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + position.startMinutes);
        const endDateTime = setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + position.endMinutes);
        const newStrikeTeamPosition = {
          id: -(Date.now() + index),
          rank,
          certifications: departmentInfoState.departmentInfo.certifications.filter((cert) =>
            position.specialities.includes(cert.code),
          ),
          startDateTime: format(startDateTime, "yyyy-MM-dd'T'HH:mm:'00'"),
          endDateTime: format(endDateTime, "yyyy-MM-dd'T'HH:mm:'00'"),
          employees: [],
        };
        strikeTeamPositions.push(newStrikeTeamPosition);
      }
    });

    const newStrikeTeam: StrikeTeamType = {
      id: -Date.now(),
      fakeApparatusId: -Date.now(),
      name: teamDefinition.name,
      station: teamDefinition.station,
      activationDate: format(shiftDuration.startTime, 'yyyy-MM-dd'),
      deactivationDate: format(performanceDates.endDate, 'yyyy-MM-dd'),
      activeWeekdays: performanceDates.repeatOn.length > 0 ? performanceDates.repeatOn : [0, 1, 2, 3, 4, 5, 6],
      positions: strikeTeamPositions,
      certificationRequirements: [],
      temporaryPositions: [],
    };

    let newAllStationCards = new Map(shiftSummaryHelper.allStationCards);
    const newEventStations: RosterStation[] = makeEventStations([newStrikeTeam]);
    let newEventStation: RosterStation;
    newEventStations.forEach((station) => {
      const created = !newAllStationCards.has(station.stationId);
      newEventStation = newAllStationCards.get(station.stationId) || station;
      const apparatuses = created ? newEventStation.apparatuses : newEventStation.apparatuses.concat(station.apparatuses);
      newEventStation = { ...newEventStation, apparatuses: apparatuses };
      newAllStationCards.set(newEventStation.stationId, newEventStation);
    });

    newAllStationCards = sortStations(newAllStationCards);
    setShiftSummaryHelper({ ...shiftSummaryHelper, allStationCards: newAllStationCards });
    setUserHasMadeChanges(true);
  };

  const onSubmit = () => {
    onCreateStrikeTeam();
    createStrikeTeamModalState.setIsOpen(false);
    SnackbarService.notify({
      content: 'Your event has been deployed',
      severity: 'success',
      duration: 5000,
    });
  };

  return (
    <Stepper
      title="Create event"
      steps={steps}
      onClose={() => createStrikeTeamModalState.setIsOpen(false)}
      onFinalSubmit={onSubmit}
      nextDisabled={nextDisabled}
      setNextDisabled={setNextDisabled}
      completedSteps={completedSteps}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
    />
  );
};
