import { Box } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@stationwise/component-module';
import { ADDITIONAL_PAID_TIME_OPTIONS } from './AdditionalPaidTimeContent';

interface TypeOfAdditionalPaidTimeProps {
  handleButtonClick: (selectedButton: string) => void;
  selectedButton: string;
}

export const TypeOfAdditionalPaidTime = ({ selectedButton, handleButtonClick }: TypeOfAdditionalPaidTimeProps) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          px: theme.spacing(2),
          my: theme.spacing(2),
          typography: 'bodyLMedium',
        })}
      >
        Type of additional paid time
      </Box>
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={selectedButton}
        onChange={(_event, newValue) => newValue !== null && handleButtonClick(newValue)}
        aria-label="Type of additional paid time"
        sx={(theme) => ({
          px: theme.spacing(2),
          width: '100%',
          '& .MuiToggleButton-root': { flex: { sm: 1 }, p: theme.spacing(1, 2) },
        })}
      >
        <ToggleButton data-cy="arrived-early-button" value={ADDITIONAL_PAID_TIME_OPTIONS.ARRIVED_EARLY}>
          Arrived early
        </ToggleButton>
        <ToggleButton data-cy="held-over-button" value={ADDITIONAL_PAID_TIME_OPTIONS.HELD_OVER}>
          Held Over
        </ToggleButton>
        <ToggleButton data-cy="manual-entry-button" value={ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY}>
          Manual entry
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
