import { alpha } from '@mui/material';
import { ElementType } from 'react';
import { Button, ButtonProps } from '../Button';

type PreviewButtonProps<E extends ElementType = 'button'> = ButtonProps<E>;

export const PreviewButton = <E extends ElementType>({ children, sx, ...props }: PreviewButtonProps<E>) => {
  const { variant = 'text' } = props;

  return (
    <Button
      {...props}
      sx={[
        { whiteSpace: 'nowrap' },
        (theme) => {
          if (variant === 'contained') {
            return {
              background: theme.palette.stationGray[50],
              color: theme.palette.stationGray[900],
              '&:hover': { background: theme.palette.stationGray[300] },
            };
          } else if (variant === 'outlined') {
            return {
              background: 'transparent',
              borderColor: theme.palette.stationGray[500],
              color: theme.palette.common.white,
              '&:hover': { background: theme.palette.stationGray[700] },
            };
          } else if (variant === 'text') {
            return {
              background: 'transparent',
              color: theme.palette.stationBlue[400],
              '&:hover': { background: alpha(theme.palette.stationBlue[400], 0.2) },
              '&.Mui-disabled': { color: theme.palette.stationGray[500] },
            };
          }

          return {};
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Button>
  );
};
