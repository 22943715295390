import { ReactComponent as BarChartSymbol } from '@material-symbols/svg-400/rounded/bar_chart.svg';
import { ReactComponent as DashboardCustomizeSymbol } from '@material-symbols/svg-400/rounded/dashboard_customize.svg';
import { ReactComponent as EditSquareSymbol } from '@material-symbols/svg-400/rounded/edit_square.svg';
import { ReactComponent as HistorySymbol } from '@material-symbols/svg-400/rounded/history.svg';
import { ReactComponent as OverviewSymbol } from '@material-symbols/svg-400/rounded/overview.svg';
import { ReactComponent as PersonCancelSymbol } from '@material-symbols/svg-400/rounded/person_cancel.svg';
import { ReactComponent as PrintSymbol } from '@material-symbols/svg-400/rounded/print.svg';
import { ReactComponent as SendSymbol } from '@material-symbols/svg-400/rounded/send.svg';
import { AutoAwesomeRounded as AutoAwesomeIcon } from '@mui/icons-material';
import { Box, ButtonBase, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  DateChanger,
  getDepartmentFeatureFlagValue,
  getLastChangesErrorPopoverFallbackAnchorProps,
  useDateQueryParam,
  useRosterContext,
  StationsSelect,
  getTooltipProps,
  useAuthUserCapabilities,
  useLoadedDepartmentInfoContext,
  SvgIcon,
} from '@stationwise/component-module';
import { AutoHireInfoView, RequestVolunteersDetailsView, InstantHireView } from '@stationwise/share-types';
import { TeamColoredBar } from '../../../../../components/Common';
import { ROUTES } from '../../../../../core/Routes';
import { HiringEngineModal } from '../../../HiringEngine/HiringEngineModal';
import { HiringEngineVacancy, VacantStation } from '../../../HiringEngine/Vacancies/vacanciesHelper';
import { DRAWER_TYPE } from '../../constants';
import { PrintDayScheduleModal } from '../PrintDayScheduleModal';
import { Responsibility } from '../Responsibility';
import { CollapseSwitch } from './CollapseSwitch';

interface TopBarProps {
  openedDrawer: DRAWER_TYPE | null;
  setOpenedDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | null>>;
  saveChanges?: () => void;
  isSaving?: boolean;
  toggleMiniCalendar: () => void;
  saveDisabled?: boolean;
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  forceRefetch: () => void;
  vacantStations: VacantStation[];
  hiringEngineResponse: {
    isLoading: boolean;
    requestVolunteers: RequestVolunteersDetailsView[];
    autoHireInfo: AutoHireInfoView | null;
    instantHires: InstantHireView[];
    forceRefetchHiringEngine: () => void;
  };
  disableForward?: boolean;
}

export const TopBar = ({
  openedDrawer,
  setOpenedDrawer,
  saveChanges,
  isSaving,
  toggleMiniCalendar,
  saveDisabled = false,
  groupedVacancies,
  forceRefetch,
  vacantStations,
  hiringEngineResponse,
  disableForward,
}: TopBarProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { isReadonly, lastChanges, resetValues, userHasMadeChanges, preferences, shiftSummaryHelper } = useRosterContext();
  const capabilities = useAuthUserCapabilities();
  const isShiftPlanEnabled = capabilities.EDIT_SHIFT_TEMPLATE && getDepartmentFeatureFlagValue(departmentInfoState, 'SHIFT_PLAN');
  const isShiftTemplateEnabled = capabilities.EDIT_SHIFT_TEMPLATE;
  const canSendMessages = capabilities.SEND_MESSAGES;
  const canViewChangelog = capabilities.VIEW_CHANGE_LOGS;
  const canEditOffRoster = capabilities.EDIT_OFF_ROSTER;
  const canFillFromStaffingList = capabilities.MANAGE_ROSTER_HIRING;

  const { addDays, selectedDateAsDate, selectedDate } = useDateQueryParam();

  const { currentTeams } = shiftSummaryHelper;
  const topBarTeamInfo =
    currentTeams.length === 1
      ? currentTeams[0]
      : { id: 0, name: format(shiftSummaryHelper.shiftDuration.startTime, 'EEEE'), color: shiftSummaryHelper.shiftColor };

  const toggleOpenedDrawer = (next: DRAWER_TYPE) => {
    setOpenedDrawer((prev) => (prev === next ? null : next));
  };

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const isDateInPast = selectedDateAsDate ? selectedDateAsDate.setHours(0, 0, 0) <= new Date().setHours(0, 0, 0) : false;
  const [hiringEngineModalOpen, setHiringEngineModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleHiringEngineClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setHiringEngineModalOpen(true);
  };

  const handlePrintModalOpen = () => {
    setIsPrintModalOpen(true);
  };

  const handlePrintModalClose = () => {
    setIsPrintModalOpen(false);
  };

  return (
    <Box>
      <Box
        display="flex"
        width="100%"
        sx={(theme) => ({
          background: theme.palette.common.white,
          p: '8px 24px',
        })}
        justifyContent="space-between"
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Box display="flex">
            <Box sx={(theme) => ({ mr: theme.spacing(1.5) })}>
              <DateChanger
                currentDate={selectedDateAsDate || new Date()}
                addDays={addDays}
                toggleMiniCalendar={toggleMiniCalendar}
                disableForward={disableForward}
              />
            </Box>
          </Box>

          <Box display="flex" alignSelf="center">
            <Responsibility />
          </Box>

          <Box display="flex" alignSelf="center">
            <StationsSelect />
          </Box>
        </Box>
        <Box display="flex">
          <Box display="flex" justifyContent="center" alignItems="center" sx={(theme) => ({ gap: theme.spacing(2) })}>
            {isShiftPlanEnabled && (
              <Tooltip arrow title="Shift planning" placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  data-cy="shift-planning-button"
                  component={Link}
                  to={ROUTES.SHIFT_PLANNING.fullRoute}
                  state={{ battalionId: preferences.selectedBattalionId }}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <SvgIcon component={DashboardCustomizeSymbol} sx={{ fontSize: '24px' }} />
                </ButtonBase>
              </Tooltip>
            )}
            {isShiftTemplateEnabled && (
              <Tooltip arrow title="Shift template" placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  data-cy="shift-template-button"
                  component={Link}
                  to={ROUTES.SHIFT_TEMPLATE.fullRoute}
                  state={{
                    teamId: currentTeams && currentTeams.length ? currentTeams[0].id : null,
                    battalionId: preferences.selectedBattalionId,
                  }}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <SvgIcon component={EditSquareSymbol} sx={{ fontSize: '24px' }} />
                </ButtonBase>
              </Tooltip>
            )}
            {canSendMessages && (
              <Tooltip arrow title="Send message " placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.MESSAGE)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <SvgIcon component={SendSymbol} sx={{ fontSize: '24px' }} />
                </ButtonBase>
              </Tooltip>
            )}
            <Tooltip arrow title="Print roster" placement="bottom" slotProps={getTooltipProps()}>
              <ButtonBase
                data-cy="print-button"
                onClick={() => handlePrintModalOpen()}
                sx={(theme) => ({
                  borderRadius: theme.spacing(1),
                  p: theme.spacing(1),
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[200],
                  },
                })}
              >
                <SvgIcon component={PrintSymbol} sx={{ fontSize: '24px' }} />
              </ButtonBase>
            </Tooltip>
            <PrintDayScheduleModal open={isPrintModalOpen} handleClose={handlePrintModalClose} />
            {canFillFromStaffingList && (
              <Tooltip arrow title="Staffing lists" placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  data-cy="overtime-button"
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.HIREBACK)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    backgroundColor: openedDrawer === DRAWER_TYPE.HIREBACK ? theme.palette.stationGray[200] : 'transparent',
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <SvgIcon component={OverviewSymbol} sx={{ fontSize: '24px' }} />
                </ButtonBase>
              </Tooltip>
            )}
            {canEditOffRoster && (
              <Tooltip arrow title="Off roster" placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  data-cy="employees-off-button"
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.EMPLOYEES_OFF)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    backgroundColor: openedDrawer === DRAWER_TYPE.EMPLOYEES_OFF ? theme.palette.stationGray[200] : 'transparent',
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <SvgIcon component={PersonCancelSymbol} sx={{ fontSize: '24px' }} />
                </ButtonBase>
              </Tooltip>
            )}
            <Tooltip arrow title="Staffing Stats" placement="bottom" slotProps={getTooltipProps()}>
              <ButtonBase
                data-cy="staffing-stats-button"
                onClick={() => toggleOpenedDrawer(DRAWER_TYPE.STAFFING_STATS)}
                sx={(theme) => ({
                  borderRadius: theme.spacing(1),
                  p: theme.spacing(1),
                  backgroundColor: openedDrawer === DRAWER_TYPE.STAFFING_STATS ? theme.palette.stationGray[200] : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[200],
                  },
                })}
              >
                <SvgIcon component={BarChartSymbol} sx={{ fontSize: '24px' }} />
              </ButtonBase>
            </Tooltip>
            {canViewChangelog && (
              <Tooltip arrow title="Change Log" placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.CHANGELOG)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <SvgIcon component={HistorySymbol} sx={{ fontSize: '24px' }} />
                </ButtonBase>
              </Tooltip>
            )}
            {capabilities.MANAGE_ROSTER_HIRING &&
              hiringEngineResponse.autoHireInfo &&
              (hiringEngineResponse.autoHireInfo.autoHires.length > 0 ||
                hiringEngineResponse.instantHires.length > 0 ||
                !isDateInPast) &&
              (hiringEngineResponse.autoHireInfo.autoHires.length > 0 ||
                hiringEngineResponse.instantHires.length > 0 ||
                Object.keys(groupedVacancies).length !== 0) && (
                <Tooltip arrow title="Hiring engine" placement="bottom" slotProps={getTooltipProps()}>
                  <ButtonBase
                    data-cy="hiring-engine-button"
                    onClick={handleHiringEngineClick}
                    sx={(theme) => ({
                      borderRadius: theme.spacing(1),
                      p: theme.spacing(1),
                      '&:hover': {
                        backgroundColor: theme.palette.stationGray[200],
                      },
                    })}
                  >
                    <SvgIcon component={AutoAwesomeIcon} sx={{ fontSize: '24px' }} />
                  </ButtonBase>
                </Tooltip>
              )}
            <HiringEngineModal
              open={hiringEngineModalOpen}
              setOpen={setHiringEngineModalOpen}
              anchorEl={anchorEl}
              forceRefetch={forceRefetch}
              vacantStations={vacantStations}
              selectedDate={selectedDate}
              selectedBattalionId={preferences.selectedBattalionId}
              isDateInPast={isDateInPast}
              groupedVacancies={groupedVacancies}
              hiringEngineResponse={hiringEngineResponse}
              hasRosterChanges={(userHasMadeChanges || saveDisabled) && !lastChanges && !isSaving && !isReadonly}
            />
            <CollapseSwitch />
            <Box sx={{ display: 'flex', gap: 1, minHeight: '40px' }}>
              <Button variant="outlined" disabled={!userHasMadeChanges || isSaving} onClick={resetValues} data-cy="cancel">
                Cancel
              </Button>
              <Button
                variant="contained"
                {...getLastChangesErrorPopoverFallbackAnchorProps()}
                disabled={!userHasMadeChanges || !!lastChanges || isSaving || saveDisabled}
                onClick={saveChanges}
                data-cy="save"
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <TeamColoredBar shiftTeam={topBarTeamInfo} />
    </Box>
  );
};
