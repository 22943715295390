import { alpha, createTheme, PaletteOptions, Components, Theme } from '@mui/material';
import './types';

const interVariable = {
  fontFamily: 'Inter',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: '100 900',
  src: "url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2')",
};

export const colors = {
  stationGray: {
    50: '#F7FAFD',
    100: '#EEF2F8',
    200: '#DEE3ED',
    300: '#CBD4E1',
    400: '#A4B0BE',
    500: '#687788',
    600: '#475467',
    700: '#323E51',
    800: '#1C2838',
    900: '#111827',
  },
  stationRed: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
  },
  stationOrange: {
    50: '#FFF7ED',
    100: '#FFEDD5',
    200: '#FED7AA',
    300: '#FDBA74',
    400: '#FB923C',
    500: '#F97316',
    600: '#EA580C',
    700: '#C2410C',
    800: '#9A3412',
    900: '#7C2D12',
  },
  stationYellow: {
    50: '#FFFBEB',
    100: '#FEF3C7',
    200: '#FDE68A',
    300: '#FCD34D',
    400: '#FBBF24',
    500: '#F59E0B',
    600: '#D97706',
    700: '#B45309',
    800: '#92400E',
    900: '#78350F',
  },
  stationElectric: {
    50: '#FFFFE5',
    100: '#FBFFC7',
    200: '#F5FF96',
    300: '#EAFD59',
    400: '#D7F316',
    500: '#BCDA08',
    600: '#91AD01',
    700: '#697E06',
    800: '#48560B',
    900: '#263102',
  },
  stationGreen: {
    50: '#ECFDF5',
    100: '#D1FAE5',
    200: '#A7F3D0',
    300: '#6EE7B7',
    400: '#34D399',
    500: '#10B981',
    600: '#059669',
    700: '#047857',
    800: '#065F46',
    900: '#064E3B',
  },
  stationTeal: {
    50: '#F0FDFA',
    100: '#CCFBF1',
    200: '#99F6E4',
    300: '#5EEAD4',
    400: '#2DD4BF',
    500: '#14B8A6',
    600: '#0D9488',
    700: '#0F766E',
    800: '#115E59',
    900: '#134E4A',
  },
  stationCyan: {
    50: '#ECFEFF',
    100: '#CFFAFE',
    200: '#A5F3FC',
    300: '#67E8F9',
    400: '#22D3EE',
    500: '#06B6D4',
    600: '#0891B2',
    700: '#0E7490',
    800: '#155E75',
    900: '#164E63',
  },
  stationLightBlue: {
    50: '#F0F9FF',
    100: '#E0F2FE',
    200: '#BAE6FD',
    300: '#7DD3FC',
    400: '#38BDF8',
    500: '#0EA5E9',
    600: '#0284C7',
    700: '#0369A1',
    800: '#075985',
    900: '#0C4A6E',
  },
  stationBlue: {
    50: '#EFF6FF',
    100: '#DBEAFE',
    200: '#BFDBFE',
    300: '#93C5FD',
    400: '#60A5FA',
    500: '#3B82F6',
    600: '#2563EB',
    700: '#1D4ED8',
    800: '#1E40AF',
    900: '#1E3A8A',
  },
  stationIndigo: {
    50: '#EEF2FF',
    100: '#E0E7FF',
    200: '#C7D2FE',
    300: '#A5B4FC',
    400: '#818CF8',
    500: '#6366F1',
    600: '#4F46E5',
    700: '#4338CA',
    800: '#3730A3',
    900: '#312E81',
  },
  stationPurple: {
    50: '#F5F3FF',
    100: '#EDE9FE',
    200: '#DDD6FE',
    300: '#C4B5FD',
    400: '#A78BFA',
    500: '#8B5CF6',
    600: '#7C3AED',
    700: '#6D28D9',
    800: '#5B21B6',
    900: '#4C1D95',
  },
  stationPink: {
    50: '#FDF2F8',
    100: '#FCE7F3',
    200: '#FBCFE8',
    300: '#F9A8D4',
    400: '#F472B6',
    500: '#EC4899',
    600: '#DB2777',
    700: '#BE185D',
    800: '#9D174D',
    900: '#831843',
  },
  stationRose: {
    50: '#FFF1F2',
    100: '#FFE4E6',
    200: '#FECDD3',
    300: '#FDA4AF',
    400: '#FB7185',
    500: '#F43F5E',
    600: '#E11D48',
    700: '#BE123C',
    800: '#9F1239',
    900: '#881337',
  },
  stationPrimary: {
    400: '#D7F316',
  },
  stationViolet: {
    50: '#F536FF',
    100: '#EACCFF',
    200: '#D699FF',
    300: '#C166FF',
    400: '#AD33FF',
    500: '#9800FF',
    600: '#8900E6',
    700: '#7A00CC',
    800: '#6A00B3',
    900: '#5B0099',
  },
};

const palette: PaletteOptions = {
  ...colors,
  common: { black: colors.stationGray[900] },
  grey: {
    ...colors.stationGray,
    A100: colors.stationGray[100],
    A200: colors.stationGray[200],
    A400: colors.stationGray[400],
    A700: colors.stationGray[700],
  },
  action: {
    active: alpha('#31496D', 0.56),
    disabled: alpha('#31496D', 0.38),
    disabledBackground: alpha('#31496D', 0.12),
    focus: alpha('#31496D', 0.12),
    hover: alpha('#31496D', 0.04),
    selected: alpha('#31496D', 0.08),
  },
  primary: { main: colors.stationBlue[600], dark: colors.stationBlue[700], light: colors.stationBlue[500] },
  secondary: { main: colors.stationPurple[500], dark: colors.stationPurple[600], light: colors.stationPurple[300] },
  info: { main: colors.stationLightBlue[700], dark: colors.stationLightBlue[900], light: colors.stationLightBlue[500] },
  success: { main: colors.stationGreen[600], dark: colors.stationGreen[900], light: colors.stationGreen[500] },
  warning: { main: colors.stationOrange[500], dark: colors.stationOrange[600], light: colors.stationOrange[400] },
  error: { main: colors.stationRed[600], dark: colors.stationRed[700], light: colors.stationRed[400] },
  text: { primary: alpha(colors.stationGray[900], 0.87), secondary: alpha('#1F2E45', 0.4), disabled: alpha('#31496D', 0.38) },
  divider: alpha(colors.stationGray[900], 0.12),
};

const components: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: { '@font-face': [interVariable] },
  },
  MuiButton: {
    styleOverrides: {
      text: {
        textTransform: 'none',
      },
      root: {
        padding: '11px 0 11px',
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '& .Mui-disabled': {
          backgroundColor: colors.stationGray[300],
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        backgroundColor: '#ffffff',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        padding: '10px 12px',
      },
      root: {
        borderRadius: 8,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.stationGray[200],
          boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.stationGray[400],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.stationBlue[400],
          boxShadow: '0px 0px 0px 4px rgba(191, 219, 254, 1)',
        },
        '&.Mui-focused [role="combobox"][aria-expanded="false"] ~ .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.stationGray[200],
          boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '12px',
        boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.1), 0px 2px 4px -1px rgba(10, 14, 22, 0.06)',
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: { fontSize: 'small' },
            style: { fontSize: '20px' },
          },
          {
            props: { fontSize: 'medium' },
            style: { fontSize: '24px' },
          },
          {
            props: { fontSize: 'large' },
            style: { fontSize: '35px' },
          },
        ],
      },
    },
  },
};

const headingXXL = { fontFamily: 'Inter', fontSize: '76px', fontWeight: 500, lineHeight: '96px', letterSpacing: '-0.02em' };
const headingXL = { fontFamily: 'Inter', fontSize: '61px', fontWeight: 500, lineHeight: '72px', letterSpacing: '-0.02em' };
const heading1 = { fontFamily: 'Inter', fontSize: '48px', fontWeight: 500, lineHeight: '56px', letterSpacing: '-0.01em' };
const heading2 = { fontFamily: 'Inter', fontSize: '40px', fontWeight: 500, lineHeight: '48px', letterSpacing: '-0.01em' };
const heading3 = { fontFamily: 'Inter', fontSize: '32px', fontWeight: 500, lineHeight: '40px', letterSpacing: 'normal' };
const heading4 = { fontFamily: 'Inter', fontSize: '28px', fontWeight: 500, lineHeight: '40px', letterSpacing: 'normal' };
const heading6 = { fontFamily: 'Inter', fontSize: '20px', fontWeight: 500, lineHeight: '32px', letterSpacing: 'normal' };

const bodyXXL = { fontFamily: 'Inter', fontSize: '24px', fontWeight: 400, lineHeight: '32px', letterSpacing: 'normal' };
const bodyXL = { fontFamily: 'Inter', fontSize: '20px', fontWeight: 400, lineHeight: '32px', letterSpacing: 'normal' };
const bodyL = { fontFamily: 'Inter', fontSize: '18px', fontWeight: 400, lineHeight: '24px', letterSpacing: 'normal' };
const bodyM = { fontFamily: 'Inter', fontSize: '16px', fontWeight: 400, lineHeight: '24px', letterSpacing: 'normal' };
const bodyS = { fontFamily: 'Inter', fontSize: '14px', fontWeight: 400, lineHeight: '20px', letterSpacing: 'normal' };
const bodyXS = { fontFamily: 'Inter', fontSize: '12px', fontWeight: 400, lineHeight: '20px', letterSpacing: 'normal' };

const buttonL = { fontFamily: 'Inter', fontSize: '16px', fontWeight: 500, lineHeight: '24px', letterSpacing: 'normal' };
const buttonM = { fontFamily: 'Inter', fontSize: '14px', fontWeight: 500, lineHeight: '16px', letterSpacing: 'normal' };
const buttonS = { fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: 'normal' };

const eyebrow = { fontFamily: 'Inter', fontSize: '14px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0.04em' };
const tagline1 = { fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0.03em' };
const tagline2 = { fontFamily: 'Inter', fontSize: '10px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0.03em' };
const chipLabel = { fontFamily: 'Inter', fontSize: '13px', fontWeight: 400, lineHeight: '18px', letterSpacing: '0.16px' };

const typographies = {
  headingXXL,
  headingXL,
  heading1,
  heading2,
  heading3,
  heading4,
  heading6,
  bodyXXLRegular: { ...bodyXXL },
  bodyXXLMedium: { ...bodyXXL, fontWeight: 500 },
  bodyXXLSemibold: { ...bodyXXL, fontWeight: 600 },
  bodyXLRegular: { ...bodyXL },
  bodyXLMedium: { ...bodyXL, fontWeight: 500 },
  bodyXLSemibold: { ...bodyXL, fontWeight: 600 },
  bodyLRegular: { ...bodyL },
  bodyLMedium: { ...bodyL, fontWeight: 500 },
  bodyLSemibold: { ...bodyL, fontWeight: 600 },
  bodyMRegular: { ...bodyM },
  bodyMMedium: { ...bodyM, fontWeight: 500 },
  bodyMSemibold: { ...bodyM, fontWeight: 600 },
  bodySRegular: { ...bodyS },
  bodySMedium: { ...bodyS, fontWeight: 500 },
  bodySSemibold: { ...bodyS, fontWeight: 600 },
  bodyXSRegular: { ...bodyXS },
  bodyXSMedium: { ...bodyXS, fontWeight: 500 },
  bodyXSSemibold: { ...bodyXS, fontWeight: 600 },
  buttonL,
  buttonM,
  buttonS,
  eyebrow,
  tagline1,
  tagline2,
  chipLabel,
};

const typography = {
  ...typographies,
  fontFamily: interVariable.fontFamily,
  h1: typographies.headingXXL,
  h2: typographies.headingXL,
  h3: typographies.heading1,
  h4: typographies.heading3,
  h5: typographies.bodyXXLRegular,
  h6: typographies.bodyXLRegular,
  body1: typographies.bodyMRegular,
  body2: typographies.bodySRegular,
  button: typographies.buttonM,
  caption: typographies.tagline1,
  overline: typographies.bodyXSRegular,
  subtitle1: typographies.bodyMRegular,
  subtitle2: typographies.bodySMedium,
};

const shape = { borderRadius: 6 };

export const theme = createTheme({ typography, palette, components, shape });
