import { Box, Divider } from '@mui/material';
import { captureException } from '@sentry/react';
import React from 'react';
import { theme, useAuthUserCapabilities } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { EmployeePayroll, PayPeriod, MANAGER_NAMES } from '@stationwise/share-types';

type EmployeePayrollSignedContentProps = {
  index: number;
  approverName: string;
  isApprover: boolean;
  isSigned?: boolean;
  isApproved?: boolean;
  employeePayroll: EmployeePayroll;
  setSelectedPayPeriod: React.Dispatch<React.SetStateAction<PayPeriod | null>>;
};

export const EmployeePayrollSignedContent: React.FC<EmployeePayrollSignedContentProps> = ({
  index,
  approverName,
  isApprover,
  isSigned = false,
  isApproved = false,
  employeePayroll,
  setSelectedPayPeriod,
}) => {
  const isForceApprovable = employeePayroll.approverRoles.length > 0;
  const capabilities = useAuthUserCapabilities();

  const handleClick = async () => {
    try {
      const params = {
        payrollEmployeeId: employeePayroll.employee.id,
        index: index,
        payPeriodId: employeePayroll.payPeriod.id,
      };
      await client.post('/payroll/personal/validate-payroll/', params);
      setSelectedPayPeriod({
        startDate: employeePayroll.payPeriod.startDate,
        endDate: employeePayroll.payPeriod.endDate,
        id: employeePayroll.payPeriod.id,
        payPeriodType: employeePayroll.payPeriod.payPeriodType,
        duration: employeePayroll.payPeriod.duration,
      });
    } catch (error) {
      captureException(error);
    }
  };

  const getIsApprovable = (): boolean => {
    if (isApproved || isSigned) return false;
    if (!isApprover || !isForceApprovable) return false;
    if (isApprover && capabilities.EDIT_ADMIN_PAYROLL) {
      const approverRoles = [MANAGER_NAMES.MANAGER_ONE, MANAGER_NAMES.MANAGER_TWO, MANAGER_NAMES.MANAGER_THREE];
      return employeePayroll.approverRoles.includes(approverRoles[index - 1]);
    }
    return false;
  };

  const getColor = () => {
    if (isApprover && isApproved) return theme.palette.common.white;
    if (!isApprover && isSigned) return theme.palette.common.white;
    if (getIsApprovable()) return theme.palette.stationPurple[500];
    return theme.palette.common.white;
  };

  const getCursor = () => {
    return getIsApprovable() ? 'pointer' : 'default';
  };
  const getBackgroundColor = () => {
    return !isSigned && !isApproved && !getIsApprovable()
      ? theme.palette.stationGray[400]
      : !isSigned && !isApproved
        ? theme.palette.common.white
        : theme.palette.stationGreen[500];
  };

  const renderStatus = () => {
    if (isApprover && isApproved) return 'Approved';
    if (isApprover && getIsApprovable()) return 'Approve';
    if (isApprover && !getIsApprovable()) return 'Unapproved';
    if (isSigned) return 'Signed';
    return 'Not Signed';
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.5),
        typography: 'bodyMMedium',
        border: `1px solid ${theme.palette.stationGray[200]}`,
        borderRadius: theme.spacing(5),
        paddingY: '6px',
        paddingLeft: '12px',
        paddingRight: '6px',
        color: theme.palette.stationGray[500],
        maxHeight: theme.spacing(5),
      })}
    >
      <Box sx={{ display: 'flex', whiteSpace: 'nowrap' }}>{approverName} </Box>
      {!isSigned && !isApproved && getIsApprovable() && <Divider orientation="vertical" flexItem />}

      <Box
        sx={{
          backgroundColor: getBackgroundColor(),
          paddingX: theme.spacing(1.5),
          paddingY: theme.spacing(0.5),
          height: '32px',
          borderRadius: '32px',
          whiteSpace: 'nowrap',
          color: getColor(),
          '&:hover': {
            backgroundColor: getIsApprovable() ? theme.palette.stationGray[100] : undefined,
          },
          cursor: getCursor(),
        }}
        onClick={getIsApprovable() ? handleClick : undefined}
      >
        {renderStatus()}
      </Box>
    </Box>
  );
};
