import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flag02Icon16, getTeamColors, HornIcon16, StationIcon24 } from '@stationwise/component-module';
import { EmployeeDefaults, EmployeeShiftDetails, NonShiftAssignmentDetail } from '@stationwise/share-types';
import { EditNonShiftAssignment } from './Edit/EditNonShiftAssignment';
import { InfoCard, InfoCardField } from './InfoCard';

type DefaultStaffingSectionProps = {
  plannedShiftDetails: EmployeeShiftDetails[];
  rankName: string;
  nonShiftDetail: NonShiftAssignmentDetail | undefined;
  employeeDefaults: EmployeeDefaults;
};

type StaffingSectionProps = {
  plannedShiftDetails: EmployeeShiftDetails[];
  rankName: string;
  isPayroll?: boolean;
};

const createFieldDefs = (props: StaffingSectionProps): InfoCardField[][][] => {
  const renderIcon = (icon: ReactNode, sx?: SxProps<Theme>) => {
    return (
      <Box
        sx={[
          (theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${theme.palette.stationGray[200]}`,
            borderRadius: '50%',
            width: 28,
            height: 28,
          }),
          ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
        ]}
      >
        {icon}
      </Box>
    );
  };

  let fieldDefs: InfoCardField[][][];

  if (Object.keys(props.plannedShiftDetails).length === 0) {
    fieldDefs = [
      [
        [
          {
            field: {
              value: props.rankName,
              type: 'text',
            },
          },
        ],
      ],
    ];
  } else {
    const uniqueShiftDetails: EmployeeShiftDetails[] = [];
    const uniqueCombinations = new Set();

    props.plannedShiftDetails.forEach((shiftDetails) => {
      const combinationKey = `${shiftDetails.teamName}-${shiftDetails.station}-${shiftDetails.apparatus}`;
      if (!uniqueCombinations.has(combinationKey)) {
        uniqueShiftDetails.push(shiftDetails);
        uniqueCombinations.add(combinationKey);
      }
    });

    fieldDefs = uniqueShiftDetails.map((shiftDetails) => [
      [
        {
          field: {
            value: shiftDetails.teamName,
            extra: {
              icon: renderIcon(<Flag02Icon16 />, (theme: Theme) => ({
                '& svg path': {
                  color: getTeamColors({ theme, color: shiftDetails.shiftColor }).background,
                },
              })),
            },
            type: 'text',
          },
        },
        {
          field: {
            value: shiftDetails.station,
            extra: {
              icon: renderIcon(<StationIcon24 />, (theme: Theme) => ({
                '& svg path': {
                  fill: theme.palette.stationGray[500],
                },
              })),
            },
            type: 'text',
          },
        },
        {
          field: {
            value: shiftDetails.apparatus,
            extra: {
              icon: renderIcon(<HornIcon16 />, (theme: Theme) => ({
                '& svg path': {
                  stroke: theme.palette.stationGray[500],
                },
              })),
            },
            type: 'text',
          },
        },
      ],
    ]);
  }
  return fieldDefs;
};

export const DefaultStaffingSection = (props: DefaultStaffingSectionProps) => {
  const fieldDefs = createFieldDefs(props);
  const urlParams = new URLSearchParams(window.location.search);
  const isEditing = urlParams.get('edit-staffing') === 'true';

  const [showModal, setShowModal] = useState(isEditing);
  const navigate = useNavigate();
  const handleEditModal = () => {
    setShowModal(true);
  };
  const gotoShiftTemplateEditor = () => {
    const teamId = props.plannedShiftDetails?.[0]?.teamId ?? '';
    const battalionId = props.plannedShiftDetails?.[0]?.battalionId ?? '';
    navigate('/app/shift-template', { state: { teamId: teamId, battalionId: battalionId } });
  };
  const hasAnyAssignment = props.plannedShiftDetails && props.plannedShiftDetails.length > 0;
  const editMenuButtons = props.nonShiftDetail
    ? []
    : [
        { onClick: handleEditModal, buttonText: 'Assign non-shift position' },
        { onClick: gotoShiftTemplateEditor, buttonText: 'Go to shift template editor' },
      ];

  return (
    <Box
      width="100%"
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: 2,
        border: `1px solid ${theme.palette.stationGray[200]}`,
        p: theme.spacing(4, 3, 3, 3),
        gap: theme.spacing(3),
      })}
    >
      <EditNonShiftAssignment
        showModal={showModal}
        setShowModal={setShowModal}
        hasAnyAssignment={hasAnyAssignment}
        nonShiftDetail={props.nonShiftDetail}
        employeeDefaults={props.employeeDefaults}
      />
      {fieldDefs.map((fieldDef, index) => {
        const title = index === 0 ? 'Default Staffing' : '';
        return (
          <InfoCard
            key={index}
            fieldDefs={fieldDef}
            title={title}
            handleEditModal={handleEditModal}
            editMenuButtons={editMenuButtons}
            editMenuTitle="Edit default staffing"
          />
        );
      })}
    </Box>
  );
};

export const StaffingSection = (props: StaffingSectionProps) => {
  const fieldDefs = createFieldDefs(props);
  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(0, 2, 3, 8),
        display: 'flex',
        justifyContent: 'flex-start',
        minWidth: 'max-content',
      })}
    >
      {fieldDefs.map((fieldDef, index) => {
        return <InfoCard key={index} fieldDefs={fieldDef} title={''} isPayroll={props.isPayroll} />;
      })}
    </Box>
  );
};
