import { Certification } from '../certification/models';
import { Manager } from '../conversation';
import { Rank } from '../organization/models';
import { EmployeeDefaults } from './models';

export const EmployeeTypes = {
  FULL_TIME: 'FULL_TIME',
  HIDDEN: 'HIDDEN',
  VOLUNTEER: 'VOLUNTEER',
};

export interface LoggedInEmployeeDepartment {
  id: number;
  name: string;
  domain: string;
}

export interface LoggedInEmployeeDataView {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  rank: Rank;
  unreadMessages: number[];
  certifications: Certification[];
  defaults: EmployeeDefaults;
  isNonShift: boolean;
  isApparatusLogin: boolean;
  departmentDomain: string;
  departments: LoggedInEmployeeDepartment[];
  lastOvertimeCreatedAt: Date;
  employeeType: (typeof EmployeeTypes)[keyof typeof EmployeeTypes];
  mobileIntercomSupportEnabled: boolean;
  managerOne: Manager | null;
  managerTwo: Manager | null;
  managerThree: Manager | null;
  capabilities: string[];
}
