import { Box, ButtonBase, Tooltip, useMediaQuery } from '@mui/material';
import { ExclamationMarkIcon16 } from '../../../assets';
import { theme } from '../../ThemeProvider/themeConfig';

export interface WaitlistWarningProps {
  limit: number;
  order: number;
  requestName?: string;
}

export const WaitlistWarning = ({ order, limit, requestName }: WaitlistWarningProps) => {
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box>
      <ButtonBase>
        <Box>
          <Tooltip
            arrow
            title={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.stationYellow[500],
                    width: '16px',
                    height: '16px',
                    mt: 0.25,
                    padding: '3px 0px 3.564px 0px',
                    borderRadius: '11px',
                    '& svg path': {
                      stroke: 'white',
                    },
                  })}
                >
                  <ExclamationMarkIcon16 />
                </Box>
                {`${requestName} capacity reached (${order}/${limit}). Person added to waiting list. Postpone this request to check in at a later date.`}
              </Box>
            }
            placement="top"
            slotProps={{
              tooltip: {
                sx: {
                  bgcolor: theme.palette.stationGray[800],
                  '& .MuiTooltip-arrow': {
                    color: theme.palette.stationGray[800],
                  },
                  borderRadius: theme.spacing(1),
                  typography: isMobileScreen ? 'tagline1' : 'bodySMedium',
                  padding: theme.spacing(isMobileScreen ? 1.25 : 1.5),
                  textAlign: 'center',
                  '&.MuiTooltip-tooltip': {
                    '&.MuiTooltip-tooltipPlacementTop': {
                      marginBottom: theme.spacing(1.5),
                    },
                  },
                },
              },
            }}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                padding: '2px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                backgroundColor: theme.palette.stationYellow[500],
                width: '16px',
                height: '16px',
                borderRadius: '11px',
                '& svg path': {
                  stroke: theme.palette.common.white,
                },
              })}
            >
              <ExclamationMarkIcon16 />
            </Box>
          </Tooltip>
        </Box>
      </ButtonBase>
    </Box>
  );
};
