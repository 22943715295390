import { addDays, format, parse, isValid } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useDateQueryParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState<string | null | undefined>(undefined);
  const [selectedDateAsDate, setSelectedDateAsDate] = useState<Date | null>(null);

  useEffect(() => {
    const dateQuery = searchParams.get('date');
    if (dateQuery !== selectedDate) {
      if (!dateQuery) {
        const currentDate = new Date();
        setSelectedDate(() => `${format(currentDate, 'MM')}/${format(currentDate, 'dd')}/${format(currentDate, 'yyyy')}`);
        setSelectedDateAsDate(currentDate);
        setSearchParams({ date: format(currentDate, 'yyyy-MM-dd') });
      } else {
        const date = parse(dateQuery, 'yyyy-MM-dd', new Date());
        setSelectedDate(() => `${format(date, 'MM')}/${format(date, 'dd')}/${format(date, 'yyyy')}`);
        setSelectedDateAsDate(date);
      }
    }
  }, [searchParams, selectedDate, setSearchParams]);

  const addDaysToQueryParam = useCallback(
    (amountOfDaysToAdd: number) => {
      const currentSelectedDate = (() => {
        if (!selectedDate) {
          return new Date();
        }
        return parse(selectedDate, 'MM/dd/yyyy', new Date());
      })();
      const newSelectedDate = addDays(currentSelectedDate, amountOfDaysToAdd);
      setSearchParams({ date: format(newSelectedDate, 'yyyy-MM-dd') });
    },
    [selectedDate, setSearchParams],
  );

  const onDateChange = (date: Date | null) => {
    if (date && isValid(date)) {
      setSelectedDate(`${format(date, 'MM')}/${format(date, 'dd')}/${format(date, 'yyyy')}`);
      setSearchParams({ date: format(date, 'yyyy-MM-dd') });
    }
  };

  return {
    selectedDate,
    selectedDateAsDate,
    onDateChange,
    addDays: addDaysToQueryParam,
  };
};
