import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle, useFetchNextShift, useLoadedAuthUserContext } from '@stationwise/component-module';
import { EmployeeTypes, ShiftTradesView, TimeOffLimit } from '@stationwise/share-types';
import { AppPageLayout } from '../../AppPageLayout';
import { useGet } from '../hooks/useGet';
import { DashboardContent } from './components/DashboardContent';

export const Dashboard = () => {
  useDocumentTitle('My Dashboard');
  const { state: authUserState } = useLoadedAuthUserContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (authUserState.employee.employeeType === EmployeeTypes.VOLUNTEER || authUserState.employee.isNonShift) {
      navigate('/app/calendar');
    }
  }, [authUserState.employee.employeeType, authUserState.employee.isNonShift, navigate]);

  const {
    isLoading: isLoadingNextShift,
    isError: isErrorNextShift,
    error: errorNextShift,
    data: dataNextShift,
  } = useFetchNextShift({ autoInitialize: true });

  const {
    isLoading: isLoadingShiftTrades,
    isError: isErrorShiftTrades,
    error: errorShiftTrades,
    data: dataShiftTrades,
    forceRefetch: forceRefetchShiftTrades,
  } = useGet<ShiftTradesView>('/employee/shift-trades/');

  const {
    isLoading: isLoadingAccruals,
    isError: isErrorAccruals,
    error: errorAccruals,
    data: dataAccruals,
  } = useGet<TimeOffLimit[]>('/employee/accruals/');

  return (
    <AppPageLayout>
      <Box data-testid="dashboard-main" sx={{ height: '100%' }}>
        <DashboardContent
          isLoadingNextShift={isLoadingNextShift}
          isErrorNextShift={isErrorNextShift}
          errorNextShift={errorNextShift || ''}
          dataNextShift={dataNextShift}
          isLoadingAccruals={isLoadingAccruals}
          isErrorAccruals={isErrorAccruals}
          errorAccruals={errorAccruals || ''}
          dataAccruals={dataAccruals ? dataAccruals : null}
          isLoadingShiftTrades={isLoadingShiftTrades}
          isErrorShiftTrades={isErrorShiftTrades}
          errorShiftTrades={errorShiftTrades || ''}
          dataShiftTrades={dataShiftTrades ? dataShiftTrades : null}
          forceRefetchShiftTrades={forceRefetchShiftTrades}
        />
      </Box>
    </AppPageLayout>
  );
};
