import { ReactComponent as CloseSymbol } from '@material-symbols/svg-400/outlined/close.svg';
import { Box, TypographyProps, Theme, useTheme, useMediaQuery } from '@mui/material';
import { AllowedColors } from '@stationwise/share-types';
import { SvgIcon } from '../SvgIcon';

export const MAX_BADGE_TITLE_LENGTH = 12;

export const getColor = (theme: Theme, color: AllowedColors) => {
  switch (color) {
    case 'green':
      return {
        background: theme.palette.stationGreen[100],
        color: theme.palette.stationGreen[600],
        adornmentColor: theme.palette.stationGreen[400],
      };
    case 'red':
      return {
        background: theme.palette.stationRed[100],
        color: theme.palette.stationRed[600],
        adornmentColor: theme.palette.stationRed[400],
      };
    case 'yellow':
      return {
        background: theme.palette.stationYellow[100],
        color: theme.palette.stationYellow[600],
        adornmentColor: theme.palette.stationYellow[400],
      };
    case 'orange':
      return {
        background: theme.palette.stationOrange[100],
        color: theme.palette.stationOrange[600],
        adornmentColor: theme.palette.stationOrange[400],
      };
    case 'blue':
      return {
        background: theme.palette.stationBlue[100],
        color: theme.palette.stationBlue[600],
        adornmentColor: theme.palette.stationBlue[400],
      };
    case 'indigo':
      return {
        background: theme.palette.stationIndigo[100],
        color: theme.palette.stationIndigo[600],
        adornmentColor: theme.palette.stationIndigo[400],
      };
    case 'purple':
      return {
        background: theme.palette.stationPurple[100],
        color: theme.palette.stationPurple[600],
        adornmentColor: theme.palette.stationPurple[400],
      };
    case 'pink':
      return {
        background: theme.palette.stationPink[100],
        color: theme.palette.stationPink[600],
        adornmentColor: theme.palette.stationPink[400],
      };
    case 'gray':
      return {
        background: theme.palette.stationGray[100],
        color: theme.palette.stationGray[600],
        adornmentColor: theme.palette.stationGray[400],
      };
    case 'white':
      return {
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        adornmentColor: theme.palette.stationGray[400],
      };
    case 'gold':
      return {
        background: theme.palette.stationYellow[100],
        color: theme.palette.stationYellow[600],
        adornmentColor: theme.palette.stationPink[400],
      };
    case 'violet':
      return {
        background: theme.palette.stationViolet[100],
        color: theme.palette.stationViolet[600],
        adornmentColor: theme.palette.stationViolet[400],
      };
    case 'rose':
      return {
        background: theme.palette.stationRose[100],
        color: theme.palette.stationRose[600],
        adornmentColor: theme.palette.stationRose[400],
      };
    default:
      return {
        background: theme.palette.stationGray[100],
        color: theme.palette.stationGray[600],
        adornmentColor: theme.palette.stationGray[400],
      };
  }
};

export interface BadgeProps {
  label: string;
  type?: 'sm' | 'lg';
  isRound?: boolean;
  addDot?: boolean;
  color?: AllowedColors;
  addClearIcon?: boolean;
  isBold?: boolean;
  noPadding?: boolean;
  isCustom?: boolean;
  customColor?: string;
  customBackgroundColor?: string;
  variant?: TypographyProps['variant'];
  overrideFontSize?: string;
  overrideMaxLength?: number;
  isFloater?: boolean;
  customBorder?: string;
}

export const Badge = ({
  label,
  type = 'lg',
  color = 'gray',
  isRound = false,
  addDot = false,
  addClearIcon = false,
  isBold = false,
  noPadding = false,
  isCustom = false,
  customColor,
  customBackgroundColor,
  variant,
  overrideMaxLength,
  overrideFontSize,
  isFloater = false,
  customBorder,
}: BadgeProps) => {
  const theme = useTheme();
  const colors = getColor(theme, color);
  const canBeCustom = isCustom && customColor;

  const shortenedLabel = label.substring(0, overrideMaxLength || MAX_BADGE_TITLE_LENGTH);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({
        background: canBeCustom ? customBackgroundColor : colors.background,
        padding: isMobile ? '2px 8px 2px 8px' : noPadding ? '2px 5px' : '4px 8px 4px 8px',
        borderRadius: theme.spacing(isRound ? 1.5 : 1),
        border: customBorder ? customBorder : 'none',
      })}
    >
      {addDot && (
        <Box
          mr={1}
          sx={(theme) => ({
            height: theme.spacing(0.75),
            width: theme.spacing(0.75),
            background: colors.adornmentColor,
            borderRadius: '100%',
          })}
        />
      )}
      <Box
        sx={() => {
          let typography = type === 'lg' ? 'bodySRegular' : 'bodyXSRegular';
          typography = isMobile && !isFloater ? 'bodyMRegular' : variant || typography;
          return {
            color: canBeCustom ? customColor : colors.color,
            typography,
            fontWeight: 500,
            fontSize: overrideFontSize,
            lineHeight: typography === 'bodyXSRegular' ? '16px' : undefined,
          };
        }}
      >
        {isBold && !isMobile ? <strong>{shortenedLabel}</strong> : shortenedLabel}
      </Box>
      {addClearIcon && (
        <SvgIcon
          component={CloseSymbol}
          sx={(theme) => ({
            fontSize: 15,
            color: colors.adornmentColor,
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(type === 'sm' ? 0.1 : 0.2),
          })}
        />
      )}
    </Box>
  );
};
