import { isEqual, max, min, setMinutes } from 'date-fns';
import { RosterEditableEmployeeSplit } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { diffCycleMinutes } from '@stationwise/shift-summary-helper';
import { SplitProps } from '../types';

export const setSplits = (props: SplitProps, newSplits: RosterEditableEmployeeSplit[]) => {
  const newEmployeeSplits = new Map(props.employeeSplits);
  newEmployeeSplits.set(props.employee.id, newSplits);
  props.setEmployeeSplits(newEmployeeSplits);
};

export const splitSplit = (props: SplitProps) => {
  const midDurationMinutes = Math.floor(differenceInUTCMinutes(props.split.endDateTime, props.split.startDateTime) / 2);
  const midDateTime = setMinutes(props.split.startDateTime, props.split.startDateTime.getMinutes() + midDurationMinutes);
  const newSplit = { ...props.split };
  const newSplits = [...props.splits];
  if (newSplit.reference.type === 'TIME_OFF_REQUEST' && newSplit.reference.id > 0) {
    const timeOffId = newSplit.reference.id;
    newSplit.reference = { ...newSplit.reference, id: 0 };
    props.setCancelTimeOffPayloads((p) => [...p, { timeOffId }]);
  }

  newSplits.splice(props.index, 1, { ...newSplit, endDateTime: midDateTime }, { ...newSplit, startDateTime: midDateTime });
  setSplits(props, newSplits);
};

export const mergeSplits = (props: SplitProps, direction?: 'UP' | 'DOWN') => {
  const prevSplit = props.splits[props.index];
  const nextSplit = props.splits[props.index + 1];
  const newSplits = [...props.splits];
  newSplits.splice(props.index, 2, {
    ...(direction === 'UP' ? prevSplit : nextSplit),
    startDateTime: prevSplit.startDateTime,
    endDateTime: nextSplit.endDateTime,
  });
  setSplits(props, newSplits);
};

export const updateSplitDateTime = (props: SplitProps, key: 'startDateTime' | 'endDateTime', value: Date) => {
  const shiftStartDateTime = props.shiftSummaryHelper.shiftDuration.startTime;
  let minutes = diffCycleMinutes(value, shiftStartDateTime);
  minutes = minutes || (props.index === 0 && key === 'startDateTime' ? 0 : 24 * 60);
  let newDateTime = setMinutes(shiftStartDateTime, shiftStartDateTime.getMinutes() + minutes);
  if (key === 'endDateTime') {
    newDateTime = max([newDateTime, props.split.startDateTime]);
  } else {
    newDateTime = min([newDateTime, props.split.endDateTime]);
  }

  const adjacentKey = key === 'endDateTime' ? 'startDateTime' : 'endDateTime';
  const adjacentIsDisabledKey = key === 'endDateTime' ? 'isStartDateTimeDisabled' : 'isEndDateTimeDisabled';
  const adjacentIndex = props.index + (key === 'endDateTime' ? 1 : -1);
  const adjacentSplit = props.splits[adjacentIndex];
  const isAdjacentSplitDisabled = props.splitStatuses[adjacentIndex]?.[adjacentIsDisabledKey];
  if (adjacentSplit) {
    if (key === 'endDateTime') {
      newDateTime = min([newDateTime, isAdjacentSplitDisabled ? adjacentSplit.startDateTime : adjacentSplit.endDateTime]);
    } else {
      newDateTime = max([newDateTime, isAdjacentSplitDisabled ? adjacentSplit.endDateTime : adjacentSplit.startDateTime]);
    }
  }

  const newSplits = [...props.splits];
  newSplits[props.index] = { ...props.split, [key]: newDateTime };
  if (adjacentSplit && !isAdjacentSplitDisabled && isEqual(props.split[key], adjacentSplit[adjacentKey])) {
    newSplits[adjacentIndex] = { ...adjacentSplit, [adjacentKey]: newDateTime };
  }

  setSplits(props, newSplits);
};

export const removeSplit = (props: SplitProps) => {
  const newSplits = [...props.splits];
  newSplits.splice(props.index, 1);
  setSplits(props, newSplits);
};
