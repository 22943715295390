import { Alert, Box, Theme, useMediaQuery } from '@mui/material';
import { checkIsEvent } from '@stationwise/shift-summary-helper';
import { Popover, POPOVER_POSITION } from '../../../Popover';
import { EmployeeActionsDialog } from '../EmployeeActionsDialog';
import { RosterEmployeePreview } from '../EmployeePreview';
import { FloatersBar } from '../FloatersBar';
import { LastChangesErrorPopover } from '../LastChangesErrorPopover';
import { useRosterContext } from '../RosterContext';
import { SplitShiftOrTimeOffModal } from '../SplitShiftOrTimeOffModal';
import { StrikeTeamAssignmentModal } from '../StrikeTeamAssignmentModal';
import { StrikeTeamExcessCapacityModal } from '../StrikeTeamExcessCapacityModal';
import { UndeployStrikeTeamModal } from '../UndeployStrikeTeamModal';
import { RosterStation } from './RosterStation';

export const RosterBoard = () => {
  const { isPast, isPersonalRoster, preferences, selectedFilledPositionState, shiftSummaryHelper } = useRosterContext();
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const openIntercom = () => {
    window.Intercom?.('show');
  };

  return (
    <Box className="SWRosterBoard-root" sx={{ height: '100%', overflowY: 'auto', position: 'relative' }}>
      <SplitShiftOrTimeOffModal />
      <StrikeTeamAssignmentModal />
      <StrikeTeamExcessCapacityModal />
      <UndeployStrikeTeamModal />
      <LastChangesErrorPopover />
      <Popover
        anchorEl={selectedFilledPositionState.anchorEl}
        open={selectedFilledPositionState.isEmployeePreviewOpen}
        onClose={selectedFilledPositionState.closeDialog}
        {...(isMobileApp ? POPOVER_POSITION.BOTTOM : POPOVER_POSITION.RIGHT)}
      >
        <RosterEmployeePreview />
      </Popover>
      <EmployeeActionsDialog />
      <Box sx={{ p: 1.5 }}>
        {isPast && !isPersonalRoster && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={(theme) => ({
                mb: theme.spacing(1.5),
                width: 'fit-content',
              })}
            >
              <Alert severity="warning">
                Only notes may be changed on past shifts. For other changes, use the yellow{' '}
                <span onClick={openIntercom} style={{ color: 'inherit', cursor: 'pointer', textDecoration: 'underline' }}>
                  intercom icon
                </span>{' '}
                in the lower right or email{' '}
                <a href="mailto:support@stationwise.com" style={{ color: 'inherit' }}>
                  support@stationwise.com
                </a>
                .
              </Alert>
            </Box>
          </Box>
        )}
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, 100%)',
            justifyContent: 'center',
            gap: theme.spacing(4.5, 0),
            [theme.breakpoints.up('sm')]: {
              gridTemplateColumns: 'repeat(auto-fit, minmax(275px, max-content))',
              gap: theme.spacing(4.5, 1.5),
            },
          })}
        >
          {Array.from(shiftSummaryHelper.allStationCards.values()).map((station) => {
            const stationName = checkIsEvent(station) ? 'Event Groups' : station.stationName;
            const selectedStationStates = preferences.selectedStationsMap.get(preferences.selectedBattalionId || -1) || new Map();
            if (!selectedStationStates.get(stationName) || !station.apparatuses.length) {
              return null;
            }

            return <RosterStation key={station.stationId} station={station} />;
          })}
        </Box>
        <FloatersBar unassignedCards={shiftSummaryHelper.unassignedCards} />
      </Box>
    </Box>
  );
};
