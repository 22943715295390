import { useCallback, useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { AutoHireSignUpView } from '../types';

interface UseFetchAutoHireSignUpDataProps {
  autoHireId: string;
  skip?: boolean;
}

export const useFetchAutoHireSignUpData = ({ autoHireId, skip = false }: UseFetchAutoHireSignUpDataProps) => {
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<AutoHireSignUpView | null>(null);

  useEffect(() => {
    if (skip) {
      return;
    }

    setIsLoading(true);
    setIsError(false);
    setError(null);
    setData(null);

    client
      .get('auto-hire/sign-up/', {
        params: {
          auto_hire_id: autoHireId,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setIsError(true);
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [autoHireId, fetchNoRequest, skip]);

  const forceRefetch = useCallback(() => {
    setFetchNoRequest((prevValue) => prevValue + 1);
  }, []);

  return {
    isLoading,
    isError,
    error,
    data,
    forceRefetch,
  };
};
