import { Box } from '@mui/material';
import { useState } from 'react';
import { RosterEmployee, RosterPosition, TEMPORARY_NON_SHIFT_TITLES } from '@stationwise/share-types';
import { checkIsTemporaryNonShiftPosition } from '@stationwise/shift-summary-helper';
import { AssignmentDetails } from './AssignmentDetails';
import { CalendarModal } from './CalendarModal';
import { NonShiftDetails } from './NonShiftDetails';
import { PreviewButton } from './PreviewButton';
import { PreviewHeader } from './PreviewHeader';

interface EmployeePreviewProps {
  employee: RosterEmployee;
  position: RosterPosition | null;
  hideDates?: boolean;
  children?: React.ReactNode;
  addNoteButton?: React.ReactNode;
  defaultStaffingSection?: React.ReactNode;
  payCodeAndDetailCodes?: React.ReactNode;
  actions?: React.ReactNode;
  hidePersonalCalendar?: boolean;
}

export const EmployeePreview = ({
  employee,
  position,
  children,
  hideDates,
  addNoteButton,
  defaultStaffingSection,
  payCodeAndDetailCodes,
  actions,
  hidePersonalCalendar,
}: EmployeePreviewProps) => {
  const TOOLTIP_WIDTH = '296px';
  const [modalOpen, setModalOpen] = useState(false);

  const teamName =
    (position && checkIsTemporaryNonShiftPosition(position)
      ? TEMPORARY_NON_SHIFT_TITLES.get(position.temporaryNonShiftAssignment?.temporaryNonShiftType)
      : employee.team?.name) || '';

  return (
    <Box sx={{ minWidth: TOOLTIP_WIDTH }} data-cy="employee-preview">
      <PreviewHeader
        position={position}
        employee={employee}
        addNoteButton={addNoteButton}
        defaultStaffingSection={defaultStaffingSection}
        actions={actions}
      />
      <Box sx={{ p: 2 }}>
        <AssignmentDetails
          employee={employee}
          hideDates={hideDates}
          payCodeAndDetailCodes={payCodeAndDetailCodes}
          teamName={teamName}
        />
        {position && checkIsTemporaryNonShiftPosition(position) && (
          <NonShiftDetails assignment={position.temporaryNonShiftAssignment} />
        )}
        {children}
        {!hidePersonalCalendar && (
          <Box sx={{ mt: 1.5 }}>
            <PreviewButton variant="outlined" sx={{ width: '100%' }} onClick={() => setModalOpen(true)}>
              View personal schedule
            </PreviewButton>
            <CalendarModal setModalOpen={setModalOpen} modalOpen={modalOpen} employee={employee} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
