import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import {
  TemporaryNonShiftAssignment,
  TemporaryNonShiftType,
  ON_ROSTER_TEMPORARY_NON_SHIFT_TYPES,
  RosterDataSource,
  RosterEmployee,
  RosterTemporaryNonShiftApparatus,
  RosterTemporaryNonShiftStation,
  TEMPORARY_NON_SHIFT_TITLES,
} from '@stationwise/share-types';
import { getOverrideEmployeePositionFields, setEmployeeActiveId } from '@stationwise/shift-summary-helper';
import { useLoadedDepartmentInfoContext } from '../../Department';

interface Props {
  selectedDate: string;
  skip?: boolean;
}

const makeTemporaryNonShiftAssignments = (selectedDate: Date, temporaryNonShiftAssignment: TemporaryNonShiftAssignment) => {
  const makeEmployee = (startDateTime: Date, endDateTime: Date): RosterEmployee => {
    return setEmployeeActiveId({
      id: `${temporaryNonShiftAssignment.employee.id}`,
      dataSource: RosterDataSource.TEMPORARY_NON_SHIFT,
      name: temporaryNonShiftAssignment.employee.name,
      rank: temporaryNonShiftAssignment.employee.rank,
      certifications: temporaryNonShiftAssignment.employee.certifications,
      team: temporaryNonShiftAssignment.employee.team,
      defaults: temporaryNonShiftAssignment.employee.defaults,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      breakDuration: temporaryNonShiftAssignment.breakDuration,
      payCodes: temporaryNonShiftAssignment.payCodes,
      detailCodes: [],
      ...getOverrideEmployeePositionFields(),
    });
  };

  const temporaryNonShiftStartTime = convertToDate(selectedDate, temporaryNonShiftAssignment.startTime);
  const temporaryNonShiftEndTime = convertToDate(selectedDate, temporaryNonShiftAssignment.endTime);

  const employees: RosterEmployee[] = [];
  employees.push(makeEmployee(temporaryNonShiftStartTime, temporaryNonShiftEndTime));

  return employees;
};

const convertToDate = (date: Date, minutes: number): Date => {
  const d = new Date(date.getTime() + minutes * 60000);
  return d;
};

export const makeTemporaryNonShiftStations = (
  temporaryNonShiftAssignments: TemporaryNonShiftAssignment[],
  selectedDate: Date,
): RosterTemporaryNonShiftStation => {
  const station: RosterTemporaryNonShiftStation = {
    stationName: 'Temporary Assignment',
    stationId: `temporary-assignment-station`,
    dataSource: RosterDataSource.TEMPORARY_NON_SHIFT,
    apparatuses: [],
    certificationRequirements: [],
  };

  const temporaryNonShiftAssignmentsByType = temporaryNonShiftAssignments.reduce(
    (acc, employee) => {
      if (!acc[employee.temporaryNonShiftType]) {
        acc[employee.temporaryNonShiftType] = [];
      }
      acc[employee.temporaryNonShiftType].push(employee);
      return acc;
    },
    {} as Record<TemporaryNonShiftType, TemporaryNonShiftAssignment[]>,
  );

  Object.entries(temporaryNonShiftAssignmentsByType).forEach(([type, employees]) => {
    if (!ON_ROSTER_TEMPORARY_NON_SHIFT_TYPES.has(type as TemporaryNonShiftType)) {
      return;
    }
    const apparatus: RosterTemporaryNonShiftApparatus = {
      id: `temporary-assignment-${type}`,
      dataSource: RosterDataSource.TEMPORARY_NON_SHIFT,
      name: TEMPORARY_NON_SHIFT_TITLES.get(type as TemporaryNonShiftType) || type,
      isForShiftLeader: false,
      positions: [],
      certificationRequirements: [],
    };
    station.apparatuses.push(apparatus);

    employees.forEach((temporaryNonShiftAssignment) =>
      apparatus.positions.push({
        id: `temporary-assignment-${type}-position-${temporaryNonShiftAssignment.id}`,
        dataSource: RosterDataSource.TEMPORARY_NON_SHIFT,
        employees: makeTemporaryNonShiftAssignments(selectedDate, temporaryNonShiftAssignment),
        startDateTime: convertToDate(selectedDate, temporaryNonShiftAssignment.startTime),
        endDateTime: convertToDate(selectedDate, temporaryNonShiftAssignment.endTime),
        rank: temporaryNonShiftAssignment.employee.rank,
        certifications: [],
        isTemporary: false,
        driver: false,
        temporaryNonShiftAssignment,
      }),
    );
  });

  station.apparatuses.sort((a, b) => a.name.localeCompare(b.name));
  return station;
};

export const useFetchTemporaryNonShiftAssignments = ({ selectedDate, skip = false }: Props) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();

  const [refetchCounter, setRefetchCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<RosterTemporaryNonShiftStation | null>(null);

  useEffect(() => {
    const [month, day, year] = selectedDate.split('/');
    const formattedDateString = `${year}-${month}-${day}`;
    const shiftStartDateTime = new Date(`${formattedDateString}T${departmentInfoState.departmentInfo.shiftStart}`);
    if (skip) {
      setIsLoading(false);
      setIsError(false);
      setData(makeTemporaryNonShiftStations([], shiftStartDateTime));
      return;
    }
    setIsLoading(true);
    setIsError(false);
    setData(null);
    client
      .get('/non-shift/temporary-lists/', {
        params: {
          date: selectedDate,
        },
      })
      .then((response) => {
        setData(makeTemporaryNonShiftStations(response.data, shiftStartDateTime));
      })
      .catch((error) => {
        setIsError(true);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedDate, skip, refetchCounter, departmentInfoState.departmentInfo.shiftStart]);

  const forceRefetch = useCallback(() => setRefetchCounter((c) => c + 1), []);

  return {
    isLoading,
    isError,
    data,
    forceRefetch,
  };
};
