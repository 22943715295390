import { Box } from '@mui/material';
import { Modal } from '@stationwise/component-module';
import { EmployeeTimeOffs } from '@stationwise/share-types';
import { TimeOffModalContent } from './TimeOffModalContent';

export const TimeOffModal = ({
  showModal,
  setShowModal,
  timeOffs,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  timeOffs: EmployeeTimeOffs;
}) => {
  return (
    <Modal open={showModal} setOpen={setShowModal} hideCloseIcon={false}>
      <Box sx={{ width: '496px', height: '787px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ typography: 'heading6' }}>Time offs</Box>
        <TimeOffModalContent title="Upcoming" timeOffs={timeOffs?.futureTimeOffs} />
        <TimeOffModalContent title="Past" timeOffs={timeOffs?.pastTimeOffs} />
      </Box>
    </Modal>
  );
};
