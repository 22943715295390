import {
  DETAIL_CODE_ACTING_OUT_OF_RANK,
  RosterPosition,
  RosterEmployee,
  DepartmentInfo,
  DetailCode,
} from '@stationwise/share-types';
import { hasRankOrCertificationActAsRank } from './requirement';
import { IShiftSummaryHelper } from './types';

export const getDepartmentDetailCodes = (departmentInfo: DepartmentInfo, detailCodes: Pick<DetailCode, 'id'>[]) => {
  const ids = new Set(detailCodes.map((dc) => dc.id));
  return departmentInfo.detailCodes.filter((dc) => ids.has(dc.id));
};

export const getMandatoryDetailCodes = (
  { departmentInfo }: IShiftSummaryHelper,
  position: RosterPosition,
  employee: RosterEmployee,
) => {
  const detailCodes = [...employee.detailCodes];
  const actingDetailCode = departmentInfo.detailCodes.find((dc) => dc.code === DETAIL_CODE_ACTING_OUT_OF_RANK);
  const actingDetailCodeIndex = detailCodes.findIndex((dc) => dc.code === DETAIL_CODE_ACTING_OUT_OF_RANK);

  if (!hasRankOrCertificationActAsRank(departmentInfo, employee, position.rank.code)) {
    if (actingDetailCode && actingDetailCodeIndex === -1) {
      detailCodes.push(actingDetailCode);
    }
  } else if (actingDetailCodeIndex !== -1) {
    detailCodes.splice(actingDetailCodeIndex, 1);
  }

  return detailCodes;
};
