import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { RequestVolunteersDetailsView } from '@stationwise/share-types';

interface Props {
  selectedDate: string;
  skip?: boolean;
}

export const useFetchRequestVolunteersForShift = ({ selectedDate, skip }: Props) => {
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<RequestVolunteersDetailsView[]>([]);

  useEffect(() => {
    if (skip) {
      return;
    }
    setIsLoading(true);
    setIsError(false);
    setData([]);

    client
      .get('/shift/bulk-call/', {
        params: {
          shiftDate: selectedDate,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setIsError(true);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedDate, fetchNoRequest, skip]);

  const forceRefetch = () => {
    setFetchNoRequest((prev) => prev + 1);
  };
  return {
    isLoading,
    isError,
    data,
    forceRefetch,
  };
};
