import { Box, useTheme } from '@mui/material';
import { Fragment, ReactNode } from 'react';
import { DEFAULT_RANK } from '@stationwise/share-types';
import { checkHasOvertimePayCode, checkIsActingAsRank, getDepartmentPayCodes } from '@stationwise/shift-summary-helper';
import { RankBadge } from '../../../Badge';
import { ActingAsChip, OvalChip } from '../../../Card';
import { SplitProps } from './types';

type SplitTitleProps = SplitProps;

export const SplitTitle = (props: SplitTitleProps) => {
  const theme = useTheme();
  const { employee, split } = props;
  const { currentBattalion, departmentInfo } = props.shiftSummaryHelper;

  let badge: ReactNode = null;
  let name: string = '';
  if (split.reference.type === 'ASSIGNMENT' && !split.reference.trade) {
    const { battalion, station, apparatus } = split.reference;
    const position = split.reference.position && {
      ...split.reference.position,
      id: `${split.reference.position.id}`,
      rank: split.reference.position.rank || DEFAULT_RANK,
    };
    const payCodes = getDepartmentPayCodes(departmentInfo, split.reference.payCodes);
    if (checkHasOvertimePayCode({ payCodes })) {
      badge = <OvalChip label="OT" backgroundColor={theme.palette.stationPink[500]} />;
    } else if (position && checkIsActingAsRank(props.shiftSummaryHelper, position, employee)) {
      badge = (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5 }}>
          <RankBadge rank={employee.rank} />
          {departmentInfo.settings.actingRolesEnabled && (
            <>
              <ActingAsChip />
              <RankBadge rank={position.rank} />
            </>
          )}
        </Box>
      );
    } else if (!position || position.rank.id !== DEFAULT_RANK.id) {
      badge = <RankBadge rank={position?.rank || employee.rank} />;
    }

    name = station && apparatus ? `${station.name} / ${apparatus.name}` : 'Floater';
    if (battalion && battalion.id !== currentBattalion.id) {
      name = `${battalion.name} / ${name}`;
    }
  } else if (split.reference.type === 'ASSIGNMENT' && split.reference.trade) {
    badge = <OvalChip label="TRD" backgroundColor={theme.palette.stationLightBlue[500]} />;
    name = `Shift Traded with ${split.reference.trade.requester.name}`;
  } else if (split.reference.type === 'SHIFT_TRADE_REQUEST') {
    badge = <OvalChip label="TRD" backgroundColor={theme.palette.stationLightBlue[500]} />;
    name = `Shift Traded with ${split.reference.receiver.name}`;
  } else {
    name = 'Off Roster';
  }

  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
      {badge}
      <Box sx={{ typography: 'bodySMedium' }}>{name}</Box>
    </Box>
  );
};
