import { Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@mui/material';
import { useState, useEffect } from 'react';
import { Button, HornIcon24, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { Certification, CertificationRequirement, RosterApparatus } from '@stationwise/share-types';
import { buildApparatus } from '../../apparatuses';
import { useShiftTemplateContext } from '../../contexts/ShiftTemplateContext';
import { CertificationRequirementCounter } from '../Certification/CertificationRequirementCounter';

const newApparatus: RosterApparatus = buildApparatus({
  name: '',
  certificationRequirements: [],
  isForShiftLeader: false,
});

export const ApparatusDialog = ({
  isOpen,
  setIsOpen,
  selectedApparatus,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  selectedApparatus: RosterApparatus | null;
}) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();

  const getInitialCertifications = (selectedApparatus: RosterApparatus | null) => {
    return departmentInfoState.departmentInfo.certifications.map((certification: Certification) => ({
      code: certification.code,
      name: certification.name,
      color: certification.color,
      id: certification.id,
      amount:
        selectedApparatus?.certificationRequirements.find(
          (certReq: CertificationRequirement) => certReq.code === certification.code,
        )?.amount || 0,
    }));
  };

  const { shiftTemplateHelper, addApparatus, editApparatus, selectedStation, getShiftLeaderApparatusSelected } =
    useShiftTemplateContext();

  const shiftLeaderApparatusSelected = getShiftLeaderApparatusSelected();

  const existingApparatusNames: string[] = [];
  Array.from(shiftTemplateHelper.allStationCards.values()).forEach((station) =>
    station.apparatuses.forEach((app) => existingApparatusNames.push(app.name.toLowerCase())),
  );

  const [apparatus, setApparatus] = useState<RosterApparatus>(selectedApparatus ? selectedApparatus : newApparatus);
  const [certRequirements, setCertRequirements] = useState<CertificationRequirement[]>(() =>
    getInitialCertifications(selectedApparatus),
  );
  const [error, setError] = useState({ duplicatedApparatus: false });

  useEffect(() => {
    if (selectedApparatus) {
      const newCertRequirements = certRequirements.map((certificationReq: CertificationRequirement) => ({
        ...certificationReq,
        amount: selectedApparatus.certificationRequirements.find((cert) => cert.code === certificationReq.code)?.amount || 0,
      }));
      setCertRequirements(newCertRequirements);
      setApparatus(selectedApparatus);
    } else {
      setApparatus(newApparatus);
      setCertRequirements(getInitialCertifications(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApparatus, isOpen]);

  const onSave = () => {
    const payload = {
      ...apparatus,
      certificationRequirements: certRequirements.filter((certReq) => certReq.amount > 0),
    };
    if (selectedApparatus && selectedStation) {
      editApparatus(payload, selectedStation);
    } else if (selectedStation) {
      addApparatus(payload, selectedStation);
    }
    closeDialog();
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} scroll="paper">
      <DialogTitle>
        <Box sx={(theme) => ({ display: 'flex', justifyContent: 'center', mt: theme.spacing(1) })}>
          {!selectedApparatus && (
            <Box
              sx={(theme) => ({
                borderRadius: '80px',
                backgroundColor: theme.palette.common.white,
                p: theme.spacing(2),
                border: `1px solid ${theme.palette.stationGray[50]}`,
                boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.10), 0px 2px 4px -1px rgba(10, 14, 22, 0.06)',
                '& svg path': { stroke: theme.palette.stationIndigo[600] },
                width: '56px',
                height: '56px',
                mb: theme.spacing(2),
              })}
            >
              <HornIcon24 />
            </Box>
          )}
        </Box>
        <Box sx={{ textAlign: 'center', typography: 'bodyXLSemibold' }}>
          {selectedApparatus ? 'Edit apparatus' : 'Add apparatus'}
        </Box>
        {!selectedApparatus && (
          <Box
            sx={(theme) => ({ textAlign: 'center', typography: 'bodyMRegular', mt: theme.spacing(0.5) })}
          >{`to ${selectedStation?.stationName}`}</Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Box width="428px">
          <Box
            sx={(theme) => ({
              pt: theme.spacing(1.5),
              justifyContent: 'center',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              '.MuiFormControl-root': {
                mb: 2,
                width: '100%',
                '.MuiInputLabel-root': {
                  top: '-5px',
                },
              },
            })}
          >
            <TextField
              label="Name"
              value={apparatus.name}
              error={error.duplicatedApparatus}
              helperText={error.duplicatedApparatus ? 'This apparatus already exists, please choose another name.' : ''}
              onChange={(event) => {
                setApparatus({ ...apparatus, name: event.currentTarget.value });
                if (existingApparatusNames.includes(event.currentTarget.value.toLowerCase())) {
                  setError({ ...error, duplicatedApparatus: true });
                } else if (error.duplicatedApparatus) {
                  setError({ ...error, duplicatedApparatus: false });
                }
              }}
              required
            />
            <Box
              component="label"
              sx={[
                {
                  cursor:
                    !!shiftLeaderApparatusSelected && shiftLeaderApparatusSelected.id !== apparatus.id
                      ? 'not-allowed'
                      : 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  typography: 'bodyMRegular',
                  userSelect: 'none',
                },
              ]}
            >
              <Checkbox
                disabled={!!shiftLeaderApparatusSelected && shiftLeaderApparatusSelected.id !== apparatus.id}
                checked={apparatus.isForShiftLeader}
                onChange={(_event) => {
                  setApparatus({ ...apparatus, isForShiftLeader: !apparatus.isForShiftLeader });
                }}
                sx={(theme) => ({
                  color: theme.palette.stationGray[600],
                  p: 0,
                  '&:hover': { backgroundColor: 'transparent' },
                  '&.Mui-checked': {
                    color: theme.palette.stationBlue[600],
                  },
                })}
              />
              <Box
                sx={(theme) => ({
                  color:
                    !!shiftLeaderApparatusSelected && shiftLeaderApparatusSelected.id !== apparatus.id
                      ? theme.palette.stationGray[400]
                      : theme.palette.stationGray[600],
                })}
              >
                Is for shift leader
              </Box>
            </Box>
            {!!shiftLeaderApparatusSelected && shiftLeaderApparatusSelected.id !== apparatus.id && (
              <Box sx={(theme) => ({ mt: theme.spacing(1), typography: 'bodyXSRegular', color: theme.palette.stationGray[500] })}>
                {`${shiftLeaderApparatusSelected.name} is selected as the shift leader apparatus, to be able to choose a new one first uncheck it.`}
              </Box>
            )}
            <Box
              sx={(theme) => ({
                typography: 'eyebrow',
                lineHeight: '20px',
                mb: theme.spacing(2),
                mt: theme.spacing(2.5),
                color: theme.palette.stationGray[700],
              })}
            >
              Apparatus level requirements
            </Box>
            {certRequirements.map((requirement) => (
              <CertificationRequirementCounter
                key={requirement.id}
                setCertRequirements={setCertRequirements}
                certRequirements={certRequirements}
                currentCertificationRequirement={requirement}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={(theme) => ({
            justifyContent: 'center',
            display: 'flex',
            gap: '16px',
            mt: theme.spacing(2),
            mb: theme.spacing(2),
            width: '100%',
          })}
        >
          <Button variant="outlined" size="large" sx={{ width: '45%' }} onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ width: '45%' }}
            disabled={!apparatus.name || error.duplicatedApparatus}
            onClick={onSave}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
