import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { useState } from 'react';
import { GenericDrawerOrModal, getVisibleTags, useDepartmentInfoContext } from '@stationwise/component-module';
import { PayCode } from '@stationwise/share-types';
import { AdditionalPaidTimeContent } from './AdditionalPaidTimeContent';
import { PayCodeSelect } from './AdditionalPaidTimePayCodeSelector';

interface AdditionalPaidTimeProps {
  handleOnClose: () => void;
  drawerOpen: boolean;
  shift: EventInput | EventImpl;
  handleAdditionalPaidTimeRequest: (responseMessage: string, error: boolean) => void;
  forceManual?: boolean;
}

export const AdditionalPaidTime = ({
  shift,
  handleOnClose,
  drawerOpen,
  handleAdditionalPaidTimeRequest,
  forceManual = false,
}: AdditionalPaidTimeProps) => {
  const { state: department } = useDepartmentInfoContext();
  const [selectPayCodeOpen, setSelectPayCodeOpen] = useState(false);
  const [selectedPayCode, setSelectedPayCode] = useState<PayCode | null>(null);
  const departmentPayCodes = department?.departmentInfo?.payCodes || [];
  const visibleTags = getVisibleTags(department?.departmentInfo, 'APT_REQUEST', 'paycode');

  // Filter pay codes based on visible tags
  const filteredPayCodes =
    visibleTags.length > 0
      ? departmentPayCodes.filter((payCode) => payCode.tags.some((tag) => visibleTags.includes(tag)))
      : departmentPayCodes;

  const handleRequest = (responseMessage: string, error: boolean) => {
    handleAdditionalPaidTimeRequest(responseMessage, error);
    setSelectedPayCode(null);
  };

  const handleSelectPayCodeOpen = () => {
    setSelectPayCodeOpen(true);
  };

  const handleSelectPayCodeClose = () => {
    setSelectPayCodeOpen(false);
  };
  const handleClose = () => {
    setSelectedPayCode(null);
    handleOnClose();
  };
  return (
    <>
      <GenericDrawerOrModal
        anchor="bottom"
        drawerOpen={drawerOpen}
        handleOnClose={handleClose}
        loading={false}
        showHeader={true}
        disableFooter={true}
        headerTitle={'Additional paid time '}
      >
        <div>
          <Box>
            <AdditionalPaidTimeContent
              shift={shift}
              handleAdditionalPaidTimeRequest={handleRequest}
              forceManual={forceManual}
              handleSelectPayCodeOpen={handleSelectPayCodeOpen}
              payCode={selectedPayCode}
            />
          </Box>
        </div>
      </GenericDrawerOrModal>

      <PayCodeSelect
        handleOnClose={handleSelectPayCodeClose}
        drawerOpen={selectPayCodeOpen}
        options={filteredPayCodes}
        setPayCode={setSelectedPayCode}
      />
    </>
  );
};
