import { Box, GlobalStyles } from '@mui/material';
import { createPortal } from 'react-dom';
import { PrintDaySchedule } from './PrintDaySchedule';

export const PrintDaySchedulePrint = () => {
  return (
    <>
      {createPortal(
        <Box className="SWSchedule-print">
          <GlobalStyles
            styles={{
              '.SWSchedule-print': { display: 'none' },
              '@media print': {
                '@page': { size: 'portrait' },
                '.MuiModal-root': { display: 'none' },
                '#root': { display: 'none' },
                '.PrintDayScheduleModal': { display: 'none' },
                '.SWSchedule-print': { display: 'block', color: 'black' },
                '.SWSchedule-print svg rect': {
                  fill: 'black',
                },
              },
            }}
          />
          <PrintDaySchedule />
        </Box>,
        document.body,
      )}
    </>
  );
};
