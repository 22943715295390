import { RosterEmployeeSplit } from '@stationwise/share-types';
import { cutDuration } from '../datetime';
import { IShiftSummaryHelper, RemoveEmployeeAvailabilityProps } from '../types';
import { removeBoardEmployeeAvailability } from './board';
import { resolveOneEmployeeOverlap } from './overlap';
import { removeUnassignedEmployeeAvailability } from './unassigned';

/**
 * Remove an employee's availability from `startTime` to `endTime`.
 * - Remove their floater and excess capacity assignments from `startTime` to `endTime`.
 * - Change their regular position assignments to vacancies from `startTime` to `endTime`.
 * - Keep their assignments the same before `startTime` or after `endTime`.
 */
export const removeEmployeeAvailability = <T extends IShiftSummaryHelper>({
  shiftSummaryHelper,
  employeeId,
  startTime,
  endTime,
  checkIsPositionExcluded = () => false,
  resolveOverlap = resolveOneEmployeeOverlap,
}: RemoveEmployeeAvailabilityProps<T>): T => {
  let newShiftSummaryHelper = shiftSummaryHelper;
  newShiftSummaryHelper = removeUnassignedEmployeeAvailability({
    shiftSummaryHelper: newShiftSummaryHelper,
    employeeId,
    startTime,
    endTime,
    resolveOverlap,
  });
  newShiftSummaryHelper = removeBoardEmployeeAvailability({
    shiftSummaryHelper: newShiftSummaryHelper,
    employeeId,
    startTime,
    endTime,
    checkIsPositionExcluded,
    resolveOverlap,
  });

  const splits = newShiftSummaryHelper.employeeSplits.get(employeeId);
  if (splits) {
    const newSplits: RosterEmployeeSplit[] = [];
    splits.forEach((split) => {
      if (split.startDateTime >= endTime || split.endDateTime <= startTime) {
        newSplits.push(split);
        return;
      }

      cutDuration(split, [{ startDateTime: startTime, endDateTime: endTime }]).forEach((newSplitDuration) => {
        newSplits.push({ ...split, ...newSplitDuration });
      });
    });
    newShiftSummaryHelper.employeeSplits = new Map(newShiftSummaryHelper.employeeSplits);
    newShiftSummaryHelper.employeeSplits.set(employeeId, newSplits);
  }

  return newShiftSummaryHelper;
};
