import { captureException } from '@sentry/react';
import { useCallback, useDeferredValue, useEffect, useRef, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { ListFieldsStaffingList } from '@stationwise/share-types';

interface UseSelectedStaffingListAPIParams {
  date: string;
}

interface UseSelectedStaffingListProps {
  selectedDate: string;
  staffingListsResponse?: { data: ListFieldsStaffingList[] | null; isError: boolean };
  initialSelectedEmpty?: boolean;
}

export const useSelectedStaffingList = ({
  selectedDate,
  staffingListsResponse,
  initialSelectedEmpty,
}: UseSelectedStaffingListProps) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const deferredSelectedDate = useDeferredValue(selectedDate);
  const paramsRef = useRef<UseSelectedStaffingListAPIParams | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [staffingLists, setStaffingLists] = useState<ListFieldsStaffingList[]>([]);
  const [selectedStaffingList, setSelectedStaffingList] = useState<ListFieldsStaffingList | undefined>(undefined);
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [selectedListWasModified, setSelectedListWasModified] = useState(false);

  useEffect(() => {
    const data = staffingListsResponse?.data;
    const isErrorResponse = staffingListsResponse?.isError || false;
    setStaffingLists(data || []);
    if (initialSelectedEmpty) {
      setSelectedStaffingList(undefined);
    } else {
      setSelectedStaffingList(selectedStaffingList ? data?.find((list) => list.id === selectedStaffingList.id) : data?.[0]);
    }
    setIsLoading(!isErrorResponse && (!data || !!(data[0] && !data[0].items)));
    setIsError(isErrorResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffingListsResponse?.data, staffingListsResponse?.isError]);

  useEffect(() => {
    const setLoadedStaffingListData = (data: ListFieldsStaffingList) => {
      setStaffingLists((sls) => sls.map((sl) => (sl.id === data.id ? data : sl)));
      setSelectedStaffingList(data);
    };

    const loadSelectedStaffingListItems = async () => {
      const prevParams = paramsRef.current;
      const currParams = { date: deferredSelectedDate };
      paramsRef.current = currParams;

      if (
        !selectedStaffingList ||
        (currParams.date === prevParams?.date && selectedStaffingList.items && !selectedListWasModified)
      ) {
        return;
      }
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      setIsError(false);
      if (!currParams.date) {
        setIsLoading(false);
        setLoadedStaffingListData({ ...selectedStaffingList, items: [] });
        return;
      }

      setIsLoading(true);
      try {
        const response = await client.get(`/staffing-list/staffing-lists/${selectedStaffingList?.id}/`, {
          params: currParams,
          signal: abortControllerRef.current.signal,
        });
        setLoadedStaffingListData(response.data);
        setIsLoading(false);
        setSelectedListWasModified(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
        !isCanceled && setIsError(true);
      }
    };

    loadSelectedStaffingListItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredSelectedDate, selectedStaffingList, fetchNoRequest]);

  const forceRefetch = useCallback(() => {
    setSelectedListWasModified(true);
    setFetchNoRequest((prevValue) => prevValue + 1);
  }, []);

  return {
    isLoading,
    isError,
    staffingLists,
    selectedStaffingList,
    setSelectedStaffingList,
    forceRefetch,
  };
};
