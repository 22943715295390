import { DetailCode, PayCode } from '../views/payroll/models';
import { ShiftPlanAssignmentAPIData } from '../views/shift/getShiftPlan';
import { RosterStation, RosterApparatus, RosterPosition } from './roster';

export enum ShiftPlanChipType {
  ROSTER_EDITOR = 'ROSTER_EDITOR',
}

export enum ShiftPlanAction {
  UPSERT_STATION = 'UPSERT_STATION',
  REMOVE_STATION = 'REMOVE_STATION',
  UPSERT_APPARATUS = 'UPSERT_APPARATUS',
  TOGGLE_APPARATUS_MODE = 'TOGGLE_APPARATUS_MODE',
  MOVE_APPARATUS = 'MOVE_APPARATUS',
  REMOVE_APPARATUS = 'REMOVE_APPARATUS',
  UPSERT_POSITION = 'UPSERT_POSITION',
  WARN_EDIT_POSITION = 'WARN_EDIT_POSITION',
  REMOVE_POSITION = 'REMOVE_POSITION',
}

export interface ShiftPlanStruct {
  station?: RosterStation;
  apparatus?: RosterApparatus;
  position?: RosterPosition;
}

export interface ShiftPlanAssignment {
  employee: ShiftPlanAssignmentAPIData['employee'];
  startDateTime: Date;
  endDateTime: Date;
  payCodes: PayCode[];
  detailCodes: DetailCode[];
  reference: ShiftPlanAssignmentAPIData['reference'];
}

export interface ShiftPlanAssignmentGroups {
  positionAssignments: Map<string, ShiftPlanAssignment[]>;
  excessAssignments: Map<string, ShiftPlanAssignment[]>;
  floaterAssignments: ShiftPlanAssignment[];
}
