import { Dialog } from '@mui/material';
import { ListFieldsStaffingList, StaffingListExemption } from '@stationwise/share-types';
import { ExemptionForm } from './ExemptionForm';

interface ExemptionDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  forceRefetchExemptions: () => void;
  staffingLists: ListFieldsStaffingList[];
  selectedStaffingList?: ListFieldsStaffingList;
  selectedDate: Date;
  forceRefetchStaffingList: () => void;
  exemptions: StaffingListExemption[];
}

export const ExemptionDialog = ({
  open,
  setOpen,
  forceRefetchExemptions,
  staffingLists,
  selectedStaffingList,
  selectedDate,
  forceRefetchStaffingList,
  exemptions,
}: ExemptionDialogProps) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { borderRadius: '16px', position: 'relative' } }}>
      <ExemptionForm
        setDialogOpen={setOpen}
        staffingLists={staffingLists}
        selectedStaffingList={selectedStaffingList}
        selectedDate={selectedDate}
        forceRefetchExemptions={forceRefetchExemptions}
        forceRefetchStaffingList={forceRefetchStaffingList}
        exemptions={exemptions}
      />
    </Dialog>
  );
};
