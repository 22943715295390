import { useEffect, useRef, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { Candidate } from '@stationwise/share-types';

interface Props {
  searchValue: string;
  certifications: Set<string>;
  startDate: string;
}

export const useFetchExemptionCandidates = ({ searchValue, certifications, startDate }: Props) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [data, setData] = useState<Candidate[]>([]);

  useEffect(() => {
    setPage(1);
    setHasNextPage(true);
    setData([]);
  }, [searchValue, certifications, startDate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();

      try {
        const url = 'staffing-list/exemption-candidates/';

        const params = {
          page,
          search: searchValue || undefined,
          certifications: [...certifications],
          startDate: startDate,
        };

        const response = await client.get(url, {
          params,
          signal: abortControllerRef.current.signal,
        });
        setData((oldData) => {
          const keys = new Set<number>();
          const newData: Candidate[] = [];
          const pushData = (result: Candidate) => {
            if (!keys.has(result.id)) {
              newData.push(result);
              keys.add(result.id);
            }
          };
          oldData.forEach(pushData);

          (response.data.results || []).forEach(pushData);

          return newData;
        });
        setIsLoading(false);
        setHasNextPage(!!response.data.next);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && setIsLoading(false);
      }
    };

    fetchData();
  }, [page, searchValue, certifications, startDate]);

  const loadMore = () => {
    if (hasNextPage && !isLoading) {
      setPage(page + 1);
    }
  };

  return {
    isLoading,
    data,
    hasNextPage,
    loadMore,
  };
};
