import { Box } from '@mui/material';
import { addHours, differenceInHours } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { ToggleButton, ToggleButtonGroup } from '../Button';

export interface Option {
  label: string;
  value: number;
}

interface RequestDurationOptionsProps {
  durationOptions: Option[];
  startDateTime: string;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  originalEndDateTime: string;
  originalStartDateTime?: string;
  durationError?: boolean;
}

export const RequestDurationOptions = ({
  durationOptions,
  startDateTime,
  duration,
  setDuration,
  originalEndDateTime,
  durationError,
}: RequestDurationOptionsProps) => {
  const remainingHours = Math.abs(differenceInHours(new Date(startDateTime), new Date(originalEndDateTime)));
  const updatedDurationOptions = durationOptions.map((option) =>
    option.label === 'Remaining Hours' ? { ...option, value: remainingHours } : option,
  );
  if (remainingHours % 4 !== 0 && !updatedDurationOptions.some((option) => option.label === 'Remaining Hours')) {
    updatedDurationOptions.push({ label: 'Remaining Hours', value: remainingHours });
  }

  const isLongOption = (option: Option) => option.label === 'Entire Shift' || option.label === 'Remaining Hours';
  const group1 = updatedDurationOptions.filter((option) => !isLongOption(option));
  const group2 = updatedDurationOptions.filter((option) => isLongOption(option));

  const renderGroup = (group: Option[], groupIndex: number) => (
    <ToggleButtonGroup
      key={groupIndex}
      exclusive
      color={durationError ? 'error' : 'primary'}
      size="large"
      value={duration}
      onChange={(_event, newValue) => newValue !== null && setDuration(newValue)}
      aria-label="Duration options"
      sx={{
        width: '100%',
        '& .MuiToggleButton-root': { p: 1 },
      }}
    >
      {group.map((option) => {
        let isDisabled = false;

        const newEnd = addHours(new Date(startDateTime), option.value);

        if (newEnd > new Date(originalEndDateTime)) {
          isDisabled = true;
        }

        return (
          <ToggleButton
            key={option.value}
            data-cy={`shift-trade-duration-${makeTestIdentifier(option.value.toString())}-hrs`}
            value={option.value}
            disabled={isDisabled}
            sx={{ flex: 1 }}
          >
            {option.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );

  return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>{[group1, group2].map(renderGroup)}</Box>;
};
