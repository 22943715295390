import { useLocation } from 'react-router-dom';
import { WaitlistVerificationView } from './components/WaitlistVerificationMain';

export const WaitlistVerification = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const waitlistRequestId = queryParams.get('request_id');

  return <WaitlistVerificationView id={waitlistRequestId || ''} />;
};
