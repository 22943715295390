import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { RosterPosition, RosterEmployee, Certification } from '@stationwise/share-types';
import { differenceInUTCMinutes, shortenEmployeeName } from '@stationwise/share-utils';
import {
  checkIsAdministration,
  checkIsTemporaryNonShift,
  checkIsShift,
  formatShiftDuration,
  getAllCertificationCodes,
  filterNonRankCertifications,
  checkHasOvertimePayCode,
} from '@stationwise/shift-summary-helper';
import { CircleHigherIcon20 } from '../../assets';
import { CardProps, Card, CollapsedCard, OvalChip, ShiftDurationIndicator } from '../Card';
import { useLoadedDepartmentInfoContext } from '../Department';

interface FilledPositionProps {
  position: RosterPosition;
  employee: RosterEmployee;
  actingAs?: boolean;
  isCollapsed: boolean;
  cardSxProps?: SxProps<Theme>;
  cardType?: 'default' | 'disable' | 'border';
  notesIndicator?: React.ReactNode;
  actions?: React.ReactNode;
}

export const FilledPosition = ({
  position,
  employee,
  actingAs,
  isCollapsed,
  cardSxProps,
  cardType,
  notesIndicator,
  actions,
  ...props
}: FilledPositionProps) => {
  const theme = useTheme();

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;
  const isFirstCard = employee === position.employees[0];
  const isLastCard = employee === position.employees[position.employees.length - 1];
  let durationMinutes = differenceInUTCMinutes(employee.endDateTime, employee.startDateTime) - (employee.breakDuration || 0);
  durationMinutes = durationMinutes <= 0 ? durationMinutes + 24 * 60 : durationMinutes;

  const sxProps = {
    borderTopLeftRadius: isFirstCard ? '12px' : '0',
    borderTopRightRadius: isFirstCard ? '12px' : '0',
    borderBottomLeftRadius: isLastCard ? '12px' : '0',
    borderBottomRightRadius: isLastCard ? '12px' : '0',
  };

  const coveredActingAs = (employee: RosterEmployee, position: RosterPosition, certification: Certification): boolean => {
    if (employee.rank.code !== position.rank.code) {
      return false;
    }

    const relatedCertificationCodes = getAllCertificationCodes([certification]);
    return departmentInfo.ranks.some((rank) => {
      return !!(
        rank.code === position.rank.code &&
        rank.canActCertification &&
        relatedCertificationCodes.has(rank.canActCertification.code)
      );
    });
  };

  const certs = filterNonRankCertifications(departmentInfo, employee.certifications, employee.rank).filter((eCert) => {
    const eCodes = getAllCertificationCodes([eCert]);
    return position.certifications.some((pCert) => eCodes.has(pCert.code)) && !coveredActingAs(employee, position, eCert);
  });

  const indicators = (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, '&:empty': { display: 'none' } }}>
      {position.isTemporary && <CircleHigherIcon20 />}
      {(() => {
        if (checkHasOvertimePayCode(employee)) {
          return <OvalChip label="OT" backgroundColor={theme.palette.stationPink[500]} />;
        } else if (employee.trade) {
          return <OvalChip label="TRD" backgroundColor={theme.palette.stationLightBlue[500]} />;
        }
        return null;
      })()}
      {notesIndicator}
    </Box>
  );

  const isActingAsRank = actingAs ?? position.rank.code !== employee.rank.code;
  const cardProps: CardProps = {
    rank: !departmentInfo.settings.actingRolesEnabled && isActingAsRank ? employee.rank : position.rank,
    durationLabel: formatShiftDuration({ startTime: employee.startDateTime, endTime: employee.endDateTime }),
    durationMinutes,
    nameLabel: employee.name,
    certs: !checkIsShift(position) ? [] : certs,
    actingAsRank: isActingAsRank && departmentInfo.settings.actingRolesEnabled ? position.rank : undefined,
    isDriver: position.driver,
    borderColor: employee.team?.color,
    indicators,
    cardSxProps: [sxProps, ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : [])],
    type: cardType,
    actions: actions,
    ...props,
  };

  if (isCollapsed) {
    cardProps.nameLabel = shortenEmployeeName(employee.name);

    if (checkIsAdministration(position) || checkIsTemporaryNonShift(position)) {
      cardProps.indicators = (
        <ShiftDurationIndicator durationLabel={cardProps.durationLabel} durationMinutes={cardProps.durationMinutes} />
      );
    }

    return (
      <CollapsedCard
        rank={cardProps.rank}
        nameLabel={cardProps.nameLabel}
        borderColor={cardProps.borderColor}
        type={cardProps.type}
        isDriver={cardProps.isDriver}
        actingAsRank={cardProps.actingAsRank}
        indicators={cardProps.indicators}
        cardSxProps={cardProps.cardSxProps}
      />
    );
  }

  return <Card {...cardProps} />;
};
