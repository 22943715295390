import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { DashboardContent } from './DashboardContent';

export const DashboardMain = () => {
  useDocumentTitle('Dashboard');
  return (
    <AppPageLayout sx={(theme) => ({ background: theme.palette.common.white })}>
      <DashboardContent />
    </AppPageLayout>
  );
};
