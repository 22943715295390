import { Box, useTheme, Tooltip } from '@mui/material';
import { captureException } from '@sentry/react';
import { useCallback, useState, useMemo } from 'react';
import {
  SelectCategoryItem,
  Link03Icon16,
  ConversationInformation,
  ISelectedRecipients,
  TextEditor,
  ConversationButtons,
  SHIFTS,
  WORK_GROUPS,
  ALL_CONTACTS,
  useConversations,
  EmbedLink,
  ConversationCategories,
  SnackbarService,
} from '@stationwise/component-module';
import { ConversationInput } from '@stationwise/share-types';
import { MessageComposerTopBar } from './MessageComposerTopBar';

interface MessageComposerProps {
  refetchConversations: () => void;
  setIsCreatingMessage: (value: boolean) => void;
}

export const MessageComposer = ({ refetchConversations, setIsCreatingMessage }: MessageComposerProps) => {
  const theme = useTheme();
  const categories: SelectCategoryItem[] = ConversationCategories();

  const [category, setCategory] = useState<string>(categories[0].value);
  const [subject, setSubject] = useState<string>('');
  const [messageContent, setMessageContent] = useState<string>('');
  const [editorKey, setEditorKey] = useState<number>(1);
  const [resetRecipients, setResetRecipients] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openEmbedLink, setOpenEmbedLink] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const [tempLink, setTempLink] = useState<string>('');
  const [recipients, setRecipients] = useState<ISelectedRecipients>({});

  const { handleCreateConversation } = useConversations();

  const isSelectedRecipientsEmpty = useMemo((): boolean => {
    if (!recipients) {
      return true;
    }

    if (Object.keys(recipients).length === 0) {
      return true;
    }

    return Object.values(recipients).every((arr) => Array.isArray(arr) && arr.length === 0);
  }, [recipients]);

  const clearEditor = useCallback(() => {
    // resets editor
    const newKey = editorKey * 43;
    setEditorKey(newKey);
  }, [editorKey]);

  const resetFields = useCallback(() => {
    setMessageContent('');
    setSubject('');
    setLink('');
    setTempLink('');
    setOpenEmbedLink(false);
    setCategory(categories[0].value);
    setResetRecipients(!resetRecipients);

    clearEditor();
  }, [categories, resetRecipients, clearEditor]);

  const handleCancel = () => {
    resetFields();
    setIsCreatingMessage(false);
  };

  const handleOnEmbedLink = useCallback(() => {
    setOpenEmbedLink(true);
  }, [setOpenEmbedLink]);

  const handleOnCloseEmbedLink = useCallback(() => {
    setOpenEmbedLink(false);
    setTempLink('');
  }, [setOpenEmbedLink, setTempLink]);

  const handleOnSaveLink = useCallback(() => {
    if (tempLink) {
      setOpenEmbedLink(false);
      setLink(tempLink);
      setTempLink('');
    }
  }, [tempLink, setLink, setOpenEmbedLink, setTempLink]);

  const sendMessage = useCallback(async () => {
    if (messageContent && subject) {
      setLoading(true);
      // CREATE CONVERSATION
      const newConversation: ConversationInput = {
        subject,
        link,
        category,
        status: 'Sent',
        messages: [
          {
            body: messageContent,
          },
        ],
        recipients: recipients && recipients[ALL_CONTACTS] ? recipients[ALL_CONTACTS].map((ind) => ({ employee: ind.id })) : [],
        workGroups: recipients && recipients[WORK_GROUPS] ? recipients[WORK_GROUPS].map((wg) => ({ id: wg.id })) : [],
        shifts: recipients && recipients[SHIFTS] ? recipients[SHIFTS].map((sh) => ({ id: sh.id })) : [],
      };

      try {
        await handleCreateConversation(newConversation);
        refetchConversations();
        setIsCreatingMessage(false);
        setLoading(false);
        resetFields();
        setResetRecipients(!resetRecipients);
        SnackbarService.notify({
          content: `Message has been successfully sent!`,
          severity: 'success',
          duration: 5000,
        });
      } catch (error) {
        setLoading(false);
        captureException(error);
        SnackbarService.notify({
          content: 'There was an error sending your message.',
          severity: 'error',
          duration: 10000,
        });
      }
    }
  }, [
    category,
    refetchConversations,
    handleCreateConversation,
    link,
    messageContent,
    resetRecipients,
    resetFields,
    subject,
    recipients,
    setIsCreatingMessage,
  ]);

  return (
    <Box>
      <MessageComposerTopBar categories={categories} category={category} setCategory={setCategory} />
      <ConversationInformation
        link={link}
        setLink={setLink}
        subject={subject}
        setSubject={setSubject}
        isEditable={true}
        setRecipients={setRecipients}
        recipients={recipients}
        resetRecipients={resetRecipients}
        conversationRecipients={undefined}
        conversationWorkGroups={undefined}
        conversationTeam={undefined}
      />

      <Box
        sx={(theme) => ({
          height: '280px',
          maxHeight: '280px',
          '@media (min-height: 701px) and (max-height: 800px)': {
            height: '415px',
            maxHeight: '415px',
          },
          '@media (min-height: 701px) and (max-height: 900px)': {
            height: '460px',
            maxHeight: '460px',
          },
          '@media (min-height: 901px) and (max-height: 1200px)': {
            height: '540px',
            maxHeight: '540px',
          },
          overflowY: 'auto',
          overflowX: 'hidden',
          boxShadow: 'unset',
          paddingBottom: theme.spacing(1),
        })}
      >
        <TextEditor
          placeholder={(() => {
            if (!loading) {
              return 'Write your message';
            } else if (loading) {
              return '';
            } else {
              return 'Write your follow up message';
            }
          })()}
          messageContent={messageContent}
          setMessageContent={setMessageContent}
        />
      </Box>
      {openEmbedLink && (
        <EmbedLink
          link={tempLink}
          setLink={setTempLink}
          handleOnCloseEmbedLink={handleOnCloseEmbedLink}
          handleOnSaveLink={handleOnSaveLink}
        />
      )}

      <Tooltip
        title="Embed link"
        arrow
        slotProps={{
          tooltip: {
            sx: {
              bgcolor: theme.palette.stationGray[800],
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              '& .MuiTooltip-arrow': {
                color: theme.palette.stationGray[800],
              },
            },
          },
        }}
      >
        <Box
          data-testid="link-icon"
          sx={(theme) => ({
            cursor: 'pointer',
            position: 'absolute',
            bottom: '12px',
            left: '195px',
            zIndex: theme.zIndex.mobileStepper,
          })}
          onClick={handleOnEmbedLink}
        >
          <Link03Icon16 />
        </Box>
      </Tooltip>

      <ConversationButtons
        loading={loading}
        handleOnCloseDrawer={handleCancel}
        handleSubmitClick={sendMessage}
        disableSubmit={!messageContent || !subject || isSelectedRecipientsEmpty || loading}
        isFollowUp={false}
      />
    </Box>
  );
};
