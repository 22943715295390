import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { EmployeeNextShiftView } from '@stationwise/share-types';
import { BalloonIcon, RequestsError, EmptyMailbox } from '../../assets';
import { InfiniteScroll } from '../InfiniteScroll';
import { Loader } from '../Loader';
import { ChipFilter, theme } from '../index';
import { RequestCard, RequestCardRequest, REQUEST_CARD_ACTION, REQUEST_CARD_STATUS } from './RequestCard';
import { RequestCardLoading } from './RequestCard/RequestCardLoading';

export interface RequestsFilterChips extends Omit<FilterChips, 'filterName'> {
  filterName: REQUEST_CARD_STATUS;
}

interface FilterChips {
  label: string;
  icon: React.ElementType | null;
  filterName: string | null;
}

interface RequestsFetcher {
  isLoading: boolean;
  isError: boolean;
  data: RequestCardRequest[];
  setData: Dispatch<RequestCardRequest[]>;
  initialize: () => void;
  hasNextPage: boolean;
  loadMore: () => void;
}

export type RequestsFetchersByStatus = Record<REQUEST_CARD_STATUS, RequestsFetcher>;

export interface NextShiftFetcher {
  data: EmployeeNextShiftView | null;
  initialize: () => void;
}

interface RequestsProps {
  filterChips: RequestsFilterChips[];
  requests: RequestsFetchersByStatus;
  nextShiftFetcher: NextShiftFetcher;
  setRefetchEvents: Dispatch<boolean>;
}

export const Requests = ({ filterChips, requests, nextShiftFetcher, setRefetchEvents }: RequestsProps) => {
  const [selectedChipFilter, setSelectedChipFilter] = useState(REQUEST_CARD_STATUS.PENDING);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const initializeRequests = requests[selectedChipFilter].initialize;
  useEffect(() => initializeRequests(), [initializeRequests, selectedChipFilter]);

  const initializeNextShift = nextShiftFetcher.initialize;
  useEffect(() => initializeNextShift(), [initializeNextShift]);

  const getRequestKey = (request: RequestCardRequest) => {
    return `${request.requestType}:${request.id}`;
  };

  const onConfirmReview = (action: REQUEST_CARD_ACTION, newRequest: RequestCardRequest) => {
    const source = requests[REQUEST_CARD_STATUS.PENDING];
    let target = source;
    if (action === REQUEST_CARD_ACTION.APPROVE) {
      target = requests[REQUEST_CARD_STATUS.APPROVED];
      setRefetchEvents(true);
    } else if (action === REQUEST_CARD_ACTION.DENY) {
      target = requests[REQUEST_CARD_STATUS.DENIED];
    }

    if (source === target) {
      const newSourceData = source.data.map((request) => {
        if (getRequestKey(request) === getRequestKey(newRequest)) {
          return newRequest;
        }
        return request;
      });
      source.setData(newSourceData);
    } else {
      const newSourceData = source.data.filter((request) => {
        return getRequestKey(request) !== getRequestKey(newRequest);
      });
      source.setData(newSourceData);

      const newTargetData = [newRequest, ...target.data];
      target.setData(newTargetData);
    }
  };

  const retryAttempts = useRef(0);

  const handleRetry = useCallback(() => {
    retryAttempts.current += 1;
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="0px 30px 0px 16px"
      width="100%"
      height="100%"
      sx={{ position: 'relative' }}
    >
      <Box display="flex" gap={2} padding="24px 0px 8px 8px" ml="-8px">
        {filterChips.map((option) => (
          <ChipFilter
            key={option.filterName}
            label={option.label}
            icon={option.icon}
            isSelected={option.filterName === selectedChipFilter}
            action={() => setSelectedChipFilter(option.filterName)}
          />
        ))}
      </Box>
      <Box mt="24px" mb="10px">
        {/* TODO: uncomment when Search is implemented.
        <SearchInput value={inputText} color="white" setValue={setInputText} />
        */}
      </Box>

      {requests[selectedChipFilter].isLoading && requests[selectedChipFilter].data.length === 0 && (
        <Box>
          <RequestCardLoading />
          <RequestCardLoading />
          <RequestCardLoading />
          <RequestCardLoading />
        </Box>
      )}

      {requests[selectedChipFilter].isError && (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt="100px">
          <Box>
            <RequestsError />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt="10px">
            <Typography textAlign={'center'} sx={{ typography: 'bodyLSemibold' }}>
              {"Couldn't load your requests."}
            </Typography>
            <Typography textAlign={'center'} sx={{ typography: 'bodyLSemibold', mt: '4px' }}>
              {"Let's give it another try"}
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              borderRadius: '6px',
              backgroundColor: theme.palette.stationBlue[600],
              display: 'flex',
              padding: '9px 17px',
              justifyContent: 'center',
              alignItems: 'center',
              width: '200px',
              mt: theme.spacing(3),
              mb: theme.spacing(3),
            })}
            onClick={handleRetry}
          >
            <Typography sx={(theme) => ({ color: theme.palette.common.white, typography: 'bodySSemibold' })}>
              Try again
            </Typography>
          </Box>
        </Box>
      )}

      {isMobile &&
        requests[selectedChipFilter].data.length === 0 &&
        !requests[selectedChipFilter].isLoading &&
        !requests[selectedChipFilter].isError && (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt="120px">
            <Box>
              <EmptyMailbox />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt="10px">
              <Typography textAlign={'center'} sx={{ typography: 'bodyXLRegular' }}>
                You have no requests to resolve!
              </Typography>
              <Typography textAlign={'center'} sx={{ typography: 'bodyMRegular' }} mt="4px">
                Nothing to see here, but your inbox is <br /> ready and waiting.
              </Typography>
            </Box>
          </Box>
        )}

      {!isMobile &&
        requests[selectedChipFilter].data.length === 0 &&
        !requests[selectedChipFilter].isLoading &&
        !requests[selectedChipFilter].isError && (
          <Box sx={{ alignItems: 'center', justifyContent: 'center', mt: '120px' }}>
            <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', mb: '32px' }}>
              <BalloonIcon />
            </Box>
            <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', textAlign: 'center', mb: '16px' }}>
              <Typography sx={{ typography: 'heading4' }}>
                Fantastic! All your <br /> to-dos are sorted out
              </Typography>
            </Box>
            <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <Typography sx={(theme) => ({ typography: 'bodyMRegular', color: theme.palette.stationGray[500] })}>
                Your to-dos will appear here.
              </Typography>
            </Box>
          </Box>
        )}

      {requests[selectedChipFilter].data.length > 0 && (
        <InfiniteScroll
          onLoadMore={requests[selectedChipFilter].loadMore}
          loader={requests[selectedChipFilter].hasNextPage && <Loader sx={{ my: 2 }} />}
          sx={{
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '6px',
              ml: '16px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: theme.palette.stationGray[300],
              borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: theme.palette.stationGray[400],
            },
          }}
          observerOptions={{ root: null }}
        >
          {requests[selectedChipFilter].data.map((request) => (
            <RequestCard
              key={getRequestKey(request)}
              request={request}
              status={selectedChipFilter}
              nextShiftData={nextShiftFetcher.data}
              onConfirmReview={onConfirmReview}
            />
          ))}
        </InfiniteScroll>
      )}
    </Box>
  );
};
