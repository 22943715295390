import { useCallback } from 'react';
import { useFetchRequestVolunteersForShift } from '../../../hooks';
import { useFetchAutoHire } from './useFetchAutoHire';
import { useFetchInstantHires } from './useFetchInstantHires';

export const useFetchHiringEngineData = ({
  selectedDate,
  skip,
  battalionId,
}: {
  selectedDate: string;
  skip: boolean;
  battalionId: number | undefined;
}) => {
  const {
    isLoading: isLoadingRequestVolunteers,
    isError: isErrorRequestVolunteers,
    data: requestVolunteers,
    forceRefetch: forceRefetchRequestVolunteers,
  } = useFetchRequestVolunteersForShift({
    selectedDate: selectedDate,
    skip: skip,
  });
  const {
    isLoading: isLoadingAutoHire,
    isError: isErrorAutoHire,
    autoHireInfo: dataAutoHire,
    forceRefetch: forceRefetchAutoHire,
  } = useFetchAutoHire({
    skip: skip,
    battalionId: battalionId,
  });
  const {
    isLoading: isLoadingInstantHires,
    isError: isErrorInstantHires,
    data: instantHires,
    forceRefetch: forceRefetchInstantHires,
  } = useFetchInstantHires({
    skip: skip,
    selectedDate: selectedDate,
  });

  const forceRefetchHiringEngine = useCallback(() => {
    forceRefetchRequestVolunteers();
    forceRefetchAutoHire();
    forceRefetchInstantHires();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoadingHiringEngine: isLoadingRequestVolunteers || isLoadingAutoHire || isLoadingInstantHires,
    isErrorHiringEngine: isErrorRequestVolunteers || isErrorAutoHire || isErrorInstantHires,
    requestVolunteers,
    autoHireInfo: dataAutoHire,
    instantHires,
    forceRefetchHiringEngine,
  };
};
