import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '../../../Button';

interface ShiftTradeCancelButtonsProps {
  onClose: () => void;
  onSubmit: () => void;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

export const ShiftTradeCancelButtons = ({ onClose, onSubmit, step, setStep }: ShiftTradeCancelButtonsProps) => {
  return (
    <Box
      sx={(theme) => ({
        justifyContent: 'space-between',
        display: 'flex',
        gap: theme.spacing(1.5),
        mt: theme.spacing(2),
      })}
    >
      <Button sx={{ width: '100%' }} variant="outlined" size="large" onClick={step === 1 ? () => onClose() : () => setStep(1)}>
        Cancel
      </Button>
      <Button sx={{ width: '100%' }} variant="contained" size="large" onClick={step === 1 ? () => setStep(2) : () => onSubmit()}>
        {step === 1 ? 'Submit' : 'Confirm'}
      </Button>
    </Box>
  );
};
