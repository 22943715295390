import { Box } from '@mui/material';
import { StationChanges } from '../../preview';
import { PublishingPreviewDetailDepartmentStructureBlockRow } from './PublishingPreviewDetailDepartmentStructureBlockRow';

interface PublishingPreviewDetailDepartmentStructureBlockProps {
  stationChanges: StationChanges;
}

export const PublishingPreviewDetailDepartmentStructureBlock = ({
  stationChanges,
}: PublishingPreviewDetailDepartmentStructureBlockProps) => {
  return (
    <Box>
      {(stationChanges.newStations.length != 0 ||
        stationChanges.deletedStations.length != 0 ||
        stationChanges.editedStations.length != 0) && (
        <Box>
          <Box sx={(theme) => ({ typography: 'bodyLSemibold', pt: theme.spacing(2) })}>Department structure changes:</Box>
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: 'fit-content(80%) min-content',
              alignItems: 'center',
              rowGap: theme.spacing(2),
              columnGap: theme.spacing(2),
              mt: theme.spacing(2),
              ml: theme.spacing(4),
              textWrap: 'nowrap',
            })}
          >
            {stationChanges.newStations.map((newStation) => (
              <PublishingPreviewDetailDepartmentStructureBlockRow
                key={'station_change' + newStation.id}
                badgeColor={'green'}
                badgeLabel={'New'}
                station={newStation}
              />
            ))}
            {stationChanges.deletedStations.map((deletedStation) => (
              <PublishingPreviewDetailDepartmentStructureBlockRow
                key={'station_change' + deletedStation.id}
                badgeColor={'red'}
                badgeLabel={'Deleted'}
                station={deletedStation}
              />
            ))}
            {stationChanges.editedStations.map((editedStation) => (
              <PublishingPreviewDetailDepartmentStructureBlockRow
                key={'station_change' + editedStation.id}
                badgeColor={'yellow'}
                badgeLabel={'Edited'}
                station={editedStation}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
