import { Box } from '@mui/material';
import { Button, theme } from '@stationwise/component-module';

interface SaveButtonContainerProps {
  handlePressCancel: () => void;
  handlePressSave: () => void;
}

export const SaveButtonContainer = ({ handlePressCancel, handlePressSave }: SaveButtonContainerProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        justifyContent: 'center',
        gap: theme.spacing(2),
        paddingY: theme.spacing(1.75),
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Button variant="outlined" onClick={handlePressCancel}>
        Cancel
      </Button>
      <Button variant="contained" onClick={handlePressSave}>
        Save Changes
      </Button>
    </Box>
  );
};
