import { RosterDataSource, ShiftPlanAPIData, RosterStation } from '@stationwise/share-types';

export const initializeStations = (data: ShiftPlanAPIData) => {
  const map = new Map<string, RosterStation>();
  data.stations.forEach((station) => {
    map.set(`${station.stationId}`, {
      ...station,
      stationId: `${station.stationId}`,
      dataSource: RosterDataSource.STATION,
      apparatuses: station.apparatuses.map((apparatus) => ({
        ...apparatus,
        id: `${apparatus.id}`,
        dataSource: RosterDataSource.STATION,
        positions: apparatus.positions.map((position) => ({
          ...position,
          id: `${position.id}`,
          dataSource: RosterDataSource.STATION,
          startDateTime: new Date(position.startDateTime),
          endDateTime: new Date(position.endDateTime),
          employees: [],
        })),
      })),
    });
  });
  map.set('floater-station', {
    stationId: 'floater-station',
    stationName: 'Floaters',
    dataSource: RosterDataSource.FLOATER,
    certificationRequirements: [],
    apparatuses: [
      {
        id: 'floater-apparatus',
        dataSource: RosterDataSource.FLOATER,
        positions: [],
        name: '',
        isForShiftLeader: false,
        certificationRequirements: [],
      },
    ],
  });
  return map;
};
