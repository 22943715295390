import { BlankCard } from '@stationwise/component-module';
import { EmployeeNextShiftView } from '@stationwise/share-types';
import { PositionRank } from './PositionRank';
import { ShiftHeader } from './ShiftHeader';
import { ShiftPrincipals } from './ShiftPrincipals';
import { ShiftSchedule } from './ShiftSchedule';

export const NextShiftCard = ({
  employeeNextShift,
  loggedInEmployeeId,
}: {
  employeeNextShift: EmployeeNextShiftView;
  loggedInEmployeeId?: string;
}) => {
  const { employee } = employeeNextShift;

  if (!employee) {
    return null;
  }

  return (
    <BlankCard type="passive" sxProps={{ border: 'none', padding: 2, boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)' }}>
      <ShiftHeader
        employeeName={employee.name}
        stationName={employee.nextShift.stationName}
        apparatusName={employee.nextShift.apparatusName}
        teamColor={employee.team?.color}
        shiftName={employee.nextShift.shiftName}
        shiftTitle={employee.nextShift.shiftTitle}
      />
      <ShiftSchedule
        startDate={employee.nextShift.startDate}
        endDate={employee.nextShift.endDate}
        startTime={employee.nextShift.startTime}
        endTime={employee.nextShift.endTime}
      />
      {employee.nextShift.positionRank && (
        <PositionRank
          isActing={employee.nextShift.positionRank.code !== employee.rank.code}
          positionRank={employee.nextShift.positionRank.name}
          employeeRank={employee.rank.name}
        />
      )}
      {employee.nextShift.shiftLeader && (
        <ShiftPrincipals
          shiftLeader={employee.nextShift.shiftLeader}
          captain={employee.nextShift.captain}
          captainRank={employee.nextShift.captain?.rank}
          loggedInEmployeeId={loggedInEmployeeId}
        />
      )}
    </BlankCard>
  );
};
