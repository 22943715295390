import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button, ClockFastForwardIcon24, XCloseIcon16, theme } from '@stationwise/component-module';

interface AccrualModalContentProps {
  closeAccrualWarningModal: () => void;
}

export const AccrualModalContent = ({ closeAccrualWarningModal }: AccrualModalContentProps) => {
  const navigation = useNavigate();
  const goToPayrollPage = () => {
    closeAccrualWarningModal();
    navigation('/app/my-payroll');
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: theme.spacing(1.5),
        }}
      >
        <Box
          sx={{
            width: '28px',
            height: '28px',
            borderRadius: '20px',
            background: theme.palette.stationGray[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.stationGray[500],
          }}
          onClick={closeAccrualWarningModal}
        >
          <XCloseIcon16 />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            backgroundColor: theme.palette.common.white,
            display: 'flex',
            border: `1px solid ${theme.palette.stationGray[50]}`,
            boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.1), 0px 2px 4px -1px rgba(10, 14, 22, 0.06)',
            padding: '20px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              '& svg': {
                height: '100%',
                width: '100%',
              },
            }}
          >
            <ClockFastForwardIcon24 color={theme.palette.stationPurple[500]} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: theme.spacing(5),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '0 48px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="bodyXXLSemibold" sx={{ textAlign: 'center' }}>
            Accrue your comp time
          </Typography>
          <Typography
            variant="bodyLRegular"
            sx={{
              marginTop: theme.spacing(2),
              textAlign: 'center',
              color: theme.palette.stationGray[500],
            }}
          >
            You worked overtime shift recently. Please accrue your comp time now or anytime on your{' '}
            <Typography
              component="span"
              variant="bodyLSemibold"
              sx={{
                color: theme.palette.stationPurple[500],
                cursor: 'pointer',
                textUnderlinePosition: 'under',
                textDecoration: 'underline',
              }}
              onClick={goToPayrollPage}
            >
              payroll page
            </Typography>
            .
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.spacing(6),
          width: '100%',
          padding: '0 16px',
          flexDirection: 'column',
        }}
      >
        <Button
          data-cy="dismiss-accrual-warning-button"
          variant="outlined"
          size="large"
          sx={{ width: '100%' }}
          onClick={closeAccrualWarningModal}
        >
          I will do it later
        </Button>
        <Button
          variant="contained"
          size="large"
          sx={(theme) => ({
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3),
            width: '100%',
          })}
          onClick={goToPayrollPage}
        >
          Accrue comp time now
        </Button>
      </Box>
    </>
  );
};
