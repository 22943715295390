import { Box, DialogTitle } from '@mui/material';
import { format, isValid, setMinutes } from 'date-fns';
import { FormEvent, useId, useMemo, useState } from 'react';
import { diffCycleMinutes, undeployStrikeTeamApparatus } from '@stationwise/shift-summary-helper';
import { Button } from '../../../Button';
import { InputLabel } from '../../../Input';
import { SnackbarService } from '../../../Snackbar';
import { useRosterContext } from '../RosterContext';
import { SplitTime } from '../SplitShiftOrTimeOffModal/SplitTime';

export const UndeployStrikeTeamForm = () => {
  const id = useId();
  const { selectedStrikeTeamApparatusState, shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges } =
    useRosterContext();
  const { shiftDuration } = shiftSummaryHelper;

  const [deactivationTime, setDeactivationTime] = useState<Date | null>(shiftDuration.startTime);

  const deactivationDateTime = useMemo(() => {
    if (!deactivationTime || !isValid(deactivationTime)) {
      return shiftDuration.startTime;
    }

    const minutes = diffCycleMinutes(deactivationTime, shiftDuration.startTime);
    return setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + minutes);
  }, [shiftDuration, deactivationTime]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { selectedApparatus } = selectedStrikeTeamApparatusState;
    if (!selectedApparatus) {
      return;
    }

    setShiftSummaryHelper(undeployStrikeTeamApparatus(shiftSummaryHelper, selectedApparatus, deactivationDateTime));
    setUserHasMadeChanges(true);
    selectedStrikeTeamApparatusState.resetState();
    SnackbarService.notify({
      content: 'Your event has been undeployed.',
      duration: 5000,
    });
  };

  return (
    <Box component="form" onSubmit={onSubmit}>
      <DialogTitle component="h2" sx={{ p: 0, m: 0, typography: 'bodyXLSemibold' }}>
        Undeploy event
      </DialogTitle>
      <Box
        sx={(theme) => ({
          mt: 2,
          typography: 'bodySRegular',
          '& ul': {
            display: 'inline-flex',
            flexDirection: 'column',
            listStyleType: 'disc',
            m: 0,
            p: theme.spacing(0, 0, 0, 2),
          },
          '& li': { m: theme.spacing(0.5, 0, 0, 0), textAlign: 'left' },
        })}
      >
        Select when the event will be undeployed.
        <ul>
          <li>Positions that start on or after the undeploy time will be removed.</li>
          <li>Positions that end on or before the undeploy time will not be affected.</li>
          <li>Positions that end after the undeploy time will end on the undeploy time.</li>
          <li>To undeploy the event on a different date, please go to the roster of that date.</li>
        </ul>
      </Box>
      <Box sx={{ mt: 2 }}>
        <InputLabel htmlFor={`${id}end`}>Undeploy time</InputLabel>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {format(deactivationDateTime, 'yyyy-MM-dd')}
          <SplitTime
            value={deactivationTime}
            onChange={(value) => setDeactivationTime(value)}
            slotProps={{ textField: { id: `${id}end` } }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
        <Button size="large" variant="outlined" sx={{ flex: 1 }} onClick={selectedStrikeTeamApparatusState.resetState}>
          Cancel
        </Button>
        <Button type="submit" size="large" variant="contained" sx={{ flex: 1 }}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};
