import { ReactComponent as KeyboardArrowDownSymbol } from '@material-symbols/svg-400/outlined/keyboard_arrow_down.svg';
import { Typography, IconButton, Box, Theme } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, parseISO } from 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import { client } from '@stationwise/share-api';
import { PayPeriod } from '@stationwise/share-types';
import { formatTimePeriod } from '@stationwise/share-utils';
import { ChevronLeftIcon24, ChevronRightIcon24, XCloseIcon16 } from '../../assets';
import { Button } from '../Button';
import { GenericDrawerOrModal } from '../GenericDrawerOrModal';
import { SvgIcon } from '../SvgIcon';
import { PayPeriodsGrid } from './PayPeriodsGrid';

interface PayPeriodPickerProps {
  selectedPayPeriod: PayPeriod | null;
  setSelectedPayPeriod: React.Dispatch<React.SetStateAction<PayPeriod | null>>;
  currentPayPeriod: PayPeriod | null;
  selectedPeriodIndex: number;
  setSelectedPeriodIndex: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPeriodIndex?: React.Dispatch<React.SetStateAction<number>>;
  employeeId?: string;
  disabled?: boolean;
}

export const PayPeriodPicker: React.FC<PayPeriodPickerProps> = ({
  selectedPayPeriod,
  setSelectedPayPeriod,
  currentPayPeriod,
  selectedPeriodIndex,
  setSelectedPeriodIndex,
  setCurrentPeriodIndex,
  employeeId,
  disabled,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isPayPeriodsLoaded, setIsPayPeriodsLoaded] = useState(false);
  const [payPeriods, setPayPeriods] = useState<PayPeriod[]>([]);
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [tempSelectedDate, setTempSelectedDate] = useState<string | null>(null);
  const [tempSelectedIndex, setTempSelectedIndex] = useState<number>(0);
  useEffect(() => {
    const fetchPayPeriods = async (year: number) => {
      try {
        const params = {
          startDate: `${year}-01-01`,
          endDate: `${year}-12-31`,
          ...(employeeId && { employeeId }),
        };
        const response = await client.get(`/payroll/personal/get-pay-periods/`, { params });
        const data = response.data.map((item: { startDate: string; endDate: string; id: number }) => ({
          startDate: item.startDate,
          endDate: item.endDate,
          id: item.id,
        }));
        const currentPayPeriodIndex = data.findIndex(
          (period: { startDate: string | undefined }) => period.startDate === currentPayPeriod?.startDate,
        );
        if (currentPayPeriodIndex === -1 && currentPayPeriod && currentYear === new Date().getFullYear()) {
          const insertIndex = data.findIndex(
            (period: PayPeriod) => new Date(period.startDate) > new Date(currentPayPeriod.startDate),
          );

          if (insertIndex === -1) {
            data.push(currentPayPeriod as PayPeriod);
          } else {
            data.splice(insertIndex, 0, currentPayPeriod as PayPeriod);
          }
        }

        if (setCurrentPeriodIndex) {
          setCurrentPeriodIndex(currentPayPeriodIndex === -1 ? data.length - 1 : currentPayPeriodIndex);
        }
        setPayPeriods(data);
        setIsPayPeriodsLoaded(true);
        if (!selectedPayPeriod) {
          setSelectedPayPeriod(currentPayPeriod);
        }
        const index = data.findIndex((period: { startDate: string }) => period.startDate === selectedPayPeriod?.startDate);
        setTempSelectedIndex(index !== -1 ? index : 0);
        setTempSelectedDate(data[index !== -1 ? index : 0].startDate);
        setSelectedPeriodIndex(index !== -1 ? index : 0);
      } catch (error) {
        captureException(error);
      }
    };

    fetchPayPeriods(currentYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear]);

  const handleOpen = useCallback(() => {
    if (disabled || !isPayPeriodsLoaded) {
      return;
    }
    setTempSelectedDate(payPeriods[selectedPeriodIndex].startDate);
    setTempSelectedIndex(selectedPeriodIndex);
    setDrawerOpen(true);
  }, [payPeriods, selectedPeriodIndex, disabled, isPayPeriodsLoaded]);

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleTempDateChange = (date: string) => {
    const index = payPeriods.findIndex((period) => period.startDate === date);
    setTempSelectedDate(date);
    setTempSelectedIndex(index);
  };

  const handleYearChange = (increment: number) => {
    setCurrentYear((prevYear) => prevYear + increment);
  };

  const handleReset = () => {
    const currentDate = new Date();
    const initialPeriodIndex = payPeriods.findIndex((period) => period.startDate === currentPayPeriod?.startDate);
    setTempSelectedDate(payPeriods[initialPeriodIndex !== -1 ? initialPeriodIndex : 0].startDate);
    setTempSelectedIndex(initialPeriodIndex !== -1 ? initialPeriodIndex : 0);
    setCurrentYear(currentDate.getFullYear());
  };

  const handleViewPayPeriod = useCallback(() => {
    const selectedPeriod = payPeriods[tempSelectedIndex];
    setSelectedPayPeriod(selectedPeriod);
    setSelectedPeriodIndex(tempSelectedIndex);
    setDrawerOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payPeriods, tempSelectedIndex]);

  const handlePrevPeriod = () => {
    if (selectedPeriodIndex > 0) {
      const newIndex = selectedPeriodIndex - 1;
      setSelectedPeriodIndex(newIndex);
      setTempSelectedDate(payPeriods[newIndex].startDate);
      setTempSelectedIndex(newIndex);
      setSelectedPayPeriod(payPeriods[newIndex]);
    }
  };

  const handleNextPeriod = () => {
    if (selectedPeriodIndex < payPeriods.length - 1) {
      const newIndex = selectedPeriodIndex + 1;
      setSelectedPeriodIndex(newIndex);
      setTempSelectedDate(payPeriods[newIndex].startDate);
      setTempSelectedIndex(newIndex);
      setSelectedPayPeriod(payPeriods[newIndex]);
    }
  };

  const currentMonth = format(new Date(), 'MMMM yyyy');
  const iconButtonStyles = (theme: Theme) => ({
    color: theme.palette.primary.main,
    width: 36,
    height: 36,
    p: 0,
  });

  return (
    <Box
      className="pay-period-picker"
      sx={(theme) => ({
        xs: {
          pb: theme.spacing(2),
        },
        lg: {
          pb: theme.spacing(0),
        },
      })}
    >
      <Box className="pay-period-picker-header" display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
        <IconButton
          onClick={handlePrevPeriod}
          disabled={selectedPeriodIndex <= 0 || payPeriods.length === 0}
          sx={iconButtonStyles}
        >
          <ChevronLeftIcon24 />
        </IconButton>
        <Button
          onClick={handleOpen}
          endIcon={disabled ? null : <SvgIcon component={KeyboardArrowDownSymbol} />}
          variant="contained"
          sx={(theme) => ({
            cursor: disabled || !isPayPeriodsLoaded ? 'default' : 'pointer',
            borderRadius: '9999px',
            lg: {
              ml: theme.spacing(0.5),
              mr: theme.spacing(0.5),
            },
          })}
        >
          {selectedPayPeriod ? formatTimePeriod(selectedPayPeriod.startDate, selectedPayPeriod.endDate) : ''}
        </Button>
        <IconButton
          onClick={handleNextPeriod}
          disabled={selectedPeriodIndex >= payPeriods.length - 1 || payPeriods.length === 0}
          sx={iconButtonStyles}
        >
          <ChevronRightIcon24 />
        </IconButton>
      </Box>
      <GenericDrawerOrModal
        anchor="bottom"
        drawerOpen={drawerOpen}
        handleOnClose={handleClose}
        loading={false}
        disableFooter={true}
      >
        <Box p={2} display="flex" flexDirection="column">
          <Box className="drawer-header" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography
              variant="bodyMSemibold"
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
              })}
            >
              Choose pay period start date
            </Typography>

            <IconButton onClick={handleClose}>
              <Box
                sx={(theme) => ({
                  width: '28px',
                  height: '28px',
                  borderRadius: '20px',
                  background: theme.palette.stationGray[100],
                  color: theme.palette.stationGray[500],
                })}
              >
                <XCloseIcon16 />
              </Box>
            </IconButton>
          </Box>
          <Box className="drawer-reset" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="bodySMedium" sx={(theme) => ({ color: theme.palette.stationGray[500] })}>
              {currentMonth}
            </Typography>
            <Button
              variant="outlined"
              onClick={handleReset}
              disabled={payPeriods.length > 0 && payPeriods[tempSelectedIndex].startDate === currentPayPeriod?.startDate}
            >
              Current
            </Button>
          </Box>
          <Box className="drawer-year-navigation" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <IconButton
              onClick={() => handleYearChange(-1)}
              disabled={
                currentYear <=
                payPeriods
                  .map((period) => parseISO(period.startDate).getFullYear())
                  .reduce((min, date) => (date < min ? date : min), currentYear)
              }
            >
              <ChevronLeftIcon24 />
            </IconButton>
            <Typography variant="bodyMMedium" sx={(theme) => ({ color: theme.palette.stationGray[700] })}>
              {currentYear}
            </Typography>
            <IconButton onClick={() => handleYearChange(1)} disabled={currentYear >= new Date().getFullYear()}>
              <ChevronRightIcon24 />
            </IconButton>
          </Box>

          <PayPeriodsGrid
            payPeriods={payPeriods}
            handleTempDateChange={handleTempDateChange}
            tempSelectedDate={tempSelectedDate}
            currentSelectedDate={currentPayPeriod?.startDate ?? null}
          />
          <Button fullWidth variant="contained" size="large" onClick={handleViewPayPeriod}>
            View pay period
          </Button>
        </Box>
      </GenericDrawerOrModal>
    </Box>
  );
};
