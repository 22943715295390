import { RosterDataSource, RosterStation, RosterApparatus, ShiftPlanStruct } from '@stationwise/share-types';
import { requireStation, upsertStation } from './crudStation';

export const requireApparatus = ({ station, apparatus }: ShiftPlanStruct) => {
  if (!station || !apparatus) {
    throw new Error('Station and Apparatus required on ShiftPlanStruct');
  }
  return { station, apparatus };
};

export const upsertApparatus = (
  stations: Map<string, RosterStation>,
  struct: ShiftPlanStruct,
  newData: Partial<RosterApparatus>,
) => {
  const { station } = requireStation(struct);

  let newApparatus = struct.apparatus || {
    id: `${-Date.now()}`,
    name: '',
    dataSource: RosterDataSource.STATION,
    isForShiftLeader: false,
    positions: [],
    certificationRequirements: [],
    isNewlyCreated: true,
  };
  newApparatus = { ...newApparatus, ...newData };
  newApparatus.certificationRequirements = newApparatus.certificationRequirements.filter((certReq) => certReq.amount > 0);

  const newApparatuses = [...station.apparatuses];
  const apparatusIndex = station.apparatuses.findIndex((a) => a.id === newApparatus.id);
  if (apparatusIndex >= 0) {
    newApparatuses[apparatusIndex] = newApparatus;
  } else {
    newApparatuses.push(newApparatus);
  }

  return upsertStation(stations, struct, { apparatuses: newApparatuses });
};

export const toggleApparatusMode = (stations: Map<string, RosterStation>, struct: ShiftPlanStruct) => {
  const { apparatus } = requireApparatus(struct);
  return upsertApparatus(stations, struct, { isReserved: !apparatus.isReserved });
};

export const moveApparatus = (stations: Map<string, RosterStation>, fromStruct: ShiftPlanStruct, toStruct: ShiftPlanStruct) => {
  let newStations = stations;
  newStations = removeApparatus(newStations, fromStruct);
  newStations = upsertApparatus(newStations, { station: toStruct.station, apparatus: fromStruct.apparatus }, {});
  return newStations;
};

export const removeApparatus = (stations: Map<string, RosterStation>, struct: ShiftPlanStruct) => {
  const { station, apparatus } = requireApparatus(struct);
  const newApparatuses = station.apparatuses.filter((a) => a.id !== apparatus.id);
  return upsertStation(stations, struct, { apparatuses: newApparatuses });
};
