import { Box, Theme, useMediaQuery } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MuiButton from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { SnackbarService } from './SnackbarService';

export type Severity = 'success' | 'error' | 'warning' | 'info' | 'default';

export const Snackbar = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  useEffect(() => setIsVisible(isDisplayed), [isDisplayed]);

  useEffect(() => {
    const handleSnackbarChange = () => {
      setIsDisplayed(SnackbarService.isOpen);
    };

    SnackbarService.subscribe(handleSnackbarChange);

    return () => {
      SnackbarService.unsubscribe(handleSnackbarChange);
    };
  }, []);

  return (
    <Box
      sx={(theme) => ({
        width: isMobileApp ? '90%' : 'auto',
        background: 'transparent',
        display: isDisplayed ? (isMobileApp ? 'flex' : 'block') : 'none',
        zIndex: theme.zIndex.snackbar,
        position: 'fixed',
        bottom: 0,
        left: isMobileApp ? '50%' : 60,
        transform: isMobileApp ? 'translateX(-50%)' : 'none',
        [theme.breakpoints.down('md')]: isMobileApp
          ? {}
          : {
              left: 0,
            },
        opacity: 0,
        justifyContent: 'center',
        animation: (() => {
          if (isVisible) {
            return 'slideIn 0.5s ease forwards';
          }
          return '';
        })(),
        '@keyframes slideIn': {
          to: {
            bottom: '20px',
            opacity: 1,
          },
        },
      })}
    >
      <Box
        sx={(theme) => ({
          width: isMobileApp ? '100%' : theme.spacing(61),
          margin: theme.spacing(0.625),
          borderRadius: theme.spacing(1.5),
          maxWidth: isMobileApp ? 'none' : theme.spacing(61),
          [theme.breakpoints.down('md')]: isMobileApp
            ? {}
            : {
                width: '90vw',
                maxWidth: '90vw',
              },
          minHeight: theme.spacing(7),
          height: 'fit-content',
          flexShrink: 0,
          position: 'relative',
        })}
      >
        <Alert
          sx={(theme) => ({
            height: 'fit-content',
            typography: isMobileApp ? 'bodyMRegular' : 'bodyLRegular',
            '& .MuiAlert-message': {
              height: 'fit-content',
            },
            background: SnackbarService.severity === 'default' ? theme.palette.common.white : 'auto',
            [theme.breakpoints.down('md')]: isMobileApp
              ? {}
              : {
                  maxWidth: '80vw',
                  width: theme.spacing(40),
                  px: theme.spacing(1),
                  typography: 'bodyMRegular',
                },
            '& .MuiAlert-action': {
              paddingLeft: theme.spacing(0.5),
            },
          })}
          severity={SnackbarService.severity !== 'default' ? SnackbarService.severity : 'info'}
          onClose={SnackbarService.showCloseButton ? () => SnackbarService.close() : undefined}
          // @ts-expect-error https://github.com/mui/material-ui/issues/33175
          slotProps={{ closeButton: { 'data-cy': 'snackbar-close-button' } }}
          action={
            SnackbarService.onUndo || SnackbarService.onCallToAction ? (
              <>
                {SnackbarService.onCallToAction && (
                  <MuiButton
                    sx={{
                      typography: isMobileApp ? 'bodyMSemibold' : 'bodyLSemibold',
                      padding: '4px 8px 4px 8px',
                    }}
                    color="inherit"
                    size="small"
                    onClick={SnackbarService.onCallToAction}
                  >
                    {SnackbarService.actionButtonText?.toUpperCase()}
                  </MuiButton>
                )}

                {SnackbarService.onUndo && (
                  <MuiButton
                    sx={{
                      typography: isMobileApp ? 'bodyMSemibold' : 'bodyLSemibold',
                      padding: '4px 8px 8px 0',
                    }}
                    color="inherit"
                    size="small"
                    onClick={SnackbarService.onUndo}
                  >
                    UNDO
                  </MuiButton>
                )}
              </>
            ) : undefined
          }
        >
          {SnackbarService.title && (
            <AlertTitle
              sx={{
                typography: isMobileApp ? 'bodyMSemibold' : 'bodyLSemibold',
              }}
            >
              {SnackbarService.title}
            </AlertTitle>
          )}
          {SnackbarService.content}
        </Alert>
      </Box>
    </Box>
  );
};
