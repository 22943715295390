import { Box, Modal } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { Button, DatePickerComp } from '@stationwise/component-module';
import { usePatchRequest } from './PatchRequestProvider';

interface EditCareerInfoProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  hireDate: string | null;
}

export const EditCareerInfo = ({ showModal, setShowModal, hireDate }: EditCareerInfoProps) => {
  const [newHireDate, setNewHireDate] = useState<Date | null>(hireDate ? parseISO(hireDate) : null);

  const { handlePatchRequest } = usePatchRequest();

  const handleSave = () => {
    const hireDate = newHireDate ? format(newHireDate, 'yyyy-MM-dd') : null;
    const data = {
      hireDate: hireDate,
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setNewHireDate(hireDate ? parseISO(hireDate) : null);
  };

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Career
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Hire date
          </Box>

          <Box
            sx={(theme) => ({
              width: '100%',
              mt: theme.spacing(1),
            })}
          >
            <DatePickerComp
              value={newHireDate}
              setValue={(newValue) => setNewHireDate(newValue)}
              openIconPosition="start"
              placeholder="Choose date"
              sxProps={{ border: '0px' }}
              format="MMM dd, yyyy"
            />
          </Box>

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="contained" size="large" sx={{ width: '216px' }} onClick={() => handleSave()}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
