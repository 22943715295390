import { DEFAULT_RANK, RosterDataSource } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import {
  checkIsPlannedEmployee,
  cutDuration,
  getBoardEmployeeNoteOverride,
  getDepartmentPayCodes,
  getDepartmentDetailCodes,
  getMandatoryPayCodes,
  makeAssignmentSplit,
  setEmployeeActiveId,
} from '@stationwise/shift-summary-helper';
import { getTradeSenderSplit } from '../getSplitStatuses';
import { SplitProps } from '../types';

export const cancelShiftTradeSplit = (props: SplitProps, policy: string) => {
  const { departmentInfo } = props.shiftSummaryHelper;
  let trade = props.split.reference.type === 'ASSIGNMENT' ? props.split.reference.trade : null;
  let senderSplit = getTradeSenderSplit(props);
  let receiverId = trade ? props.employee.id : null;
  if (props.split.reference.type === 'SHIFT_TRADE_REQUEST') {
    trade = { id: props.split.reference.id, requester: props.employee };
    senderSplit = props.split;
    receiverId = props.split.reference.receiver.id;
  }
  if (!trade || trade.id <= 0 || !receiverId) {
    return;
  }

  const newEmployeeSplits = new Map(props.employeeSplits);
  const receiverSplits = (newEmployeeSplits.get(receiverId) || []).filter((s) => {
    return s.reference.type === 'ASSIGNMENT' && s.reference.trade?.id === trade.id;
  });
  const newSenderSplits = (newEmployeeSplits.get(trade.requester.id) || []).filter((s) => s !== senderSplit);
  const newReceiverSplits = (newEmployeeSplits.get(receiverId) || []).filter((s) => !receiverSplits.includes(s));
  if (senderSplit && checkIsPlannedEmployee(props.shiftSummaryHelper, trade.requester)) {
    const senderNoteOverride = getBoardEmployeeNoteOverride(props.shiftSummaryHelper, trade.requester.id);
    receiverSplits.forEach((split) => {
      if (split.reference.type === 'ASSIGNMENT') {
        const position = split.reference.position && {
          ...split.reference.position,
          id: `${split.reference.position.id}`,
          rank: split.reference.position.rank || DEFAULT_RANK,
          isTemporary: false,
        };
        const sender = setEmployeeActiveId({
          ...trade.requester,
          dataSource: split.reference.apparatus ? RosterDataSource.STATION : RosterDataSource.FLOATER,
          startDateTime: split.startDateTime,
          endDateTime: split.endDateTime,
          payCodes: getDepartmentPayCodes(departmentInfo, split.reference.payCodes),
          detailCodes: getDepartmentDetailCodes(departmentInfo, split.reference.detailCodes),
          noteOverride: senderNoteOverride,
          trade: null,
        });
        sender.payCodes = getMandatoryPayCodes(props.shiftSummaryHelper, position, sender);
        newSenderSplits.push({
          ...split,
          reference: { ...split.reference, trade: null },
          employee: sender,
        });
      }
    });
    cutDuration(senderSplit, receiverSplits).forEach((duration) => {
      const newFloater = setEmployeeActiveId({
        ...trade.requester,
        dataSource: RosterDataSource.FLOATER,
        startDateTime: duration.startDateTime,
        endDateTime: duration.endDateTime,
        payCodes: getDepartmentPayCodes(departmentInfo, [trade.requester.defaults.regularAssignmentPayCode]),
        detailCodes: [],
        noteOverride: senderNoteOverride,
        trade: null,
      });
      const newFloaterSplit = makeAssignmentSplit(props.shiftSummaryHelper, null, null, null, newFloater);
      newSenderSplits.push({ ...newFloaterSplit, backup: newFloaterSplit });
    });
    newSenderSplits.sort((a, b) => differenceInUTCMinutes(a.startDateTime, b.startDateTime));
  }

  newEmployeeSplits.set(trade.requester.id, newSenderSplits);
  newEmployeeSplits.set(receiverId, newReceiverSplits);
  props.setEmployeeSplits(newEmployeeSplits);
  props.setCancelShiftTradePayloads((p) => [...p, { shiftTradeId: trade.id, tradedHoursBalancePolicy: policy }]);
};
