import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GenericDrawerOrModal, GenericResponse, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ROUTES } from '../../../../../core/Routes';
import { DenyMessage } from './DenyMessage';

type ShiftTradeResultProps = {
  isAccepted: boolean;
  name: string;
  requestId: number;
  date: string;
};

export const ShiftTradeResult = ({ isAccepted, name, requestId, date }: ShiftTradeResultProps) => {
  const [note, setNote] = useState('');
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isAdminApprovalRequired = departmentInfoState.departmentInfo.settings.shiftTradeRequiresAdminApproval;

  const dateUrl = date.split('T')[0];

  const navigate = useNavigate();

  const goToCalendar = () => {
    navigate(ROUTES.CALENDAR.fullRoute + `?date=${dateUrl}`);
  };

  if (isAccepted) {
    return (
      <GenericDrawerOrModal
        anchor="bottom"
        loading={false}
        drawerOpen={true}
        handleOnClose={() => goToCalendar()}
        footerButtons={[
          {
            label: 'Show shift in calendar',
            onClick: goToCalendar,
          },
        ]}
      >
        <GenericResponse
          firstMessage="You accepted the trade"
          secondMessage={`You will be able to see the shift in your calendar ${isAdminApprovalRequired ? 'after an admin approval' : ''}.`}
        />
      </GenericDrawerOrModal>
    );
  } else {
    return note ? (
      <GenericDrawerOrModal
        anchor="bottom"
        loading={false}
        drawerOpen={true}
        handleOnClose={() => goToCalendar()}
        footerButtons={[
          {
            label: 'Close',
            onClick: goToCalendar,
            variant: 'outlined',
          },
        ]}
      >
        <GenericResponse firstMessage="You denied the trade" secondMessage={`${name} will be notified about your denial.`} />
      </GenericDrawerOrModal>
    ) : (
      <DenyMessage name={name} setNote={setNote} requestId={requestId} />
    );
  }
};
