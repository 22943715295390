import { IconButton, IconButtonProps, useTheme } from '@mui/material';
import { XCloseIcon24 } from '../../assets';

interface DialogXButtonProps extends Omit<IconButtonProps, 'children'> {
  onClose: () => void;
}

export const DialogXButton = ({ onClose, sx, ...props }: DialogXButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.stationGray[400],
        '&:hover': {
          color: theme.palette.stationGray[700],
        },
        ...sx,
      }}
      {...props}
    >
      <XCloseIcon24 />
    </IconButton>
  );
};
