import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { client, isAxiosError } from '@stationwise/share-api';
import { ShiftSummary } from '@stationwise/share-types';

interface Props {
  selectedDate: string;
  battalionId?: number;
  skip?: boolean;
}

export const useFetchShiftSummary = ({ selectedDate, battalionId, skip = false }: Props) => {
  const [, setSearchParams] = useSearchParams();
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);
  const [data, setData] = useState<ShiftSummary | null>(null);

  useEffect(() => {
    if (skip) {
      return;
    }
    setIsLoading(true);
    setError(null);
    setData(null);

    client
      .get('/shift/summary/', {
        params: {
          shiftDate: selectedDate,
          battalionId,
        },
      })
      .then((response) => {
        setData(response.data[selectedDate]);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
      });
  }, [selectedDate, refetchCounter, skip, battalionId]);

  useEffect(() => {
    if (isLoading && error) {
      if (isAxiosError(error) && error.status === 404) {
        const today = new Date();
        if (selectedDate !== format(today, 'MM/dd/yyyy')) {
          setError(null);
          setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev);
            newParams.set('date', format(today, 'yyyy-MM-dd'));
            return newParams;
          });
          return;
        }
      }

      captureException(error);
      setIsLoading(false);
    }
  }, [isLoading, error, selectedDate, setSearchParams]);

  const forceRefetch = useCallback(() => {
    setIsLoading(true);
    setRefetchCounter((c) => c + 1);
  }, []);

  return {
    isLoading,
    isError: !!error,
    data,
    forceRefetch,
  };
};
