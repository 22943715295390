import { Theme, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ConversationRecipient } from '@stationwise/share-types';
import { randomValuesFromArray } from '@stationwise/share-utils';

const getFilters = (
  action: React.Dispatch<React.SetStateAction<string>>,
  selectedFilter: string,
  deliveredAmount: number,
  readAmount: number,
  completedAmount: number,
) => [
  {
    label: 'All',
    icon: null,
    action,
    isSelected: selectedFilter.toLocaleLowerCase() === 'all',
    amount: completedAmount + readAmount + deliveredAmount,
  },
  {
    label: 'Delivered',
    icon: null,
    action,
    isSelected: selectedFilter.toLocaleLowerCase() === 'delivered',
    amount: deliveredAmount,
  },
  {
    label: 'Read',
    icon: null,
    action,
    isSelected: selectedFilter.toLocaleLowerCase() === 'read',
    amount: readAmount,
  },
  {
    label: 'Completed',
    icon: null,
    action,
    isSelected: selectedFilter.toLocaleLowerCase() === 'completed',
    amount: completedAmount,
  },
];

const getPrompt = (type: string, amount: number, onClick: () => void) => {
  return {
    type,
    onClick,
    amount,
  };
};

const getAllPrompts = (
  deliveredAmount: number,
  readAmount: number,
  readOrCompletedAmount: number,
  onDelivered: () => void,
  onNotRead: () => void,
  onNotCompleted: () => void,
) => [
  {
    ...getPrompt('not-seeing', deliveredAmount, onNotRead),
  },
  {
    ...getPrompt('not-seeing-not-completed', readOrCompletedAmount, onDelivered),
  },
  {
    ...getPrompt('seeing-not-completed', readAmount, onNotCompleted),
  },
];

const getIdsOfType = (recipients: IRecipient[], type: string) => {
  return recipients.filter((message) => message.status === type).map((recipient) => recipient.employee.id);
};

const getDeliveredIds = (recipients: IRecipient[]) => {
  return getIdsOfType(recipients, 'delivered');
};

const getNotReadIds = (recipients: IRecipient[]) => {
  return getIdsOfType(recipients, 'read');
};

const getNotCompletedIds = (recipients: IRecipient[]) => {
  const completed = getIdsOfType(recipients, 'completed');
  return recipients.filter((recipient) => !completed.includes(recipient.employee.id)).map((recipient) => recipient.employee.id);
};

const getAllowedColors = (theme: Theme) => {
  return [
    theme.palette.stationBlue[500],
    theme.palette.stationGreen[500],
    theme.palette.stationRed[500],
    theme.palette.stationYellow[500],
    theme.palette.stationPurple[500],
    theme.palette.stationPink[500],
    theme.palette.stationOrange[500],
    theme.palette.stationTeal[500],
  ];
};

interface IRecipient extends ConversationRecipient {
  status: string;
}

const getTotalAmountOfMessages = (
  recipients: IRecipient[],
  keyToGroup: string,
  conditionFn?: (recipient: IRecipient) => boolean,
) => {
  return recipients.reduce((acc, recipient) => {
    const isConditionMet = conditionFn ? conditionFn(recipient) : false;
    if (recipient.status === keyToGroup || isConditionMet) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

const addColorAndStatusToRecipients = (recipients: ConversationRecipient[], theme: Theme) => {
  return recipients.map((recipient) => ({
    ...recipient,
    color: randomValuesFromArray(getAllowedColors(theme), 1)[0],
    status: (() => {
      if (recipient.openedAt && !recipient.isCompleted) return 'read';
      if (recipient.openedAt && recipient.isCompleted) return 'completed';
      return 'delivered';
    })(),
  }));
};

const areMissingToDoTasks = (recipient: IRecipient) => {
  return !recipient.isCompleted && recipient.status !== 'completed';
};

interface UseAudienceProps {
  recipients: ConversationRecipient[];
  // If the message is of type ToDo this should be true
  isToDo?: boolean;
  handleResend: (buttonType: string, recipients: string[]) => void;
}

export const useAudience = ({ handleResend, recipients, isToDo = false }: UseAudienceProps) => {
  const theme = useTheme();
  const localRecipients = useMemo(() => addColorAndStatusToRecipients(recipients, theme), [recipients, theme]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [searchValue, setSearchValue] = useState('');
  const [completedFilter, setCompletedFilter] = useState(getTotalAmountOfMessages(localRecipients, 'completed'));
  const [readFilter, setReadFilter] = useState(getTotalAmountOfMessages(localRecipients, 'read'));
  // This is not a filter. This will be used for the prompts on ToDo recipients
  const [readAndFinishFilter, setReadAndFinishFilter] = useState(
    getTotalAmountOfMessages(localRecipients, 'read', areMissingToDoTasks),
  );
  const [deliveredAmount, setDeliveredFilter] = useState(getTotalAmountOfMessages(localRecipients, 'delivered'));
  const [allAmount, setAllAmount] = useState(recipients.length);

  useEffect(() => {
    setSelectedFilter('all');
    setSearchValue('');
    setCompletedFilter(getTotalAmountOfMessages(localRecipients, 'completed'));
    setReadFilter(getTotalAmountOfMessages(localRecipients, 'read'));
    setDeliveredFilter(getTotalAmountOfMessages(localRecipients, 'delivered'));
    setAllAmount(localRecipients.length);
    setReadAndFinishFilter(getTotalAmountOfMessages(localRecipients, 'read', areMissingToDoTasks));
  }, [localRecipients]);

  const filters = getFilters(setSelectedFilter, selectedFilter, deliveredAmount, readFilter, completedFilter);

  const visibleRecipients = localRecipients.filter((message) => {
    if (searchValue !== '') {
      const isSearchValueIncluded =
        message.employee.firstName.toLocaleLowerCase().includes(searchValue) ||
        message.employee.lastName.toLocaleLowerCase().includes(searchValue) ||
        message.employee.email.toLocaleLowerCase().includes(searchValue);
      if (!isSearchValueIncluded) {
        return false;
      }
    }

    if (selectedFilter.toLowerCase() === 'all') {
      return true;
    }
    return message.status.toLowerCase() === selectedFilter.toLowerCase();
  });

  const allPrompts = getAllPrompts(
    deliveredAmount,
    readFilter,
    readAndFinishFilter,
    () => handleResend('not-seeing-not-completed', getNotCompletedIds(localRecipients)),
    () => handleResend('not-seeing', getDeliveredIds(localRecipients)),
    () => handleResend('seeing-not-completed', getNotReadIds(localRecipients)),
  );

  const workablePrompts =
    // Slicing first prompt because it is not a todo prompt
    (!isToDo ? allPrompts.slice(0, 1) : allPrompts).filter((prompt) => prompt.amount > 0);

  return {
    messages: visibleRecipients,
    searchValue,
    setSearchValue,
    filters: isToDo ? filters : filters.slice(0, 3),
    amountOfMessages: allAmount,
    prompts: workablePrompts || [],
  };
};
