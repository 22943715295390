import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['p', 'span', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'u', 's', 'strong', 'em', 'strike', 'br', 'div'],
  allowedAttributes: {
    a: ['href', 'target', 'style', 'rel'],
    ul: ['style'],
  },
};

const replaceSpanToU = (input: string) => {
  return input.replace(/<span style="text-decoration: underline;">(.*?)<\/span>/g, (_matched, index) => {
    return '<u>' + index + '</u>';
  });
};

function decodeHtmlEntities(text: string): string {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
}

function removeDomainIfSame(url: string): string {
  const currentDomain = window.location.origin;

  if (url.startsWith(currentDomain)) {
    return url.replace(currentDomain, '');
  }

  return url;
}

function formatInternalLink(url: string): string {
  const parsedUrl = new URL(url);
  const isStationwiseDomain =
    parsedUrl.origin.toLowerCase().includes('stationwise') || parsedUrl.origin.toLowerCase().includes('localhost');

  if (isStationwiseDomain) {
    const customText = 'Click to see details.';
    const strippedUrl = removeDomainIfSame(url);

    return `<a href="${strippedUrl}" target="_blank" rel="noopener noreferrer">
              <span>${customText}</span>
            </a>`;
  }

  return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
}

function makeUrlsClickable(text: string): string {
  const urlRegex =
    /(https?:\/\/(?:localhost(?::\d{1,5})?|(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b)(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)?)/g;
  return text.replace(urlRegex, (url) => {
    const decodedUrl = decodeHtmlEntities(url);
    return formatInternalLink(decodedUrl);
  });
}

export const sanitizeMessage = (dirty: string) => {
  return sanitizeHtml(replaceSpanToU(makeUrlsClickable(dirty)), defaultOptions);
};
