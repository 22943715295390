import { Box, Link as MUILink } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { ChevronLeftIcon24 } from '@stationwise/component-module';
import { AppPageLayout } from '../../../../AppPageLayout';
import { EmployeeProfileContent } from './EmployeeProfileContent';

export const EmployeeProfile = () => {
  const { employeeId } = useParams();

  return (
    <AppPageLayout>
      <Box>
        <Box sx={{ px: 5, pt: 4, pb: 8 }}>
          <MUILink
            component={RouterLink}
            to="/app/employees"
            underline="none"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              display: 'inline-flex',
              alignItems: 'center',
              gap: 3,
            })}
          >
            <ChevronLeftIcon24 />
            <Box component="h1" sx={{ m: 0, typography: 'heading4' }}>
              Employees
            </Box>
          </MUILink>
        </Box>
        <EmployeeProfileContent employeeId={employeeId} />
      </Box>
    </AppPageLayout>
  );
};
