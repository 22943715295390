import { Box, useTheme } from '@mui/material';
import { CircleWarningIcon20 } from '../../../../assets';
import { SplitErrorResult } from './types';

interface SplitErrorBannerProps {
  errors: Map<string, SplitErrorResult>;
}

export const SplitErrorBanner = ({ errors }: SplitErrorBannerProps) => {
  const theme = useTheme();

  const error = Array.from(errors.values()).find((e) => typeof e !== 'boolean' && !!e);
  if (!error) {
    return null;
  }

  return (
    <Box
      sx={{
        background: 'linear-gradient(0deg, #FFE4E6, #FFE4E6), linear-gradient(0deg, #FECDD3, #FECDD3)',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.stationRose[200]}`,
        display: 'flex',
        gap: 1,
        p: 2,
        mt: 2,
      }}
    >
      <Box sx={{ width: '20px', height: '20px' }}>
        <CircleWarningIcon20 />
      </Box>
      <Box sx={{ ml: 1, color: theme.palette.stationRose[700], typography: 'bodyMRegular' }}>{error}</Box>
    </Box>
  );
};
