import { Box, Modal as MuiModal } from '@mui/material';
import { ReactElement } from 'react';
import { XCloseIcon24 } from '../../assets';

export const Modal = ({
  open,
  setOpen,
  children,
  hideCloseIcon,
  disableBackdropClick,
  margin,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: ReactElement;
  hideCloseIcon?: boolean;
  disableBackdropClick?: boolean;
  margin?: string;
}) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <MuiModal
      open={open}
      onClose={disableBackdropClick ? undefined : onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: margin ? margin : '0' }}
    >
      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          borderRadius: theme.spacing(1.5),
          padding: theme.spacing(2),
          overflowX: 'hidden',
          maxHeight: 'calc(100% - 64px)',
          minWidth: '450px',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
          }}
        >
          {!hideCloseIcon && (
            <Box
              sx={(theme) => ({
                mt: '-12px',
                mr: '-12px',
                cursor: 'pointer',
                '& svg': {
                  path: {
                    stroke: theme.palette.stationGray[400],
                  },
                  float: 'right',
                },
              })}
              onClick={onClose}
            >
              <XCloseIcon24 />
            </Box>
          )}
          {children}
        </Box>
      </Box>
    </MuiModal>
  );
};
