import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Theme } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, isEqual, isAfter, isBefore, isValid, differenceInHours, parseISO, startOfDay } from 'date-fns';
import { client } from '@stationwise/share-api';
import { GetMyScheduledCalendarDataView } from '@stationwise/share-types';
import { PayPeriodDateInfo } from './Calendar';
import { SHIFT_TITLES, TEMPORARY_NON_SHIFT_TITLES, TIME_OFFS_TITLES, STATUS_TITLES, CALENDAR_EVENT_TYPES } from './constants';

//helper function to divide multiday shifts in independent daily slots
export const splitEvents = (events: GetMyScheduledCalendarDataView[], theme: Theme): EventInput[] => {
  const splittedEvents: EventInput[] = [];
  const adjustedEvents = adjustTimeOff(events);

  adjustedEvents.forEach((ev, index) => {
    if (ev.endDate && ev.startDate) {
      const start = new Date(ev.startDate + 'T' + ev.startTime);
      const end = new Date(ev.endDate + 'T' + ev.endTime);

      const intervalInHours = differenceInHours(start, end, { roundingMethod: 'ceil' });

      if (intervalInHours <= 24) {
        const mappedEvent = mapEvents([ev], theme)[0];
        splittedEvents.push(mappedEvent);
      } else {
        //multiday event
        const slots = intervalInHours / 24;

        for (let i = 0; i < slots; i++) {
          const newSlot: EventInput = {};
          const lastMapped = splittedEvents[splittedEvents.length - 1];
          newSlot.id = `${ev.id}_${index}_${i}`;

          newSlot.start = (() => {
            if (i === 0) {
              return start;
            } else if (lastMapped && lastMapped.end) {
              return lastMapped.end.toString();
            } else {
              return '';
            }
          })();

          newSlot.end = i === slots ? end : format(new Date(newSlot.start).getTime() + 60 * 60 * 24 * 1000, 'yyyy-MM-dd HH:mm');

          const eventColor = getEventBgColor(ev, theme);
          const fontColor = getEventFontColor(ev, theme);

          newSlot.backgroundColor = eventColor;
          newSlot.borderColor = eventColor;
          newSlot.textColor = fontColor;
          newSlot.title = ev.title;
          newSlot.display = 'block';
          newSlot.extendedProps = {
            eventType: ev.eventType,
            // custom prop for split shifts that start after midnight to save the original shift date
            originalShiftDate: ev.originalShiftDate ? ev.originalShiftDate : '',
          };

          splittedEvents.push(newSlot);
        }
      }
    }
  });
  return splittedEvents;
};
//transform event view model objects to event input objects for fullcalendar
export const mapEvents = (events: GetMyScheduledCalendarDataView[], theme: Theme): EventInput[] => {
  return events.map((ev) => {
    let start = new Date(ev.startDate + 'T' + ev.startTime);
    let end = new Date(ev.endDate + 'T' + ev.endTime);
    // if INCIDENT, initialize as UTC time
    if (ev.title.includes(SHIFT_TITLES.INCIDENT)) {
      start = new Date(ev.startDate + 'T' + ev.startTime + ':00Z');
      end = new Date(ev.endDate + 'T' + ev.endTime + ':00Z');
    }
    const eventColor = getEventBgColor(ev, theme);
    const fontColor = getEventFontColor(ev, theme);

    return {
      backgroundColor: eventColor,
      borderColor: eventColor,
      textColor: fontColor,
      title: ev.title,
      id: ev.id,
      display: 'block',
      start: start,
      end: end,
      status: ev.status,
      isPartial: ev.isPartial ? ev.isPartial : false,
      extendedProps: {
        eventType: ev.eventType,
        // custom prop for split shifts that start after midnight to save the original shift date
        originalShiftDate: ev.originalShiftDate ? ev.originalShiftDate : '',
      },
    };
  });
};

export const hasTemporaryNonShiftEvent = (events: EventInput[]) => {
  return events.some((event) => !!(event.extendedProps?.['eventType'] === CALENDAR_EVENT_TYPES.TEMPORARY_NON_SHIFT));
};

export const hasKellyDay = (events: EventInput[]) => {
  return events.some((event) => !!(event.extendedProps?.['eventType'] === CALENDAR_EVENT_TYPES.KELLY_DAY));
};

export const isAdditionalPaidTimeEvent = (event: EventInput | EventImpl) => {
  return event.extendedProps?.['eventType'] === CALENDAR_EVENT_TYPES.ADDITIONAL_PAID_TIME_REQUEST;
};

export const isTimeOffEvent = (event: EventInput | EventImpl) => {
  return event.extendedProps?.['eventType'] === CALENDAR_EVENT_TYPES.TIME_OFF_REQUEST;
};

const isTimeOffOnShift = (shift: GetMyScheduledCalendarDataView, timeOff: GetMyScheduledCalendarDataView): boolean => {
  const regularShiftStart = new Date(`${shift.startDate}T${shift.startTime}`);
  const regularShiftEnd = new Date(`${shift.endDate}T${shift.endTime}`);
  const timeOffEventStart = new Date(`${timeOff.startDate}T${timeOff.startTime}`);
  const timeOffEventEnd = new Date(`${timeOff.endDate}T${timeOff.endTime}`);

  return isAfter(timeOffEventEnd, regularShiftStart) && isBefore(timeOffEventStart, regularShiftEnd);
};

export const adjustTimeOff = (events: GetMyScheduledCalendarDataView[]): GetMyScheduledCalendarDataView[] => {
  let adjustedEvents = events;
  const timeOffEvents = adjustedEvents.filter(
    (event) => event.status === 'APPROVED' && event.eventType === CALENDAR_EVENT_TYPES.TIME_OFF_REQUEST,
  );
  timeOffEvents.forEach((timeOffEvent) => {
    const regularShift = adjustedEvents.find(
      (event) => event.title === SHIFT_TITLES.REGULAR && isTimeOffOnShift(event, timeOffEvent),
    );

    if (regularShift && regularShift.startTime && timeOffEvent.startTime && regularShift.endTime && timeOffEvent.endTime) {
      const regularShiftStart = new Date(`${regularShift.startDate}T${regularShift.startTime}`);
      const regularShiftEnd = new Date(`${regularShift.endDate}T${regularShift.endTime}`);
      const timeOffEventStart = new Date(`${timeOffEvent.startDate}T${timeOffEvent.startTime}`);
      const timeOffEventEnd = new Date(`${timeOffEvent.endDate}T${timeOffEvent.endTime}`);
      const shiftIndex = adjustedEvents.findIndex((ev) => ev === regularShift);
      const timeOffIndex = adjustedEvents.findIndex((ev) => ev === timeOffEvent);

      if (!isBefore(regularShiftStart, timeOffEventStart) && !isAfter(regularShiftEnd, timeOffEventEnd)) {
        // timeoff event covers the whole shift
        adjustedEvents = adjustedEvents.filter((event) => event !== regularShift);
      } else if (isEqual(regularShiftStart, timeOffEventStart) && isBefore(timeOffEventEnd, regularShiftEnd)) {
        // Partial match, time off at the beginning of the shift
        regularShift.startDate = timeOffEvent.endDate;
        regularShift.startTime = timeOffEvent.endTime;
        [adjustedEvents[shiftIndex], adjustedEvents[timeOffIndex]] = [adjustedEvents[timeOffIndex], adjustedEvents[shiftIndex]];
      } else if (isEqual(regularShiftEnd, timeOffEventEnd) && isAfter(timeOffEventStart, regularShiftStart)) {
        // Partial match, time off at the end of the shift
        regularShift.endDate = timeOffEvent.startDate;
        regularShift.endTime = timeOffEvent.startTime;
      } else {
        // Partial match, time off in the middle of the shift
        const newRegularShift: GetMyScheduledCalendarDataView = {
          id: regularShift.id,
          title: regularShift.title,
          startDate: timeOffEvent.endDate,
          startTime: timeOffEvent.endTime,
          endDate: regularShift.endDate,
          endTime: regularShift.endTime,
          eventType: regularShift.eventType,
        };
        regularShift.endDate = timeOffEvent.startDate;
        regularShift.endTime = timeOffEvent.startTime;
        adjustedEvents.splice(timeOffIndex + 1, 0, newRegularShift);
      }
    }
  });
  return adjustedEvents;
};

export const formatDate = (date: Date): string => {
  return `${format(date, 'MM')}/${format(date, 'dd')}/${format(date, 'yyyy')}`;
};

export const parseDateParam = (dateParam: string) => {
  const date = parseISO(dateParam);
  const today = startOfDay(new Date());
  if (!isValid(date)) {
    return today;
  }

  if (dateParam.length === 7) {
    // E.g. if the date param is 2024-03, and today is 2024-03-15, prefer today over 2024-03-01.
    return format(date, 'yyyy-MM') === format(today, 'yyyy-MM') ? today : date;
  }

  return date;
};

//sets colors for calendar events backgrounds
export const getEventBgColor = (event: GetMyScheduledCalendarDataView, theme: Theme) => {
  if (event.eventType === CALENDAR_EVENT_TYPES.TIME_OFF_REQUEST) {
    return event.status === STATUS_TITLES.DENIED
      ? theme.palette.stationRose[600]
      : event.status === STATUS_TITLES.APPROVED
        ? theme.palette.stationTeal[600]
        : theme.palette.stationGray[200];
  }
  if (event.title.toUpperCase().includes(SHIFT_TITLES.INCIDENT)) {
    if (event.title.toUpperCase().includes(SHIFT_TITLES.INCIDENT_PAY)) {
      return theme.palette.stationBlue[300];
    }
    return event.isActive ? theme.palette.stationGreen[500] : theme.palette.stationRed[400];
  }
  if (event.eventType === CALENDAR_EVENT_TYPES.STAFFING_LIST_MARK) {
    return event.title.includes('SIGN UP') ? theme.palette.stationPurple[200] : theme.palette.stationYellow[200];
  }
  switch (event.title.toUpperCase()) {
    case SHIFT_TITLES.REGULAR:
      return theme.palette.stationBlue[500];
    case SHIFT_TITLES.SHIFT_TRADE:
      return theme.palette.stationBlue[500];
    case SHIFT_TITLES.OFF_TRADE:
      return theme.palette.stationGray[200];
    case SHIFT_TITLES.TRADE_REQUESTED:
      return theme.palette.stationLightBlue[200];
    case SHIFT_TITLES.OVERTIME:
      return theme.palette.stationPink[500];
    case SHIFT_TITLES.INCIDENT_PAY:
      return theme.palette.stationBlue[300];
    case SHIFT_TITLES.ADDITIONAL_PAID_TIME:
      return event.status && event.status === STATUS_TITLES.APPROVED
        ? theme.palette.stationTeal[600]
        : theme.palette.stationRose[600];
    case SHIFT_TITLES.ADDITIONAL_PAID_TIME_REQUEST:
      return theme.palette.stationTeal[100];
    case TEMPORARY_NON_SHIFT_TITLES.LIGHT_DUTY:
      return event.status === STATUS_TITLES.ACTIVE ? theme.palette.stationGray[900] : theme.palette.stationGray[200];
    case TEMPORARY_NON_SHIFT_TITLES.EXTENDED_LEAVE:
      return theme.palette.stationGray[900];
    case SHIFT_TITLES.KELLY_DAY:
      return theme.palette.stationGray[400];
    case SHIFT_TITLES.INCIDENT:
      return event.isActive ? theme.palette.stationGreen[500] : theme.palette.stationRed[400];
    default:
      return theme.palette.stationGray[400];
  }
};

// every event except vacation is disabled for select
export const isEventSelectDisabled = (eventTitle: string) => {
  return eventTitle !== TIME_OFFS_TITLES.VACATION;
};

//sets colors for calendar events fonts
export const getEventFontColor = (event: GetMyScheduledCalendarDataView, theme: Theme) => {
  if (event.eventType === CALENDAR_EVENT_TYPES.TIME_OFF_REQUEST) {
    return event.status === STATUS_TITLES.APPROVED || event.status === STATUS_TITLES.DENIED
      ? theme.palette.common.white
      : theme.palette.stationGray[700];
  }
  if (event.eventType === CALENDAR_EVENT_TYPES.STAFFING_LIST_MARK) {
    return event.title.includes('SIGN UP') ? theme.palette.stationPurple[600] : theme.palette.stationYellow[700];
  }

  switch (event.title.toUpperCase()) {
    case SHIFT_TITLES.TRADE_REQUESTED:
      return theme.palette.stationLightBlue[700];
    case SHIFT_TITLES.OFF_TRADE:
      return theme.palette.stationGray[700];
    case SHIFT_TITLES.ADDITIONAL_PAID_TIME_REQUEST:
      return theme.palette.stationTeal[700];
    case TEMPORARY_NON_SHIFT_TITLES.LIGHT_DUTY:
      return event.status === STATUS_TITLES.ACTIVE ? theme.palette.common.white : theme.palette.stationGray[700];
    case TEMPORARY_NON_SHIFT_TITLES.EXTENDED_LEAVE:
      return theme.palette.common.white;
    case SHIFT_TITLES.KELLY_DAY:
      return theme.palette.stationGray[700];
    default:
      return theme.palette.common.white;
  }
};

export const isPastDate = (date: Date) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return date.getTime() < currentDate.getTime();
};

export const isFutureDate = (date: Date) => {
  const currentDate = new Date();
  currentDate.setHours(23, 59, 59, 999);
  return date.getTime() > currentDate.getTime();
};

export const fetchEvents = async (params: {
  startDate: string;
  endDate: string;
  employeeId?: string;
}): Promise<GetMyScheduledCalendarDataView[]> => {
  try {
    const response = await client.get('/employee/scheduled-calendar-data/', { params });
    return response.data;
  } catch (err) {
    captureException(err);
    return [];
  }
};

export const fetchPayPeriods = async (params: {
  startDate: Date;
  endDate: Date;
  employeeId?: string;
}): Promise<PayPeriodDateInfo[]> => {
  const formattedStartDate = format(params.startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(params.endDate, 'yyyy-MM-dd');

  try {
    const response = await client.get('/payroll/personal/get-pay-periods/', {
      params: {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        employeeId: params.employeeId,
      },
    });

    return response.data.map((period: { startDate: string; endDate: string }) => ({
      startDate: period.startDate,
      endDate: period.endDate,
    }));
  } catch (error) {
    captureException(error);
    return [];
  }
};
