import { ReactComponent as CloseSymbol } from '@material-symbols/svg-400/outlined/close.svg';
import { Box, List, Popover, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useId, useMemo } from 'react';
import { Certification, Rank, StaffingListItem, Team, Station } from '@stationwise/share-types';
import { getCertColors } from '../../../../../utils/colors';
import { Button } from '../../../../Button';
import { SvgIcon } from '../../../../SvgIcon';
import { StaffingListFilterState, StaffingListFilterGroup } from './StaffingListFilterGroup';

interface StaffingListFilterPopoverProps {
  items: StaffingListItem[];
  filterPopoverAnchorEl: HTMLElement | null;
  setFilterPopoverAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  filterStates: StaffingListFilterState[];
}

export const StaffingListFilterPopover = (props: StaffingListFilterPopoverProps) => {
  const id = useId();
  const theme = useTheme();

  const [rankFilterState, certFilterState, teamFilterState, stationFilterState] = props.filterStates;

  const rankOptions = useMemo(() => {
    const options = new Map<string, Rank>();
    props.items.forEach((item) => options.set(item.employee.rank.name, item.employee.rank));
    return Array.from(options.values()).sort((a, b) => a.sortOrder - b.sortOrder);
  }, [props.items]);

  const certOptions = useMemo(() => {
    const options = new Map<string, Certification>();
    props.items.forEach((item) => item.employee.certifications.forEach((c) => options.set(c.code, c)));
    return Array.from(options.values()).sort((a, b) => a.code.localeCompare(b.code));
  }, [props.items]);

  const teamOptions = useMemo(() => {
    const options = new Map<string, Team>();
    props.items.forEach((item) => item.employee.team && options.set(item.employee.team.name, item.employee.team));
    return Array.from(options.values()).sort((a, b) => a.name.localeCompare(b.name));
  }, [props.items]);

  const stationOptions = useMemo(() => {
    const options = new Map<string, Station>();
    props.items.forEach(
      (item) =>
        item.employee.station && item.employee.station.name && options.set(item.employee.station.name, item.employee.station),
    );
    return Array.from(options.values()).sort((a, b) => a.name.localeCompare(b.name));
  }, [props.items]);

  return (
    <Popover
      id={`${id}filter`}
      open={!!props.filterPopoverAnchorEl}
      anchorEl={props.filterPopoverAnchorEl}
      onClose={() => props.setFilterPopoverAnchorEl(null)}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: -8 }}
      sx={{ '& .MuiPaper-root': { width: 304, height: 548, pb: 9 } }}
    >
      <Box sx={{ height: '100%', position: 'relative', overflowY: 'auto', p: 2 }}>
        <Button
          color="inherit"
          size="small"
          onClick={() => props.setFilterPopoverAnchorEl(null)}
          startIcon={<SvgIcon component={CloseSymbol} />}
          sx={{ typography: 'buttonM', lineHeight: '18px' }}
        >
          Close
        </Button>
        <List component="div" disablePadding={true}>
          <StaffingListFilterGroup
            {...rankFilterState}
            title="Rank"
            options={rankOptions}
            getOptionKey={(option) => option.name}
            getOptionLabel={(option) => option.name}
          />
          <StaffingListFilterGroup
            {...certFilterState}
            title="Certifications"
            options={certOptions}
            optionsSx={{ flexDirection: 'row', flexWrap: 'wrap', '& .MuiFormControlLabel-root': { minWidth: '33.33%' } }}
            getOptionKey={(option) => option.code}
            getOptionLabel={(option) => (
              <Box component="span" sx={{ color: getCertColors({ theme, color: option.color }).color }}>
                {option.code}
              </Box>
            )}
          />
          <StaffingListFilterGroup
            {...teamFilterState}
            title="Shift"
            options={teamOptions}
            getOptionKey={(option) => option.name}
            getOptionLabel={(option) => option.name}
          />

          <StaffingListFilterGroup
            {...stationFilterState}
            title="Home station"
            options={stationOptions}
            getOptionKey={(option) => option.name}
            getOptionLabel={(option) => option.name}
          />
        </List>
      </Box>
      <Box
        sx={{
          background: theme.palette.common.white,
          borderTop: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          p: 2,
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
        }}
      >
        <Button
          data-cy="clear-staffing-list-filters-button"
          size="large"
          disabled={props.filterStates.every((state) => !state.selectedOptions.size)}
          startIcon={<SvgIcon component={CloseSymbol} />}
          onClick={() => props.filterStates.forEach((state) => state.setSelectedOptions(new Set()))}
        >
          Clear all
        </Button>
        <Button
          data-cy="apply-staffing-list-filters-button"
          variant="contained"
          size="large"
          disabled={props.filterStates.every((state) => !state.isDirty)}
          onClick={() => {
            props.setFilterPopoverAnchorEl(null);
            props.filterStates.forEach((state) => state.setAppliedOptions(state.selectedOptions));
          }}
        >
          Filter
        </Button>
      </Box>
    </Popover>
  );
};
