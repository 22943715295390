import { ReactComponent as MoreHorizSymbol } from '@material-symbols/svg-400/outlined/more_horiz.svg';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Button } from '../../Button';
import { SvgIcon } from '../../SvgIcon';

interface MoreButtonProps {
  disabled?: boolean;
  setRequestMultipleDaysOff?: (value: boolean) => void;
}

export const MoreButton = ({ disabled = false, setRequestMultipleDaysOff }: MoreButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={disabled}
        sx={{ width: '36px', height: '36px', minWidth: 0, minHeight: 0, p: 0 }}
        variant="outlined"
        onClick={handleClick}
      >
        <SvgIcon component={MoreHorizSymbol} sx={{ fontSize: '20px' }} />
      </Button>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={(event) => {
            setRequestMultipleDaysOff && setRequestMultipleDaysOff(true);
            handleClose(event);
          }}
        >
          Request multiple days off
        </MenuItem>
      </Menu>
    </>
  );
};
