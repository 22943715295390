import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { FormEvent, useState } from 'react';
import { Link, To } from 'react-router-dom';
import { client, isAxiosError } from '@stationwise/share-api';
import { ArrowLeftIcon20, Lock01Icon20 } from '../../../assets';
import { useDocumentTitle, useNextURLQueryParam } from '../../../hooks';
import { Button } from '../../Button';
import { ErrorBanner } from '../../ErrorBanner';
import { Input } from '../../Input';
import { MailSentPage } from './MailSentPage';

interface EnterEmailPageProps {
  redirectBackTo: To;
}

export const EnterEmailPage = ({ redirectBackTo }: EnterEmailPageProps) => {
  useDocumentTitle('Forgot Password?');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const nextUrl = useNextURLQueryParam();

  const setAnError = (message: string) => {
    setErrorMessage(message);
    setEmail('');
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    try {
      await client.post('/auth/login/reset-password/send-email/', { email: email, next: nextUrl });
      setIsSubmitted(true);
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      if (!message) {
        captureException(err);
      }
      setAnError(message || 'Something went wrong please try again later');
    }
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handleSubmit();
  };

  if (isSubmitted) {
    return <MailSentPage email={email} />;
  }

  return (
    <Box
      sx={(theme) => ({
        mt: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
          mt: theme.spacing(4),
        },
      })}
    >
      <Box
        component="form"
        onSubmit={handleFormSubmit}
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
        })}
      >
        <Box
          sx={(theme) => ({
            mx: 'auto',
            maxWidth: '368px',
            px: theme.spacing(2),
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
              width: 368,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              mb: 0.5,
              flexShrink: 0,
              mx: 'auto',
              width: 48,
              height: 48,
              borderRadius: '50%',
              border: theme.palette.stationGray[50],
              background: theme.palette.common.white,
              boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.10)',
              color: theme.palette.stationBlue[600],
              [theme.breakpoints.up('sm')]: {
                mb: 6,
                width: 80,
                height: 80,
              },
              '& svg': {
                width: 24,
                height: 24,
                [theme.breakpoints.up('sm')]: {
                  width: 40,
                  height: 40,
                },
                '& path': {
                  stroke: theme.palette.stationBlue[600],
                },
              },
            })}
          >
            <Lock01Icon20 />
          </Box>
          <Box
            component="h1"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              mt: 5,
              mb: 4,
              typography: 'heading4',
              textAlign: 'center',
              [theme.breakpoints.up('sm')]: {
                fontSize: '32px',
                mb: 6,
              },
            })}
          >
            Reset your password
          </Box>
          <Box
            component="p"
            sx={(theme) => ({
              color: theme.palette.stationGray[600],
              mt: 3,
              mb: 0.5,
              typography: 'bodyMRegular',
              textAlign: 'center',
            })}
          >
            Enter email address associated with your account and we will send you reset instructions.
          </Box>
          {!isSubmitting && !!errorMessage && <ErrorBanner sx={{ mt: 1, mb: -2 }}>{errorMessage}</ErrorBanner>}
          <Box sx={{ mb: 0.5, mt: 4.5 }}>
            <Input
              disabled={isSubmitting}
              error={!!errorMessage}
              label="Email"
              type={'email'}
              value={email}
              placeholder="you@example.gov"
              onChange={(event) => {
                setEmail(event.currentTarget.value);
                setErrorMessage('');
              }}
            />
          </Box>
          <Box
            sx={(theme) => ({
              my: theme.spacing(5.5),
              [theme.breakpoints.down('md')]: {
                mb: theme.spacing(2),
              },
            })}
          >
            <Button
              variant="contained"
              size="large"
              type="submit"
              disabled={email.length === 0}
              loading={isSubmitting}
              sx={{ width: '100%' }}
            >
              Continue
            </Button>
          </Box>
          <Box>
            <Button size="large" component={Link} to={redirectBackTo} startIcon={<ArrowLeftIcon20 />} sx={{ width: '100%' }}>
              Back to log in
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
