import { Autocomplete, Box, Dialog, FormControl, FormHelperText, TextField } from '@mui/material';
import { useState } from 'react';
import { Button, SnackbarService, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { DetailCode } from '@stationwise/share-types';

interface DetailCodeModalProps {
  payCodes: string[];
  onSaveSuccess: () => void;
  selectedDetailCode?: DetailCode;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
}

const newDetailCode: DetailCode = {
  id: -1,
  name: '',
  code: '',
  payCodeType: '',
};

export const DetailCodeModal = ({
  payCodes,
  onSaveSuccess,
  selectedDetailCode,
  setShowModal,
  showModal,
}: DetailCodeModalProps) => {
  const {
    state: { departmentInfo },
    dispatch,
  } = useLoadedDepartmentInfoContext();
  const existingDetailCodes = departmentInfo.detailCodes
    .map((detailCode) => detailCode.code.toLowerCase())
    .filter((detailCode) => detailCode !== selectedDetailCode?.code.toLowerCase());

  const title = selectedDetailCode ? 'Edit Detail Code Details' : 'Create New Detail Code';
  const [detailCode, setDetailCode] = useState<DetailCode>(selectedDetailCode ? selectedDetailCode : newDetailCode);
  const [error, setError] = useState({ duplicateCode: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const disabled =
    !detailCode.name || !detailCode.code || !detailCode.payCodeType || Object.values(error).some((e) => e) || isSubmitting;

  const handleClose = () => {
    setIsSubmitting(false);
    setShowModal(false);
    setDetailCode(newDetailCode);
  };

  const handleSuccess = () => {
    handleClose();
    onSaveSuccess();
    dispatch({ type: 'REFETCH_DEPARTMENT_INFO' });
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    if (selectedDetailCode) {
      //  UPDATE
      try {
        const response = await client.put(`/payroll/detail-code/${detailCode.id}/`, detailCode);
        SnackbarService.notify({
          content: `Successfully updated detail code: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save detail code: ${isAxiosError(error) ? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    } else {
      // CREATE
      try {
        const response = await client.post('/payroll/detail-code/', detailCode);
        SnackbarService.notify({
          content: `Successfully added detail code: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save new detail code: ${isAxiosError(error) ? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    }
  };

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
            '.MuiFormControl-root': {
              mb: 4,
              '.MuiInputLabel-root': {
                top: '-5px',
              },
            },
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              mb: 4,
            }}
          >
            {title}
          </Box>

          <TextField
            label="Name"
            onChange={(event) => {
              setDetailCode({ ...detailCode, name: event.currentTarget.value });
            }}
            required
            value={detailCode.name}
          />

          <TextField
            error={error.duplicateCode}
            label="Code"
            helperText={error.duplicateCode ? 'This code already exists.' : ''}
            onChange={(event) => {
              setDetailCode({ ...detailCode, code: event.currentTarget.value });
              if (existingDetailCodes.includes(event.currentTarget.value.toLowerCase())) {
                setError({ ...error, duplicateCode: true });
              } else if (error.duplicateCode) {
                setError({ ...error, duplicateCode: false });
              }
            }}
            required
            value={detailCode.code}
          />
          {payCodes?.length > 0 && (
            <FormControl>
              <Autocomplete
                value={detailCode.payCodeType}
                options={payCodes}
                onChange={(_event, value) =>
                  setDetailCode({
                    ...detailCode,
                    payCodeType: value ?? '',
                  })
                }
                renderInput={(params) => <TextField {...params} label="Pay Code Type" placeholder={'Select Pay Code Type'} />}
                sx={{
                  '.MuiFormControl-root': {
                    mb: 0,
                    '.MuiInputLabel-root:not(.Mui-focused)': {
                      top: 0,
                    },
                  },
                }}
              />
              <FormHelperText>Please select related pay code type</FormHelperText>
            </FormControl>
          )}

          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              width: '100%',
            }}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ width: '216px' }}
              onClick={() => handleSave()}
              disabled={disabled}
              loading={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
