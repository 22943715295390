import { Box } from '@mui/material';
import { useState } from 'react';
import {
  useFetchNextShift,
  useRequests,
  Requests,
  RequestsFilterChips,
  SuccessIcon,
  ToDoListIcon16,
  XCircleIcon20,
  REQUEST_CARD_STATUS,
} from '@stationwise/component-module';

const chipFiltersRequests: RequestsFilterChips[] = [
  {
    label: 'Unresolved',
    icon: () => (
      <Box
        sx={{
          mt: '8px',
        }}
      >
        <ToDoListIcon16 />
      </Box>
    ),
    filterName: REQUEST_CARD_STATUS.PENDING,
  },
  {
    label: 'Approved',
    icon: SuccessIcon,
    filterName: REQUEST_CARD_STATUS.APPROVED,
  },
  {
    label: 'Denied',
    icon: XCircleIcon20,
    filterName: REQUEST_CARD_STATUS.DENIED,
  },
];

export const RequestsPanel = () => {
  const [refetchCounter] = useState(0);
  const requests = useRequests(refetchCounter);
  const nextShiftFetcher = useFetchNextShift({ excludeToday: true });
  const [, setRefetchEvents] = useState<boolean>(false);
  return (
    <Box sx={(theme) => ({ background: theme.palette.stationGray[50], flex: 1 })}>
      <Requests
        filterChips={chipFiltersRequests}
        requests={requests}
        nextShiftFetcher={nextShiftFetcher}
        setRefetchEvents={setRefetchEvents}
      />
    </Box>
  );
};
