import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import {
  GenericDrawerOrModal,
  InfoCircle20,
  Mail02Icon24,
  PhoneSmsIcon24,
  SnackbarService,
  useLoadedAuthUserContext,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { CustomToggleItem } from './CustomToggleItem';
import { NotificationCategory, warningMessage } from './utils';

interface NotificationDrawerProps {
  checkedEmail: boolean;
  checkedSms: boolean;
  clickedNotificationType?: NotificationCategory;
  fetchUserNotificationSettings: () => void;
  isDrawerOpen: boolean;
  isNotificationTypeLoading: boolean;
  selectedDepartmentEmailSetting: boolean;
  selectedDepartmentSmsSetting: boolean;
  setCheckedSms: (value: boolean) => void;
  setCheckedEmail: (value: boolean) => void;
  setIsDrawerOpen: (value: boolean) => void;
}

export const NotificationDrawer = ({
  checkedEmail,
  checkedSms,
  clickedNotificationType,
  fetchUserNotificationSettings,
  isDrawerOpen,
  isNotificationTypeLoading,
  selectedDepartmentEmailSetting,
  selectedDepartmentSmsSetting,
  setCheckedSms,
  setCheckedEmail,
  setIsDrawerOpen,
}: NotificationDrawerProps) => {
  const handleSmsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedSms(event.target.checked);
  };
  const handleMailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedEmail(event.target.checked);
  };
  const handleOnClose = () => {
    setIsDrawerOpen(false);
  };

  const { state: userState } = useLoadedAuthUserContext();
  const id: string = userState.employee.id;

  const onSubmitChanges = async () => {
    try {
      setIsDrawerOpen(false);
      if (clickedNotificationType && Object.values(NotificationCategory).includes(clickedNotificationType)) {
        const params = {
          email: checkedEmail,
          sms: checkedSms,
          notificationType: clickedNotificationType,
        };
        await client.put('/notification/notification-preference/' + id + '/', params);
        SnackbarService.notify({
          content: 'Your notification preferences have been updated successfully.',
          severity: 'success',
          duration: 3000,
        });
        fetchUserNotificationSettings();
      }
    } catch (error) {
      SnackbarService.notify({
        content: 'An error occurred while trying to change your notification preferences. Please try again later.',
        severity: 'error',
        duration: 5000,
      });
      captureException(error);
    } finally {
      setIsDrawerOpen(false);
    }
  };

  return (
    <GenericDrawerOrModal
      anchor={'bottom'}
      drawerOpen={isDrawerOpen}
      handleOnClose={handleOnClose}
      loading={isNotificationTypeLoading}
      headerTitle={'How do you want to be notified?'}
      showHeader={true}
      disableFooter={false}
      footerButtons={[
        {
          label: 'Save Notification Changes',
          onClick: onSubmitChanges,
          disabled: selectedDepartmentSmsSetting && selectedDepartmentEmailSetting,
        },
      ]}
      sxProps={{
        '.MuiDrawer-paper': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          px: 0.5,
        },
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
          padding: theme.spacing(2),
          flexDirection: 'column',
          gap: theme.spacing(4),
        })}
      >
        {/* should we implement a copy of this for department notification settings if user is admin*/}
        <CustomToggleItem
          name={'SMS'}
          onChange={handleSmsChange}
          checked={checkedSms}
          icon={<PhoneSmsIcon24 />}
          departmentSetting={selectedDepartmentSmsSetting}
        />
        {/*Not implemented yet!!!*/
        /*<CustomToggleItem
                      name={'Push notifications'}
                      checked={checkedSms}
                      onChange={handleSmsChange}
                      icon={<PhoneNotificationIcon24 />}
                                />*/}
        <CustomToggleItem
          name={'Email'}
          onChange={handleMailChange}
          checked={checkedEmail}
          icon={
            <Box sx={(theme) => ({ color: theme.palette.stationPurple[500] })}>
              <Mail02Icon24 />
            </Box>
          }
          departmentSetting={selectedDepartmentEmailSetting}
        />
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.stationGray[200],
            height: '1px',
            width: '100%',
            alignSelf: 'stretch',
          })}
        ></Box>
        {(selectedDepartmentSmsSetting || selectedDepartmentEmailSetting) && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'flex-start',
              gap: theme.spacing(1),
              alignSelf: 'stretch',
            })}
          >
            <InfoCircle20 />
            <Box
              sx={(theme) => ({
                flex: '1 1 0',
                color: theme.palette.stationGray[500],
                typography: 'bodySRegular',
              })}
            >
              {warningMessage(selectedDepartmentSmsSetting, selectedDepartmentEmailSetting)}
            </Box>
          </Box>
        )}
      </Box>
    </GenericDrawerOrModal>
  );
};
