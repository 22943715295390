import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { UsersXIcon20, useDateQueryParam, SelectButton, StationsSelect, DatePickerComp } from '@stationwise/component-module';
import { RosterEmployee, RosterStation, RosterApparatus } from '@stationwise/share-types';
import { EmployeesOffDrawer } from '../EmployeesOffDrawer';
import { ResponsibilityDrawer } from '../ResponsibilityDrawer';
import { ShiftDetails } from './ShiftDetails';

interface ShiftEmployeeDetails {
  employee: RosterEmployee;
  station?: RosterStation;
  apparatus?: RosterApparatus;
}

interface TopBarProps {
  shiftDateRange: string;
  shiftLeaderDetails: ShiftEmployeeDetails | null;
  currentEmployeeDetails: ShiftEmployeeDetails | null;
}

const CustomBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display="flex"
      width="100%"
      sx={(theme) => ({
        flexDirection: 'row',
        justifyContent: { xs: 'space-between', lg: 'start' },
        alignItems: 'center',
        background: theme.palette.common.white,
        borderBottom: {
          xs: `1px dashed ${theme.palette.stationGray[100]}`,
          md: 'none',
        },
        padding: '8px 0px 8px 0px',
      })}
    >
      {children}
    </Box>
  );
};

export const TopBar = ({ shiftDateRange, shiftLeaderDetails, currentEmployeeDetails }: TopBarProps) => {
  const [isEmployeesOffDrawerOpen, setIsEmployeesOffDrawerOpen] = useState(false);
  const [isResponsibilityOpen, setIsResponsibilityOpen] = useState(false);
  const { selectedDateAsDate, onDateChange } = useDateQueryParam();

  return (
    <Box>
      <CustomBox>
        <Box
          sx={{
            alignSelf: 'center',
            display: 'flex',
          }}
        >
          <StationsSelect />
        </Box>
        <IconButton
          color="inherit"
          aria-label="open off roster drawer"
          onClick={() => setIsEmployeesOffDrawerOpen((prev) => !prev)}
          sx={{ width: '40px' }}
        >
          <UsersXIcon20 />
        </IconButton>
      </CustomBox>
      <CustomBox>
        <Box
          sx={(theme) => ({
            alignItems: 'center',
            display: 'flex',
            gap: '6px',
            paddingLeft: theme.spacing(2),
            width: '100%',
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              gap: theme.spacing(2),
            })}
          >
            <DatePickerComp sxProps={{ border: 0, width: 180 }} value={selectedDateAsDate} setValue={onDateChange} />
            <SelectButton
              sx={{ minHeight: '43px', typography: 'bodyMRegular' }}
              onClick={() => setIsResponsibilityOpen(true)}
              caret={isResponsibilityOpen}
            >
              Responsibilities
            </SelectButton>
          </Box>
        </Box>
      </CustomBox>
      <EmployeesOffDrawer open={isEmployeesOffDrawerOpen} onClose={() => setIsEmployeesOffDrawerOpen(false)} />
      <Box>
        {shiftLeaderDetails && shiftLeaderDetails.employee.id !== currentEmployeeDetails?.employee.id && (
          <ShiftDetails shiftDateRange={shiftDateRange} {...shiftLeaderDetails} />
        )}
        {currentEmployeeDetails && <ShiftDetails shiftDateRange={shiftDateRange} {...currentEmployeeDetails} />}
      </Box>
      <ResponsibilityDrawer isResponsibilityOpen={isResponsibilityOpen} setIsResponsibilityOpen={setIsResponsibilityOpen} />
    </Box>
  );
};
