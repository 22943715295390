import { Box, useTheme } from '@mui/material';
import { useState } from 'react';
import {
  Apparatus,
  EmployeePreview,
  Popover,
  POPOVER_POSITION,
  Position,
  Station,
  PayAndDetailCodes,
  EmptyCard,
  Actions,
} from '@stationwise/component-module';
import { RosterApparatus, RosterEmployee, RosterPosition, RosterStation } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { useShiftTemplateContext } from '../contexts/ShiftTemplateContext';
import { AddNewObjectPlaceholder } from './AddNewObjectPlaceholder';
import { ApparatusDialog } from './Apparatus/ApparatusDialog';
import { MoveApparatusModal } from './Apparatus/MoveApparatusModal';
import { ShiftTemplateAssignmentModal } from './Assignment/ShiftTemplateAssignmentModal';
import { CertificationRequirementChip } from './Certification/CertificationRequirementChip';
import { PositionDialog } from './Position/PositionDialog';
import { ShiftTemplateActions } from './ShiftTemplateActions';
import { ShiftTemplateWarningModal } from './ShiftTemplateWarningModal';

export const ShiftTemplateBoard = () => {
  const {
    shiftTemplateHelper,
    unassignEmployeeFromPosition,
    error,
    errorWarningModalOpen,
    setErrorWarningModalOpen,
    handleDetailCodeChange,
    handlePayCodeChange,
    setUnsavedUnassignedEmployees,
    handleToggleApparatusMode,
    moveApparatus,
    setIsStationDialogOpen,
    setSelectedStation,
    selectedStation,
    stationWarningModalOpen,
    setStationWarningModalOpen,
    removeStation,
    removeApparatus,
    removePosition,
  } = useShiftTemplateContext();
  const [assignmentModalOpen, setAssignmentModalOpen] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<RosterPosition | null>(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState<HTMLElement | null>(null);
  const [employeePreviewIsOpen, setEmployeePreviewIsOpen] = useState<boolean>(false);
  const [activeEmployee, setActiveEmployee] = useState<RosterEmployee | null>(null);

  const [selectedApparatus, setSelectedApparatus] = useState<RosterApparatus | null>(null);

  const [reserveApparatusModalOpen, setReserveApparatusModalOpen] = useState<boolean>(false);
  const [selectedApparatusToReserve, setSelectedApparatusToReserve] = useState<RosterApparatus | null>(null);

  const [moveApparatusModalOpen, setMoveApparatusModalOpen] = useState<boolean>(false);
  const [selectedApparatusToMove, setSelectedApparatusToMove] = useState<{
    apparatus: RosterApparatus;
    originalStation: RosterStation;
    availableStations: RosterStation[];
  } | null>(null);

  const [isApparatusDialogOpen, setIsApparatusDialogOpen] = useState(false);
  const [apparatusWarningModalOpen, setApparatusWarningModalOpen] = useState(false);

  const [isPositionDialogOpen, setIsPositionDialogOpen] = useState(false);
  const [positionWarningModalOpen, setPositionWarningModalOpen] = useState(false);
  const [editPositionWarningModalOpen, setEditPositionWarningModalOpen] = useState<boolean>(false);

  const theme = useTheme();

  const onClickPosition = (
    position: RosterPosition,
    apparatus: RosterApparatus,
    anchorEl: HTMLElement | null,
    employee?: RosterEmployee,
  ) => {
    setSelectedApparatus(apparatus);
    setSelectedPosition(position);

    if (employee) {
      if (!employee.activeId || !anchorEl) {
        return;
      }
      setActiveEmployee(employee);
      setSelectedAnchorEl(anchorEl);
      setEmployeePreviewIsOpen(true);
    } else {
      setAssignmentModalOpen(true);
    }
  };

  const onUnassignClick = () => {
    if (selectedApparatus && selectedPosition && activeEmployee) {
      unassignEmployeeFromPosition(selectedApparatus, selectedPosition, activeEmployee);
      setUnsavedUnassignedEmployees((prev) => [...prev, activeEmployee]);
    }
    closePopover();
    resetSelected();
  };

  const closePopover = () => {
    setEmployeePreviewIsOpen(false);
    setSelectedAnchorEl(null);
    resetSelected();
  };

  const resetSelected = () => {
    setSelectedPosition(null);
    setActiveEmployee(null);
    setSelectedApparatus(null);
  };

  const addExcessCapacity = (apparatus: RosterApparatus) => {
    setSelectedApparatus(apparatus);
    setAssignmentModalOpen(true);
  };

  const handleMoveApparatus = (apparatus: RosterApparatus, originalStation: RosterStation, stations: RosterStation[]) => {
    setMoveApparatusModalOpen(true);
    setSelectedApparatusToMove({ apparatus: apparatus, originalStation: originalStation, availableStations: stations });
  };

  const confirmMoveApparatus = (apparatus: RosterApparatus, originalStation: RosterStation, stationId: string) => {
    moveApparatus(apparatus, originalStation, stationId);
    setSelectedApparatusToMove(null);
  };

  const handleEditStation = (station: RosterStation) => {
    setSelectedStation(station);
    setIsStationDialogOpen(true);
  };

  const handleRemoveStation = (station: RosterStation) => {
    setStationWarningModalOpen(true);
    setSelectedStation(station);
  };

  const confirmRemoveStation = () => {
    if (selectedStation) {
      removeStation(selectedStation);
    }
    setStationWarningModalOpen(false);
    setSelectedStation(null);
  };

  const handleAddApparatus = (station: RosterStation) => {
    setSelectedStation(station);
    setSelectedApparatus(null);
    setIsApparatusDialogOpen(true);
  };

  const handleEditApparatus = (apparatus: RosterApparatus, station: RosterStation) => {
    setSelectedStation(station);
    setSelectedApparatus(apparatus);
    setIsApparatusDialogOpen(true);
  };

  const handleRemoveApparatus = (apparatus: RosterApparatus, station: RosterStation) => {
    setSelectedStation(station);
    setSelectedApparatus(apparatus);
    setApparatusWarningModalOpen(true);
  };

  const handleAddPosition = (apparatus: RosterApparatus, station: RosterStation) => {
    setSelectedStation(station);
    setSelectedApparatus(apparatus);
    setSelectedPosition(null);
    setIsPositionDialogOpen(true);
  };

  const handleEditPosition = (position: RosterPosition, apparatus: RosterApparatus, station: RosterStation) => {
    setSelectedStation(station);
    setSelectedApparatus(apparatus);
    setSelectedPosition(position);
    setEditPositionWarningModalOpen(true);
  };

  const confirmEditPosition = () => {
    if (selectedPosition && selectedApparatus) {
      setEditPositionWarningModalOpen(false);
      setIsPositionDialogOpen(true);
    }
  };

  const handleRemovePosition = (position: RosterPosition, apparatus: RosterApparatus, station: RosterStation) => {
    setSelectedStation(station);
    setSelectedApparatus(apparatus);
    setSelectedPosition(position);
    setPositionWarningModalOpen(true);
  };

  const confirmRemoveApparatus = () => {
    if (selectedApparatus && selectedStation) {
      removeApparatus(selectedApparatus, selectedStation);
    }
    setApparatusWarningModalOpen(false);
    setSelectedStation(null);
    setSelectedApparatus(null);
  };

  const confirmRemovePosition = () => {
    if (selectedPosition && selectedApparatus && selectedStation) {
      removePosition(selectedPosition, selectedApparatus, selectedStation);
    }
    setPositionWarningModalOpen(false);
    setSelectedStation(null);
    setSelectedApparatus(null);
    setSelectedPosition(null);
  };

  const getApparatusActions = (apparatus: RosterApparatus, station: RosterStation) => {
    const actions = [];
    if (apparatus.id !== 'floater-apparatus') {
      const stationList = Array.from(shiftTemplateHelper.allStationCards.values()).filter(
        (station) => station.stationId !== 'floater-station' && !station.apparatuses.some((app) => app.id === apparatus.id),
      );
      // this actions are available only for stations that were created and saved in the db
      if (apparatus.id !== apparatus.name && station.stationId !== station.stationName) {
        actions.push({
          label: apparatus.isReserved ? 'Activate' : 'Put on reserve',
          onClick: () =>
            !apparatus.isReserved && apparatus.positions.some((pos) => pos.employees.some((employee) => employee.activeId))
              ? showReserveModeWarning(apparatus)
              : handleToggleApparatusMode(apparatus, !apparatus.isReserved),
        });
        if (stationList.length > 0) {
          actions.push({ label: 'Move apparatus', onClick: () => handleMoveApparatus(apparatus, station, stationList) });
        }
      }
      actions.push({ label: 'Edit apparatus', onClick: () => handleEditApparatus(apparatus, station) });
      actions.push({ label: 'Remove apparatus', onClick: () => handleRemoveApparatus(apparatus, station) });
      actions.push({ label: 'Add position', onClick: () => handleAddPosition(apparatus, station) });
    }

    if (!apparatus.isReserved) {
      actions.push({ label: 'Add extra person', onClick: () => addExcessCapacity(apparatus) });
    }

    return actions;
  };

  const getStationActions = (station: RosterStation) => {
    const actions = [];

    if (station.stationId !== 'floater-station') {
      actions.push({ label: 'Edit station', onClick: () => handleEditStation(station) });
      actions.push({ label: 'Remove station', onClick: () => handleRemoveStation(station) });
      actions.push({ label: 'Add apparatus', onClick: () => handleAddApparatus(station) });
    }

    if (!actions || actions.length === 0) {
      return null;
    }
    return <Actions actions={actions} color={theme.palette.stationGray[500]} hoverColor={theme.palette.common.white} />;
  };

  const getPositionActions = (position: RosterPosition, apparatus: RosterApparatus, station: RosterStation) => {
    const actions = [];

    if (station.stationId !== 'floater-station') {
      if (!position.isTemporary) {
        actions.push({ label: 'Edit position', onClick: () => handleEditPosition(position, apparatus, station) });
      }
      actions.push({ label: 'Remove position', onClick: () => handleRemovePosition(position, apparatus, station) });
    }

    if (!actions || actions.length === 0) {
      return null;
    }
    return (
      <Box onClick={(e) => e.stopPropagation()}>
        <Actions actions={actions} hoverColor="inherit" />
      </Box>
    );
  };

  const showReserveModeWarning = (apparatus: RosterApparatus) => {
    setSelectedApparatusToReserve(apparatus);
    setReserveApparatusModalOpen(true);
  };

  const payAndDetailCodes = activeEmployee && (
    <PayAndDetailCodes
      employee={activeEmployee}
      defaultPayCodes={activeEmployee.payCodes}
      handleDetailCodeChange={handleDetailCodeChange}
      handlePayCodeChange={handlePayCodeChange}
      disableOverrideNotes
    />
  );

  return (
    <Box sx={{ height: '100%', overflowY: 'auto', position: 'relative' }}>
      <Box sx={{ p: 1.5 }}>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, 100%)',
            justifyContent: 'center',
            gap: theme.spacing(4.5, 0),
            [theme.breakpoints.up('sm')]: {
              gridTemplateColumns: 'repeat(auto-fit, minmax(275px, max-content))',
              gap: theme.spacing(4.5, 1.5),
            },
          })}
        >
          {Array.from(shiftTemplateHelper.allStationCards.values()).map((station) => (
            <Station
              key={station.stationId}
              station={station}
              certificationRequirements={
                <Box sx={(theme) => ({ display: 'flex', flexWrap: 'wrap', pt: theme.spacing(1), mb: '-5px' })}>
                  {station.certificationRequirements.map((certReq) => (
                    <CertificationRequirementChip key={certReq.id} certificationRequirement={certReq} />
                  ))}
                </Box>
              }
              actions={getStationActions(station)}
            >
              {station.apparatuses.length === 0 && (
                <AddNewObjectPlaceholder text="+ Add apparatus" onClick={() => handleAddApparatus(station)} />
              )}
              {station.apparatuses.map((apparatus) => (
                <Apparatus
                  key={apparatus.id}
                  apparatus={apparatus}
                  actions={<Actions actions={getApparatusActions(apparatus, station)} />}
                >
                  {apparatus.certificationRequirements.length > 0 && (
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        flexWrap: 'wrap',
                        pt: theme.spacing(1),
                        mb: '-5px',
                        ml: theme.spacing(0.5),
                      })}
                    >
                      {apparatus.certificationRequirements.map((certReq) => (
                        <CertificationRequirementChip
                          key={certReq.id}
                          certificationRequirement={certReq}
                          backgroundColor="inherit"
                        />
                      ))}
                    </Box>
                  )}

                  <Box>
                    {apparatus.id === 'floater-apparatus' && apparatus.positions.length === 0 && (
                      <EmptyCard
                        durationLabel=""
                        certs={[]}
                        cardSxProps={() => ({ mt: 1.5, cursor: 'pointer', '&:hover': { backgroundColor: 'transparent' } })}
                        onClick={() => addExcessCapacity(apparatus)}
                      />
                    )}
                    {apparatus.id !== 'floater-apparatus' && apparatus.positions.length === 0 && (
                      <Box sx={(theme) => ({ pt: theme.spacing(1) })}>
                        <AddNewObjectPlaceholder text="+ Add position" onClick={() => handleAddPosition(apparatus, station)} />
                      </Box>
                    )}
                    {apparatus.positions.map((position, index) => (
                      <Box
                        key={position.id || index}
                        data-cy={`position-${makeTestIdentifier(position.rank.code)}`}
                        sx={{
                          mt: 1.5,
                        }}
                      >
                        <Position
                          apparatus={apparatus}
                          position={position}
                          isCollapsed={false}
                          onClickPosition={onClickPosition}
                          actions={getPositionActions(position, apparatus, station)}
                        />
                      </Box>
                    ))}
                  </Box>
                </Apparatus>
              ))}
            </Station>
          ))}
        </Box>
      </Box>
      {selectedApparatus &&
        (!selectedPosition || selectedPosition?.employees.length === 0 || (activeEmployee && !activeEmployee.id)) && (
          <ShiftTemplateAssignmentModal
            open={assignmentModalOpen}
            setOpen={setAssignmentModalOpen}
            apparatus={selectedApparatus}
            position={selectedPosition}
            activeEmployee={activeEmployee}
            resetSelected={resetSelected}
          />
        )}
      {selectedPosition && activeEmployee && (
        <Popover anchorEl={selectedAnchorEl} open={employeePreviewIsOpen} onClose={closePopover} {...POPOVER_POSITION.RIGHT}>
          <EmployeePreview
            hideDates
            hidePersonalCalendar
            position={selectedPosition}
            employee={activeEmployee}
            payCodeAndDetailCodes={payAndDetailCodes}
            actions={<ShiftTemplateActions onUnassignClick={onUnassignClick} employeeId={activeEmployee.id} />}
          />
        </Popover>
      )}
      {errorWarningModalOpen && error?.message && (
        <ShiftTemplateWarningModal
          onConfirm={() => setErrorWarningModalOpen(false)}
          warningModalOpen={errorWarningModalOpen}
          setWarningModalOpen={setErrorWarningModalOpen}
          primaryMessage={error?.message}
          secondaryMessage={error?.secondaryMessage}
          hideButtons
        />
      )}
      {selectedApparatusToReserve && (
        <ShiftTemplateWarningModal
          onConfirm={() => {
            setReserveApparatusModalOpen(false);
            handleToggleApparatusMode(selectedApparatusToReserve, true);
            setSelectedApparatusToReserve(null);
          }}
          warningModalOpen={reserveApparatusModalOpen}
          setWarningModalOpen={setReserveApparatusModalOpen}
          primaryMessage={`You are about to put ${selectedApparatusToReserve.name} on reserve mode.`}
        />
      )}
      {selectedApparatusToMove && (
        <MoveApparatusModal
          selectedApparatusToMove={selectedApparatusToMove}
          isOpen={moveApparatusModalOpen}
          setIsOpen={setMoveApparatusModalOpen}
          moveApparatus={confirmMoveApparatus}
        />
      )}
      {stationWarningModalOpen && selectedStation && (
        <ShiftTemplateWarningModal
          onConfirm={confirmRemoveStation}
          warningModalOpen={stationWarningModalOpen}
          setWarningModalOpen={setStationWarningModalOpen}
          primaryMessage={`You are about to remove ${selectedStation.stationName}.`}
          secondaryMessage="All assigned employees of all teams will be moved to the floater station."
        />
      )}
      {isApparatusDialogOpen && (
        <ApparatusDialog
          isOpen={isApparatusDialogOpen}
          setIsOpen={setIsApparatusDialogOpen}
          selectedApparatus={selectedApparatus}
        />
      )}
      {apparatusWarningModalOpen && selectedApparatus && (
        <ShiftTemplateWarningModal
          onConfirm={confirmRemoveApparatus}
          warningModalOpen={apparatusWarningModalOpen}
          setWarningModalOpen={setApparatusWarningModalOpen}
          primaryMessage={`You are about to remove ${selectedApparatus.name}.`}
          secondaryMessage="All assigned employees of all teams will be moved to the floater station."
        />
      )}
      {editPositionWarningModalOpen && selectedPosition && (
        <ShiftTemplateWarningModal
          onConfirm={confirmEditPosition}
          warningModalOpen={editPositionWarningModalOpen}
          setWarningModalOpen={setEditPositionWarningModalOpen}
          primaryMessage={`To edit a position it must be empty.`}
          secondaryMessage="All assigned employees of all teams will be moved to the floater station."
        />
      )}
      {positionWarningModalOpen && selectedPosition && selectedApparatus && selectedStation && (
        <ShiftTemplateWarningModal
          onConfirm={confirmRemovePosition}
          warningModalOpen={positionWarningModalOpen}
          setWarningModalOpen={setPositionWarningModalOpen}
          primaryMessage={`You are about to remove ${selectedPosition.rank.name} position from ${selectedApparatus.name}`}
          secondaryMessage="All assigned employees of all teams will be moved to the floater station."
        />
      )}
      {isPositionDialogOpen && selectedApparatus && (
        <PositionDialog
          isOpen={isPositionDialogOpen}
          setIsOpen={setIsPositionDialogOpen}
          selectedPosition={selectedPosition}
          selectedApparatus={selectedApparatus}
        />
      )}
    </Box>
  );
};
