import { Box } from '@mui/material';
import { Users01Icon16 as UsersIcon } from '../../../../assets';
import { SelectCommonOption } from './SelectCommonOption';

export interface SelectWorkspaceOptionProps {
  id?: number[];
  chipBackgroundColor?: string;
  chipLabel?: string;
  name: string;
  email?: string;
  userImg?: string;
  onClick?: () => void;
  checked?: boolean;
  count?: number;
}

export const SelectWorkspaceOption = ({ chipBackgroundColor, name, onClick, checked, id, count }: SelectWorkspaceOptionProps) => {
  const adornment = (
    <Box
      data-cy={`recipient-option-${name.toLowerCase().replace(' ', '-')}`}
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '50%',
        backgroundColor: chipBackgroundColor,
        color: theme.palette.common.white,
      })}
    >
      <UsersIcon />
    </Box>
  );

  return (
    <SelectCommonOption
      chipBackgroundColor={chipBackgroundColor}
      chipLabel=""
      name={name}
      email=""
      onClick={onClick}
      checked={checked}
      id={id}
      adornment={adornment}
      count={count}
    />
  );
};
