export const SHIFT_TITLES = {
  REGULAR: 'REGULAR',
  SHIFT_TRADE: 'SHIFT TRADE',
  OFF_TRADE: 'OFF - TRADE',
  OVERTIME: 'OVERTIME',
  TRADE_REQUESTED: 'TRADE REQUESTED',
  TIME_OFF_REQUEST: 'TIME OFF REQUEST',
  ADDITIONAL_PAID_TIME_REQUEST: 'ADD. PAID TIME REQUEST',
  ADDITIONAL_PAID_TIME: 'ADDITIONAL PAID TIME',
  KELLY_DAY: 'KELLY DAY',
  INCIDENT: 'INCIDENT',
  INCIDENT_PAY: 'INCIDENT PAY',
};
export const TEMPORARY_NON_SHIFT_TITLES = {
  LIGHT_DUTY: 'LIGHT DUTY',
  EXTENDED_LEAVE: 'EXTENDED LEAVE',
};
export const TIME_OFFS_TITLES = {
  VACATION: 'VACATION',
  SICK_DAY: 'SICK',
  EMERGENCY_LEAVE: 'EMERGENCY',
  BEREAVEMENT: 'BEREAVEMENT DAY',
  COMP_TIME: 'COMP TIME',
  JURY_DUTY: 'JURY DUTY',
  // clarify: another time off requests
};
export const STATUS_TITLES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CANCELLED: 'CANCELLED',
  REVIEWING: 'REVIEWING',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  USER_CANCELLED: 'USER_CANCELLED',
  USER_MESSAGE_SENT: 'USER_MESSAGE_SENT',
  POSTPONED: 'POSTPONED',
};
export const CALENDAR_EVENT_TYPES = {
  ADDITIONAL_PAID_TIME_REQUEST: 'ADDITIONAL_PAID_TIME_REQUEST',
  COMP_TIME_REQUEST: 'COMP_TIME_REQUEST',
  INCIDENT: 'INCIDENT',
  KELLY_DAY: 'KELLY_DAY',
  NON_SHIFT: 'NON_SHIFT',
  TEMPORARY_NON_SHIFT: 'TEMPORARY_NON_SHIFT',
  OVERRIDE_EMPLOYEE_POSITION: 'OVERRIDE_EMPLOYEE_POSITION',
  PLANNED_EMPLOYEE_POSITION: 'PLANNED_EMPLOYEE_POSITION',
  SHIFT_TRADE_REQUEST: 'SHIFT_TRADE_REQUEST',
  TIME_OFF_REQUEST: 'TIME_OFF_REQUEST',
  STAFFING_LIST_MARK: 'STAFFING_LIST_MARK',
};
export const SHIFT_OVERVIEW_OPTIONS = {
  TRADE_SHIFT: 'trade-shift',
  REQUEST_TIME_OFF: 'request-time-off',
  ADDITIONAL_PAID_TIME: 'additional-paid-time',
  CANCEL_OVERTIME_REQUEST: 'cancel-overtime-request',
  EDIT_INCIDENT: 'edit-incident',
  CLOSE_INCIDENT: 'close-incident',
};
