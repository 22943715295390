import { SxProps, Theme } from '@mui/material';
import { PreviewButton } from './PreviewButton';

interface ViewProfileButtonProps {
  employeeId: string;
  sx?: SxProps<Theme>;
}

export const ViewProfileButton = ({ employeeId, sx }: ViewProfileButtonProps) => (
  <PreviewButton variant="outlined" href={`/app/employees/${employeeId}`} target="_blank" rel="noopener noreferrer" sx={sx}>
    Full profile
  </PreviewButton>
);
