import { Box, List, ListItem, Typography } from '@mui/material';

export const PublishingEffectsDetail = ({ isCancel }: { isCancel?: boolean }) => {
  const cancelEffectWarning = (
    <Typography display={'inline'} variant={'bodyMSemibold'}>
      This effect will not be reverted on cancellation.
    </Typography>
  );
  return (
    <Box
      sx={(theme) => ({
        textAlign: 'left',
        typography: 'bodyMRegular',
        mt: theme.spacing(2),
        border: `1px solid ${theme.palette.stationGray[100]}`,
        borderRadius: '8px',
        background: theme.palette.stationGray[50],
        p: '12px 32px 12px 12px',
        whiteSpace: 'pre-line',
      })}
    >
      <Box sx={{ typography: 'bodyMRegular' }}>{`Changes on schedule can affect employees directly or indirectly.`}</Box>
      <Box sx={(theme) => ({ typography: 'bodyLSemibold', pt: theme.spacing(2) })}>{`Directly affected employees:`}</Box>
      <Box sx={(theme) => ({ ml: theme.spacing(3) })}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItem sx={{ display: 'list-item' }}>{`Employee assignment is modified but remains on the same team.`}</ListItem>
          <ListItem
            sx={{ display: 'list-item' }}
          >{`Employee is completely unassigned from schedule or moved to another team.`}</ListItem>
        </List>
      </Box>
      <Box
        sx={(theme) => ({ typography: 'bodyMSemibold', pt: theme.spacing(2) })}
      >{`Effects of changing assignments on the same team:`}</Box>
      <Box sx={(theme) => ({ ml: theme.spacing(3) })}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItem
            sx={{ display: 'list-item' }}
          >{`Time-offs, Kelly days, Light Duties, Extended Leaves, Overtimes and Strike team assignments will remain as they are.`}</ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            {`Shift trades will be cancelled and the hours refunded.`} {isCancel && cancelEffectWarning}
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            {`Full and partial override positions will be deleted.`} {isCancel && cancelEffectWarning}
          </ListItem>
        </List>
      </Box>
      <Box
        sx={(theme) => ({ typography: 'bodyMSemibold', pt: theme.spacing(2) })}
      >{`Effects of completely unassigning an employee or changing teams:`}</Box>
      <Box sx={(theme) => ({ ml: theme.spacing(3) })}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItem sx={{ display: 'list-item' }}>{`Strike team assignments will remain as they are.`}</ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            {`Time-offs, Kelly days, Light Duties, Extended Leaves will be cancelled.`} {isCancel && cancelEffectWarning}
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            {`Shift trades will be cancelled and the hours refunded.`} {isCancel && cancelEffectWarning}
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            {`Full and partial override positions will be deleted.`} {isCancel && cancelEffectWarning}
          </ListItem>
        </List>
      </Box>
      <Box sx={(theme) => ({ typography: 'bodyLSemibold', pt: theme.spacing(2) })}>{`Indirectly affected employees:`}</Box>
      <Box sx={(theme) => ({ ml: theme.spacing(3) })}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItem
            sx={{ display: 'list-item' }}
          >{`They are overriding a position whose planned assignee was modified.`}</ListItem>
          <ListItem
            sx={{ display: 'list-item' }}
          >{`They are working overtime on a position whose planned assignee was modified.`}</ListItem>
        </List>
      </Box>
      <Box sx={(theme) => ({ typography: 'bodyMSemibold', pt: theme.spacing(2) })}>{`Effects:`}</Box>
      <Box sx={(theme) => ({ ml: theme.spacing(3) })}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItem sx={{ display: 'list-item' }}>
            {`Override: employee will be returned to his/her original place or positioned as a floater in case the position is occupied.`}{' '}
            {isCancel && cancelEffectWarning}
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            {`Overtime: employee will be positioned as floater and the overtime will be cancelled.`}{' '}
            {isCancel && cancelEffectWarning}
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            {`Shift trade: The shift trade will be cancelled and the hours refunded.`} {isCancel && cancelEffectWarning}
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};
