import { addDays, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { PayrollCode } from '@stationwise/share-types';
import { AccrueCompTimeCard } from './AccrueCompTimeCard';
import { AvailableCompTime } from './utils';

interface AccrueCompTimeCardWrapperProps {
  compTime: AvailableCompTime;
  children?: React.ReactNode;
  index: number;
  isSelected: boolean;
  onSelect: (index: number) => void;
  payrollCode: PayrollCode | null;
}

export const AccrueCompTimeCardWrapper: React.FC<AccrueCompTimeCardWrapperProps> = ({
  compTime,
  children,
  index,
  isSelected,
  onSelect,
  payrollCode,
}) => {
  const [endDate, setEndDate] = useState<Date>(parseISO(compTime.overtimeDate));
  const hour = compTime.overtimeHours;
  const day = parseISO(compTime.overtimeDate);

  useEffect(() => {
    if (hour < 24) {
      setEndDate(day);
    } else {
      setEndDate(addDays(day, 1));
    }
  }, [day, hour]);

  const handleClick = () => {
    onSelect(index);
  };

  return (
    <AccrueCompTimeCard
      convertRatio={compTime.convertRatio}
      key={index}
      day={day}
      endDate={endDate}
      hour={hour}
      isSelected={isSelected}
      onClick={handleClick}
      payrollCode={payrollCode}
    >
      {children}
    </AccrueCompTimeCard>
  );
};
