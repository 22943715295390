import { Box } from '@mui/material';
import {
  BlankCard,
  CardCertBadges,
  CircleHigherIcon20,
  RankBadge,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { ShiftPlanAssignment } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { filterNonRankCertifications } from '@stationwise/shift-summary-helper';

interface ShiftPlanAssignmentCardProps {
  assignment: ShiftPlanAssignment;
}

export const ShiftPlanAssignmentCard = (props: ShiftPlanAssignmentCardProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;
  const { employee, reference } = props.assignment;

  return (
    <BlankCard type="border" borderColor={employee.team?.color} sxProps={{ gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <RankBadge rank={employee.rank} />
        <Box sx={{ typography: 'bodySMedium' }}>{shortenEmployeeName(employee.name)}</Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CardCertBadges certs={filterNonRankCertifications(departmentInfo, employee.certifications, employee.rank)} />
        <Box sx={{ flex: 1 }} />
        {reference.apparatus && !reference.position && <CircleHigherIcon20 />}
      </Box>
    </BlankCard>
  );
};
