import { Box, Typography, useTheme } from '@mui/material';
import { Chip, useLoadedDepartmentInfoContext } from '@stationwise/component-module';

export const PositionRank = ({
  isActing,
  positionRank,
  employeeRank,
}: {
  isActing: boolean;
  positionRank: string;
  employeeRank: string;
}) => {
  const theme = useTheme();
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        mt: theme.spacing(1),
        mb: theme.spacing(1),
        justifyContent: { sm: 'space-between' },
      }}
    >
      <Box display="flex" sx={{ width: '40%' }}>
        {departmentInfo.settings.actingRolesEnabled && isActing ? (
          <Chip
            label="ACTING AS"
            variant="eyebrow"
            chipSxProps={() => ({
              background: theme.palette.stationGray[400],
              borderRadius: '7px',
              height: '24px',
              maxWidth: '104px',
              p: '10px',
              '&:after': {
                content: `url('data:image/svg+xml,<svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.30463 2.93237C4.15848 1.10758 2.15488 0 0 0V24C2.15488 24 4.15848 22.8924 5.30463 21.0676L8.66146 15.7232C10.0912 13.4469 10.0912 10.5531 8.66147 8.27681L5.30463 2.93237Z" fill="%23A3A9B5"/></svg>')`,
                position: 'absolute',
                height: '100%',
                width: '8px',
                right: '-3px',
              },
            })}
          />
        ) : (
          <Typography variant="eyebrow" sx={{ color: theme.palette.stationGray[400] }}>
            RANK
          </Typography>
        )}
      </Box>
      <Box data-cy="position-rank">
        <Typography variant="bodyMMedium">
          {departmentInfo.settings.actingRolesEnabled && isActing ? positionRank : employeeRank}
        </Typography>
      </Box>
    </Box>
  );
};
