import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, useMediaQuery, Theme, Popover } from '@mui/material';
import { Dispatch, useMemo, useState } from 'react';
import {
  GenericDrawerOrModal,
  SHIFT_TITLES,
  isTimeOffEvent,
  XCloseIcon24,
  isAdditionalPaidTimeEvent,
} from '@stationwise/component-module';
import { RequestOverview as IRequestOverview } from '@stationwise/share-types';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { CancelCommonRequest } from './CancelCommonRequest';
import { RequestOverviewContent } from './RequestOverviewContent';
import { useFetchRequestOverview } from './hooks/useFetchRequestOverview';

export const getOverviewArgs = (shift: EventInput | EventImpl) => {
  let overviewArgs;
  if (isTimeOffEvent(shift)) {
    overviewArgs = {
      fetchUrl: `request/time-off-request/${shift.id}/overview/`,
      cancelUrl: `request/time-off-request/${shift.id}/`,
      title: 'Time off',
    };
  } else if (shift.title === SHIFT_TITLES.TRADE_REQUESTED || shift.title === SHIFT_TITLES.OFF_TRADE) {
    overviewArgs = {
      fetchUrl: `request/shift-trade-request/${shift.id}/overview/`,
      cancelUrl: `/request/shift-trade-request/${shift.id}/cancel/`,
      title: 'Shift trade',
    };
  } else if (isAdditionalPaidTimeEvent(shift)) {
    overviewArgs = {
      fetchUrl: `/request/additional-paid-time-request/${shift.id}/overview/`,
      cancelUrl: `/request/additional-paid-time-request/${shift.id}/`,
      title: 'Additional paid time',
    };
  }
  return (
    overviewArgs || {
      fetchUrl: '',
      cancelUrl: '',
      title: '',
    }
  );
};

interface RequestOverviewProps {
  drawerOpen: boolean;
  handleOnClose: () => void;
  eventColor?: string;
  setRefetchEvents: Dispatch<React.SetStateAction<boolean>>;
  shift: EventInput | EventImpl;
  anchorEl: null | HTMLElement;
}

export const RequestOverview = ({ drawerOpen, handleOnClose, setRefetchEvents, shift, anchorEl }: RequestOverviewProps) => {
  const overviewInfo = useMemo(() => getOverviewArgs(shift), [shift]);
  const { data: overview } = useFetchRequestOverview<IRequestOverview>(overviewInfo.fetchUrl);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const iosWebViewPaddingBottom = useIOSPaddingBottom();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleClick = () => {
    handleOnClose();
  };

  if (showCancelModal && overview) {
    return (
      <CancelCommonRequest
        drawerOpen={showCancelModal}
        handleOnClose={handleOnClose}
        url={overviewInfo.cancelUrl}
        title={overviewInfo.title}
        setRefetchEvents={setRefetchEvents}
      />
    );
  }

  if (isMobile) {
    return (
      <GenericDrawerOrModal
        anchor="bottom"
        drawerOpen={drawerOpen}
        handleOnClose={handleOnClose}
        loading={false}
        showHeader={true}
        headerTitle={overviewInfo?.title?.toUpperCase().includes(SHIFT_TITLES.INCIDENT_PAY) ? 'Incident pay' : overviewInfo.title}
        disableFooter={true}
        noBorderOnHeader={true}
      >
        <RequestOverviewContent shift={shift} handleOnClose={handleOnClose} setShowCancelModal={setShowCancelModal} />
      </GenericDrawerOrModal>
    );
  } else {
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClick}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 200, left: window.innerWidth - 430 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={(theme) => ({
          '& .MuiPaper-root': {
            background: theme.palette.common.white,
            borderRadius: '16px',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
            width: '450px',
            paddingBottom: iosWebViewPaddingBottom,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            height: '48px',
            pr: theme.spacing(2),
            color: theme.palette.stationGray[400],
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          })}
          onClick={handleOnClose}
        >
          <XCloseIcon24 />
        </Box>
        <RequestOverviewContent setShowCancelModal={setShowCancelModal} shift={shift} handleOnClose={handleOnClose} />
      </Popover>
    );
  }
};
