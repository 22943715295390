import { MenuItem, Select, Tooltip, useTheme } from '@mui/material';
import { getTooltipProps, selectStyle, ChevronDownIcon16, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { useShiftPlanContext } from '../ShiftPlanContext';

export const BattalionSelect = () => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { battalion: selectedBattalion, userHasMadeChanges, forceRefetch } = useShiftPlanContext();

  if (departmentInfoState.departmentInfo.battalions.length <= 1) {
    return null;
  }

  return (
    <Tooltip
      title="Save or cancel your changes before selecting another battalion"
      placement="bottom"
      slotProps={getTooltipProps()}
      disableFocusListener={!userHasMadeChanges}
      disableHoverListener={!userHasMadeChanges}
    >
      <Select
        value={`${selectedBattalion.id}`}
        onChange={(event) => forceRefetch(event.target.value)}
        variant="standard"
        IconComponent={ChevronDownIcon16}
        disableUnderline
        sx={selectStyle(theme, '', undefined)}
        disabled={userHasMadeChanges}
      >
        {departmentInfoState.departmentInfo.battalions.map((battalion) => (
          <MenuItem key={battalion.id} value={`${battalion.id}`}>
            {battalion.name}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};
