import { Box, BoxProps } from '@mui/material';
import { LottieLogo } from './LottieLogo';

type LottieLogoPageLoaderProps = Omit<BoxProps<'div'>, 'children'>;

export const LottieLogoPageLoader = ({ sx, ...props }: LottieLogoPageLoaderProps) => (
  <Box
    {...props}
    sx={[
      { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' },
      ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
    ]}
  >
    <LottieLogo height="200px" width="200px" />
  </Box>
);
