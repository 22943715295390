import { Box } from '@mui/material';
import { Button } from '@stationwise/component-module';

export interface Step {
  title?: string;
  subtitle?: string;
  stepNumber: number;
  component: React.ReactElement;
}

export const Stepper = ({
  title,
  steps,
  onClose,
  onFinalSubmit,
  nextDisabled,
  setNextDisabled,
  completedSteps,
  currentStep,
  setCurrentStep,
}: {
  title: string;
  steps: Step[];
  onClose: () => void;
  onFinalSubmit: () => void;
  nextDisabled?: boolean;
  setNextDisabled: (nextDisabled: boolean) => void;
  completedSteps: number[];
  currentStep: Step;
  setCurrentStep: (currentStep: Step) => void;
}) => {
  const onNextClick = () => {
    if (currentStep.stepNumber < steps.length - 1) {
      const nextNumber = currentStep.stepNumber + 1;
      setCurrentStep(steps[nextNumber]);

      if (!completedSteps.includes(steps[nextNumber].stepNumber)) {
        setNextDisabled(true);
      }
    } else {
      onFinalSubmit();
    }
  };

  const onPreviousClick = () => {
    const prevValue = currentStep.stepNumber - 1;
    setCurrentStep(steps[prevValue]);
    setNextDisabled(false);
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          mt: theme.spacing(1),
          justifyContent: 'center',
        })}
      >
        {steps.map((step, index) => (
          <Box display="flex" key={step.stepNumber}>
            <Box
              sx={(theme) => ({
                backgroundColor:
                  step.stepNumber <= currentStep.stepNumber ? theme.palette.stationBlue[600] : theme.palette.common.white,
                border: `2px solid ${theme.palette.stationBlue[600]}`,
                width: '12px',
                height: '12px',
                borderRadius: '40px',
              })}
            ></Box>
            {index !== steps.length - 1 && (
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.stationBlue[600],
                  width: '26px',
                  height: '2px',
                  mt: '5px',
                })}
              ></Box>
            )}
          </Box>
        ))}
      </Box>
      <Box
        sx={(theme) => ({
          typography: 'bodyXLSemibold',
          my: theme.spacing(3),
          justifyContent: 'center',
          textAlign: 'center',
        })}
      >
        {title}
      </Box>
      {steps.map((step) => (
        <Box key={`step_${step.stepNumber}`} sx={{ display: step.stepNumber === currentStep.stepNumber ? 'unset' : 'none' }}>
          {step.title && (
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  width: '24px',
                  height: '24px',
                  backgroundColor: theme.palette.stationBlue[600],
                  color: theme.palette.common.white,
                  borderRadius: '40px',
                  p: theme.spacing(0.5),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: theme.spacing(1),
                })}
              >
                {step.stepNumber + 1}
              </Box>
              <Box sx={{ typography: 'bodyLMedium' }}>{step.title}</Box>
            </Box>
          )}
          {step.subtitle && <Box sx={(theme) => ({ typography: 'bodySRegular', ml: theme.spacing(4) })}>{step.subtitle}</Box>}
          <Box>{step.component}</Box>
        </Box>
      ))}
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          mt: theme.spacing(3),
          gap: theme.spacing(1.5),
          width: '100%',
        })}
      >
        <Button
          onClick={() => (currentStep.stepNumber === 0 ? onClose() : onPreviousClick())}
          variant="outlined"
          size="large"
          sx={{ width: '100%' }}
        >
          {currentStep.stepNumber === 0 ? 'Cancel' : 'Previous'}
        </Button>
        <Button disabled={nextDisabled} onClick={onNextClick} variant="contained" size="large" sx={{ width: '100%' }}>
          {currentStep.stepNumber < steps.length - 1 ? 'Next' : 'Submit'}
        </Button>
      </Box>
    </>
  );
};
