import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Menu, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { Button, Modal, SnackbarService } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { ListFieldsStaffingList, StaffingListExemption } from '@stationwise/share-types';
import { WarningModal } from '../../../../../components/Common';
import { ExemptionDialog } from './ExemptionDialog';

interface ExemptionsContentProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  exemptions: StaffingListExemption[];
  forceRefetchExemptions: () => void;
  staffingLists: ListFieldsStaffingList[];
  selectedStaffingList?: ListFieldsStaffingList;
  selectedDate: Date;
  forceRefetchStaffingList: () => void;
}

export const ExemptionsContent = ({
  anchorEl,
  handleClose,
  exemptions,
  forceRefetchExemptions,
  staffingLists,
  selectedStaffingList,
  selectedDate,
  forceRefetchStaffingList,
}: ExemptionsContentProps) => {
  const [createExemptionDialogOpen, setCreateExemptionDialogOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const open = Boolean(anchorEl);

  const handleAddNewExemptionClick = () => {
    setCreateExemptionDialogOpen(true);
  };

  const handleDeleteExemption = (id: number) => {
    setWarningModalOpen(true);
    setIdToDelete(id);
  };

  const deleteExemption = async () => {
    setWarningModalOpen(false);

    try {
      await client.delete(`staffing-list/exemptions/${idToDelete}/`);

      setIdToDelete(null);
      forceRefetchExemptions();
      forceRefetchStaffingList();

      SnackbarService.notify({
        content: 'Exemption was successfully deleted.',
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      const message = isAxiosError(error) ? error.response?.data?.message || error.message : 'Error deleting exemption';

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
    }
  };

  return (
    <Box>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: -8, horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        sx={(theme) => ({
          top: theme.spacing(0),
          '& .MuiMenu-paper': {
            border: `1px solid ${theme.palette.stationGray[100]}`,
            borderRadius: 4,
            boxShadow: '0px 10px 10px -5px #0A0E160A, 0px 20px 25px -5px #0A0E161A',
            color: theme.palette.stationGray[900],
            p: 2.5,
          },
          '& .MuiMenu-list': { p: 0 },
          '& .MuiListItem-root': { backgroundColor: 'transparent', display: 'block', p: 0 },
        })}
      >
        <Box sx={{ minWidth: '500px' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              typography: theme.typography.h6,
            })}
          >
            Exemptions
            <Box sx={(theme) => ({ color: theme.palette.stationGray[500], cursor: 'pointer' })} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: 2,
              overflow: 'auto',
              maxHeight: '50vh',
              flexDirection: 'column',
              mb: theme.spacing(0),
            })}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Employees</TableCell>
                  <TableCell>Effective date(s)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exemptions.map((exemption) => (
                  <TableRow key={exemption.id}>
                    <TableCell>{exemption.employeeNames}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-between">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1.5,
                            textAlign: 'left',
                            width: '100%',
                          }}
                        >
                          <Box sx={{ flex: 1, typography: 'body2' }}>
                            {exemption.startDate === exemption.endDate
                              ? format(parseISO(exemption.startDate), 'MMM d, yyyy')
                              : `${format(parseISO(exemption.startDate), 'MMM d, yyyy')} - ${format(parseISO(exemption.endDate), 'MMM d, yyyy')}`}
                          </Box>
                        </Box>

                        <Box sx={{ position: 'relative', width: '24px', height: '24px' }}>
                          <IconButton
                            sx={(theme) => ({
                              color: theme.palette.stationGray[500],
                              position: 'absolute',
                              top: theme.spacing(-1),
                              right: theme.spacing(-1),
                            })}
                            onClick={() => handleDeleteExemption(exemption.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Button variant="outlined" sx={{ mt: 2 }} onClick={handleAddNewExemptionClick}>
            Add new
          </Button>
        </Box>
      </Menu>
      <ExemptionDialog
        open={createExemptionDialogOpen}
        setOpen={setCreateExemptionDialogOpen}
        forceRefetchExemptions={forceRefetchExemptions}
        staffingLists={staffingLists}
        selectedStaffingList={selectedStaffingList}
        selectedDate={selectedDate}
        forceRefetchStaffingList={forceRefetchStaffingList}
        exemptions={exemptions}
      />
      <Modal open={warningModalOpen} setOpen={setWarningModalOpen}>
        <WarningModal setModalOpen={setWarningModalOpen} onConfirm={deleteExemption}>
          <Box sx={(theme) => ({ width: '416px', mb: theme.spacing(3) })}>
            <Box sx={{ typography: 'bodyXXLSemibold' }}>{`You are about to delete this exemption`}</Box>
            <Box sx={(theme) => ({ typography: 'bodyMRegular', mt: theme.spacing(2) })}>Are you sure?</Box>
          </Box>
        </WarningModal>
      </Modal>
    </Box>
  );
};
