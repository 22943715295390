import { DEFAULT_RANK, RosterDataSource, RosterStation, RosterPosition, ShiftPlanStruct } from '@stationwise/share-types';
import { requireApparatus, upsertApparatus } from './crudApparatus';

export const requirePosition = ({ station, apparatus, position }: ShiftPlanStruct) => {
  if (!station || !apparatus || !position) {
    throw new Error('Station, Apparatus, and Position required on ShiftPlanStruct');
  }
  return { station, apparatus, position };
};

export const upsertPosition = (
  stations: Map<string, RosterStation>,
  struct: ShiftPlanStruct,
  newData: Partial<RosterPosition>,
) => {
  const { apparatus } = requireApparatus(struct);

  let newPosition = struct.position || {
    id: `${-Date.now()}`,
    dataSource: RosterDataSource.STATION,
    startDateTime: new Date(),
    endDateTime: new Date(),
    rank: DEFAULT_RANK,
    certifications: [],
    employees: [],
    driver: false,
    isTemporary: false,
    isNewlyCreated: true,
  };
  newPosition = { ...newPosition, ...newData };

  const newPositions = [...apparatus.positions];
  const positionIndex = apparatus.positions.findIndex((p) => p.id === newPosition.id);
  if (positionIndex >= 0) {
    newPositions[positionIndex] = newPosition;
  } else {
    newPositions.push(newPosition);
  }

  return upsertApparatus(stations, struct, { positions: newPositions });
};

export const removePosition = (stations: Map<string, RosterStation>, struct: ShiftPlanStruct) => {
  const { apparatus, position } = requirePosition(struct);
  const newPositions = apparatus.positions.filter((p) => p.id !== position.id);
  return upsertApparatus(stations, struct, { positions: newPositions });
};
