import { Box, Skeleton, Theme, useTheme } from '@mui/material';
import { Mail02Icon16, CheckCircleBrokenIcon16 } from '@stationwise/component-module';

const categorySelector = (category: string, theme: Theme) => {
  switch (category) {
    case 'General':
      return {
        name: 'GENERAL',
        iconSrc: <Mail02Icon16 />,
        color: theme.palette.stationBlue[500],
        adjustment: { mt: '-2px' },
      };
    case 'To-Do':
      return {
        name: 'TO-DO',
        iconSrc: <CheckCircleBrokenIcon16 />,
        color: theme.palette.stationPurple[500],
        adjustment: { mt: '-4px' },
      };
    default:
      return {
        name: 'GENERAL',
        iconSrc: <Mail02Icon16 />,
        color: theme.palette.stationBlue[500],
      };
  }
};

export interface CategoryChipProps {
  category: string;
  isLoading?: boolean;
}

export const CategoryChip = ({ category, isLoading }: CategoryChipProps) => {
  const theme = useTheme();
  const categoryObj = categorySelector(category, theme);

  if (isLoading) {
    return (
      <Box display="flex" gap={0.5} flexDirection="column">
        <Skeleton variant="text" width={50} height={28} />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.5}
      sx={(theme) => ({
        background: theme.palette.common.white,
        padding: '2px 6px',
        borderRadius: theme.spacing(0.5),
      })}
    >
      <Box
        display="flex"
        alignContent="center"
        justifyContent="center"
        sx={{
          height: '12px',
          width: '12px',
          '& path': {
            stroke: categoryObj.color,
          },
          ...categoryObj.adjustment,
        }}
      >
        {categoryObj.iconSrc}
      </Box>
      <Box sx={{ color: categoryObj.color, typography: 'tagline1' }}>{categoryObj.name}</Box>
    </Box>
  );
};
