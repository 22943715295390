import { Box } from '@mui/material';
import { RosterEmployee, ShiftSummaryOverride } from '@stationwise/share-types';
import { updateBoardEmployeeNote } from '@stationwise/shift-summary-helper';
import { Pencil01Icon16 } from '../../../../../assets';
import { useLoadedAuthUserContext } from '../../../../Auth';
import { ControlledPopup } from '../../../../ControlledPopup';
import { OverridePopoverContent } from '../../../../Override';
import { useRosterContext } from '../../RosterContext';
import { NoteRow } from '../NoteRow';
import { notifyEmployeeNoteChanged } from './snackbarHelper';

interface NotePopoverProps {
  employee: RosterEmployee;
  noteOverride: ShiftSummaryOverride;
  onEdit: () => void;
}

export const NotePopover = ({ employee, noteOverride, onEdit }: NotePopoverProps) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges } = useRosterContext();

  const onDelete = () => {
    setShiftSummaryHelper(updateBoardEmployeeNote(shiftSummaryHelper, '', employee.id, authUserState.employee));
    setUserHasMadeChanges(true);
    notifyEmployeeNoteChanged(employee, '');
  };

  const icon = <Box component={Pencil01Icon16} sx={(theme) => ({ '& path': { stroke: theme.palette.common.white } })} />;

  return (
    <ControlledPopup
      delay={500}
      anchorComponent={
        <NoteRow icon={icon} isAnchor={true}>
          {noteOverride.note}
        </NoteRow>
      }
      popupComponent={
        <OverridePopoverContent
          override={noteOverride}
          label="Note"
          noteLabel={null}
          overrideByLabel="Author"
          icon={icon}
          colorMode="dark"
          handleEditClick={onEdit}
          handleDeleteClick={onDelete}
          noteProp={noteOverride.note}
        />
      }
    />
  );
};
