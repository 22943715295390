import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Alert, Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { differenceInMinutes, format } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  CalendarShiftCard,
  GenericDrawerOrModal,
  FeedbackMessage,
  Status,
  useLoadedDepartmentInfoContext,
  useLoadedAuthUserContext,
  getVisibleTags,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { DepartmentSettingsView, PostTimeOffRequestDTO, TimeOffLimit, Waitlist } from '@stationwise/share-types';
import { useIOSPaddingBottom, useIsIOSWebView } from '../../../../../context/DeviceInfoContext';
import { NonShiftTimeSelect } from './NonShiftTimeSelect';
import { TimeOffLimitSelect } from './TimeOffLimitSelect';
import { TimeOffNote } from './TimeOffNote';
import { TimeOffTimeSelect } from './TimeOffTimeSelect';
import { TimeOffWaitlist } from './TimeOffWaitlist';
import { RemainingTimeError } from './warning/RemainingTimeError';
import { WaitlistWarning } from './warning/WaitlistWarning';

interface TimeOffRequestProps {
  shift: EventInput | EventImpl;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  setRefetchEvents: Dispatch<SetStateAction<boolean>>;
  selectedTimeOffDates?: string[];
  setSelectedTimeOffDates: Dispatch<SetStateAction<string[]>>;
}

export const TimeOffRequest = ({
  shift,
  setOpen,
  open,
  setRefetchEvents,
  selectedTimeOffDates = [],
  setSelectedTimeOffDates,
}: TimeOffRequestProps) => {
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();
  const { state: authUserState } = useLoadedAuthUserContext();

  const [timeOffStart, setTimeOffStart] = useState<string>(shift.start as string);
  const [duration, setDuration] = useState<number>(24);
  const [timeOffEnd, setTimeOffEnd] = useState<string>(shift.end as string);
  const [selectedTimeOffLimit, setSelectedTimeOffLimit] = useState<TimeOffLimit | undefined>(undefined);
  const [timeOffLimits, setTimeOffLimits] = useState<TimeOffLimit[]>([]);
  const [timeOffNote, setTimeOffNote] = useState<string>('');
  const [durationError, setDurationError] = useState<boolean>(false);
  const [resultDrawerOpen, setResultDrawerOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>('success');
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [waitlist, setWaitlist] = useState<Waitlist | null>(null);

  const isIOSWebView = useIsIOSWebView();
  const iosWebViewPaddingBottom = useIOSPaddingBottom();
  const isAccrualsEnabled = departmentInfo.settings.accrualLogicEnabled;
  const isWaitlistEnabled = departmentInfo.settings.timeOffWaitlistEnabled;
  const { employee } = authUserState;

  const isDailyApprovedLimitReached = waitlist && waitlist.approvedCount >= waitlist.limit;
  const selectedTimeOffType = selectedTimeOffLimit ? selectedTimeOffLimit.payCode.name.toLowerCase() : 'time off';
  const selectedTimeOffPayCode = selectedTimeOffLimit ? selectedTimeOffLimit.payCode.code : '';
  const canSubmit = !!(
    selectedTimeOffLimit &&
    duration !== 0 &&
    !durationError &&
    (isWaitlistEnabled || !isDailyApprovedLimitReached)
  );

  const isNonShift = employee?.isNonShift ?? false;

  const reset = useCallback(() => {
    setTimeOffStart(shift.start as string);
    setDuration(24);
    setTimeOffEnd(shift.end as string);
    setSelectedTimeOffLimit(undefined);
    setTimeOffNote('');
    setDurationError(false);
    setResultDrawerOpen(false);
    setStatus('success');
    setStatusMessage('');
    setWaitlist(null);
    setSelectedTimeOffDates([]);
  }, [shift, setSelectedTimeOffDates]);

  useEffect(() => {
    if (selectedTimeOffDates.length > 0) {
      // assume that all selected dates have the same duration
      setDuration(selectedTimeOffDates.length * 24);
    }
  }, [selectedTimeOffDates]);

  useEffect(() => {
    if (timeOffStart && duration) {
      const endDate = new Date(timeOffStart);
      endDate.setHours(endDate.getHours() + duration);
      setTimeOffEnd(endDate.toString());
    }
  }, [timeOffStart, duration]);

  useEffect(() => {
    if (selectedTimeOffLimit) {
      if (duration > selectedTimeOffLimit.accruedTimeOff - selectedTimeOffLimit.pending && isAccrualsEnabled) {
        setDurationError(true);
      } else {
        setDurationError(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeOffLimit, duration]);

  useEffect(() => {
    const fetchTimeOffLimits = async () => {
      try {
        const response = await client.get('request/time-off-limits/');
        setTimeOffLimits(response.data);
      } catch (error) {
        setStatus('error');
        captureException(error);
        setStatusMessage('We were unable to process your time off request. Please try again later.');
      }
    };

    reset();
    fetchTimeOffLimits();
  }, [reset, shift]);

  useEffect(() => {
    if (selectedTimeOffPayCode === '') return;
    if (!shift.start) return;
    const shiftDate = format(shift.start.toString(), 'yyyy-MM-dd');
    const fetchWaitlist = async () => {
      try {
        const response = await client.get('/request/time-off-waitlist/', {
          params: {
            date: shiftDate,
            rank: authUserState.employee.rank.id,
            payCode: selectedTimeOffPayCode,
          },
        });
        setWaitlist(response.data === '' ? null : response.data);
      } catch (error) {
        captureException(error);
      }
    };
    fetchWaitlist();
  }, [shift, selectedTimeOffPayCode, authUserState.employee.rank.id, departmentInfo.settings.timeOffWaitlistEnabled]);

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const requestTimeOff = async (requestDto: PostTimeOffRequestDTO) => {
    if (!canSubmit) {
      return;
    }
    try {
      await client.post('request/time-off-request/', requestDto);
      setRefetchEvents(true);
      setStatus('success');
      setStatusMessage(`Your ${selectedTimeOffType} request has been successfully sent.`);
    } catch (error) {
      setStatus('error');
      const message = isAxiosError(error) ? error.response?.data.nonFieldErrors?.[0] : '';
      setStatusMessage(message || 'We were unable to process your time off request. Please try again later.');
    } finally {
      setOpen(false);
      setResultDrawerOpen(true);
    }
  };

  const handleRequest = () => {
    if (!canSubmit) {
      return;
    }

    if (selectedTimeOffDates.length > 0) {
      selectedTimeOffDates.forEach((date) => {
        const requestDto: PostTimeOffRequestDTO = {
          note: timeOffNote,
          pay_code: selectedTimeOffLimit.payCode.code,
          time_off_date: format(new Date(date), 'yyyy-MM-dd'),
          assigned_position_id: 0, // ignore this for now
          start_time: 0,
          end_time: 24 * 60,
        };
        requestTimeOff(requestDto);
      });
    } else {
      const shiftDate = shift.extendedProps?.['originalShiftDate'] || format(new Date(shift.start as string), 'yyyy-MM-dd');
      const requestDto: PostTimeOffRequestDTO = {
        note: timeOffNote,
        pay_code: selectedTimeOffLimit.payCode.code,
        time_off_date: shiftDate,
        //It is necessary to know which position the time off is being requested for in case there are split shifts
        assigned_position_id: Number(shift.id),
        start_time: 0,
        end_time: 24 * 60,
      };

      if (duration < 24) {
        const departmentShiftStart = `${shiftDate}T${departmentInfo.shiftStart}`;
        const shiftStart = new Date(departmentShiftStart).getTime();
        const startTimeInMinutes = (new Date(timeOffStart).getTime() - shiftStart) / (1000 * 60);
        const endTimeInMinutes = startTimeInMinutes + duration * 60;
        requestDto.start_time = startTimeInMinutes;
        requestDto.end_time = endTimeInMinutes;
      }
      requestTimeOff(requestDto);
    }
  };

  const createTimeOffDurationOptions = (dep: DepartmentSettingsView) => {
    return dep.settings.timeOffIntervals.map((value) => ({
      label: value === 24 ? 'Entire Shift' : `${value} hrs`,
      value: value,
    }));
  };

  const timeOffDurationOptions = createTimeOffDurationOptions(departmentInfo);

  const visibleTags = getVisibleTags(departmentInfo, 'TIME_OFF_REQ', 'paycode');
  const filteredTimeOffLimits = timeOffLimits.filter(
    (timeOffLimit) => (visibleTags.length > 0 ? timeOffLimit.payCode.tags?.some((tag) => visibleTags.includes(tag)) : true), // If no visible tags are specified, show all
  );
  return (
    <>
      <GenericDrawerOrModal
        anchor="bottom"
        drawerOpen={open}
        handleOnClose={handleClose}
        loading={false}
        showHeader
        headerTitle="Request time off"
        disableFooter
        noBorderOnHeader={true}
      >
        <Box sx={{ position: 'relative' }}>
          <TimeOffWaitlist setOpen={setShowWaitlist} isOpen={showWaitlist} waitlist={waitlist} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {selectedTimeOffDates.length > 0 ? (
              <Box sx={{ m: 2 }}>
                <Box
                  sx={(theme) => ({
                    color: theme.palette.stationGray[900],
                    typography: 'bodyLMedium',
                  })}
                >
                  Dates Off
                </Box>
                {[...selectedTimeOffDates]
                  .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
                  .map((date) => {
                    return <Box key={date}>{date}</Box>;
                  })}
              </Box>
            ) : (
              <CalendarShiftCard shift={shift} showIcon={false} />
            )}
            <Box
              sx={(theme) => ({
                m: theme.spacing(2),
              })}
            >
              {filteredTimeOffLimits.length > 0 && (
                <TimeOffLimitSelect
                  timeOffLimits={filteredTimeOffLimits}
                  selectedTimeOffLimit={selectedTimeOffLimit}
                  setSelectedTimeOffLimit={setSelectedTimeOffLimit}
                  isAccrualsEnabled={isAccrualsEnabled}
                />
              )}
              {isWaitlistEnabled && <WaitlistWarning setOpen={setShowWaitlist} waitlist={waitlist} />}
              {!isWaitlistEnabled && isDailyApprovedLimitReached && (
                <Alert sx={(theme) => ({ mt: theme.spacing(1) })} severity="error">
                  Limit reached for number of users taking time off on this day. You cannot request time off for this type.
                </Alert>
              )}
            </Box>
            <Box
              sx={{
                mx: 2,
                mt: selectedTimeOffDates.length > 0 ? 0 : 2,
              }}
            >
              <Box>
                {selectedTimeOffDates.length === 0 &&
                  (isNonShift && !!shift.start && !!shift.end && !!timeOffStart ? (
                    <NonShiftTimeSelect
                      setStartDateTime={setTimeOffStart}
                      setDuration={setDuration}
                      duration={differenceInMinutes(new Date(shift.end.toString()), new Date(shift.start.toString())) / 60}
                      shiftStart={new Date(shift.start.toString())}
                      shiftEnd={new Date(shift.end.toString())}
                      startDateTime={timeOffStart}
                    ></NonShiftTimeSelect>
                  ) : (
                    shift.start && (
                      <TimeOffTimeSelect
                        startDateTime={timeOffStart}
                        setStartDateTime={setTimeOffStart}
                        duration={duration}
                        setDuration={setDuration}
                        originalEndDateTime={shift.end as string}
                        originalStartDateTime={shift.start as string}
                        durationError={durationError && isAccrualsEnabled}
                        timeOffDurationOptions={timeOffDurationOptions}
                      />
                    )
                  ))}
                {durationError && selectedTimeOffLimit && isAccrualsEnabled && (
                  <RemainingTimeError
                    hours={selectedTimeOffLimit.accruedTimeOff - selectedTimeOffLimit.pending}
                    type={selectedTimeOffType}
                  />
                )}
                <TimeOffNote timeOffNote={timeOffNote} setTimeOffNote={setTimeOffNote} />
                <Box sx={{ height: '100px' }} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              position: 'sticky',
              bottom: 0,
              width: '100%',
              boxShadow: '0px -10px 10px -5px rgba(10, 14, 22, 0.04), 0px -20px 25px -5px rgba(10, 14, 22, 0.1)',
              p: theme.spacing(2),
              borderRadius: 2,
              backgroundColor: theme.palette.common.white,
              paddingBottom: isIOSWebView ? iosWebViewPaddingBottom : 'none',
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                mb: theme.spacing(2),
              })}
            >
              <Badge
                label={`${selectedTimeOffType} request`.toUpperCase()}
                overrideMaxLength={64}
                isCustom
                customColor="#323E51"
                customBackgroundColor="#DEE3ED"
              />
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                mb: theme.spacing(2),
                typography: 'bodyMRegular',
              })}
            >
              {timeOffStart && <Box>{format(timeOffStart, 'M/d/yy')}</Box>}

              {timeOffStart && (
                <Box sx={(theme) => ({ ml: theme.spacing(1), color: theme.palette.stationGray[400] })}>
                  {format(timeOffStart, 'HH:mm')}
                </Box>
              )}

              <Box sx={(theme) => ({ mx: theme.spacing(0.5), color: theme.palette.stationGray[400] })}>-</Box>

              {timeOffEnd && (
                <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
                  {duration !== 0 ? format(timeOffEnd, 'HH:mm') : '....'}
                </Box>
              )}
            </Box>
            <Box
              sx={(theme) => ({
                mr: theme.spacing(1),
              })}
            >
              <Button
                data-cy="request-time-off-submit-button"
                variant="contained"
                size="large"
                sx={{ width: '100%' }}
                onClick={handleRequest}
                disabled={!canSubmit}
              >
                {isWaitlistEnabled && isDailyApprovedLimitReached ? 'Add me to the waitlist' : 'Request time off'}
              </Button>
            </Box>
          </Box>
        </Box>
      </GenericDrawerOrModal>
      <GenericDrawerOrModal
        anchor="bottom"
        drawerOpen={resultDrawerOpen}
        handleOnClose={reset}
        footerButtons={[
          {
            label: 'Go back to calendar',
            onClick: reset,
          },
        ]}
        loading={false}
        paddingBottom={iosWebViewPaddingBottom}
      >
        <FeedbackMessage status={status} firstMessage={statusMessage} />
      </GenericDrawerOrModal>
    </>
  );
};
