import { useDocumentTitle, CAPABILITIES } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { ActivityHistoryMain } from './components/ActivityHistoryMain';

export const ActivityHistory = () => {
  useDocumentTitle('Change Log');
  return (
    <AppPageLayout
      sx={(theme) => ({ background: theme.palette.common.white })}
      requiredCapability={CAPABILITIES.VIEW_CHANGE_LOGS}
    >
      <ActivityHistoryMain />
    </AppPageLayout>
  );
};
