import { ReactComponent as CloseSymbol } from '@material-symbols/svg-400/outlined/close.svg';
import { Box, List, Popover, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useId, useMemo } from 'react';
import { Button, SvgIcon, StaffingListFilterState, StaffingListFilterGroup } from '@stationwise/component-module';
import { BasicFieldsBattalion, StaffingListItem, Team } from '@stationwise/share-types';

interface HiringEngineStaffinListFilterPopoverProps {
  items: StaffingListItem[];
  filterPopoverAnchorEl: HTMLElement | null;
  setFilterPopoverAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  filterStates: StaffingListFilterState[];
}

export const HiringEngineStaffinListFilterPopover = (props: HiringEngineStaffinListFilterPopoverProps) => {
  const id = useId();
  const theme = useTheme();

  const [teamFilterState, battalionFilterState] = props.filterStates;

  const { teamOptions } = useMemo(() => {
    const teams = new Map<string, Team>();

    props.items.forEach((item) => {
      item.employee.team && teams.set(item.employee.team.name, item.employee.team);
    });

    return {
      teamOptions: Array.from(teams.values()).sort((a, b) => a.name.localeCompare(b.name)),
    };
  }, [props.items]);

  const { battalionOptions } = useMemo(() => {
    const battalions = new Map<string, BasicFieldsBattalion>();
    props.items.forEach((item) => {
      item.employee.battalion && battalions.set(item.employee.battalion.name, item.employee.battalion);
    });

    return {
      battalionOptions: Array.from(battalions.values()).sort((a, b) => a.name.localeCompare(b.name)),
    };
  }, [props.items]);

  return (
    <Popover
      id={`${id}filter`}
      open={!!props.filterPopoverAnchorEl}
      anchorEl={props.filterPopoverAnchorEl}
      onClose={() => props.setFilterPopoverAnchorEl(null)}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: -8 }}
      sx={{ '& .MuiPaper-root': { width: 304, height: 548, pb: 9 } }}
    >
      <Box sx={{ height: '100%', position: 'relative', overflowY: 'auto', p: 2 }}>
        <Button
          color="inherit"
          size="small"
          onClick={() => props.setFilterPopoverAnchorEl(null)}
          startIcon={<SvgIcon component={CloseSymbol} />}
          sx={{ typography: 'buttonM', lineHeight: '18px' }}
        >
          Close
        </Button>
        <List component="div" disablePadding={true}>
          <StaffingListFilterGroup
            {...teamFilterState}
            title="Shift"
            options={teamOptions}
            getOptionKey={(option) => option.name}
            getOptionLabel={(option) => option.name}
          />
          <StaffingListFilterGroup
            {...battalionFilterState}
            title="Battalion"
            options={battalionOptions}
            getOptionKey={(option) => option.name}
            getOptionLabel={(option) => option.name}
          />
        </List>
      </Box>
      <Box
        sx={{
          background: theme.palette.common.white,
          borderTop: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          p: 2,
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
        }}
      >
        <Button
          data-cy="clear-staffing-list-filters-button"
          size="large"
          disabled={props.filterStates.every((state) => !state.selectedOptions.size)}
          startIcon={<SvgIcon component={CloseSymbol} />}
          onClick={() => props.filterStates.forEach((state) => state.setSelectedOptions(new Set()))}
        >
          Clear all
        </Button>
        <Button
          data-cy="apply-staffing-list-filters-button"
          variant="contained"
          size="large"
          disabled={props.filterStates.every((state) => !state.isDirty)}
          onClick={() => {
            props.setFilterPopoverAnchorEl(null);
            props.filterStates.forEach((state) => state.setAppliedOptions(state.selectedOptions));
          }}
        >
          Filter
        </Button>
      </Box>
    </Popover>
  );
};
