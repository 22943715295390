import { GenericDrawerOrModal } from '@stationwise/component-module';
import { AccrualModalContent } from './AccrualWarningContent';

interface AccrualWarningModalProps {
  accrualWarningModalOpen: boolean;
  closeAccrualWarningModal: () => void;
}

export const AccrualWarningModal = ({ accrualWarningModalOpen, closeAccrualWarningModal }: AccrualWarningModalProps) => {
  return (
    <GenericDrawerOrModal
      anchor="bottom"
      handleOnClose={closeAccrualWarningModal}
      drawerOpen={accrualWarningModalOpen}
      loading={false}
      disableFooter={true}
      sxProps={{
        '.MuiDrawer-paper': {
          borderRadius: '16px 16px 0 0',
          paddingBottom: 0,
          marginBottom: 0,
        },
      }}
    >
      <AccrualModalContent closeAccrualWarningModal={closeAccrualWarningModal} />
    </GenericDrawerOrModal>
  );
};
