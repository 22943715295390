import { ReactComponent as AddSymbol } from '@material-symbols/svg-400/outlined/add.svg';
import { Box, Dialog, DialogContent, DialogTitle, Modal, Tooltip, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, SvgIcon, DialogXButton } from '@stationwise/component-module';
import { RequestVolunteersDetailsView } from '@stationwise/share-types';
import { RequestVolunteersHistoryCard } from './RequestVolunteersHistoryCard';
import { RequestVolunteersHistoryContent } from './RequestVolunteersHistoryContent';

interface RequestVolunteersHistoryModalProps {
  modalOpen: boolean;
  setIsRequestVolunteersHistoryModalOpen: Dispatch<SetStateAction<boolean>>;
  requestVolunteers: RequestVolunteersDetailsView[];
  setRequestVolunteersIsOpen: Dispatch<SetStateAction<boolean>>;
  hasRosterChanges: boolean;
  hasVacancies: boolean;
}

export const RequestVolunteersHistoryModal = ({
  modalOpen,
  setIsRequestVolunteersHistoryModalOpen,
  requestVolunteers,
  setRequestVolunteersIsOpen,
  hasRosterChanges,
  hasVacancies,
}: RequestVolunteersHistoryModalProps) => {
  const [selectedRequestVolunteers, setSelectedRequestVolunteers] = useState<RequestVolunteersDetailsView | null>(null);
  const [selectedRequestVolunteersIndex, setSelectedRequestVolunteersIndex] = useState<number>(0);
  const [isRequestVolunteersDetailsModalOpen, setIsRequestVolunteersDetailsModalOpen] = useState(false);
  const closeDetailsModal = () => {
    setIsRequestVolunteersDetailsModalOpen(false);
    setSelectedRequestVolunteers(null);
  };

  const closeModal = () => {
    setIsRequestVolunteersHistoryModalOpen(false);
  };

  return (
    <Dialog
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={modalOpen}
      onClose={closeModal}
    >
      <DialogTitle>
        <Box sx={{ typography: 'bodyXLSemibold' }}>Request Volunteers</Box>
        <DialogXButton onClose={closeModal} />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="buttonL">Request History</Typography>
            {hasVacancies && (
              <Tooltip title={hasRosterChanges ? 'You should save your roster changes before creating a new request' : ''}>
                <span>
                  <Button
                    startIcon={<SvgIcon component={AddSymbol} />}
                    onClick={() => setRequestVolunteersIsOpen(true)}
                    disabled={hasRosterChanges}
                  >
                    Create a new request
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {requestVolunteers.map((requestVolunteers, index) => (
              <RequestVolunteersHistoryCard
                key={requestVolunteers.id}
                requestVolunteers={requestVolunteers}
                setSelectedRequestVolunteers={setSelectedRequestVolunteers}
                setIsRequestVolunteersDetailsModalOpen={setIsRequestVolunteersDetailsModalOpen}
                setSelectedRequestVolunteersIndex={setSelectedRequestVolunteersIndex}
                index={index + 1}
              />
            ))}
          </Box>
        </Box>
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={isRequestVolunteersDetailsModalOpen}
          onClose={closeModal}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.common.white,
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                padding: theme.spacing(3),
                flexDirection: 'column',
                height: '90vh',
                width: '60vw',
              })}
            >
              <RequestVolunteersHistoryContent
                requestVolunteers={selectedRequestVolunteers}
                closeModal={closeDetailsModal}
                index={selectedRequestVolunteersIndex}
              />
            </Box>
          </Box>
        </Modal>
      </DialogContent>
    </Dialog>
  );
};
