import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { Button, ButtonProps } from '@stationwise/component-module';

export interface InfoBannerProps {
  Icon: React.ElementType;
  Text: React.ReactNode;
  buttonVariant: ButtonProps['variant'];
  onClick?: () => void;
  buttonText: string;
  textColor: string;
  backgroundColor: string;
  isLoading?: boolean;
  disableButton?: boolean;
}

export const InfoBanner = ({
  Icon,
  Text,
  buttonText,
  buttonVariant,
  onClick,
  textColor,
  backgroundColor,
  isLoading = false,
  disableButton = false,
}: InfoBannerProps) => {
  const IconComp = (() => {
    if (Icon) {
      return <Icon />;
    }
    return null;
  })();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor,
        padding: '12px 24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <Box sx={{ height: '20px', width: '20px' }}>{IconComp}</Box>
        <Box sx={{ color: textColor, typography: 'bodySRegular' }}>{Text}</Box>
      </Box>
      <Box>
        {onClick && (
          <Tooltip
            title={disableButton ? 'Please wait 24 hours before resending this message.' : ''}
            disableHoverListener={!disableButton}
          >
            <span>
              <Button disabled={disableButton} loading={isLoading} variant={buttonVariant} size="small" onClick={onClick}>
                {buttonText}
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
