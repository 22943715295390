import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { BigArrowRightIcon, ChevronDownIcon24, ChevronRightIcon24 } from '../../assets';
import { BlankCard } from '../BlankCard';
import { Chip } from '../Card/Chip';

export const CalendarShiftCard = ({
  shift,
  children,
  handleClick,
  statusBadge,
  selectedView,
  showIcon = true,
}: {
  shift: EventInput | EventImpl;
  children?: React.ReactNode;
  handleClick?: (event: React.MouseEvent<HTMLDivElement>, shift: EventInput | EventImpl) => void;
  statusBadge?: React.ReactNode;
  selectedView?: string;
  showIcon?: boolean;
}) => {
  const isSmallScreen = useMediaQuery('(max-width:400px)');
  const truncateTitle = (title: string, maxLength: number) => {
    return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
  };

  return (
    <Box
      data-cy={`event-card-${makeTestIdentifier(shift.title || '')}`}
      className="shift-card-box"
      sx={{
        mx: 2,
        pb: { xs: selectedView === 'dayGridMonth' ? 2 : '', lg: '' },
      }}
      onClick={(event) => handleClick && handleClick(event, shift)}
    >
      <BlankCard sxProps={{ padding: '12px', pr: '6px' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={(theme) => ({ flexGrow: 1, mr: theme.spacing(1) })}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <Chip
                  label={truncateTitle((shift.title || '').toUpperCase(), isSmallScreen ? 32 : 37)}
                  chipSxProps={() => ({
                    background: shift.backgroundColor,
                    height: '24px',
                    p: '4px 8px',
                    borderRadius: '7px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    alignItems: 'flex-start',
                  })}
                  variant="eyebrow"
                  typographyStyles={{
                    color: shift.textColor ? shift.textColor : 'inherit',
                  }}
                />
              </Box>
              {statusBadge && <Box>{statusBadge}</Box>}
            </Box>

            <Box sx={(theme) => ({ mt: theme.spacing(1), display: 'flex', typography: 'bodyMRegular' })}>
              {shift.start?.toString() && <Box>{format(shift.start?.toString(), 'M/d/yy')}</Box>}
              <Box sx={{ ml: '9px', mr: '9px', mt: '2px' }}>
                <BigArrowRightIcon />
              </Box>
              {shift.end?.toString() && <Box>{format(shift.end?.toString(), 'M/d/yy')}</Box>}
              <Box
                display="flex"
                sx={(theme) => ({
                  ml: '10px',
                  color: theme.palette.stationGray[400],
                })}
              >
                {shift.start?.toString() && <Box>{format(shift.start?.toString(), 'HH:mm')}</Box>}
                <Box sx={{ ml: '3px', mr: '3px' }}>-</Box>
                {shift.end?.toString() && <Box>{format(shift.end?.toString(), 'HH:mm')}</Box>}
              </Box>
            </Box>
          </Box>
          {showIcon && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Divider orientation="vertical" flexItem />
              <Box
                sx={(theme) => ({
                  pl: theme.spacing(0.5),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '& svg': { color: theme.palette.stationGray[500] },
                })}
              >
                {shift.title === 'REGULAR' ? <ChevronDownIcon24 /> : <ChevronRightIcon24 />}
              </Box>
            </Box>
          )}
        </Box>
        {children}
      </BlankCard>
    </Box>
  );
};
