import React from 'react';
import { TeamFlag } from '../../../TeamFlag';
import { SelectCommonOption } from './SelectCommonOption';

export interface SelectShiftOptionProps {
  id?: number[];
  chipBackgroundColor?: string;
  chipLabel?: string;
  name: string;
  email?: string;
  userImg?: string;
  onClick?: () => void;
  checked?: boolean;
  count?: number;
}

export const SelectShiftOption = ({ chipBackgroundColor, name, onClick, checked, id }: SelectShiftOptionProps) => {
  const adornment = <TeamFlag color={chipBackgroundColor} />;

  return (
    <SelectCommonOption
      chipBackgroundColor={chipBackgroundColor}
      chipLabel=""
      name={name}
      email=""
      onClick={onClick}
      checked={checked}
      id={id}
      adornment={adornment}
    />
  );
};
