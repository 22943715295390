import { Box } from '@mui/material';
import { ElementType } from 'react';
import { Button, ButtonProps, PlusIcon20 } from '@stationwise/component-module';

type CreateButtonProps<E extends ElementType = 'button'> = ButtonProps<E>;

export const CreateButton = <E extends ElementType>({ children, sx, ...props }: CreateButtonProps<E>) => {
  return (
    <Button
      variant="contained"
      size="large"
      {...props}
      sx={[
        (theme) => ({
          position: 'absolute',
          bottom: '20px',
          right: '80px',
          borderRadius: '48px',
          height: '48px',
          px: 1.5,
          zIndex: theme.zIndex.appBar,
          gap: 1,
          [theme.breakpoints.down('lg')]: { minWidth: 0, p: 0, width: '48px', borderRadius: '50%' },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      <PlusIcon20 />
      <Box component="span" sx={(theme) => ({ [theme.breakpoints.down('lg')]: { display: 'none' } })}>
        {children}
      </Box>
    </Button>
  );
};
