import { SelectItem } from '@stationwise/component-module';

export const getFilterAndSortOptions = () => {
  const sortRules: SelectItem[] = [
    {
      label: 'Last overtime date',
      value: 'LAST_OVERTIME_DATE',
      helperText: 'Employees who have never worked OT are at the top of the list.',
    },
    {
      label: 'Last overtime staffed at',
      value: 'LAST_OVERTIME_STAFFED_AT',
      helperText: 'Sorted based on the timestamp employees were staffed from the respective OT list.',
    },
    { label: 'Reverse seniority', value: 'REVERSE_SENIORITY', helperText: 'The least senior person is prioritized.' },
    { label: 'Total overtime', value: 'TOTAL_OVERTIME', helperText: 'Sorted based on least amount of OT worked.' },
    {
      label: 'Priority overtime',
      value: 'PRIORITY_OVERTIME',
      helperText: "Prioritize employees working either yesterday's or tomorrow's shift, if it is different from today's shift.",
    },
  ];
  const tiebreakerRules: SelectItem[] = [
    {
      label: 'Last overtime date',
      value: 'LAST_OVERTIME_DATE',
      helperText: 'Employees who have never worked OT are at the top of the list.',
    },
    {
      label: 'Second last overtime date',
      value: 'SECOND_LAST_OVERTIME_DATE',
      helperText: 'If two people have the same last OT date, we consider the 2nd last overtime date.',
    },
    {
      label: 'Third last overtime date',
      value: 'THIRD_LAST_OVERTIME_DATE',
      helperText: 'If two people have the same last and second last OT dates, we consider the 3rd last overtime date.',
    },
    {
      label: 'Last overtime staffed at',
      value: 'LAST_OVERTIME_STAFFED_AT',
      helperText: 'Sorted based on the timestamp employees were added to the respective OT list.',
    },
    { label: 'Reverse seniority', value: 'REVERSE_SENIORITY', helperText: 'The least senior person is prioritized.' },
    { label: 'Total overtime', value: 'TOTAL_OVERTIME', helperText: 'Sorted based on least amount of OT worked.' },
    {
      label: 'Priority overtime',
      value: 'PRIORITY_OVERTIME',
      helperText: "Prioritize employees working either yesterday's or tomorrow's shift, if it is different from today's shift.",
    },
    {
      label: 'Employee name',
      value: 'NAME',
    },
    {
      label: 'Employee id',
      value: 'ID',
    },
  ];

  const excludeOptions: SelectItem[] = [
    {
      label: 'Other staffing lists',
      value: 'EXCLUDE_OTHER_STAFFING_LIST',
      helperText: 'Employees that are present in other staffing lists.',
    },
    {
      label: 'Unavailable',
      value: 'EXCLUDE_UNAVAILABLE',
      helperText: 'Employees that are assigned or off.',
    },
    {
      label: 'Non-shift',
      value: 'EXCLUDE_NON_SHIFT',
      helperText: 'Employees that are not assigned to any regular shift.',
    },
  ];
  const includeOptions: SelectItem[] = [
    {
      label: 'Other staffing lists',
      value: 'INCLUDE_OTHER_STAFFING_LIST',
      helperText: 'Employees that are present in other staffing lists.',
    },
    {
      label: 'Planned assignments',
      value: 'INCLUDE_PLANNED_SHIFT_TEMPLATE',
      helperText: 'Employees that have planned assignments.',
    },
    {
      label: 'Working on the previous or next shift date',
      value: 'INCLUDE_PREV_OR_NEXT_SHIFT_DATE_EMPLOYEES',
      helperText: 'Employees that planned or not they are working on the previous or next shift date.',
    },
    {
      label: 'Schedule overtime',
      value: 'INCLUDE_SCHEDULED_STAFFING_LIST_MARK',
      helperText: 'Employees that have scheduled overtime for the selected date.',
    },
  ];
  return { sortRules, includeOptions, excludeOptions, tiebreakerRules };
};
