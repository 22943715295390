import { Box, useTheme } from '@mui/material';
import { ExclamationMarkIcon24 } from '@stationwise/component-module';
import { IconCircle } from './IconCircle';

interface WarningMessageProps {
  primaryText: string;
  secondaryText?: string;
}

export const WarningMessage = (props: WarningMessageProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: theme.spacing(3, 3, 2, 3),
        textAlign: 'center',
        width: '464px',
        maxWidth: '100%',
      }}
    >
      <IconCircle sx={{ color: theme.palette.stationOrange[500], mb: 3 }}>
        <ExclamationMarkIcon24 />
      </IconCircle>
      <Box sx={{ typography: 'bodyXXLSemibold' }}>{props.primaryText}</Box>
      {props.secondaryText && <Box sx={{ mt: 2 }}>{props.secondaryText}</Box>}
    </Box>
  );
};
