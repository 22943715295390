import { EventSourceFuncArg } from '@fullcalendar/core/index.js';
import { Box, Typography } from '@mui/material';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  formatDate,
  useLogout,
  LoadingFailure,
  theme,
  useFetchNextShift,
  useLoadedDepartmentInfoContext,
  useSelectedBattalionPreference,
  useRequests,
  fetchShiftsData,
  REQUEST_CARD_STATUS,
  useConversations,
  useAuthUserCapabilities,
} from '@stationwise/component-module';
import { ConversationListView } from '@stationwise/share-types';
import { PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { ROUTES } from '../../../../core/Routes';
import { Audience } from './Audience';
import { Calendar } from './Calendar';
import { CancelMessageModal } from './CancelMessageModal';
import { Conversation } from './Conversation';
import { MessagesInbox } from './MessagesInbox';

export const DashboardContent = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    conversations,
    onCancelConversation,
    openCancelModal,
    forceRefetch,
    handleCloseCancelModal,
    handleCancelConversation,
    handleCreateConversation,
    handleUpdateConversation,
    hasNextPage,
    loadMore,
    searchConversation,
  } = useConversations();
  const [refetchCounter, setRefetchCounter] = useState(0);
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentContext.refreshTriggerChannel;

  const requests = useRequests(refetchCounter);

  const [open, setOpen] = useState(window.innerWidth >= 1340);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openAudience, setOpenAudience] = useState<boolean>(false);
  const [selectedConversation, setSelectedConversation] = useState<ConversationListView | null>(null);
  const [selectedConversationLock, setSelectedConversationLock] = useState<number | null>(null);
  const [refetchEvents, setRefetchEvents] = useState<boolean>(false);
  const [calendarDataError, setCalendarDataError] = useState<boolean>(false);
  const [selectedBattalionId, setSelectedBattalionId] = useSelectedBattalionPreference();
  const capabilities = useAuthUserCapabilities();
  const canSendMessages = capabilities.SEND_MESSAGES;
  const logout = useLogout();

  const retryAttempts = useRef(0);

  const handleRetry = useCallback(() => {
    retryAttempts.current += 1;
  }, []);

  const onComposeClick = useCallback(() => {
    setOpenDrawer(true);
    setOpenAudience(false);
    setSelectedConversation(null);
  }, []);

  const onConversationClick = useCallback(
    (id: number) => {
      const conversation = conversations.find((c) => c.id === id);
      if (conversation) {
        setSelectedConversation(conversation);
        setOpenDrawer(true);
        setOpenAudience(true);
      }
    },
    [conversations],
  );

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (data.triggerAll || data.message === PUSHER_UPDATE_MESSAGE) {
        setRefetchCounter((c) => c + 1);
      }
    };
    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.REQUEST, PUSHER_EVENT_TYPES.REVIEW, PUSHER_EVENT_TYPES.CANCELLATION];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [refreshTriggerChannel, refetchCounter, refetchEvents]);

  useEffect(() => {
    if (selectedConversation && selectedConversationLock !== selectedConversation.id) {
      setSelectedConversationLock(null);
      onConversationClick(selectedConversation.id);
    }
    // This is on purpose in order to avoid re rendering and
    // show the message note when clicking on cancel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations, selectedConversationLock]);

  const onCellClick = useCallback(
    (date: string) => {
      if (!open || !openDrawer) {
        navigate(`${ROUTES.SCHEDULE.fullRoute}?date=${date}`);
      }
    },
    [navigate, open, openDrawer],
  );

  const getAllCalendarData = useCallback(async (fetchInfo: EventSourceFuncArg, battalionId?: number) => {
    const formattedStartDate: string = formatDate(fetchInfo.start);
    const formattedEndDate: string = formatDate(fetchInfo.end);

    try {
      return await fetchShiftsData(formattedStartDate, formattedEndDate, battalionId);
    } catch {
      setCalendarDataError(true);
      return [];
    }
  }, []);

  const nextShiftFetcher = useFetchNextShift({ excludeToday: true });

  const closeAudience = useCallback(() => {
    setOpenAudience(false);
    setOpenDrawer(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth >= 1340);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const pendingRequest = requests[REQUEST_CARD_STATUS.PENDING];
    pendingRequest.initialize();
  }, [requests]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: open && openAudience ? 'calc(100% - 950px)' : open && !openAudience ? 'calc(100% - 500px)' : '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {!calendarDataError && (
          <Calendar
            onCellClick={onCellClick}
            setRefetchEvents={setRefetchEvents}
            getAllCalendarData={getAllCalendarData}
            refetchEvents={refetchEvents}
            refreshTriggerChannel={refreshTriggerChannel}
            selectedBattalionId={selectedBattalionId}
            setSelectedBattalionId={setSelectedBattalionId}
            setOpen={setOpen}
            open={open}
            count={requests.Pending.data.length}
          />
        )}
        {calendarDataError && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <LoadingFailure />
            </Box>
            <Typography variant="bodyXXLMedium" lineHeight="20px">
              {"Couldn't load your calendar."}
            </Typography>

            <Typography variant="bodyXXLMedium" lineHeight="20px">
              {"Let's give it another try"}
            </Typography>

            <Box
              sx={(theme) => ({
                borderRadius: '6px',
                backgroundColor: theme.palette.stationBlue[600],
                display: 'flex',
                padding: '9px 17px',
                justifyContent: 'center',
                alignItems: 'center',
                width: '240px',
                mt: theme.spacing(3),
                mb: theme.spacing(3),
              })}
              onClick={handleRetry}
            >
              <Typography sx={(theme) => ({ color: theme.palette.common.white })} variant="buttonM">
                Try again
              </Typography>
            </Box>

            <Typography>
              Or try{' '}
              <span style={{ color: theme.palette.stationBlue[600], cursor: 'pointer' }} onClick={logout}>
                signing out
              </span>{' '}
              and back in
            </Typography>
          </Box>
        )}
      </Box>
      {openAudience && selectedConversation && (
        <Box sx={{ overflowY: 'auto', width: '450px', height: '100%' }}>
          <Audience
            closeAudience={closeAudience}
            conversation={selectedConversation}
            handleCreateConversation={handleCreateConversation}
            refetchConversations={forceRefetch}
          />
        </Box>
      )}
      {open && (
        <Box sx={{ overflow: 'hidden', width: '500px', height: '100%' }}>
          <MessagesInbox
            conversations={conversations}
            requests={requests}
            nextShiftFetcher={nextShiftFetcher}
            onComposeClick={onComposeClick}
            onConversationClick={onConversationClick}
            onCancelConversation={onCancelConversation}
            isLoading={isLoading}
            setRefetchEvents={setRefetchEvents}
            setOpen={setOpen}
            hasNextPage={hasNextPage}
            loadMore={loadMore}
            searchConversation={searchConversation}
            canSendMessages={canSendMessages}
          />
          {canSendMessages && (
            <>
              <Box>
                <Conversation
                  openAudience={openAudience}
                  setOpenAudience={setOpenAudience}
                  openDrawer={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                  conversation={selectedConversation}
                  setSelectedConversation={setSelectedConversation}
                  onCancelConversation={onCancelConversation}
                  refetchConversations={forceRefetch}
                  handleCreateConversation={handleCreateConversation}
                  handleUpdateConversation={handleUpdateConversation}
                />
              </Box>
              <CancelMessageModal
                open={openCancelModal}
                onClose={handleCloseCancelModal}
                onCancelConversation={handleCancelConversation}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
