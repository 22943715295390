import { format, parseISO } from 'date-fns';
import { Dispatch, SetStateAction, memo } from 'react';
import { SearchAndFilter } from '@stationwise/component-module';
import { ListFieldsStaffingList, StaffingListItem } from '@stationwise/share-types';

interface SelectedStaffingListControlsProps {
  selectedStaffingList: ListFieldsStaffingList | undefined;
  searchInput: string;
  setSearchInput: Dispatch<SetStateAction<string>>;
  selectedTeam: string;
  setSelectedTeam: Dispatch<SetStateAction<string>>;
  selectedLastOvertimeDate: string;
  setSelectedLastOvertimeDate: Dispatch<SetStateAction<string>>;
  selectedStation: string;
  setSelectedStation: Dispatch<SetStateAction<string>>;
}

export const SelectedStaffingListControls = memo((props: SelectedStaffingListControlsProps) => {
  const items = props.selectedStaffingList?.items || [];

  const getOptionValues = (getOptionValue: (item: StaffingListItem) => string) => {
    const values = new Set(items.map(getOptionValue));
    values.delete('');
    return Array.from(values).sort((a, b) => a.localeCompare(b));
  };

  const teams = getOptionValues((item) => item.employee.team?.name || '');
  const lastOvertimeDates = getOptionValues((item) => item.attributes.lastThreeOvertimes?.[0]?.date || '').reverse();
  const stations = getOptionValues((item) => item.employee.station.name || '');

  const filters = [
    {
      name: 'Shift',
      options: teams.map((value) => ({ value, label: value })),
      selected: props.selectedTeam,
      onChange: props.setSelectedTeam,
    },
    {
      name: 'Work date',
      options: lastOvertimeDates.map((value) => ({ value, label: format(parseISO(value), 'MMM dd, yyyy') })),
      selected: props.selectedLastOvertimeDate,
      onChange: props.setSelectedLastOvertimeDate,
    },
    {
      name: 'Home station',
      options: stations.map((value) => ({ value, label: value })),
      selected: props.selectedStation,
      onChange: props.setSelectedStation,
    },
  ];

  const clearAllFilters = () => {
    props.setSelectedTeam('');
    props.setSelectedLastOvertimeDate('');
    props.setSelectedStation('');
  };

  return (
    <SearchAndFilter
      searchText={props.searchInput}
      setSearchText={props.setSearchInput}
      filters={filters}
      clearAllFilters={clearAllFilters}
      searchPlaceholder="Search for people"
    />
  );
});

SelectedStaffingListControls.displayName = 'Memo(SelectedStaffingListControls)';
