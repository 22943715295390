import { Box } from '@mui/material';
import React from 'react';
import { Checkbox } from '../../../Checkbox';

export interface SelectCommonOptionProps {
  id?: number[];
  chipBackgroundColor?: string;
  chipLabel?: string;
  name: string;
  email?: string;
  userImg?: string;
  onClick?: () => void;
  checked?: boolean;
  adornment?: React.ReactNode;
  count?: number;
}

export const SelectCommonOption = ({ name, onClick, checked, adornment, count }: SelectCommonOptionProps) => {
  return (
    <Box
      data-cy={`recipient-option-${name.toLowerCase().replace(' ', '-')}`}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: theme.spacing(2),
        })}
      >
        <Checkbox checked={checked} onClick={onClick} />
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(1),
            typography: 'bodyMMedium',
          })}
        >
          {adornment}
          {name}
        </Box>
      </Box>
      {count !== undefined && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            padding: '6px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '106px',
            border: `1px solid ${theme.palette.grey[200]}`,
            typography: 'bodySRegular',
          })}
        >
          {count} people
        </Box>
      )}
    </Box>
  );
};
