import { Box, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { HiringEngineVacancy } from '../../Vacancies/vacanciesHelper';
import { AutoHireVacancyInfoCard } from './AutoHireVacancyInfoCard';

interface VacanciesInfoProps {
  numberOfVacancies?: number;
  shiftDate: string;
  vacancies: HiringEngineVacancy[];
  onPressVacancy: (autoHireVacany: HiringEngineVacancy) => void;
}

export const VacanciesInfo = ({ numberOfVacancies, shiftDate, vacancies, onPressVacancy }: VacanciesInfoProps) => {
  return (
    <Box
      display="flex"
      sx={() => ({
        width: '92%',
        ml: '4%',
        mr: '4%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'left',
      })}
    >
      <Box>
        <Box
          display="flex"
          sx={{
            width: '100%',
            mt: '4%',
            mb: '4%',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="bodyLSemibold"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
            })}
          >
            Eligible for {numberOfVacancies} vacancies on {format(parseISO(shiftDate), 'MMM dd')}
          </Typography>
        </Box>
        {numberOfVacancies && numberOfVacancies > 0 && (
          <Box
            display="flex"
            sx={{
              width: '100%',
              height: '10%',
              mt: '4%',
              mb: '4%',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="bodyMRegular"
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
              })}
            >
              You can press to sign up for any of the positions below.
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {vacancies.map((vacancy, index) => (
          <AutoHireVacancyInfoCard key={index} vacancy={vacancy} onClick={() => onPressVacancy(vacancy)} />
        ))}
      </Box>
    </Box>
  );
};
