import { ButtonBase, Dialog, DialogProps, useTheme } from '@mui/material';
import { XCloseIcon24 } from '@stationwise/component-module';
import { ShiftPlanAction } from '@stationwise/share-types';
import { useShiftPlanContext } from '../ShiftPlanContext';

interface ShiftPlanDialogProps extends Omit<DialogProps, 'open' | 'onClose'> {
  action: ShiftPlanAction;
  open?: boolean;
}

export const ShiftPlanDialog = ({ action, children, ...props }: ShiftPlanDialogProps) => {
  const theme = useTheme();
  const { selectedAction, setSelectedAction } = useShiftPlanContext();

  const onClose = () => setSelectedAction(null);

  return (
    <Dialog open={action === selectedAction} onClose={onClose} {...props}>
      <ButtonBase
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          p: 1.5,
          '& svg path': { stroke: theme.palette.stationGray[400] },
        }}
      >
        <XCloseIcon24 />
      </ButtonBase>
      {children}
    </Dialog>
  );
};
