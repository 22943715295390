import { Box, Checkbox, FormControlLabel, PopperProps } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useLoadedDepartmentInfoContext } from '../../../Department';
import { InputLabel } from '../../../Input';
import { AdminSelectItem, AdminAutocomplete } from '../../../MultipleSelect';

const PAY_CODE_TYPE_ORDER = ['ACCRUALS_OR_BANKED_HOURS', 'TIME_OFF'];

interface PayAndDetailCodesProps {
  hasDutyDayPayCodes: boolean;
  selectedPayCodeOptions: AdminSelectItem[];
  selectedDutyDayPayCodeOptions: AdminSelectItem[];
  selectedDetailCodeOptions: AdminSelectItem[];
  setHasDutyDayPayCodes: Dispatch<SetStateAction<boolean>>;
  setSelectedPayCodeOptions: Dispatch<SetStateAction<AdminSelectItem[]>>;
  setSelectedDutyDayPayCodeOptions: Dispatch<SetStateAction<AdminSelectItem[]>>;
  setSelectedDetailCodeOptions: Dispatch<SetStateAction<AdminSelectItem[]>>;
}

export const PayAndDetailCodes = (props: PayAndDetailCodesProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;

  const payCodeOptions = departmentInfo.payCodes
    .map((pc, index) => ({ pc, index }))
    .sort((a, b) => {
      const aPayCodeTypeIndex = PAY_CODE_TYPE_ORDER.indexOf(a.pc.payCodeType);
      const bPayCodeTypeIndex = PAY_CODE_TYPE_ORDER.indexOf(b.pc.payCodeType);
      return aPayCodeTypeIndex - bPayCodeTypeIndex || a.index - b.index;
    })
    .map(({ pc }) => ({ label: pc.name, value: pc.code, secondLabel: pc.code }));

  const detailCodeOptions = departmentInfo.detailCodes.map((dc) => ({ label: dc.name, value: dc.code }));

  const popperProps: Partial<PopperProps> = {
    placement: 'top',
    sx: { '& .MuiAutocomplete-listbox': { maxHeight: '300px' } },
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <InputLabel>Pay codes</InputLabel>
        <AdminAutocomplete
          placeholder="Choose pay codes"
          options={payCodeOptions}
          onChange={(_event, value) => props.setSelectedPayCodeOptions(value)}
          value={props.selectedPayCodeOptions}
          multiple={true}
          slotProps={{ popper: popperProps }}
        />
      </Box>
      <FormControlLabel
        label="Use different pay codes on duty days"
        sx={(theme) => ({ m: theme.spacing(0, 0, -0.5, -1) })}
        control={
          <Checkbox
            size="small"
            checked={props.hasDutyDayPayCodes}
            onChange={(event) => props.setHasDutyDayPayCodes(event.target.checked)}
          />
        }
      />
      {props.hasDutyDayPayCodes && (
        <Box>
          <InputLabel>Duty day pay codes</InputLabel>
          <AdminAutocomplete
            placeholder="Choose duty day pay codes"
            options={payCodeOptions}
            onChange={(_event, value) => props.setSelectedDutyDayPayCodeOptions(value)}
            value={props.selectedDutyDayPayCodeOptions}
            multiple={true}
            slotProps={{ popper: popperProps }}
          />
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <InputLabel>Detail codes</InputLabel>
        <AdminAutocomplete
          placeholder="Choose detail codes"
          options={detailCodeOptions}
          onChange={(_event, value) => props.setSelectedDetailCodeOptions(value)}
          value={props.selectedDetailCodeOptions}
          multiple={true}
          slotProps={{ popper: popperProps }}
        />
      </Box>
    </>
  );
};
