import { Box, Link as MUILink } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { ChevronLeftIcon24 } from '@stationwise/component-module';
import { AppPageLayout } from '../../../../AppPageLayout';
import { EmployeePayrollContent } from './EmployeePayrollContent';

export const EmployeePayroll = () => {
  const { payrollId } = useParams();

  return (
    <AppPageLayout>
      <Box>
        <Box sx={{ px: 5, pt: 4, pb: 8 }}>
          <MUILink
            component={RouterLink}
            to="/app/payroll"
            underline="none"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              display: 'inline-flex',
              alignItems: 'center',
              gap: 3,
            })}
          >
            <ChevronLeftIcon24 />
            <Box
              component="h1"
              sx={{
                typography: 'heading4',
                m: 0,
              }}
            >
              Time cards
            </Box>
          </MUILink>
        </Box>
        <EmployeePayrollContent employeePayrollId={payrollId} />
      </Box>
    </AppPageLayout>
  );
};
