import { Box, Divider } from '@mui/material';
import { format } from 'date-fns';
import { CalendarIcon16 } from '@stationwise/component-module';
import { EmployeeTimeOff } from '@stationwise/share-types';

export const TimeOffModalContent = ({ title, timeOffs }: { title: string; timeOffs: EmployeeTimeOff[] }) => {
  return (
    <Box sx={(theme) => ({ my: theme.spacing(2) })}>
      <Box sx={(theme) => ({ mb: theme.spacing(1), typography: 'heading6' })}>{title}</Box>
      {timeOffs.length === 0 ? (
        <Box
          sx={(theme) => ({
            px: theme.spacing(2),
            pt: theme.spacing(2),
            typography: 'bodySMedium',
            color: theme.palette.stationGray[500],
          })}
        >
          No time off
        </Box>
      ) : (
        <>
          <Box
            sx={(theme) => ({
              height: '56px',
              px: theme.spacing(2),
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            <Box sx={{ width: '340px', typography: 'bodySMedium' }}> Pay code</Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ typography: 'bodySMedium' }}> Duration</Box>
          </Box>
          {timeOffs?.map((timeOff, index) => (
            <Box key={timeOff.id} sx={(theme) => ({ px: theme.spacing(2) })}>
              <Box
                key={timeOff.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Box sx={{ display: 'flex' }}>
                    <Box
                      sx={(theme) => ({
                        mr: theme.spacing(1),
                        display: 'flex',
                        alignItems: 'center',
                        '& svg': {
                          '& path': {
                            stroke: theme.palette.stationGray[400],
                          },
                        },
                      })}
                    >
                      <CalendarIcon16 />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {format(timeOff.startDate, 'MMM d, yyyy')}
                      <Box sx={(theme) => ({ ml: theme.spacing(1), color: theme.palette.stationGray[500] })}>
                        ({timeOff.startTimeStr})
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      mt: theme.spacing(1),
                      color: theme.palette.stationGray[500],
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                      maxWidth: '340px',
                    })}
                  >
                    {timeOff?.payCode?.name}
                  </Box>
                </Box>
                <Box
                  sx={(theme) => ({
                    color: theme.palette.stationGray[500],
                  })}
                >
                  {Math.round(Number(timeOff.duration) / 60)} h
                </Box>
              </Box>
              {index < timeOffs.length - 1 && <Divider sx={(theme) => ({ my: theme.spacing(2) })} />}
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};
