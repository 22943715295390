import { Box, DialogTitle } from '@mui/material';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import {
  RosterCancelShiftTradePayload,
  RosterCancelTimeOffPayload,
  RosterEmployee,
  TimeOffLimit,
} from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { checkIsPlannedEmployee, cutDuration, getEmployeeSplits, splitEmployee } from '@stationwise/shift-summary-helper';
import { RankBadge } from '../../../Badge';
import { Button } from '../../../Button';
import { useGet } from '../../hooks/useGet';
import { useRosterContext } from '../RosterContext';
import { MergeSplitsButtonGroup } from './MergeSplitsButtonGroup';
import { getTimeOffReasonOptions } from './Reason';
import { Split } from './Split';
import { UnstaffedCard } from './UnstaffedCard';
import { getSplitStatuses } from './getSplitStatuses';

interface SplitShiftOrTimeOffFormProps {
  employee: RosterEmployee;
}

export const SplitShiftOrTimeOffForm = ({ employee }: SplitShiftOrTimeOffFormProps) => {
  const {
    employeeOffPayloads,
    selectedFilledPositionState,
    splitShiftOrTimeOffState,
    setUserHasMadeChanges,
    shiftSummaryHelper,
    setShiftSummaryHelper,
  } = useRosterContext();
  const { departmentInfo, shiftDuration } = shiftSummaryHelper;
  const isPlannedEmployee = checkIsPlannedEmployee(shiftSummaryHelper, employee);

  const [initialEmployeeSplits] = useState(() => {
    const employeeSplits = getEmployeeSplits({ ...employeeOffPayloads, shiftSummaryHelper });
    const employeeIds = new Set([employee.id]);
    (employeeSplits.get(employee.id) || []).forEach((split) => {
      if (split.reference.type === 'SHIFT_TRADE_REQUEST') {
        employeeIds.add(split.reference.receiver.id);
      } else if (split.reference.type === 'ASSIGNMENT' && split.reference.trade) {
        employeeIds.add(split.reference.trade.requester.id);
      }
    });
    return new Map(
      Array.from(employeeIds).map((employeeId) => {
        const splits = (employeeSplits.get(employeeId) || []).map((split) => ({ ...split, backup: split }));
        return [employeeId, splits];
      }),
    );
  });
  const [employeeSplits, setEmployeeSplits] = useState(initialEmployeeSplits);
  const [cancelShiftTradePayloads, setCancelShiftTradePayloads] = useState<RosterCancelShiftTradePayload[]>([]);
  const [cancelTimeOffPayloads, setCancelTimeOffPayloads] = useState<RosterCancelTimeOffPayload[]>([]);

  const initialSplits = useMemo(() => initialEmployeeSplits.get(employee.id) || [], [employee.id, initialEmployeeSplits]);
  const splits = employeeSplits.get(employee.id) || [];
  const unstaffedDurations = cutDuration({ startDateTime: shiftDuration.startTime, endDateTime: shiftDuration.endTime }, splits);

  const { data: accruals } = useGet<TimeOffLimit[]>(`/employee/accruals/?employeeId=${employee.id}`);

  const timeOffReasonOptions = useMemo(() => {
    return getTimeOffReasonOptions(departmentInfo, accruals, initialSplits);
  }, [departmentInfo, accruals, initialSplits]);

  const commonSplitProps = {
    employee,
    isPlannedEmployee,
    employeeSplits,
    splits,
    shiftSummaryHelper,
    timeOffReasonOptions,
    setEmployeeSplits,
    setCancelShiftTradePayloads,
    setCancelTimeOffPayloads,
  };

  const splitStatuses = getSplitStatuses(commonSplitProps);

  const [submitted, setSubmitted] = useState(false);

  const isEverySplitValid = splitStatuses.every(({ errors }) => Array.from(errors.values()).filter(Boolean).length === 0);
  const canSubmit = employeeSplits !== initialEmployeeSplits && isEverySplitValid && !submitted;

  const onSubmit = async () => {
    setSubmitted(true);
    let newShiftSummaryHelper = { ...shiftSummaryHelper };
    employeeSplits.forEach((splits, employeeId) => {
      newShiftSummaryHelper = splitEmployee(newShiftSummaryHelper, employeeId, splits);
    });
    newShiftSummaryHelper.cancelShiftTradePayloads = [...newShiftSummaryHelper.cancelShiftTradePayloads];
    newShiftSummaryHelper.cancelShiftTradePayloads.push(...cancelShiftTradePayloads);
    newShiftSummaryHelper.cancelTimeOffPayloads = [...newShiftSummaryHelper.cancelTimeOffPayloads];
    newShiftSummaryHelper.cancelTimeOffPayloads.push(...cancelTimeOffPayloads);
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    selectedFilledPositionState.closeDialog();
    splitShiftOrTimeOffState.setIsDialogOpen(false);
  };

  return (
    <Box sx={{ p: 3, position: 'relative', overflowY: 'auto' }}>
      <DialogTitle component="h2" sx={{ p: 0, m: 0, typography: 'bodyXLSemibold' }}>
        Modify or split shift
      </DialogTitle>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 3, typography: 'bodyMMedium' }}>
        <RankBadge rank={employee.rank} />
        {employee.name}
      </Box>
      {(() => {
        const cards = new Map<Date, ReactNode>();
        splits.forEach((split, index) => {
          const splitProps = {
            ...commonSplitProps,
            splitStatuses,
            split,
            splitStatus: splitStatuses[index],
            index,
          };
          cards.set(
            split.startDateTime,
            <Fragment key={`split|${index}|${splits.length}`}>
              <Split {...splitProps} />
              <MergeSplitsButtonGroup {...splitProps} />
            </Fragment>,
          );
        });
        unstaffedDurations.forEach((duration, index) => {
          cards.set(
            duration.startDateTime,
            <UnstaffedCard key={`unstaffed|${index}|${unstaffedDurations.length}`} duration={duration} />,
          );
        });
        return Array.from(cards.keys())
          .sort((a, b) => differenceInUTCMinutes(a, b))
          .map((key) => cards.get(key));
      })()}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
        <Button
          data-cy="manage-cancel-button"
          onClick={() => splitShiftOrTimeOffState.setIsDialogOpen(false)}
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
        <Button data-cy="manage-apply-button" onClick={onSubmit} disabled={!canSubmit} variant="contained" size="large">
          Confirm
        </Button>
      </Box>
    </Box>
  );
};
