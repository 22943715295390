import { Box } from '@mui/material';
import { ChevronRightIcon16 } from '../../../../assets';

interface NoteRowProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  isAnchor?: boolean;
}

export const NoteRow = ({ icon, children, isAnchor = false }: NoteRowProps) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.stationGray[800],
        borderRadius: 2,
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        p: theme.spacing(1.5, 1),
        width: '100%',
        ...(isAnchor && { '&:hover': { background: theme.palette.stationGray[700] } }),
      })}
    >
      {icon}
      <Box sx={{ flex: 1, minWidth: '1px', typography: 'bodyMRegular' }}>
        <Box sx={{ maxWidth: '225px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{children}</Box>
      </Box>
      {isAnchor && <Box component={ChevronRightIcon16} sx={(theme) => ({ color: theme.palette.stationGray[400] })} />}
    </Box>
  );
};
