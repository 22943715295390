import { Grid, Typography, Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { Button } from '../Button';
import { theme } from '../ThemeProvider';

export interface PayPeriodsGridProps {
  payPeriods: Array<{ startDate: string }>;
  handleTempDateChange: (date: string) => void;
  tempSelectedDate: string | null;
  currentSelectedDate: string | null;
}

export const PayPeriodsGrid: React.FC<PayPeriodsGridProps> = ({
  payPeriods,
  handleTempDateChange,
  tempSelectedDate,
  currentSelectedDate,
}) => {
  return (
    <Box
      className="drawer-content"
      flexGrow={1}
      overflow="auto"
      mt={1}
      mb={2}
      p={1}
      border={1}
      borderRadius="8px"
      display="grid"
      gridTemplateColumns="repeat(4, 1fr)"
      gridAutoRows="minmax(50px, auto)"
      sx={(theme) => ({
        border: `1px solid ${theme.palette.stationGray[200]}`,
      })}
    >
      {payPeriods.map((period, index) => (
        <Grid item xs={3} key={index}>
          <Button
            variant="text"
            size="large"
            fullWidth
            onClick={() => handleTempDateChange(period.startDate)}
            sx={(theme) => ({
              borderRadius: '9999px',
              border:
                tempSelectedDate === period.startDate
                  ? `2px solid ${theme.palette.stationGray[900]}`
                  : currentSelectedDate === period.startDate
                    ? `2px solid ${theme.palette.stationGray[300]}`
                    : 'none',
              color:
                tempSelectedDate === period.startDate
                  ? theme.palette.stationGray[900]
                  : currentSelectedDate === period.startDate
                    ? theme.palette.stationGray[300]
                    : theme.palette.common.black,
            })}
          >
            <Typography variant="bodySMedium" sx={(theme) => ({ color: theme.palette.stationGray[900] })}>
              {`${format(parseISO(period.startDate), 'MMM')} `}{' '}
              <span style={{ color: theme.palette.stationGray[400] }}> {` ${format(parseISO(period.startDate), 'd')}`} </span>
            </Typography>
          </Button>
        </Grid>
      ))}
    </Box>
  );
};
