import { Box, Typography, useTheme } from '@mui/material';
import { RankBadge } from '@stationwise/component-module';
import { AllowedColors } from '@stationwise/share-types';

interface LeaderInfoProps {
  stationLeader: string;
  stationLeaderRankCode: string;
  stationLeaderRankColor: string;
}

export const LeaderInfo = ({ stationLeader, stationLeaderRankCode, stationLeaderRankColor }: LeaderInfoProps) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      sx={() => ({
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderBottomLeftRadius: theme.spacing(1.5),
        borderBottomRightRadius: theme.spacing(1.5),
        boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.102)',
      })}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={() => ({
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            p: theme.spacing(1.5),
            gap: theme.spacing(1),
          })}
        >
          <RankBadge
            rank={{
              name: stationLeaderRankCode,
              code: stationLeaderRankCode,
              color: stationLeaderRankColor as AllowedColors,
            }}
          />
          <Typography variant="bodyMMedium">{stationLeader}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
