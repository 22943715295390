import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  createFilterOptions,
  InputAdornment,
  ListItem,
  Popper,
  PopperProps as CustomPopperProps,
  TextField,
} from '@mui/material';
import { useRef } from 'react';
import { DetailCode, PayCode } from '@stationwise/share-types';
import { ChevronDownIcon16, OverrideCircleGrayIcon } from '../../assets';

interface CodeAutocompleteProps {
  label: string;
  handleChange: (event: React.SyntheticEvent, value: PayCode | DetailCode) => void;
  options: PayCode[] | DetailCode[];
  getDisplayedOption?: (option: PayCode | DetailCode) => PayCode | DetailCode;
  value: PayCode | DetailCode;
  showIconAdornment?: boolean;
  isDisabled?: boolean;
  isDarkBackground?: boolean;
}

type LightPopperProps = CustomPopperProps;

const CustomPopper = (props: CustomPopperProps) => (
  <Popper
    {...props}
    style={{ width: 'fit-content' }}
    sx={(theme) => ({
      '& .MuiAutocomplete-listbox': {
        backgroundColor: theme.palette.stationGray[700],
        border: `1px solid ${theme.palette.stationGray[600]}`,
        '.MuiAutocomplete-option': {
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          '&[aria-selected="true"], &.Mui-focused, &[aria-selected="true"].Mui-focused, &:hover': {
            backgroundColor: theme.palette.stationGray[800],
          },
        },
      },
      '& .MuiAutocomplete-noOptions': {
        backgroundColor: theme.palette.stationGray[700],
        color: theme.palette.stationGray[300],
        border: `1px solid ${theme.palette.stationGray[600]}`,
      },
    })}
  />
);

const LightPopper = (props: LightPopperProps) => (
  <Popper
    {...props}
    style={{ width: 'fit-content' }}
    sx={{
      '& .MuiAutocomplete-listbox': {
        '.MuiAutocomplete-option': {
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
        },
      },
    }}
  />
);

export const CodeAutocomplete = ({
  label,
  handleChange,
  options,
  getDisplayedOption = (option) => option,
  value,
  showIconAdornment = false,
  isDisabled = false,
  isDarkBackground = true,
}: CodeAutocompleteProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleParentClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
      // Simulate a key down event to open the Popper -- this is a hacky workaround
      const event = new KeyboardEvent('keydown', { bubbles: true, key: 'ArrowDown' });
      inputRef.current.dispatchEvent(event);
    }
  };

  let longestOptionCode = '';
  for (const option of options) {
    if (getDisplayedOption(option).code.length > longestOptionCode.length) {
      longestOptionCode = getDisplayedOption(option).code;
    }
  }

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <Box
      onClick={isDisabled ? () => undefined : handleParentClick}
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
        ...(isDarkBackground && {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.stationGray[900],
        }),
        borderRadius: 2,
        height: '40px',
        mb: 1,
        px: 1.5,
        '&:hover': {
          cursor: isDisabled ? 'default' : 'pointer',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          flex: '0 1 auto',
          typography: 'bodySRegular',
          ...((isDarkBackground || isDisabled) && {
            color: theme.palette.stationGray[400],
          }),
        })}
      >
        {label}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          flex: 1,
        }}
      >
        {showIconAdornment && <OverrideCircleGrayIcon />}
        <TextField
          {...params}
          variant="standard"
          inputRef={inputRef}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <ChevronDownIcon16 />
              </InputAdornment>
            ),
          }}
          sx={(theme) => ({
            flex: 1,
            '& .MuiInputBase-root': {
              border: 'none',
              ...(isDarkBackground && {
                color: theme.palette.common.white,
              }),
              typography: 'bodyLSemibold',
              transition: 'none',
              '&::after, &::before': {
                borderBottom: 'none',
                transition: 'none',
              },
              ...(!isDisabled && {
                '&:hover': {
                  '&::after, &::before': {
                    borderBottom: 'none',
                  },
                },
              }),
              '&:focus': {
                '&::after, &::before': {
                  borderBottom: 'none',
                },
              },
            },
            '& .MuiInputBase-input': {
              textAlign: 'right',
              ...(isDarkBackground && {
                '&.Mui-disabled': {
                  color: theme.palette.common.white,
                  WebkitTextFillColor: theme.palette.common.white,
                },
              }),
            },
            ...(isDarkBackground && {
              '& .MuiInputAdornment-positionEnd': {
                color: theme.palette.stationGray[400],
              },
            }),
            '& .Mui-disabled .MuiInputAdornment-root': {
              display: 'none',
              visibility: 'hidden',
            },
          })}
        />
      </Box>
    </Box>
  );

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: PayCode | DetailCode) => {
    return (
      <ListItem
        {...props}
        key={option.code}
        {...(isDarkBackground && {
          sx: (theme) => ({
            backgroundColor: theme.palette.stationGray[700],
            color: theme.palette.common.white,
            '&:hover, &:hover': {
              backgroundColor: theme.palette.stationGray[800],
            },
          }),
        })}
      >
        <Box
          sx={(theme) => ({
            ...(isDarkBackground && { color: theme.palette.stationGray[300] }),
            typography: 'bodySRegular',
          })}
        >
          {getDisplayedOption(option).name}
        </Box>
        {getDisplayedOption(option).code !== getDisplayedOption(option).name && (
          <Box sx={{ typography: 'bodyMRegular' }}>{getDisplayedOption(option).code}</Box>
        )}
      </ListItem>
    );
  };

  return (
    <Autocomplete
      disabled={isDisabled}
      onChange={isDisabled ? undefined : handleChange}
      options={options}
      filterOptions={createFilterOptions({
        stringify: (option) => `${getDisplayedOption(option).name} ${getDisplayedOption(option).code}`,
      })}
      value={value}
      getOptionLabel={(option) => getDisplayedOption(option).code}
      renderInput={renderInput}
      renderOption={renderOption}
      isOptionEqualToValue={(option, value) => option.code === value.code && option.name === value.name}
      PopperComponent={isDarkBackground ? CustomPopper : LightPopper}
      disableClearable
      sx={{
        '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot': {
          paddingRight: 0,
        },
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          minWidth: 13 * Math.max(3, Math.min(12, longestOptionCode.length)),
        },
      }}
    />
  );
};
