import { Alert, Box, Dialog, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Input,
  MultipleSelect,
  AdminSelect,
  getCertColors,
  useDepartmentInfoContext,
} from '@stationwise/component-module';
import { Certification } from '@stationwise/share-types';
import { arraysAreEqualIgnoreOrder } from '@stationwise/share-utils';
import { usePatchRequest } from './PatchRequestProvider';

interface EditQualificationsProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  certifications: Certification[];
  driversLicenseNumber: string | null;
  driversLicenseClasses: string[];
  rank: string;
}

export const EditQualifications = ({
  showModal,
  setShowModal,
  certifications,
  driversLicenseNumber,
  driversLicenseClasses,
  rank,
}: EditQualificationsProps) => {
  const theme = useTheme();
  const [selectedCertifications, setSelectedCertifications] = useState<string[]>([]);
  const [selectedLicenseNo, setSelectedLicenseNo] = useState(driversLicenseNumber);
  const [selectedLicenseClasses, setSelectedLicenseClasses] = useState<string[]>(driversLicenseClasses);
  const [selectedRank, setSelectedRank] = useState<string>(rank);
  const { state: department } = useDepartmentInfoContext();

  const { handlePatchRequest } = usePatchRequest();

  const certificationOptions = department.departmentInfo?.certifications.map((cert) => ({
    label: cert.name,
    value: cert.code,
    color: getCertColors({ theme, color: cert.color }).color,
  }));
  const initialEmployeeCertifications = certifications.map((cert) => cert.code);
  const rankOptions = department.departmentInfo?.ranks.map((rank) => ({
    label: rank.name,
    value: rank.name,
  }));
  const licenseClassOptions = ['A', 'B', 'C', 'D', 'E'].map((cls) => ({
    label: '',
    value: cls,
  }));

  useEffect(() => {
    setSelectedCertifications(initialEmployeeCertifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certifications]);

  const handleSave = () => {
    const data = {
      rank: selectedRank,
      driversLicenseNumber: selectedLicenseNo,
      driversLicenseClasses: selectedLicenseClasses,
      certifications: selectedCertifications,
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedCertifications(initialEmployeeCertifications);
    setSelectedRank(rank);
    setSelectedLicenseNo(driversLicenseNumber);
    setSelectedLicenseClasses(driversLicenseClasses);
  };

  const isDisabled = useMemo(() => {
    const isEmptyRank = selectedRank === '';
    const hasNoChange =
      arraysAreEqualIgnoreOrder(selectedCertifications, initialEmployeeCertifications) &&
      selectedLicenseNo === driversLicenseNumber &&
      selectedRank === rank &&
      arraysAreEqualIgnoreOrder(driversLicenseClasses, selectedLicenseClasses);
    return isEmptyRank || hasNoChange;
  }, [
    selectedLicenseClasses,
    selectedRank,
    selectedLicenseNo,
    selectedCertifications,
    initialEmployeeCertifications,
    driversLicenseClasses,
    driversLicenseNumber,
    rank,
  ]);

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Qualifications
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Rank
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            {rankOptions && (
              <AdminSelect
                items={rankOptions}
                selectedItem={selectedRank}
                setSelectedItem={setSelectedRank}
                placeholder="Choose a rank"
              />
            )}
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Certification
          </Box>

          {initialEmployeeCertifications.some((cert) => !selectedCertifications.includes(cert)) && (
            <Alert severity="info" sx={(theme) => ({ my: theme.spacing(1) })}>
              If the employee is no longer qualified, existing shift trades could be automatically canceled.
            </Alert>
          )}
          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            {certificationOptions && (
              <MultipleSelect
                items={certificationOptions}
                selectedItems={selectedCertifications}
                setSelectedItems={setSelectedCertifications}
                placeholder="Add certification"
              />
            )}
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            {"Driver's license no."}
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <Input
              value={selectedLicenseNo}
              onChange={(event) => {
                setSelectedLicenseNo(event.currentTarget.value);
              }}
              placeholder="Enter a license number"
            />
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            {"Driver's license classes"}
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <MultipleSelect
              items={licenseClassOptions}
              selectedItems={selectedLicenseClasses}
              setSelectedItems={setSelectedLicenseClasses}
              placeholder="Select license classes"
            />
          </Box>

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="contained" size="large" sx={{ width: '216px' }} onClick={() => handleSave()} disabled={isDisabled}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
