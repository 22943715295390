import { useLocation } from 'react-router-dom';
import { RequestVolunteersSignUpMain } from './components/RequestVolunteersSignUpMain';

export const RequestVolunteersSignUp = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const requestVolunteersId = queryParams.get('request_volunteers_id');

  return (
    <RequestVolunteersSignUpMain
      getRequestVolunteersSignUpDto={{
        requestVolunteersId: requestVolunteersId || '',
      }}
    />
  );
};
