import { RosterApparatus, RosterDataSource, RosterPosition, RosterShiftDuration, RosterStation } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { IShiftSummaryHelper } from '@stationwise/shift-summary-helper';
import { ManageApparatusesPayload } from './apparatuses';
import { sendPositionEmployeesToFloaterStation } from './assignments';
import { buildAddress, ManageStationsPayload } from './stations';

export interface PositionPayload {
  actingRankId: number;
  startTime: number;
  endTime: number;
  certificationIds: number[];
  id?: string;
  isTemporary?: boolean;
  employees: EmployeePositionPayload[];
}

export interface EmployeePositionPayload {
  employeeId?: string;
  activeId: string;
  startTime: number;
  endTime: number;
}

export interface ManagePositionsPayload {
  create: PositionPayload[];
  update: { id: string; position: PositionPayload }[];
  delete: string[];
}

type ActionType = 'create' | 'update' | 'delete';

export const buildPosition = () => {
  // this fake id is used to track object that were not saved on the db yet
  const newPosition: RosterPosition = {
    id: `${Math.floor(Date.now() * Math.random())}`,
    dataSource: RosterDataSource.STATION,
    startDateTime: new Date(),
    endDateTime: new Date(),
    employees: [],
    driver: false,
    rank: {
      id: 0,
      isOfficer: false,
      name: '',
      color: 'black',
      code: '',
      sortOrder: 0,
      payPeriodType: '',
    },
    certifications: [],
    isNewlyCreated: true,
    isTemporary: false,
  };
  return newPosition;
};

const emptyPayload = {
  create: [],
  update: [],
  delete: [],
};

export const createPosition = (
  shiftTemplateHelper: IShiftSummaryHelper,
  position: RosterPosition,
  positionApparatus: RosterApparatus,
  apparatusStation: RosterStation,
) => {
  const newAllStationCards = new Map();

  for (const [stationId, station] of shiftTemplateHelper.allStationCards.entries()) {
    const copyStation = { ...station };

    if (stationId === apparatusStation.stationId) {
      const apparatusIndex = station.apparatuses.findIndex(
        (app) => app.id === positionApparatus.id || positionApparatus.id === app.name,
      );
      if (apparatusIndex >= 0) {
        copyStation.apparatuses[apparatusIndex] = {
          ...copyStation.apparatuses[apparatusIndex],
          positions: [...copyStation.apparatuses[apparatusIndex].positions, position],
        };
      }
    }

    newAllStationCards.set(stationId, copyStation);
  }
  return { ...shiftTemplateHelper, allStationCards: newAllStationCards };
};

export const updatePosition = (
  shiftTemplateHelper: IShiftSummaryHelper,
  position: RosterPosition,
  positionApparatus: RosterApparatus,
  apparatusStation: RosterStation,
) => {
  const newAllStationCards = new Map();

  for (const [stationId, station] of shiftTemplateHelper.allStationCards.entries()) {
    const copyStation = { ...station };

    if (stationId === apparatusStation.stationId) {
      const apparatusIndex = station.apparatuses.findIndex(
        (app) => app.id === positionApparatus.id || positionApparatus.id === app.name,
      );
      if (apparatusIndex >= 0) {
        const positionIndex = copyStation.apparatuses[apparatusIndex].positions.findIndex((pos) => pos.id === position.id);
        if (positionIndex >= 0) {
          copyStation.apparatuses[apparatusIndex].positions[positionIndex] = {
            ...copyStation.apparatuses[apparatusIndex].positions[positionIndex],
            ...position,
          };
        }
      }
    }

    newAllStationCards.set(stationId, copyStation);
  }
  return { ...shiftTemplateHelper, allStationCards: newAllStationCards };
};

export const deletePosition = (
  shiftTemplateHelper: IShiftSummaryHelper,
  position: RosterPosition,
  positionApparatus: RosterApparatus,
  apparatusStation: RosterStation,
) => {
  const newAllStationCards = new Map();

  for (const [stationId, station] of shiftTemplateHelper.allStationCards.entries()) {
    const copyStation = { ...station };

    if (stationId === apparatusStation.stationId) {
      const apparatusIndex = station.apparatuses.findIndex(
        (app) => app.id === positionApparatus.id || positionApparatus.id === app.name,
      );
      if (apparatusIndex >= 0) {
        if (position.employees.length > 0) {
          sendPositionEmployeesToFloaterStation(shiftTemplateHelper, position, positionApparatus);
        }
        copyStation.apparatuses[apparatusIndex].positions = copyStation.apparatuses[apparatusIndex].positions.filter(
          (pos) => pos.id !== position.id,
        );
      }
    }

    newAllStationCards.set(stationId, copyStation);
  }
  return { ...shiftTemplateHelper, allStationCards: newAllStationCards };
};

const addPositionChangesToApparatusPayload = (
  shiftDuration: RosterShiftDuration,
  action: ActionType,
  apparatusesPayload: ManageApparatusesPayload,
  positionApparatus: RosterApparatus,
  position: RosterPosition,
) => {
  if (action === 'create') {
    addCreatePositionToApparatusPayload(shiftDuration, apparatusesPayload, positionApparatus, position);
  } else if (action === 'update') {
    addUpdatePositionToApparatusPayload(shiftDuration, apparatusesPayload, positionApparatus, position);
  } else if (action === 'delete') {
    addDeletePositionToApparatusPayload(apparatusesPayload, positionApparatus, position);
  }
};

export const addPositionChangesToStationsPayload = (
  shiftDuration: RosterShiftDuration,
  manageStationsPayload: ManageStationsPayload,
  position: RosterPosition,
  positionApparatus: RosterApparatus,
  apparatusStation: RosterStation,
  action: ActionType,
) => {
  const newManageStationsPayload = { ...manageStationsPayload };

  const newStationIndex = newManageStationsPayload.create.findIndex(
    (stationToCreate) => stationToCreate.name === apparatusStation.stationName,
  );
  const stationToUpdateIndex = newManageStationsPayload.update.findIndex(
    (stationToUpdate) => stationToUpdate.id === apparatusStation.stationId,
  );
  // the position is being added to a new station
  if (newStationIndex >= 0) {
    const apparatusesPayload = newManageStationsPayload.create[newStationIndex].apparatusesPayload || emptyPayload;
    addPositionChangesToApparatusPayload(shiftDuration, action, apparatusesPayload, positionApparatus, position);

    newManageStationsPayload.create[newStationIndex] = {
      ...newManageStationsPayload.create[newStationIndex],
      apparatusesPayload: apparatusesPayload,
    };
  }
  // the new position is from an existing station that is already in the payload to be updated
  else if (stationToUpdateIndex >= 0) {
    const stationPayload = newManageStationsPayload.update[stationToUpdateIndex].station;
    const apparatusesPayload = stationPayload.apparatusesPayload || emptyPayload;
    addPositionChangesToApparatusPayload(shiftDuration, action, apparatusesPayload, positionApparatus, position);

    newManageStationsPayload.update[stationToUpdateIndex] = {
      ...newManageStationsPayload.update[stationToUpdateIndex],
      station: {
        ...stationPayload,
        apparatusesPayload: apparatusesPayload,
      },
    };
  }
  // the new position is from an existing station that is not in the payload yet
  // we need to add a station update object with the new position
  else {
    const apparatusesPayload = { ...emptyPayload };
    addPositionChangesToApparatusPayload(shiftDuration, action, apparatusesPayload, positionApparatus, position);

    newManageStationsPayload.update = [
      ...newManageStationsPayload.update,
      {
        id: apparatusStation.stationId,
        station: {
          name: apparatusStation.stationName,
          address: apparatusStation.address || buildAddress(),
          certificationRequirements: apparatusStation.certificationRequirements,
          apparatusesPayload: apparatusesPayload,
        },
      },
    ];
  }
  return newManageStationsPayload;
};

export const addCreatePositionToApparatusPayload = (
  shiftDuration: RosterShiftDuration,
  apparatusesPayload: ManageApparatusesPayload,
  positionApparatus: RosterApparatus,
  position: RosterPosition,
) => {
  const positionPayload: PositionPayload = {
    actingRankId: position.rank.id,
    startTime: 0,
    endTime: 24 * 60,
    certificationIds: position.certifications.map((cert) => cert.id),
    id: position.id,
    isTemporary: position.isTemporary,
    employees: position.employees.map((positionEmployee) => ({
      employeeId: positionEmployee.id,
      activeId: positionEmployee.activeId,
      startTime: differenceInUTCMinutes(positionEmployee.startDateTime, shiftDuration.startTime),
      endTime: differenceInUTCMinutes(positionEmployee.endDateTime, shiftDuration.startTime),
    })),
  };

  const newApparatusIndex = apparatusesPayload.create.findIndex((app) => app.name === positionApparatus.name);
  const apparatusToUpdateIndex = apparatusesPayload.update.findIndex((appToUpdate) => appToUpdate.id === positionApparatus.id);

  // the position is being added to a new or moved apparatus
  if (newApparatusIndex >= 0) {
    const positionsPayload = apparatusesPayload.create[newApparatusIndex].positionsPayload || emptyPayload;

    apparatusesPayload.create[newApparatusIndex].positionsPayload = {
      ...positionsPayload,
      create: [...positionsPayload.create, positionPayload],
    };
    // the new position is from an existing apparatus that is already in the payload to be updated
  } else if (apparatusToUpdateIndex >= 0) {
    const positionsPayload = apparatusesPayload.update[apparatusToUpdateIndex].apparatus.positionsPayload || emptyPayload;
    apparatusesPayload.update[apparatusToUpdateIndex].apparatus.positionsPayload = {
      ...positionsPayload,
      create: [...positionsPayload.create, positionPayload],
    };
    // the new position is from an existing apparatus that is not in the payload yet
    // we need to add an apparatus update object with the new position
  } else {
    apparatusesPayload.update = [
      ...apparatusesPayload.update,
      {
        id: positionApparatus.id,
        apparatus: {
          name: positionApparatus.name,
          certificationRequirements: positionApparatus.certificationRequirements,
          isForShiftLeader: positionApparatus.isForShiftLeader,
          positionsPayload: {
            create: [positionPayload],
            update: [],
            delete: [],
          },
        },
      },
    ];
  }
};

export const addUpdatePositionToApparatusPayload = (
  shiftDuration: RosterShiftDuration,
  apparatusesPayload: ManageApparatusesPayload,
  positionApparatus: RosterApparatus,
  position: RosterPosition,
) => {
  const positionPayload: PositionPayload = {
    actingRankId: position.rank.id,
    startTime: 0,
    endTime: 24 * 60,
    certificationIds: position.certifications.map((cert) => cert.id),
    id: position.id,
    isTemporary: position.isTemporary,
    employees: position.employees.map((positionEmployee) => ({
      employeeId: positionEmployee.id,
      activeId: positionEmployee.activeId,
      startTime: differenceInUTCMinutes(positionEmployee.startDateTime, shiftDuration.startTime),
      endTime: differenceInUTCMinutes(positionEmployee.endDateTime, shiftDuration.startTime),
    })),
  };
  const newApparatusIndex = apparatusesPayload.create.findIndex((app) => app.name === positionApparatus.name);
  const apparatusToUpdateIndex = apparatusesPayload.update.findIndex((appToUpdate) => appToUpdate.id === positionApparatus.id);

  // the position we are updating is from a new or moved apparatus
  if (newApparatusIndex >= 0) {
    const positionsPayload = apparatusesPayload.create[newApparatusIndex].positionsPayload || emptyPayload;
    const newPositionIndex = positionsPayload.create.findIndex((pos) => pos.id === position.id);

    // the position we are updating is new
    if (newPositionIndex >= 0) {
      positionsPayload.create[newPositionIndex] = positionPayload;
    }
    // the apparatus we are in was moved and the position we are editing belongs to it
    // we need to send its payload in the create array and also a delete, since the position needs to be copied
    else if (apparatusesPayload.create[newApparatusIndex].movedApparatusId) {
      positionsPayload.create = [...positionsPayload.create, { ...positionPayload, employees: [] }];
      positionsPayload.delete = [...positionsPayload.delete, position.id];
    }
    apparatusesPayload.create[newApparatusIndex] = { ...apparatusesPayload.create[newApparatusIndex], positionsPayload };
  }
  // the position we are updating is from an existing apparatus that is already in the payload to be updated
  else if (apparatusToUpdateIndex >= 0) {
    let positionsPayload = apparatusesPayload.update[apparatusToUpdateIndex].apparatus.positionsPayload || emptyPayload;

    const newPositionIndex = positionsPayload.create.findIndex((pos) => pos.id === position.id);
    const positionToUpdateIndex = positionsPayload.update.findIndex((objectToUpdate) => objectToUpdate.id === position.id);
    // position is new
    if (newPositionIndex >= 0) {
      positionsPayload.create[newPositionIndex] = positionPayload;
    }
    // position was updated before and it's in the payload already
    else if (positionToUpdateIndex >= 0) {
      positionsPayload.update[positionToUpdateIndex] = {
        ...positionsPayload.update[positionToUpdateIndex],
        position: positionPayload,
      };
    }
    // position is being edited for the first time so we need to add it to the payload
    else {
      positionsPayload = {
        ...positionsPayload,
        update: [
          ...positionsPayload.update,
          {
            id: position.id,
            position: positionPayload,
          },
        ],
      };
    }

    apparatusesPayload.update[apparatusToUpdateIndex].apparatus.positionsPayload = positionsPayload;
  }
  // the position we are updating is from an existing apparatus that is not in the payload yet
  // we need to add an apparatus update object with the new position
  else {
    apparatusesPayload.update = [
      ...apparatusesPayload.update,
      {
        id: positionApparatus.id,
        apparatus: {
          name: positionApparatus.name,
          certificationRequirements: positionApparatus.certificationRequirements,
          isForShiftLeader: positionApparatus.isForShiftLeader,
          positionsPayload: {
            create: [],
            update: [{ id: position.id, position: positionPayload }],
            delete: [],
          },
        },
      },
    ];
  }
};

export const addDeletePositionToApparatusPayload = (
  apparatusesPayload: ManageApparatusesPayload,
  positionApparatus: RosterApparatus,
  position: RosterPosition,
) => {
  const newApparatusIndex = apparatusesPayload.create.findIndex((app) => app.name === positionApparatus.name);
  const apparatusToUpdateIndex = apparatusesPayload.update.findIndex((appToUpdate) => appToUpdate.id === positionApparatus.id);

  // the position we are deleting is from a new or moved apparatus
  if (newApparatusIndex >= 0) {
    let positionsPayload = apparatusesPayload.create[newApparatusIndex].positionsPayload || emptyPayload;
    const isMovedApparatus = !!apparatusesPayload.create[newApparatusIndex].movedApparatusId;
    positionsPayload = addDeletePositionToPositionsPayload(positionsPayload, position, true, isMovedApparatus);

    apparatusesPayload.create[newApparatusIndex].positionsPayload = positionsPayload;
  }
  // the position we are deleting is from an existing apparatus that is already in the payload to be updated
  else if (apparatusToUpdateIndex >= 0) {
    let positionsPayload = apparatusesPayload.update[apparatusToUpdateIndex].apparatus.positionsPayload || emptyPayload;
    positionsPayload = addDeletePositionToPositionsPayload(positionsPayload, position, false, false);

    apparatusesPayload.update[apparatusToUpdateIndex].apparatus.positionsPayload = positionsPayload;
  }
  // the position we are deleting is from an existing apparatus that is not in the payload yet
  // we need to add the position to the apparatus delete array
  else {
    apparatusesPayload.update = [
      ...apparatusesPayload.update,
      {
        id: positionApparatus.id,
        apparatus: {
          name: positionApparatus.name,
          certificationRequirements: positionApparatus.certificationRequirements,
          isForShiftLeader: positionApparatus.isForShiftLeader,
          positionsPayload: {
            create: [],
            update: [],
            delete: [position.id],
          },
        },
      },
    ];
  }
};

export const addDeletePositionToPositionsPayload = (
  positionsPayload: ManagePositionsPayload,
  position: RosterPosition,
  belongsToNewApparatus: boolean,
  belongsToMovedApparatus: boolean,
) => {
  // make sure to not send any create for the same position we are deleting
  let newDeletes = [...positionsPayload.delete];
  let newCreates = [...positionsPayload.create];
  let newUpdates = [...positionsPayload.update];

  // avoid sending a create for a position that is being removed
  newCreates = newCreates.filter((pos) => pos.id !== position.id);

  if (!belongsToNewApparatus || belongsToMovedApparatus) {
    newDeletes = [...newDeletes, position.id];
  }
  // avoid sending updates for a position that is being removed
  newUpdates = newUpdates.filter((objToUpdate) => objToUpdate.id !== position.id);

  return {
    create: newCreates,
    delete: newDeletes,
    update: newUpdates,
  };
};

export const getUpdatedPositionIds = (manageStationsPayload: ManageStationsPayload) => {
  const updatedPositions = manageStationsPayload.update.map((stationToUpdate) =>
    stationToUpdate.station.apparatusesPayload?.update.map((appToUpdate) =>
      appToUpdate.apparatus.positionsPayload?.update.map((posToUpdate) => posToUpdate.id),
    ),
  );
  return updatedPositions.flat(2);
};
