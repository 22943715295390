import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { parseISO } from 'date-fns';
import { Button } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { useIOSPaddingBottom } from '../../../../context/DeviceInfoContext';
import { AvailableCompTime, CompTimeRequestDto } from './utils';

interface AccrueCompTimeFooterProps {
  setOpenResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  availableCompTime: AvailableCompTime;
  duration: number;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AccrueCompTimeFooter = ({
  setOpenResponseModal,
  availableCompTime,
  duration,
  setMessage,
  setError,
}: AccrueCompTimeFooterProps) => {
  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  const sendRequest = async () => {
    try {
      const compTimeRequestDto: CompTimeRequestDto = {
        overtimeDate: parseISO(availableCompTime.overtimeDate),
        overtimeCode: availableCompTime.overtimeCode,
        requestedHours: duration,
        timeEntryId: availableCompTime.timeEntryId,
      };
      const response = await client.post('request/comp-time-request/', compTimeRequestDto);

      if (response.status === 201) {
        setMessage('Your accrue comp time request has been succesfully sent');
        setError(false);
      }
    } catch (err: unknown) {
      captureException(err);
      setMessage('We were unable to process your accrue comp time request. Please try again later.');
      setError(true);
    } finally {
      setOpenResponseModal(true);
    }
  };
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: iosWebViewPaddingBottom,
        width: '100%',
        border: '1px solid #DEE3ED',
        borderBottom: 0,
        boxShadow: '0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
      }}
    >
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          width: '100%',
        })}
      >
        <Button
          data-cy="submit-accrue-comp-time-button"
          size="large"
          onClick={sendRequest}
          variant="contained"
          sx={{ width: '100%' }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};
