import { Box, Checkbox, DialogTitle, FormControlLabel, TextField, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { HornIcon24, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { upsertApparatus } from '../../helpers/crudApparatus';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { IconCircle, ShiftPlanDialogActions } from '../ShiftPlanDialog';
import { CertificationRequirementCounter, makeCertificationRequirements } from './CertificationRequirementCounter';

export const UpsertApparatusForm = () => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { stations, setStations, selectedStruct, setSelectedAction } = useShiftPlanContext();
  const { station: selectedStation, apparatus: selectedApparatus } = selectedStruct;

  const [apparatusName, setApparatusName] = useState(selectedApparatus?.name || '');
  const [isForShiftLeader, setIsForShiftLeader] = useState(selectedApparatus?.isForShiftLeader || false);
  const [certificationRequirements, setCertificationRequirements] = useState(() => {
    return makeCertificationRequirements(departmentInfoState.departmentInfo, selectedApparatus?.certificationRequirements);
  });

  const existingApparatusNames = useMemo(() => {
    const names = Array.from(stations.values()).flatMap((s) => s.apparatuses.map((a) => a.name.toLowerCase()));
    return new Set(names);
  }, [stations]);

  const otherShiftLeaderApparatus = useMemo(() => {
    for (const station of stations.values()) {
      for (const apparatus of station.apparatuses) {
        if (apparatus.isForShiftLeader && apparatus.id !== selectedApparatus?.id) {
          return apparatus;
        }
      }
    }
  }, [stations, selectedApparatus]);

  const [error, setError] = useState({ duplicatedApparatus: false });
  const isSaveDisabled = !apparatusName || Object.values(error).some(Boolean);

  const onSave = () => {
    const newApparatusData = {
      name: apparatusName,
      isForShiftLeader,
      certificationRequirements,
    };
    setStations(upsertApparatus(stations, selectedStruct, newApparatusData));
    setSelectedAction(null);
  };

  return (
    <>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 3, pt: 3, pb: 2 }}>
        {!selectedApparatus && (
          <IconCircle sx={{ color: theme.palette.stationIndigo[600], mb: 2 }}>
            <HornIcon24 />
          </IconCircle>
        )}
        <Box sx={{ typography: 'bodyXLSemibold' }}>{selectedApparatus ? 'Edit apparatus' : 'Add apparatus'}</Box>
        {!selectedApparatus && <Box sx={{ mt: 0.5, typography: 'bodyMRegular' }}>{`to ${selectedStation?.stationName}`}</Box>}
      </DialogTitle>
      <Box sx={{ flex: 1, minHeight: '1px', overflowY: 'auto', px: 3, pt: 1, width: '495px', maxWidth: '100%' }}>
        <TextField
          label="Name"
          value={apparatusName}
          required={true}
          error={error.duplicatedApparatus}
          helperText={error.duplicatedApparatus ? 'This apparatus already exists, please choose another name.' : ''}
          size="small"
          sx={{ width: '100%', '& .MuiOutlinedInput-input': { p: '8.5px 14px' } }}
          onChange={(event) => {
            setApparatusName(event.target.value);
            if (existingApparatusNames.has(event.target.value.toLowerCase())) {
              setError({ ...error, duplicatedApparatus: true });
            } else if (error.duplicatedApparatus) {
              setError({ ...error, duplicatedApparatus: false });
            }
          }}
        />
        <FormControlLabel
          label="Is for shift leader"
          sx={{ m: theme.spacing(1, 0, 0, '-9px') }}
          control={
            <Checkbox
              checked={isForShiftLeader}
              disabled={!!otherShiftLeaderApparatus}
              onChange={(event) => setIsForShiftLeader(event.target.checked)}
            />
          }
        />
        {otherShiftLeaderApparatus && (
          <Box sx={{ color: theme.palette.stationGray[500], typography: 'bodyXSRegular' }}>
            {`${otherShiftLeaderApparatus.name} is selected as the shift leader apparatus, to be able to choose a new one first uncheck it.`}
          </Box>
        )}
        <Box sx={{ color: theme.palette.stationGray[700], my: 2.5, typography: 'eyebrow' }}>Apparatus level requirements</Box>
        {certificationRequirements.map((certReq) => (
          <CertificationRequirementCounter
            key={certReq.id}
            certificationRequirement={certReq}
            setCertificationRequirements={setCertificationRequirements}
          />
        ))}
      </Box>
      <ShiftPlanDialogActions isSaveDisabled={isSaveDisabled} onSave={onSave} />
    </>
  );
};
