import { Box, Typography } from '@mui/material';
import { addMinutes, format, parseISO } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ErrorPage, theme } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { ROUTES } from '../../../../core/Routes';
import { TimeOffRequestDTO } from '../types';

interface SignUpPageProps {
  data: TimeOffRequestDTO;
  forceRefetch: () => void;
}

export const SignUpPage = ({ data, forceRefetch }: SignUpPageProps) => {
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const onclickSignUp = async () => {
    if (!data) {
      return;
    }
    try {
      await client.patch(`/request/time-off-request/${data.id}/verify/`);
      forceRefetch();
      navigate(`${ROUTES.CALENDAR.fullRoute}/?display=month&date=${format(parseISO(data.shiftDate.date), 'yyyy-MM-dd')}`);
    } catch (error) {
      isAxiosError(error);
      setIsError(true);
    }
  };

  const onclickCancel = async () => {
    if (!data) {
      return;
    }
    try {
      await client.patch(`/request/time-off-request/${data.id}/reject-verification/`);
      forceRefetch();
      navigate(`${ROUTES.CALENDAR.fullRoute}/?display=month&date=${format(parseISO(data.shiftDate.date), 'yyyy-MM-dd')}`);
    } catch (error) {
      isAxiosError(error);
      setIsError(true);
    }
  };

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Box sx={{ display: 'flex', position: 'relative', justifyContent: 'center', py: theme.spacing(2) }}>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          display: 'flex',
          width: '100%',
          minWidth: '350px',
          maxWidth: '500px',
          padding: theme.spacing(3),
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: theme.spacing(2),
          borderRadius: theme.spacing(3),
        }}
      >
        <Box
          sx={(theme) => ({
            typography: 'h6',
            color: theme.palette.common.black,
          })}
        >
          You are verifying your time off request for given date
        </Box>
        <Typography variant="body1">
          From {format(parseISO(data.shiftDate.date), 'dd MMM')}, {data.startTimeStr} to{' '}
          {format(addMinutes(parseISO(`${data.shiftDate.date}T${data.startTimeStr}`), data.duration), "dd MMM',' HH:mm")}{' '}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            pt: theme.spacing(2),
            alignItems: 'center',
            gap: '149px',
            alignSelf: 'stretch',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(2),
            }}
          >
            <Button color="error" variant="outlined" size="large" onClick={onclickCancel}>
              Reject
            </Button>
            <Button variant="contained" size="large" onClick={onclickSignUp}>
              Verify
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
